// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBsNEFQX5WaXpr7NJP3bPkeVj9MtNV9QV4",
  authDomain: "meritus-5bbfa.firebaseapp.com",
  projectId: "meritus-5bbfa",
  storageBucket: "meritus-5bbfa.appspot.com",
  messagingSenderId: "290396280273",
  appId: "1:290396280273:web:d271c5b3e7a51895177cf0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app