// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-img-wrapper{
    width: 100%;
    height: 200px;
    border: 2px solid #c3c5f8 !important;
    border-radius: 15px !important;
    padding: 20px;
}

.blog-img-wrapper img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}`, "",{"version":3,"sources":["webpack://./src/pages/Blog/editBlog/EditBlog.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,oCAAoC;IACpC,8BAA8B;IAC9B,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".blog-img-wrapper{\n    width: 100%;\n    height: 200px;\n    border: 2px solid #c3c5f8 !important;\n    border-radius: 15px !important;\n    padding: 20px;\n}\n\n.blog-img-wrapper img{\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
