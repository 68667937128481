import React, { useEffect, useState } from 'react';
import './SchoolTable.css';
import { DataGrid } from '@mui/x-data-grid';
import { Visibility } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, getAllUsers } from '../../redux/apiCalls';
import CircleLoader from '../circleLoader/CircleLoader';

const SchoolTable = (props) => {

    const dispatch = useDispatch();

    useEffect(() => {
        getAllUsers(dispatch);
    }, [dispatch]);

    const isFetching = useSelector((state) => state.allUsers.isFetching);
    const users = useSelector((state) => state.allUsers.users);
    const schools = users && users.filter((item) => item.isSchoolPrincipal && item.isMeritusSchool);

    const schoolsWithIndex = schools?.map((school, index) => ({
        ...school,
        index: index + 1, // Add 1 to the index to start from 1 instead of 0
    }));
    const currentFranchise = users && users.find((item) => item._id === props.franchiseId);

    const franchiseSchool = users && users.filter((item) => item.franchiseId === props.franchiseId && item.isSchoolPrincipal && item.isFranchiseSchool);


    const columns = [
        { field: 'index', headerName: '#', width: 50 },
        {
            field: 'schoolName',
            headerName: 'School',
            width: 200,
            editable: false,
        },
        {
            field: 'firstName',
            headerName: 'Principal Name',
            width: 150,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 200,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 150,
        },
        {
            field: 'View',
            headerName: 'View School',
            sortable: false,
            width: 150,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={'/view-school/' + params.id}>
                            <button className='btn btn-primary viewSchoolBtn'>View <Visibility /> </button>
                        </Link>
                    </>
                )
            }
        },
        {
            field: 'edit',
            headerName: 'Edit',
            // sortable: false,
            width: 100,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={'/edit-school/' + params.id}>
                            <button className='btn btn-primary studentListEdit'>Edit</button>
                        </Link>
                    </>
                )
            }
        },
    ];

    if (isFetching) {
        return (
            <CircleLoader />
        )
    }

    return (
        <div className='studentTableWrapper py-5'>
            <div className="row">
                <div className="col-12">
                    <div className="tableButtonWrapper d-flex justify-content-between mb-3">
                        <h2>Schools List</h2>
                        <Link to={props.isFranchise ? `/add-franchise-school/${props.franchiseId}` : '/add-school'}>
                            <button className='custom-btn purple-bg'>New School <AddCircleOutlineOutlinedIcon className='newStudentIcon' /></button>
                        </Link>

                    </div>
                </div>
            </div>

            {
                schools && (
                    <DataGrid
                        rows={schoolsWithIndex}
                        getRowId={(rows) => rows._id}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[10]}
                        checkboxSelection={false}
                        disableRowSelectionOnClick
                    />
                )
            }

        </div>
    )
}

export default SchoolTable
