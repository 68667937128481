import React from 'react';
import './NewSchoolStudent.css'
import NewStudent from '../../../../components/newStudent/NewStudent';
import { useLocation } from 'react-router-dom';

const NewSchoolStudent = () => {

    const location = useLocation();

    const schoolId = location.pathname.split('/')[2]

  return (
    <NewStudent isSchool={true} schoolId = {schoolId}  />
  )
}

export default NewSchoolStudent
