import React, { useEffect, useState } from 'react';
import './ViewUser.css'
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MEDIA_URL, isUrl } from '../../../config';
import { getAllUsers, getProducts } from '../../../redux/apiCalls';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import { userRequest } from '../../../requestMethod';
import Loader from '../../../global/Loader';
import CircleLoader from '../../../components/circleLoader/CircleLoader';
import MiniLoader from '../../../global/MiniLoader';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { assignCourse, removeAssignedCourse } from '../../../redux/orderRedux';

const ViewUser = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        getProducts(dispatch)
    }, [dispatch]);

    useEffect(() => {
        getAllUsers(dispatch);
    }, [dispatch]);

    const location = useLocation();
    const currentUserId = location.pathname.split('/')[2];
    const userDetails = useSelector((state) => state.allUsers?.users?.find((user) => user._id === currentUserId));

    const isFetching = useSelector((state) => state.products.isFetching);
    const isUserFetching = useSelector((state) => state.allUsers.isFetching);
    const products = useSelector((state) => state.products?.products?.filter((item) => !item.isRobotixProduct));
    const orders = useSelector((state) => state.orders?.orders);

    // const orders = useSelector((state) => state.orders?.orders?.filter((order) => order.isItFromSpecificAccess && order?.userId?._id === currentUserId));

    // const assignedCourse = orders
    const [assignedCourse, setAssignedCourse] = useState([]);



    const [progressOfAllCourses, setProgressOfAllCourses] = useState(null);
    const [isProgressOfAllCoursesFetching, setIsProgressOfAllCoursesFetching] = useState(false);
    const [selectedProductsError, setSelectedProductsError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [isLoaderActive, setIsLoaderActive] = useState(false);
    const [showConfirmButton, setShowConfirmButton] = useState(false);
    const [confirmButtons, setConfirmButtons] = useState({});
    const [section, setSection] = useState('account');


    const handleSectionToShow = (section) => {
        setSection(section);
        setSuccess(false);
        setError(false);
    }


    // useEffect(() => {
    //     const allAssignedCourse = orders?.filter((order) => order.isItFromSpecificAccess && order?.userId?._id === currentUserId);
    //     setAssignedCourse(allAssignedCourse);
    // },[orders, currentUserId]);

    const updateAssignCourseInState = (orders) => {
        // Step 1: Filter the orders based on specific criteria
        const allAssignedCourse = orders?.filter((order) => {
            return order.isItFromSpecificAccess && order?.userId?._id === currentUserId;
        });


        // Step 2 & 3: Extract and flatten products, adding order._id to each item
        const allProductsWithOrderId = allAssignedCourse?.flatMap(order =>
            order.products.map(product => ({
                ...product,
                orderId: order._id
            }))
        );
        // Step 4: Set the result to your state variable
        setAssignedCourse(allProductsWithOrderId);
    }

    useEffect(() => {
        // const allAssignedCourse = orders?.filter((order) => {
        //     return order.isItFromSpecificAccess && order?.userId?._id === currentUserId;
        // });
        // setAssignedCourse(allAssignedCourse);

        // Step 1: Filter the orders based on specific criteria
        const allAssignedCourse = orders?.filter((order) => {
            return order.isItFromSpecificAccess && order?.userId?._id === currentUserId;
        });


        // Step 2 & 3: Extract and flatten products, adding order._id to each item
        const allProductsWithOrderId = allAssignedCourse?.flatMap(order =>
            order.products.map(product => ({
                ...product,
                orderId: order._id
            }))
        );
        // Step 4: Set the result to your state variable
        setAssignedCourse(allProductsWithOrderId);
    }, [orders, currentUserId]);

    useEffect(() => {
    }, [assignedCourse]);



    const handleShowConfirmButton = (productId) => {
        setConfirmButtons((prev) => ({ ...prev, [productId]: true }));
    };

    const handleHideConfirmButton = (productId) => {
        setConfirmButtons((prev) => ({ ...prev, [productId]: false }));
    };


    // useEffect(() => {
    //     setAccountSection(true);
    //     setActiveClassForAcc(true);
    // }, []);

    const getAllCoursesProgressOfSpecificUser = async (id) => {
        try {
            setIsProgressOfAllCoursesFetching(true)
            const res = await userRequest.get(`/update-course-progress/${id}/progress`);
            if (res?.status === 200) {
                setIsProgressOfAllCoursesFetching(false)
                const fetchedData = res.data
                setProgressOfAllCourses(fetchedData);
            }
        } catch (error) {
            setIsProgressOfAllCoursesFetching(false)
        }
    };

    useEffect(() => {
        getAllCoursesProgressOfSpecificUser(currentUserId)
    }, [currentUserId]);

    const [selectedProducts, setSelectedProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (e) => {
        // Handle the change of the search input
        setSearchTerm(e.target.value);
    };

    const handleProductToggle = (selectedProductId) => {
        setSelectedProducts((prevSelectedProducts) => {
            if (prevSelectedProducts?.some((product) => product?.productId?._id === selectedProductId._id)) {
                // Remove the product if it's already selected
                return prevSelectedProducts.filter((product) => product.productId._id !== selectedProductId._id);
            } else {
                // Add the product if it's not selected
                return [...prevSelectedProducts, { productId: selectedProductId }];
            }
        });
    };

    useEffect(() => {
    }, [selectedProducts])
    // const handleProductToggle = (selectedProductId) => {
    //     setSelectedProducts((prevSelectedProducts) => {
    //         if (prevSelectedProducts.includes(selectedProductId)) {
    //             // Remove the product if it's already selected
    //             return prevSelectedProducts.filter((id) => id !== selectedProductId);
    //         } else {
    //             // Add the product if it's not selected
    //             return [...prevSelectedProducts, {productId : selectedProductId}];
    //         }
    //     });
    // };



    // Filter products based on the search term
    const filteredProducts = products?.filter(product =>
        product.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const updateSpecificCourses = async (courses) => {
        try {
            const res = await userRequest.post(`/user/assign-course`, courses);
            if (res?.status === 200) {
                setIsLoaderActive(false);
                setSuccess(true);
                setSuccessMessage("Course has been assigned to this user");
                dispatch(assignCourse(res.data));
                updateAssignCourseInState(orders)
            }
        } catch (error) {
            setIsLoaderActive(false)
            setSuccess(false);
            setSuccessMessage("");
            setError(true);
            setErrorMessage("Facing error with assinging the course");
        }
    }


    const handleSubmitSpecificCourse = () => {
        setIsLoaderActive(true);
        setSuccess(false);
        setError(false);
        if (selectedProducts?.length < 1) {
            setIsLoaderActive(false)
            setSelectedProductsError(true)
        } else {
            setSelectedProductsError(false)
            updateSpecificCourses({ userId: currentUserId, products: selectedProducts, isItFromSpecificAccess: true, paymentStatus: "success" })
            // updateSpecificCourses({userId :currentUserId, specificCourseAccess : selectedProducts})
        }
    }

    const handleRemoveCourseAccess = async (orderId, productId) => {
        dispatch(removeAssignedCourse({ orderId, productId }))
        setIsLoaderActive(true);
        setSuccess(false);
        setError(false);
        try {
            const res = await userRequest.delete(`/user/remove-assigned-course/${orderId}/${productId}`);
            if (res?.status == 200) {
                setIsLoaderActive(false);
                setSuccess(true);
                setSuccessMessage("Course access has been removed successfully");
                setConfirmButtons({})
            }
        } catch (error) {
            setIsLoaderActive(false)
            setSuccess(false);
            setSuccessMessage("");
            setError(true);
            setErrorMessage("Facing error with removing the course access");
        }
    }

    if (isUserFetching || isProgressOfAllCoursesFetching) {
        return (
            <div className='loader-wrapper'>
                <CircleLoader />
            </div>
        )
    }

    return (
        <div className='container'>
            <div className="row">
                <div className="col-10 mx-auto">
                    <div className="userWrapper">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="user-left-wrapper">
                                    <div className="viewUserImgWrapper">
                                        {
                                            userDetails?.profileImg ? (
                                                <img src={MEDIA_URL + userDetails?.profileImg} alt="" />
                                            ) : (
                                                <div className="view-img-not-available">
                                                    <h4 className='text-center'> Image not available</h4>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="viewUserTabWrapper">
                                        <ul>
                                            <li className={section == 'account' ? 'active' : ""} onClick={() => handleSectionToShow('account')}> <AccountBoxIcon /> Account Details</li>
                                            <li className={section == 'address' ? 'active' : ""} onClick={() => handleSectionToShow('address')}><LocalShippingIcon /> Shipping Address</li>
                                            <li className={section == 'specificCourse' ? 'active' : ""} onClick={() => handleSectionToShow('specificCourse')}><AssignmentIcon /> Assign a specific course</li>
                                            <li className={section == 'assignedCourse' ? 'active' : ""} onClick={() => handleSectionToShow('assignedCourse')}><AssignmentTurnedInIcon /> Assigned courses</li>
                                            <li className={section == 'progress' ? 'active' : ""} onClick={() => handleSectionToShow('progress')}><DataUsageIcon /> Progress of courses</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8">
                                <div className="user-right-wrapper">
                                    {
                                        section == 'account' && (
                                            <>
                                                <div className="viewUserHeadingWrapper">
                                                    <h2>Account Details</h2>
                                                </div>
                                                <div className="viewUserAccountWrapper">
                                                    <div className="viewUserInfo">
                                                        <p className='viewUserTitle'>Name</p>
                                                        <p className='viewUserDetail'>{userDetails?.firstName} {userDetails?.lastName}</p>
                                                    </div>
                                                    <div className="viewUserInfo">
                                                        <p className='viewUserTitle'>Email</p>
                                                        <p className='viewUserDetail'>{userDetails?.email}</p>
                                                    </div>
                                                    <div className="viewUserInfo">
                                                        <p className='viewUserTitle'>Mobile Number</p>
                                                        <p className='viewUserDetail'>{userDetails?.phone}</p>
                                                    </div>
                                                    <div className="viewUserInfo">
                                                        <p className='viewUserTitle'>Date of Birth</p>
                                                        <p className='viewUserDetail'>{userDetails?.dateOfBirth}</p>
                                                    </div>
                                                    <div className="viewUserInfo">
                                                        <p className='viewUserTitle'>Refer Code</p>
                                                        <p className='viewUserDetail'>{userDetails?.referCode ? userDetails?.referCode : "Not Available"}</p>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                    {
                                        section == 'address' && (
                                            <>
                                                <div className="viewUserHeadingWrapper">
                                                    <h2>Shipping Address</h2>
                                                </div>
                                                <div className="viewUserAccountWrapper">
                                                    <div className="viewUserInfo">
                                                        <p className='viewUserTitle'>Name</p>
                                                        <p className='viewUserDetail'>Moin Khan</p>
                                                    </div>
                                                    <div className="viewUserInfo">
                                                        <p className='viewUserTitle'>Flat No.</p>
                                                        <p className='viewUserDetail'>{userDetails?.address?.flatNo ? userDetails?.address?.flatNo : "Not available"}</p>
                                                    </div>
                                                    <div className="viewUserInfo">
                                                        <p className='viewUserTitle'>Area</p>
                                                        <p className='viewUserDetail'>{userDetails?.address?.area ? userDetails?.address?.area : "Not available"}</p>
                                                    </div>
                                                    <div className="viewUserInfo">
                                                        <p className='viewUserTitle'>Landmark</p>
                                                        <p className='viewUserDetail'>{userDetails?.address?.landmark ? userDetails?.address?.landmark : "Not available"}</p>
                                                    </div>
                                                    <div className="viewUserInfo">
                                                        <p className='viewUserTitle'>City</p>
                                                        <p className='viewUserDetail'>{userDetails?.address?.city ? userDetails?.address?.city : "Not available"}</p>
                                                    </div>
                                                    <div className="viewUserInfo">
                                                        <p className='viewUserTitle'>Pin Code</p>
                                                        <p className='viewUserDetail'>{userDetails?.address?.pincode ? userDetails?.address?.pincode : "Not available"}</p>
                                                    </div>
                                                    <div className="viewUserInfo">
                                                        <p className='viewUserTitle'>State</p>
                                                        <p className='viewUserDetail'>{userDetails?.address?.state ? userDetails?.address?.state : "Not available"}</p>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        section == 'specificCourse' && (
                                            <>
                                                <div className="viewUserHeadingWrapper">
                                                    <h2>Select any course</h2>
                                                </div>
                                                <div className="row">
                                                    {
                                                        isFetching ? (
                                                            <div className="inner-loader-wrapper">
                                                                <MiniLoader />
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div className="col-12">
                                                                    <div className="discountInputFieldWrapper">
                                                                        <label>Select Product</label>
                                                                        <div className="dicountInfoWrapper">
                                                                            <input type="text" placeholder='Search here...' value={searchTerm}
                                                                                onChange={handleSearchChange} />
                                                                            <div className="product-list-wrapper">
                                                                                {filteredProducts.map((product, index) => {
                                                                                    return (
                                                                                        <button className={`product-option-button ${selectedProducts?.length > 0 && selectedProducts?.some((item) => item?.productId?._id === product?._id) ? 'selected' : ''}`}
                                                                                            key={index} onClick={() => handleProductToggle(product)}>
                                                                                            <div className="product-option-wrapper pr-3">
                                                                                                <div className="option-img-wrapper">
                                                                                                    {
                                                                                                        isUrl(product?.productImg) ? (
                                                                                                            <img src={product.productImg} alt='img' />
                                                                                                        ) : (
                                                                                                            <img src={MEDIA_URL + product.productImg} alt='img' />
                                                                                                        )
                                                                                                    }

                                                                                                </div>
                                                                                                <p>{product.title}</p>
                                                                                                <span className={`${product.status === true ? "green-bg" : "red-bg"} product-status`}>{product.status === true ? "active" : "draft"}</span>
                                                                                            </div>
                                                                                        </button>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                            {
                                                                                selectedProductsError && (
                                                                                    <span className='error-text mt-2'>Please select at least one product</span>
                                                                                )
                                                                            }
                                                                            {
                                                                                success && (
                                                                                    <div className="alert alert-success mt-2">
                                                                                        {successMessage}
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            {
                                                                                error && (
                                                                                    <div className="alert alert-danger mt-2">
                                                                                        {errorMessage}
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 mt-3">
                                                                    <div className="submit-btn-wrapper">
                                                                        <button type="submit" className={`${isLoaderActive ? 'button-loader load' : 'custom-btn purple-bg'}`} onClick={handleSubmitSpecificCourse}><span>Submit</span></button>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </>
                                        )
                                    }
                                    {
                                        section == 'assignedCourse' && (
                                            <>
                                                <div className="viewUserHeadingWrapper">
                                                    <h2>Assigned courses</h2>
                                                </div>
                                                <div className="row">
                                                    {
                                                        isFetching ? (
                                                            <div className="inner-loader-wrapper">
                                                                <MiniLoader />
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div className="col-12">
                                                                    <div className="discountInputFieldWrapper">
                                                                        {/* <label>Select Product</label> */}
                                                                        <div className="dicountInfoWrapper">
                                                                            {/* <input type="text" placeholder='Search here...' value={searchTerm}
                                                                                onChange={handleSearchChange} /> */}
                                                                            <div className="product-list-wrapper">
                                                                                {assignedCourse?.length > 0 ? assignedCourse.map((product, index) => {
                                                                                    // return (
                                                                                    //     <>
                                                                                    //         {
                                                                                    //             order.products?.map((product, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <div className="product-option-wrapper">
                                                                                                <div className="product-option-left">
                                                                                                    <div className="option-img-wrapper">
                                                                                                        {
                                                                                                            isUrl(product?.productId?.productImg[0]) ? (
                                                                                                                <img src={product?.productId?.productImg[0]} alt='img' />
                                                                                                            ) : (
                                                                                                                <img src={MEDIA_URL + product?.productId?.productImg[0]} alt='img' />
                                                                                                            )
                                                                                                        }
                                                                                                        {/* <img src={product?.productId?.productImg?.length > 0 && product?.productId?.productImg[0]} alt='img' /> */}
                                                                                                    </div>
                                                                                                    <p>{product?.productId?.title}</p>
                                                                                                    <span className={`${product?.productId?.status === true ? "green-bg" : "red-bg"} product-status`}>{product?.productId?.status === true ? "active" : "draft"}</span>
                                                                                                </div>
                                                                                                <div className="product-option-right">
                                                                                                    {
                                                                                                        !confirmButtons[index] ? (
                                                                                                            // !confirmButtons[product.productId._id] ? (
                                                                                                            <button className='custom-btn purple-bg' onClick={() => handleShowConfirmButton(index)}>Remove</button>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                {
                                                                                                                    isLoaderActive ? (
                                                                                                                        <MiniLoader />
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            <button className='confirm-btn' onClick={() => handleRemoveCourseAccess(product.orderId, product.productId._id)}>
                                                                                                                                <CheckCircleIcon />
                                                                                                                            </button>
                                                                                                                            <button className='confirm-btn' onClick={() => handleHideConfirmButton(index)}>
                                                                                                                                {/* <button className='confirm-btn' onClick={() => handleHideConfirmButton(product.productId._id)}> */}
                                                                                                                                <CancelIcon />
                                                                                                                            </button>
                                                                                                                        </>
                                                                                                                    )
                                                                                                                }

                                                                                                            </>
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                    //             })
                                                                                    //         }
                                                                                    //     </>
                                                                                    // )
                                                                                }) : (
                                                                                    <h3>No course assigned to this user</h3>
                                                                                )}
                                                                                {/* {assignedCourse.map((order, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            {
                                                                                                order.products?.map((product, index) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <div className="product-option-wrapper">
                                                                                                                <div className="product-option-left">
                                                                                                                    <div className="option-img-wrapper">
                                                                                                                        <img src={product?.productId?.productImg?.length > 0 && product?.productId?.productImg[0]} alt='img' />
                                                                                                                    </div>
                                                                                                                    <p>{product?.productId?.title}</p>
                                                                                                                    <span className={`${product?.productId?.status === true ? "green-bg" : "red-bg"} product-status`}>{product?.productId?.status === true ? "active" : "draft"}</span>
                                                                                                                </div>
                                                                                                                <div className="product-option-right">
                                                                                                                    {
                                                                                                                        !confirmButtons[index] ? (
                                                                                                                            !confirmButtons[product.productId._id] ? (
                                                                                                                            <button className='custom-btn purple-bg' onClick={() => handleShowConfirmButton(index)}>Remove</button>
                                                                                                                        ) : (
                                                                                                                            <>
                                                                                                                                {
                                                                                                                                    isLoaderActive ? (
                                                                                                                                        <MiniLoader />
                                                                                                                                    ) : (
                                                                                                                                        <>
                                                                                                                                            <button className='confirm-btn' onClick={() => handleRemoveCourseAccess(order._id, product.productId._id)}>
                                                                                                                                                <CheckCircleIcon />
                                                                                                                                            </button>
                                                                                                                                            <button className='confirm-btn' onClick={() => handleHideConfirmButton(index)}>
                                                                                                                                                <button className='confirm-btn' onClick={() => handleHideConfirmButton(product.productId._id)}>
                                                                                                                                                <CancelIcon />
                                                                                                                                            </button>
                                                                                                                                        </>
                                                                                                                                    )
                                                                                                                                }

                                                                                                                            </>
                                                                                                                        )
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                })} */}
                                                                            </div>
                                                                            {
                                                                                success && (
                                                                                    <div className="alert alert-success mt-2">
                                                                                        {successMessage}
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            {
                                                                                error && (
                                                                                    <div className="alert alert-danger mt-2">
                                                                                        {errorMessage}
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-12 mt-3">
                                                                    <div className="submit-btn-wrapper">
                                                                        <button type="submit" className={`${isLoaderActive ? 'button-loader load' : 'custom-btn purple-bg'}`} onClick={handleSubmitSpecificCourse}><span>Submit</span></button>
                                                                    </div>
                                                                </div> */}
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        section == 'progress' && (
                                            <div className="row">
                                                <div className="col-12">
                                                    {
                                                        isProgressOfAllCoursesFetching ? (
                                                            <MiniLoader />
                                                        ) : (
                                                            <>
                                                                {
                                                                    progressOfAllCourses?.length > 0 ? progressOfAllCourses.map((courseOuter, courseIndex) => {
                                                                        return (
                                                                            <div key={courseIndex}>
                                                                                {
                                                                                    courseOuter?.chaptersProgress?.length > 0 ? courseOuter.chaptersProgress.map((progress, index) => {
                                                                                        if (progress) {
                                                                                            return (
                                                                                                <div className='progress-wrapper'>
                                                                                                    <div className="progress-title-wrapper">
                                                                                                        <p>{progress.title}</p>
                                                                                                        <span>{progress.completionPercentage}%</span>
                                                                                                    </div>
                                                                                                    <div class="progress">
                                                                                                        <div class="progress-bar" role="progressbar" style={{ width: `${progress.completionPercentage}%` }} aria-valuenow={progress.completionPercentage} aria-valuemin="0" aria-valuemax="100">{progress.completionPercentage}%</div>
                                                                                                    </div></div>
                                                                                            )
                                                                                        }

                                                                                    }) : (
                                                                                        <p>it is not a array {courseIndex}</p>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        )
                                                                    }
                                                                    ) : (
                                                                        <h3>User has not purchased any course yet</h3>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                {/* <div className="user-right-wrapper">
                                    {
                                        accountSection ? (
                                            <>
                                                <div className="viewUserHeadingWrapper">
                                                    <h2>Account Details</h2>
                                                </div>
                                                <div className="viewUserAccountWrapper">
                                                    <div className="viewUserInfo">
                                                        <p className='viewUserTitle'>Name</p>
                                                        <p className='viewUserDetail'>{userDetails?.firstName} {userDetails?.lastName}</p>
                                                    </div>
                                                    <div className="viewUserInfo">
                                                        <p className='viewUserTitle'>Email</p>
                                                        <p className='viewUserDetail'>{userDetails?.email}</p>
                                                    </div>
                                                    <div className="viewUserInfo">
                                                        <p className='viewUserTitle'>Mobile Number</p>
                                                        <p className='viewUserDetail'>{userDetails?.phone}</p>
                                                    </div>
                                                    <div className="viewUserInfo">
                                                        <p className='viewUserTitle'>Date of Birth</p>
                                                        <p className='viewUserDetail'>{userDetails?.dateOfBirth}</p>
                                                    </div>
                                                    <div className="viewUserInfo">
                                                        <p className='viewUserTitle'>Refer Code</p>
                                                        <p className='viewUserDetail'>{userDetails?.referCode ? userDetails?.referCode : "Not Available"}</p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : shippingSection ? (
                                            <>
                                                <div className="viewUserHeadingWrapper">
                                                    <h2>Shipping Address</h2>
                                                </div>
                                                <div className="viewUserAccountWrapper">
                                                    <div className="viewUserInfo">
                                                        <p className='viewUserTitle'>Name</p>
                                                        <p className='viewUserDetail'>Moin Khan</p>
                                                    </div>
                                                    <div className="viewUserInfo">
                                                        <p className='viewUserTitle'>Flat No.</p>
                                                        <p className='viewUserDetail'>{userDetails?.address?.flatNo ? userDetails?.address?.flatNo : "Not available"}</p>
                                                    </div>
                                                    <div className="viewUserInfo">
                                                        <p className='viewUserTitle'>Area</p>
                                                        <p className='viewUserDetail'>{userDetails?.address?.area ? userDetails?.address?.area : "Not available"}</p>
                                                    </div>
                                                    <div className="viewUserInfo">
                                                        <p className='viewUserTitle'>Landmark</p>
                                                        <p className='viewUserDetail'>{userDetails?.address?.landmark ? userDetails?.address?.landmark : "Not available"}</p>
                                                    </div>
                                                    <div className="viewUserInfo">
                                                        <p className='viewUserTitle'>City</p>
                                                        <p className='viewUserDetail'>{userDetails?.address?.city ? userDetails?.address?.city : "Not available"}</p>
                                                    </div>
                                                    <div className="viewUserInfo">
                                                        <p className='viewUserTitle'>Pin Code</p>
                                                        <p className='viewUserDetail'>{userDetails?.address?.pincode ? userDetails?.address?.pincode : "Not available"}</p>
                                                    </div>
                                                    <div className="viewUserInfo">
                                                        <p className='viewUserTitle'>State</p>
                                                        <p className='viewUserDetail'>{userDetails?.address?.state ? userDetails?.address?.state : "Not available"}</p>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                            : specificCourseSection ? (
                                                <>
                                                    <div className="viewUserHeadingWrapper">
                                                        <h2>Select any course</h2>
                                                    </div>
                                                    <div className="row">
                                                        {
                                                            isFetching ? (
                                                                <div className="inner-loader-wrapper">
                                                                    <MiniLoader />
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div className="col-12">
                                                                        <div className="discountInputFieldWrapper">
                                                                            <label>Select Product</label>
                                                                            <div className="dicountInfoWrapper">
                                                                                <input type="text" placeholder='Search here...' value={searchTerm}
                                                                                    onChange={handleSearchChange} />
                                                                                <div className="product-list-wrapper">
                                                                                    {filteredProducts.map((product, index) => {
                                                                                        return (
                                                                                            <button className={`product-option-button ${selectedProducts?.length > 0 && selectedProducts?.some((item) => item?.productId?._id === product?._id) ? 'selected' : ''}`}
                                                                                                key={index} onClick={() => handleProductToggle(product)}>
                                                                                                <div className="product-option-wrapper">
                                                                                                    <div className="option-img-wrapper">
                                                                                                        <img src={product.productImg} alt='img' />
                                                                                                    </div>
                                                                                                    <p>{product.title}</p>
                                                                                                    <span className={`${product.status === true ? "green-bg" : "red-bg"} product-status`}>{product.status === true ? "active" : "draft"}</span>
                                                                                                </div>
                                                                                            </button>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                                {
                                                                                    selectedProductsError && (
                                                                                        <span className='error-text mt-2'>Please select at least one product</span>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    success && (
                                                                                        <div className="alert alert-success mt-2">
                                                                                            {successMessage}
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    error && (
                                                                                        <div className="alert alert-danger mt-2">
                                                                                            {errorMessage}
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 mt-3">
                                                                        <div className="submit-btn-wrapper">
                                                                            <button type="submit" className={`${isLoaderActive ? 'button-loader load' : 'custom-btn purple-bg'}`} onClick={handleSubmitSpecificCourse}><span>Submit</span></button>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </>
                                            ) : assignedSpecificCourseSection ? (
                                                <>
                                                    <div className="row">
                                                        {
                                                            isFetching ? (
                                                                <div className="inner-loader-wrapper">
                                                                    <MiniLoader />
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div className="col-12">
                                                                        <div className="discountInputFieldWrapper">
                                                                            <label>Select Product</label>
                                                                            <div className="dicountInfoWrapper">
                                                                                <input type="text" placeholder='Search here...' value={searchTerm}
                                                                                    onChange={handleSearchChange} />
                                                                                <div className="product-list-wrapper">
                                                                                    {filteredProducts.map((product, index) => {
                                                                                        return (
                                                                                            <button className={`product-option-button ${selectedProducts?.length > 0 && selectedProducts?.some((item) => item?.productId?._id === product?._id) ? 'selected' : ''}`}
                                                                                                key={index} onClick={() => handleProductToggle(product)}>
                                                                                                <div className="product-option-wrapper">
                                                                                                    <div className="option-img-wrapper">
                                                                                                        <img src={product.productImg} alt='img' />
                                                                                                    </div>
                                                                                                    <p>{product.title}</p>
                                                                                                    <span className={`${product.status === true ? "green-bg" : "red-bg"} product-status`}>{product.status === true ? "active" : "draft"}</span>
                                                                                                </div>
                                                                                            </button>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                                {
                                                                                    selectedProductsError && (
                                                                                        <span className='error-text mt-2'>Please select at least one product</span>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    success && (
                                                                                        <div className="alert alert-success mt-2">
                                                                                            {successMessage}
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    error && (
                                                                                        <div className="alert alert-danger mt-2">
                                                                                            {errorMessage}
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 mt-3">
                                                                        <div className="submit-btn-wrapper">
                                                                            <button type="submit" className={`${isLoaderActive ? 'button-loader load' : 'custom-btn purple-bg'}`} onClick={handleSubmitSpecificCourse}><span>Submit</span></button>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </>
                                            ) : courseProgressSection ? (
                                                <div className="row">
                                                    <div className="col-12">
                                                        {
                                                            isProgressOfAllCoursesFetching ? (
                                                                <MiniLoader />
                                                            ) : (
                                                                <>
                                                                    {
                                                                        progressOfAllCourses?.length > 0 ? progressOfAllCourses.map((courseOuter, courseIndex) => {
                                                                            return (
                                                                                <div key={courseIndex}>
                                                                                    {
                                                                                        courseOuter?.chaptersProgress?.length > 0 ? courseOuter.chaptersProgress.map((progress, index) => {
                                                                                            if (progress) {
                                                                                                return (
                                                                                                    <div className='progress-wrapper'>
                                                                                                        <div className="progress-title-wrapper">
                                                                                                            <p>{progress.title}</p>
                                                                                                            <span>{progress.completionPercentage}%</span>
                                                                                                        </div>
                                                                                                        <div class="progress">
                                                                                                            <div class="progress-bar" role="progressbar" style={{ width: `${progress.completionPercentage}%` }} aria-valuenow={progress.completionPercentage} aria-valuemin="0" aria-valuemax="100">{progress.completionPercentage}%</div>
                                                                                                        </div></div>
                                                                                                )
                                                                                            }

                                                                                        }) : (
                                                                                            <p>it is not a array {courseIndex}</p>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        }
                                                                        ) : (
                                                                            <h3>User has not purchased any course yet</h3>
                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            ) : (
                                                null
                                            )
                                    }
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewUser

// if (item) {
//     // return (
//     //     <div class="progress" style={{ height: "10px" }}>
//     //         <div class="progress-bar" role="progressbar" aria-label="Example 1px high" style={{ width: "25%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
//     //     </div>
//     // )
// } else {
//     // return null; // Or you can return a placeholder if needed
// }
