import React, { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { getAllUsers } from '../../../redux/apiCalls';

const SingleFranchise = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        getAllUsers(dispatch);
    }, [dispatch]);

    const location = useLocation();
    const franchiseId = location.pathname.split('/')[2];
    const currentFranchise = useSelector((state) => state.allUsers.users.find((item) => item._id === franchiseId))

    // Franchise variable
    const schoolAdmins = useSelector((state) => state.allUsers.users.filter((item) => item.franchiseId === franchiseId && item.isSchoolAdmin === true))

    const alluser = useSelector((state) => state.allUsers.users);

    const [activeTable, setActiveTable] = useState("admin");

    // column for admin
    const adminColumn = [
        { field: '_id', headerName: 'ID', width: 200 },
        {
            field: 'schoolName',
            headerName: 'School',
            width: 200,
            editable: false,
            renderCell: (params) => {
                return params.row.franchiseId === franchiseId ? currentFranchise.schoolName : "Not associated with this school"
            }
        },
        {
            field: 'firstName',
            headerName: 'Admin Name',
            width: 150,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 200,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 100,
        },
        {
            field: 'edit',
            headerName: 'Edit',
            // sortable: false,
            width: 100,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={'/edit-school-admin/' + params.id}>
                            <button className='btn btn-primary studentListEdit'>Edit</button>
                        </Link>
                        {/* <DeleteOutline className='studentListDelete text-danger' /> */}
                    </>
                )
            }
        },
    ];

    // School HOD variable
    const schoolHOD = useSelector((state) => state.allUsers.users.filter((item) => item.franchiseId === franchiseId && item.isSchoolHOD === true));



    // columns for HOD
    const hodColumn = [
        { field: '_id', headerName: 'ID', width: 200 },
        {
            field: 'schoolName',
            headerName: 'School',
            width: 200,
            editable: false,
            renderCell: (params) => {
                return params.row.franchiseId === franchiseId ? currentFranchise.schoolName : "Not associated with this school"
            }
        },
        {
            field: 'firstName',
            headerName: 'HOD Name',
            width: 150,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 200,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 100,
        },
        {
            field: 'edit',
            headerName: 'Edit',
            // sortable: false,
            width: 100,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={'/edit-school-admin/' + params.id}>
                            <button className='btn btn-primary studentListEdit'>Edit</button>
                        </Link>
                        {/* <DeleteOutline className='studentListDelete text-danger' /> */}
                    </>
                )
            }
        },
    ];

    // School HOD variable
    const schoolTeacher = useSelector((state) => state.allUsers.users.filter((item) => item.franchiseId === franchiseId && item.isSchoolTeacher === true));


    // Coulmn for teacher
    const tacherColumn = [
        { field: '_id', headerName: 'ID', width: 200 },
        {
            field: 'schoolName',
            headerName: 'School',
            width: 200,
            editable: false,
            renderCell: (params) => {
                return params.row.franchiseId === franchiseId ? currentFranchise.schoolName : "Not associated with this school"
            }
        },
        {
            field: 'firstName',
            headerName: 'HOD Name',
            width: 150,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 200,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 100,
        },
        {
            field: 'edit',
            headerName: 'Edit',
            // sortable: false,
            width: 100,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={'/edit-school-teacher/' + params.id}>
                            <button className='btn btn-primary studentListEdit'>Edit</button>
                        </Link>
                        {/* <DeleteOutline className='studentListDelete text-danger' /> */}
                    </>
                )
            }
        },
    ];

    return (
        <div className='container'>
            <div className="row">
                <div className="col-12">
                    <div className="singleSchool-topSection">
                        <div className="singleSchool-title-wrapper">
                            <h2 className="page-heading text-capitalize"> Franchise : {currentFranchise.firstName + " " + currentFranchise.lastName}</h2>
                        </div>
                        <div className="schoolActionBtnWrapper">
                            <button className={`${activeTable === "admin" ? "active" : ""} btn`} onClick={() => setActiveTable("admin")}>School Admin</button>
                            <button className={`${activeTable === "hod" ? "active" : ""} btn`} onClick={() => setActiveTable("hod")}>School HOD</button>
                            <button className={`${activeTable === "teacher" ? "active" : ""} btn`} onClick={() => setActiveTable("teacher")}>School Teacher</button>
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    {
                        activeTable === "admin" && (
                            <div className="schoolAdminTableWrapper">
                                <div className="singleSchoolHeadingWrapper singleSchoolHeader mb-3">
                                    <h2>School List</h2>
                                    <Link to={`/add-school-admin/${franchiseId}`}>
                                        <button className='custom-btn purple-bg'>Create Admin <AddCircleOutlineOutlinedIcon className='newStudentIcon' /></button>
                                    </Link>
                                </div>
                                <DataGrid
                                    rows={schoolAdmins}
                                    getRowId={(rows) => rows._id}
                                    columns={adminColumn}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 10,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[10]}
                                    checkboxSelection
                                    disableRowSelectionOnClick
                                />
                            </div>
                        )
                    }

                    {
                        activeTable === "hod" && (
                            <div className="schoolHODTableWrapper">
                                <div className="singleSchoolHeadingWrapper singleSchoolHeader mb-3">
                                    <h2>School HODs</h2>
                                    <Link to={`/add-school-hod/${franchiseId}`}>
                                        <button className='custom-btn purple-bg'>Create HOD <AddCircleOutlineOutlinedIcon className='newStudentIcon' /></button>
                                    </Link>
                                </div>
                                <DataGrid
                                    rows={schoolHOD}
                                    getRowId={(rows) => rows._id}
                                    columns={hodColumn}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 10,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[10]}
                                    checkboxSelection
                                    disableRowSelectionOnClick
                                />
                            </div>
                        )
                    }

                    {
                        activeTable === "teacher" && (
                            <div className="schoolTeacherTableWrapper">
                                <div className="singleSchoolHeadingWrapper singleSchoolHeader mb-3">
                                    <h2>School Teachers</h2>
                                    <Link to={`/add-school-teacher/${franchiseId}`}>
                                        <button className='custom-btn purple-bg'>Create Teacher <AddCircleOutlineOutlinedIcon className='newStudentIcon' /></button>
                                    </Link>
                                </div>
                                <DataGrid
                                    rows={schoolTeacher}
                                    getRowId={(rows) => rows._id}
                                    columns={tacherColumn}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 10,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[10]}
                                    checkboxSelection
                                    disableRowSelectionOnClick
                                />
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default SingleFranchise
