import React, { useEffect, useState } from 'react';
// import './NewBlog.css';
import { useDispatch, useSelector } from 'react-redux';
import { addBlogCategoryFailure, addBlogCategoryStart, addBlogCategorySuccess, updateBlogCategoryFailure, updateBlogCategoryStart, updateBlogCategorySuccess } from '../../../../redux/blogCategory';
import { fileUploadRequest, userRequest } from '../../../../requestMethod';
import { validateRequired } from '../../../../formValidation';
import CircleLoader from '../../../../components/circleLoader/CircleLoader';
import { getAllBlogCategory } from '../../../../redux/apiCalls';
import { useLocation } from 'react-router-dom';
import Popup from '../../../../global/Popup';
import { allowedImageFormats } from '../../../../config';


const EditBlogCategory = () => {

    const dispatch = useDispatch();

    // fetch all existing categories
    useEffect(() => {
        getAllBlogCategory(dispatch)
    }, [dispatch]);

    const location = useLocation();
    const categoryId = location.pathname.split('/')[2];

    // const blogData = useSelector((state) => state.blog.blogs.find((item) => item._id == blogId));

    const blogCategory = useSelector((state) => state.blogCategory.blogCategory.find((item) => item._id == categoryId));

    const [category, setCategory] = useState(blogCategory && blogCategory.category);
    const [categoryImage, setCategoryImage] = useState(blogCategory && blogCategory.categoryImage);
    const [imagePreview, setImagePreview] = useState(blogCategory && blogCategory.categoryImage);
    const [status, setStatus] = useState(blogCategory && blogCategory.status);
    // const [categoryImageErr, setCategoryImageErr] = useState("");
    const [titleError, setTitleError] = useState(false);
    const [titleErrorMessage, setTitleErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [activeLoader, setActiveLoader] = useState(false);
    const [loaderText, setLoaderText] = useState("");
    const [popupText, setPopuptext] = useState('');
    const [popupStatus, setPopupStatus] = useState(true);
    const [activePopup, setActivePopup] = useState(false);


    useEffect(() => {
        setCategory(blogCategory && blogCategory.category);
        setCategoryImage(blogCategory && blogCategory.categoryImage);
        setImagePreview(blogCategory && blogCategory.categoryImage);
        setStatus(blogCategory && blogCategory.status);
    }, [blogCategory]);

    // Handle category image 
    const handleCategoryImageChange = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            // Check if the selected file is an image
            const isImage = allowedImageFormats.includes(files[0].type);
            if (!isImage) {
                // Show an error message or perform some action for invalid image format
                alert('Please select a valid image file (JPEG, PNG, or GIF).');
                event.target.value = null;
                setCategoryImage('');
                return;
            } else {
                setCategoryImage(files[0]);
                setImagePreview(URL.createObjectURL(files[0]));
            }
        }
        else {
            setCategoryImage(null);
            setImagePreview(blogCategory && blogCategory.categoryImage);
        }
    };


    // Validate function for form validation start=======
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const titleErr = validateRequired(category);
        const categoryImageErr = validateRequired(categoryImage);
        if (titleErr) {
            formIsValid = false;
            newErrors.category = titleErr;
        }
        if (categoryImageErr) {
            formIsValid = false;
            newErrors.categoryImage = categoryImageErr;
        }

        setErrors(newErrors);
        return formIsValid;
    }
    // Validate function for form validation end======


    // const allowedImageFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/webp'];


    // const uploadCategoryImage = async () => {
    //     const promises = uploadFile(categoryImage);
    //     return Promise.all(promises);
    // };


    const uploadFileToSpace = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        setActiveLoader(true)
        setLoaderText("Uploading Image");
        try {
            const response = await fileUploadRequest.post('/upload/file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response && response.status === 200) {
                setActiveLoader(false)
                setLoaderText("");
                return response.data.fileUrl;
            }
            // You can now use the file URL as needed, e.g., storing it in your database.
        } catch (error) {
            setActiveLoader(false)
            setLoaderText("");
            console.error('Error uploading file:', error);
        }
    };


    //add api call action
    const updateBlogCategory = async (dispatch, categoryId, category) => {
        dispatch(updateBlogCategoryStart());
        try {
            const res = await userRequest.put(`/blogCategory/${categoryId}`, category);
            if (res.status === 200) {
                setActiveLoader(false)
                setCategory("");
                setCategoryImage(null);
                setActivePopup(true);
                setPopupStatus(true)
                setPopuptext("Blog Category has been updated")
            }
            dispatch(updateBlogCategorySuccess(res.data));
        } catch (error) {
            dispatch(updateBlogCategoryFailure());
            if (error.response.status === 409) {
                setTitleError(true);
                setTitleErrorMessage(error.response.data);
            }

            else {
                setActivePopup(true);
                setPopupStatus(false)
                setPopuptext("Something went wrong")
            }
        }
    };

    const handleUpdateData = async () => {
        setError(false);
        setSuccess(false);
        setTitleError(false);

        if (validateForm()) {
            setActiveLoader(true);
            setLoaderText("uploading");
            const trimmedValue = category.trim()
            let categoryImageURL ;
            if(categoryImage.type){
                categoryImageURL = await uploadFileToSpace(categoryImage);
            }
            else{
                categoryImageURL = categoryImage
            }
             
            updateBlogCategory(dispatch, categoryId, { category, categoryImage: categoryImageURL, status });
        }
    };


    const hidePopup = () => {
        setActivePopup(false);
    }


    if (activePopup) {
        return (
            <Popup status={popupStatus} message={popupText} onClickAction={hidePopup} />
        )
    }


    return (
        <div className='container'>
            <div className="row mt-5 mb-3">
                <div className="col-12">
                    <div className="pageTitleWrapper">
                        <h2 className="page-heading">Update Blog Category</h2>
                    </div >
                </div >
            </div >
            <div className="row">
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>title</label>
                        <input type="text" name='title' value={category} className='newUserInput' onChange={(e) => setCategory(e.target.value)} placeholder='Title' required />
                        {
                            titleError && (
                                <p className='error-text text-danger'>{titleErrorMessage}</p>
                            )
                        }
                        <span className='error-text'>{errors.category}</span>
                    </div>
                </div>
                {/* <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Category</label>
                        <select name="blogCategories" value={category} onChange={(e) => setCategory(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            {
                                blogCategories ? (
                                    blogCategories.map((item, index) => {
                                        return <option value={item.category} key={index} >{item.category}</option>
                                    })
                                ) : (
                                    <option value="" selected disabled>Not Available</option>
                                )
                            }
                        </select>
                        <span className='error-text'>{errors.category}</span>
                    </div>
                </div> */}
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Category Image</label>
                        {/* <input type="file" name='file' className='newUserInput' onChange={(e) => setBlogImage(e.target.files[0])} placeholder='Image' />
                        <span className='error-text'>{errors.blogImage}</span> */}
                        {/* <div className="col-12 p-0"> */}
                        <input type="file" placeholder='' className='newUserInput' onChange={handleCategoryImageChange} accept={allowedImageFormats} />
                        {
                            errors.categoryImage && (
                                <p className='error-text'>{errors.categoryImage}</p>
                            )
                        }
                        {/* </div> */}
                        <div className="blog-img-wrapper">
                            <img src={imagePreview || categoryImage} alt="blog-img" />
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Status</label>
                        <select name="status" value={status} className='newUserInput' onChange={(e) => setStatus(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value="true" >Active</option>
                            <option value="false" >Draft</option>
                        </select>
                    </div>
                </div>
                <div className="col-12">
                    <div className="newStudentSubmitbtn">
                        {
                            // isFetching ? (
                            //     <button disabled={true} className="custom-btn purple-bg">Processing...</button>
                            // ) :
                            <button className="custom-btn purple-bg" onClick={handleUpdateData}>Update</button>
                        }
                    </div>
                </div>

                <div className="col-12 mt-3">
                    {
                        success ? (
                            <div className='alert alert-success'>
                                {successMessage}
                            </div>
                        ) : error && (
                            <div className='alert alert-danger'>
                                {errorMessage}
                            </div>
                        )
                    }
                </div>
                {
                    activeLoader && (
                        <CircleLoader title={loaderText} />
                    )
                }
            </div>


        </div>
    )
}

export default EditBlogCategory
