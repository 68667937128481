
import { createSlice } from "@reduxjs/toolkit";

const quizReducer = createSlice({
    name: 'quiz',
    initialState: {
        quiz: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get All Quiz
        getQuizStart: (state) => {
            state.isFetching = true;
        },
        getQuizSuccess: (state, action) => {
            state.isFetching = false;
            state.quiz = action.payload;
        },
        getQuizFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Delete Quiz                         
        deleteQuizStart: (state) => {
            state.isFetching = true;
        },
        deleteQuizSuccess: (state, action) => {
            state.isFetching = false;
            state.quiz.splice(
                state.quiz.findIndex((item) => item._id === action.payload), 1
            );
        },
        deleteQuizFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Update Quiz
        updateQuizStart: (state) => {
            state.isFetching = true;
        },
        updateQuizSuccess: (state, action) => {
            state.isFetching = false;
            const quizIndex = state.quiz.findIndex(quiz => quiz._id === action.payload._id);

            if (quizIndex !== -1) {
                state.quiz[quizIndex] = action.payload;
            }
        },
        updateQuizFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        //  Add Quiz
        addQuizStart: (state) => {
            state.isFetching = true;
        },
        addQuizSuccess: (state, action) => {
            state.isFetching = false;
            // state.quiz = action.payload;
            const newData = action.payload;
            state.quiz = [ ...state.quiz, newData ];
        },
        addQuizFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const { getQuizStart, getQuizSuccess, getQuizFailure, deleteQuizStart, deleteQuizSuccess, deleteQuizFailure, updateQuizStart, updateQuizSuccess, updateQuizFailure, addQuizStart, addQuizSuccess, addQuizFailure } = quizReducer.actions;
export default quizReducer.reducer;