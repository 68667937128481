// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blinking{
    /* display: none !important; */
    background: #000;
}

`, "",{"version":3,"sources":["webpack://./src/pages/GiftCard/giftCardTable/DiscountTable.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,gBAAgB;AACpB","sourcesContent":[".blinking{\n    /* display: none !important; */\n    background: #000;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
