import React, { useEffect, useState } from 'react';
import './CourseType.css';
import { useDispatch, useSelector } from 'react-redux';
import { addCourseTypeFailure, addCourseTypeStart, addCourseTypeSuccess, deleteCourseTypeFailure, deleteCourseTypeStart, deleteCourseTypeSuccess } from '../../../redux/courseTypeRedux';
import { userRequest } from '../../../requestMethod';
import { getAllCourseTypes } from '../../../redux/apiCalls';
import { Delete } from '@mui/icons-material';
import Loader from '../../../global/Loader';
import { validateRequired } from '../../../formValidation';
import CircleLoader from '../../../components/circleLoader/CircleLoader';

const CourseType = () => {

    const dispatch = useDispatch();

    const [courseType, setCourseType] = useState();
    const [titleError, setTitleError] = useState(false);
    const [titleErrorMessage, setTitleErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');


    //add api call action
    const addCourseType = async (dispatch, courseType) => {
        dispatch(addCourseTypeStart());
        try {
            const res = await userRequest.post('/courseType/create', courseType);
            if (res.status === 200) {
                setSuccess(true);
                setSuccessMessage("Course type has been added!")
            }
            dispatch(addCourseTypeSuccess(res.data));
        } catch (error) {
            dispatch(addCourseTypeFailure());
            if (error.response.status === 409) {
                setTitleError(true);
                setTitleErrorMessage(error.response.data);
            }

            else {
                setError(true);
                setErrorMessage("Something went wrong!");
            }
        }
    };

    // Delete api call action start=======
    const deleteCourseType = async (dispatch, courseTypeId) => {
        dispatch(deleteCourseTypeStart());
        try {
            const res = await userRequest.delete(`/courseType/${courseTypeId}`);
            dispatch(deleteCourseTypeSuccess(courseTypeId));
        } catch (error) {
            dispatch(deleteCourseTypeFailure());
        }
    }
    // Delete api call action end=======


    const handleDeleteCourseType = (courseTypeId) => {
        setError(false);
        setSuccess(false);
        setTitleError(false);
        deleteCourseType(dispatch, courseTypeId)
    }


    
    // Validate function for form validation start=======
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const titleErr = validateRequired(courseType);
        if (titleErr) {
            formIsValid = false;
            newErrors.title = titleErr;
        }

        setErrors(newErrors);
        return formIsValid;
    }
    // Validate function for form validation end======
    

    // Function for handle submit data start======
    const handleSubmitData = () => {
        setError(false);
        setSuccess(false);
        setTitleError(false);
        let trimmedValue;
        if(courseType){
             trimmedValue = courseType.trim();
        }
        if (validateForm()) {
            addCourseType(dispatch, { courseType : trimmedValue })
        }
    }
    // Function for handle submit data start=========


    // fetch all existing age groups start========
    useEffect(() => {
        getAllCourseTypes(dispatch)
    }, [dispatch]);
    // fetch all existing age groups end========



    const { isFetching, courseTypes } = useSelector((state) => state.courseType);

    if (isFetching) {
        return (
            <div className='loader-wrapper'>
                <CircleLoader />
            </div>
        )
    }

    return (
        <div className='container'>
            <div className="row">
                <div className="col-12">
                    <div className="courseTypeWrapper">
                        <h1>Course Types</h1>
                        <div className="newCourseTypeWrapper">
                            <div className="newCourseTypeInputWrapper">
                                <input type="text" placeholder='Enter age group here' value={courseType} onChange={(e) => setCourseType(e.target.value)} />
                                <button className="custom-btn" onClick={handleSubmitData}>Add</button>
                            </div>
                            {
                                titleError && (
                                    <p className='error-text text-danger'>{titleErrorMessage}</p>
                                )
                            }
                            <span className='error-text'>{errors.title}</span>
                            <div className="col-12 mt-3">
                                {
                                    success ? (
                                        <div className='alert alert-success'>
                                            {successMessage}
                                        </div>
                                    ) : error && (
                                        <div className='alert alert-danger'>
                                            {errorMessage}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="avilableCourseTypeGroupWrapper">
                            <h2>Existing: </h2>
                            <div className="courseTypeContainer">
                                {
                                    courseTypes.length > 0 ? courseTypes.map((item, index) => {
                                        return (
                                            <div className="availableCourseType" key={index}>
                                                <span className="groupItem">{item.courseType}</span>
                                                <Delete className='courseTypeDelete' onClick={() => handleDeleteCourseType(item._id)} />
                                            </div>
                                        )
                                    }) : (
                                        <p>No course types available!</p>
                                    )
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CourseType
