import React, { useEffect, useState } from 'react';
import './NewEvent.css';
import { fileUploadRequest, userRequest } from '../../../requestMethod';
import { useDispatch, useSelector } from 'react-redux';
import { validateRequired } from '../../../formValidation';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { addBlogFailure, addBlogStart, addBlogSuccess } from '../../../redux/blogRedux';
import { getAllBlogCategory } from '../../../redux/apiCalls';
import CircleLoader from '../../../components/circleLoader/CircleLoader';
import { addEventFailure, addEventStart, addEventSuccess } from '../../../redux/eventRedux';
import Popup from '../../../global/Popup';

const NewEvent = () => {

    // const blogCategories = useSelector((state) => state.blogCategory.blogCategory);

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeywords, setMetaKeywords] = useState('');
    const [category, setCategory] = useState('');
    const [author, setAuthor] = useState('');
    const [status, setStatus] = useState(false);
    const [titleError, setTitleError] = useState(false);
    const [titleErrorMessage, setTitleErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [eventImage, seteventImage] = useState(null);
    const { isFetching, products } = useSelector((state) => state.products)
    const [activeLoader, setActiveLoader] = useState(false);
    const [loaderText, setLoaderText] = useState("");
    const [popupStatus, setPopupStatus] = useState(false);
    const [activePopup, setActivePopup] = useState(false);
    const [popupText, setPopuptext] = useState("");

    const handleDescriptionChange = (value) => {
        setDescription(value);
    };

    

    const dispatch = useDispatch();

     // fetch all existing categories
     useEffect(() => {
        getAllBlogCategory(dispatch)
    }, [dispatch]);

    const addEvent = async (dispatch, blog) => {
        dispatch(addEventStart());
        setActiveLoader(true);
        setLoaderText("uploading details");
        try {
            const res = await userRequest.post('/event/create', blog);
            if (res && res.status === 200) {
                setTitle('');
                setDescription('');
                setCategory('');
                setAuthor('');
                setMetaTitle('');
                setMetaDescription('');
                setMetaKeywords('');
                setStatus(false);
                seteventImage(null);
                setActiveLoader(false);
                setActivePopup(true)
                setPopupStatus(true);
                setPopuptext("Event has been added!");
            }
            dispatch(addEventSuccess(res.data));
            setActiveLoader(false);
            setLoaderText("");
        } catch (error) {
            dispatch(addEventFailure());
            setActiveLoader(false);
            setLoaderText("");
            if (error && error.response?.status === 409) {
                setTitleError(true);
                setTitleErrorMessage(error.response.data);
            }

            else {
                setActivePopup(true)
                setPopupStatus(false);
                setPopuptext("Request failed.")
            }
        }
    };

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const titleErr = validateRequired(title);
        const descErr = validateRequired(description);
        const categoryErr = validateRequired(category);
        const authorErr = validateRequired(author);
        const eventImageErr = validateRequired(eventImage);

        if (titleErr) {
            formIsValid = false;
            newErrors.title = titleErr;
        }
        if (descErr) {
            formIsValid = false;
            newErrors.desc = descErr;
        }
        if (categoryErr) {
            formIsValid = false;
            newErrors.category = categoryErr;
        }

        if(authorErr) {
            formIsValid = false;
            newErrors.author = authorErr;
        }
        if(eventImageErr) {
            formIsValid = false;
            newErrors.eventImage = eventImageErr;
        }

        setErrors(newErrors);
        return formIsValid;
    };

    const uploadFileToSpace = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        setActiveLoader(true)
        setLoaderText("Uploading Image");
        try {
            const response = await fileUploadRequest.post('/upload/file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response && response.status === 200) {
                setActiveLoader(false)
                setLoaderText("");
                return response.data.fileUrl;
            }
            // You can now use the file URL as needed, e.g., storing it in your database.
        } catch (error) {
            setActiveLoader(false)
            setLoaderText("");
            console.error('Error uploading file:', error);
        }
    };

    const handleAdd = async () => {
        setSuccess(false);
        setError(false);
        setTitleError(false);
        const trimmedTitle = title.trim();
        const eventImgUrl = eventImage &&  await uploadFileToSpace(eventImage);
        if (validateForm()) {
            addEvent(dispatch, { title : trimmedTitle, description,category, author, status, eventImage : eventImgUrl, metaTitle, metaDescription,metaKeywords });
            // addEvent(dispatch, { title : trimmedTitle, description,category, author, status});
        }
    };

    const hidePopup = () => {
        setActivePopup(false);
    }

    if (activePopup) {
        return (
            <Popup status={popupStatus} message={popupText} onClickAction={hidePopup} />
        )
    };



    return (
        <div className='container'>
            <div className="row mt-5 mb-3">
                <div className="col-12">
                    <div className="pageTitleWrapper">
                        <h2 className="page-heading">Add new event</h2>
                    </div >
                </div >
            </div >
            <div className="row">
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>title</label>
                        <input type="text" name='title' value={title} className='newUserInput' onChange={(e) => setTitle(e.target.value)} placeholder='Title' required />
                        {
                            titleError && (
                                <p className='error-text text-danger'>{titleErrorMessage}</p>
                            )
                        }
                        <span className='error-text'>{errors.title}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>meta title</label>
                        <input type="text" name='meta title' value={metaTitle} className='newCourseInput' onChange={(e) => setMetaTitle(e.target.value)} placeholder='Meta Title' />
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Category</label>
                        <select name="blogCategories" value={category} onChange={(e) => setCategory(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            {/* {
                                blogCategories ? (
                                    blogCategories.map((item, index) => {
                                        return <option value={item.category} key={index} >{item.category}</option>
                                    })
                                ) : (
                                    <option value="" selected disabled>Not Available</option>
                                )
                            } */}
                            <option value= "latest" >Latest Event</option>
                            <option value= "past" >Past Event</option>
                        </select>
                        <span className='error-text'>{errors.category}</span>

                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Author</label>
                        <input type="text" name='price' value={author} className='newUserInput' onChange={(e) => setAuthor(e.target.value)} placeholder='Author' />
                        <span className='error-text'>{errors.author}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Image</label>
                        <input type="file" name='file' className='newUserInput' onChange={(e) => seteventImage(e.target.files[0])} placeholder='Image' />
                        <span className='error-text'>{errors.eventImage}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Status</label>
                        <select name="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value="true" >Active</option>
                            <option value="false" >Draft</option>
                        </select>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>description</label>
                        {/* <input type="text" name='description' value={description} className='newUserInput' onChange={(e) => setDescription(e.target.value)} placeholder='Description' /> */}
                        <ReactQuill value={description} onChange={handleDescriptionChange} />

                        <span className='error-text'>{errors.desc}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>meta description</label>
                        <input type="text" name='meta description' value={metaDescription} className='newCourseInput' onChange={(e) => setMetaDescription(e.target.value)} placeholder='Meta Description' />
                        {/* <span className='error-text'>{errors.desc}</span> */}
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>meta keywords</label>
                        <input type="text" name='meta description' value={metaKeywords} className='newCourseInput' onChange={(e) => setMetaKeywords(e.target.value)} placeholder='Meta Keywords' />
                        {/* <span className='error-text'>{errors.desc}</span> */}
                    </div>
                </div>
                <div className="col-12">
                    <div className="newStudentSubmitbtn">
                        {
                            isFetching ? (
                                <button disabled={true} className="custom-btn purple-bg">Processing...</button>
                            ) :
                                <button className="custom-btn purple-bg" onClick={handleAdd}>create</button>
                        }
                    </div>
                </div>
                
                <div className="col-12 mt-3">
                    {
                        success ? (
                            <div className='alert alert-success'>
                                {successMessage}
                            </div>
                        ) : error && (
                            <div className='alert alert-danger'>
                                {errorMessage}
                            </div>
                        )
                    }
                </div>
                {
                    activeLoader && (
                        <CircleLoader title={loaderText} />
                    )
                }
            </div>
        </div>
    )
}

export default NewEvent
