import { createSlice } from "@reduxjs/toolkit";

const giftCardReducer = createSlice({
    name : 'giftCard',
    initialState : {
        giftCards : [],
        isFetching : false,
        error : false,
    },

    reducers : {
        // Get gift card actions
        getGiftCardStart : (state) => {
            state.isFetching = true;
        },

        getGiftCardSuccess : (state, action) => {
            state.isFetching = false;
            state.giftCards = action.payload;
        },

        getGiftCardFailure : (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Delete gift card actions
        deleteGiftCardStart: (state) => {
            state.isFetching = true;
        },
        deleteGiftCardSuccess: (state, action) => {
            state.isFetching = false;
            state.giftCards.splice(
                state.giftCards.findIndex((item) => item._id === action.payload), 1
            );
        },
        deleteGiftCardFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Add gift card actions
        addGiftCardStart : (state) => {
            state.isFetching  = true;
        },

        addGiftCardSuccess : (state, action) => {
            state.isFetching = false;
            const newData = action.payload;
            state.giftCards = [ ...state.giftCards, newData ];
        },

        addGiftCardfailure : (state) => {
            state.isFetching = false;
            state.error = false;
        },

        // Update gift card actions
        updateGiftCardStart: (state) => {
            state.isFetching = true;
        },
        updateGiftCardSuccess: (state, action) => {
            state.isFetching = false;
            const giftCardIndex = state.giftCards.findIndex(giftCard => giftCard._id === action.payload._id);
            if (giftCardIndex !== -1) {
                state.giftCards[giftCardIndex] = action.payload;
            }
        },
        updateGiftCardFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

    },
});

export const { getGiftCardStart, getGiftCardSuccess, getGiftCardFailure, deleteGiftCardStart, deleteGiftCardSuccess,deleteGiftCardFailure, addGiftCardStart, addGiftCardSuccess, addGiftCardfailure, updateGiftCardStart, updateGiftCardSuccess, updateGiftCardFailure } = giftCardReducer.actions;
export default giftCardReducer.reducer