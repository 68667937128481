
import { createSlice } from "@reduxjs/toolkit";

const contactFormQueriesReducer = createSlice({
    name: 'contactFormQueries',
    initialState: {
        queries: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get All Blog
        getQueriesStart: (state) => {
            state.isFetching = true;
        },
        getQueriesSuccess: (state, action) => {
            state.isFetching = false;
            state.queries = action.payload;
        },
        getQueriesFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const { getQueriesStart, getQueriesSuccess, getQueriesFailure } = contactFormQueriesReducer.actions;
export default contactFormQueriesReducer.reducer;