import React, { useEffect } from 'react';
import './AllLibraries.css';
import { Link } from 'react-router-dom';
import { getAllLectureLibraries } from '../../../../redux/apiCalls';
import { useDispatch, useSelector } from 'react-redux';
import CircleLoader from '../../../../components/circleLoader/CircleLoader';

const AllLibraries = () => {

    //  const getVideoLibraries = async() => {
    //     try {
    //         const res = await userRequest.get('/product/libraries');
    //     } catch (error) {
    //     }
    // }
    const dispatch = useDispatch();

    useEffect(() => {
        getAllLectureLibraries(dispatch);
    }, [dispatch]);

    const allLibraries = useSelector((state) => state.lectureLibrary.lectureLibrary);
    const isFetching = useSelector((state) => state.lectureLibrary.isFetching);


    if(isFetching){
        return(
            <CircleLoader />
        )
    }

    return (
        <div className='container'>
            <div className="row">
                <div className="col-12">
                    <h1>All Libraries</h1>
                </div>
                <div className="col-12">
                    <div className="libraries-container">
                        {
                            allLibraries && allLibraries.map((item, index) => {
                                const libraryId = item.uri.split('/')[4];
                                return (
                                    <Link key={index} className='library-item' to={`/lectures/${libraryId}`}>
                                        <div className="libraies-wrapper">
                                            <div className="foler-img-wrapper">
                                                <img src={`${process.env.PUBLIC_URL}/images/folder-1.png`} alt="img" />
                                            </div>
                                            <p className='library-name'>{item.name}</p>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllLibraries
