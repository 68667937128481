import React from 'react';
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

const SchoolPrincipalAndAdminComponents = () => {
    const currentUser = useSelector((state) => state.user.currentUser);
    let auth;
    // if (currentUser) {
    //     currentUser.isSchoolPrincipal ? auth = true : auth = false;
    // }

    if (currentUser) {
        if (currentUser.isSchoolPrincipal || currentUser.isSchoolAdmin) {
            auth = true;
        }
        else{
            auth = false;
        }
    }

    return auth ? <Outlet /> : <Navigate to='/' />
}

export default SchoolPrincipalAndAdminComponents
