import React from 'react';
import SchoolTable from '../../../../../components/schoolTable/SchoolTable';


const FranchiseSchoolTable = () => {
  return (
    <SchoolTable />
  )
}

export default FranchiseSchoolTable
