import React, { useEffect, useState } from 'react';
import './LectureTable.css';
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline, Visibility } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useDispatch, useSelector } from 'react-redux';
// import { deleteProducts, getProducts } from '../../../redux/apiCalls';
// import Loader from '../../../global/Loader';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { getAllLectures } from '../../../../redux/apiCalls';
import Loader from '../../../../global/Loader';
import CircleLoader from '../../../../components/circleLoader/CircleLoader';

const LectureTable = () => {
    const dispatch = useDispatch();

    const location = useLocation();
    const libraryId = location.pathname.split('/')[2];


    const [deletePopup, setDeletePopup] = useState(false);
    const [deletingItem, setDeletingItem] = useState(null);

    const handlePopup = (id) => {
        setDeletePopup(true)
        setDeletingItem(id)
    };

    const handlePopupCancel = () => {
        setDeletePopup(false)
    }

    useEffect(() => {
        getAllLectures(dispatch, libraryId)
    }, [dispatch]);

    let { isFetching, lectures } = useSelector((state) => state.lecture);

    let lecturesWithId;
    if (lectures) {
        let idCounter = 1;
        lecturesWithId = lectures.map((lecture) => ({
            ...lecture,
            id: idCounter++,
        }));
    }
    const state = useSelector((state) => state);


    const deleteCourse = (productId) => {
        // deleteLec(productId, dispatch);
        setDeletePopup(false);
    }


    const columns = [
        {
            field: 'id',
            headerName: '#',
            width: 20,
            renderCell: () => {
            }
        },
        {
            field: 'pictures',
            headerName: 'Thumbnail',
            width: 100,
            editable: false,
            sortable: false,
            renderCell: (params) => {
                return <img src={params.row.pictures.base_link} className='courseTable-img' alt="thumbnail-img" />
            }
        },
        {
            field: 'name',
            headerName: 'Title',
            width: 350,
            editable: false,
        },

        {
            field: 'release_time',
            headerName: 'Release Date',
            description: 'This column has a value getter and is not sortable.',
            sortable: true,
            width: 150,
            renderCell: (params) => {
                const fetchedDate = new Date(params.value);
                const formattedDate = fetchedDate.toLocaleDateString();
                return formattedDate
            }
        },
        // {
        //     field: 'status',
        //     headerName: 'Status',
        //     // description: 'This column has a value getter and is not sortable.',
        //     sortable: false,
        //     width: 100,
        //     renderCell: (params) => {
        //         return params.row.status ? "Active" : "Draft";
        //     }
        // },
        // {
        //     field: 'edit',
        //     headerName: 'Edit',
        //     sortable: false,
        //     width: 100,
        //     renderCell: (params) => {
        //         return (
        //             <>
        //                 <Link to={'/edit-course/' + params.id}>
        //                     <button className='btn btn-primary studentListEdit'>Edit</button>
        //                 </Link>
        //             </>
        //         )
        //     }
        // },
        {
            field: 'delete',
            headerName: 'Delete',
            sortable: false,
            width: 100,
            renderCell: (params) => {
                const courseId = params.row._id;
                return (
                    <>
                        <DeleteOutline onClick={() => handlePopup(courseId)} className='courseListDelete text-danger' />
                    </>
                )
            }
        },
    ];

    if (isFetching) {
        return (
            <div className='loader-wrapper'>
                <CircleLoader title="fetching" />
            </div>
        )

    }

    return (
        <div className='py-5'>
            <div className="row">
                <div className="col-12">
                    <div className="tableButtonWrapper d-flex justify-content-between mb-3">
                        <h2>Lectures</h2>
                        <div className="buttonsWrapper d-flex gx-2">
                            {/* <Link to='/add-course'>
                                <button className='custom-btn purple-bg'>New Lecture <AddCircleOutlineOutlinedIcon className='newStudentIcon' /></button>
                            </Link> */}
                        </div>

                    </div>
                </div>
            </div>
            {
                deletePopup && (
                    <div className="deletePopupAlertWrapper">
                        <div className="deletePopupAlert">
                            <WarningAmberIcon style={{ color: "var(--orange)", fontSize: "40px" }} />
                            <h4>Warning</h4>
                            <div className="deleteAlertTextWrapper text-center">
                                <p>This lecture will be deleted permanently.</p>
                                <p>Are you sure?</p>
                            </div>

                            <div className="deleteBtnsWrapper">
                                <button className='btn' onClick={handlePopupCancel} style={{ background: "var(--green)", color: "var(--white)" }}>Cancel</button>
                                <button className='btn' onClick={() => deleteCourse(deletingItem)} style={{ background: "var(--red)", color: "var(--white)" }}>Delete</button>
                            </div>
                        </div>
                    </div>
                )
            }

            {
                lectures && (
                    <DataGrid
                        rows={lecturesWithId}
                        getRowId={(rows) => {
                            const uniqueId = rows.uri.split('/')[2];
                            return uniqueId
                        }}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[10]}
                        checkboxSelection
                        disableRowSelectionOnClick
                    />
                )
            }

            {
                lectures.length < 1 && (
                    <p className='text-center'>No data found</p>
                )
            }
        </div>
    )
}

export default LectureTable
