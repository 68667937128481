// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.foler-img-wrapper{
    width: 100px;
}

.libraries-container{
    display: flex;
    column-gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap;
}

.library-name{
    color: #000;
    text-transform: capitalize;
    word-wrap: break-word;
}

.library-item{
    text-decoration: none;
}

.libraies-wrapper{
    width: 100px;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/course/lectures/allLibraries/AllLibraries.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,0BAA0B;IAC1B,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".foler-img-wrapper{\n    width: 100px;\n}\n\n.libraries-container{\n    display: flex;\n    column-gap: 20px;\n    row-gap: 20px;\n    flex-wrap: wrap;\n}\n\n.library-name{\n    color: #000;\n    text-transform: capitalize;\n    word-wrap: break-word;\n}\n\n.library-item{\n    text-decoration: none;\n}\n\n.libraies-wrapper{\n    width: 100px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
