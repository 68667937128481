import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers } from '../../../../redux/apiCalls';
import { Link, useLocation } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Visibility } from '@mui/icons-material';
import SchoolTable from '../../../../components/schoolTable/SchoolTable';

const ViewFranchise = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        getAllUsers(dispatch);
    }, [dispatch]);

    const location = useLocation();
    const franchiseId = location.pathname.split('/')[2];
    const currentFranchise = useSelector((state) => state.allUsers.users.find((item) => item._id === franchiseId));
    const schools = useSelector((state) => state.allUsers.users.filter((item) => item.franchiseId === franchiseId));
    // const allusers = useSelector((state) => state.allUsers.users.find((item) => item.isSchoolPrincipal));

    const alluser = useSelector((state) => state.allUsers.users);

    // const [activeTable, setActiveTable] = useState("admin");

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="singleSchool-title-wrapper">
                            <h2 className="page-heading text-capitalize"> Franchise Owner : {currentFranchise.firstName + " " + currentFranchise.lastName}</h2>
                        </div>
                    </div>
                </div>
            </div>

            <SchoolTable franchiseId={franchiseId} isFranchise={true} />
        </>

    )
    // // column for admin
    // const schoolColumn = [
    //     { field: '_id', headerName: 'ID', width: 200 },
    //     {
    //         field: 'schoolName',
    //         headerName: 'School',
    //         width: 220,
    //         editable: false,
    //     },
    //     {
    //         field: 'firstName',
    //         headerName: 'Principal Name',
    //         width: 150,
    //         editable: false,
    //     },
    //     {
    //         field: 'email',
    //         headerName: 'Email',
    //         description: 'This column has a value getter and is not sortable.',
    //         sortable: false,
    //         width: 200,
    //     },
    //     {
    //         field: 'phone',
    //         headerName: 'Phone',
    //         description: 'This column has a value getter and is not sortable.',
    //         sortable: false,
    //         width: 100,
    //     },

    //     {
    //         field: 'View',
    //         headerName: 'View School',
    //         // sortable: false,
    //         width: 100,
    //         renderCell: (params) => {
    //             return (
    //                 <>
    //                     <Link to={'/view-franchise-school/' + params.id}>
    //                         <button className='btn btn-primary viewSchoolBtn'>View <Visibility /> </button>
    //                     </Link>
    //                 </>
    //             )
    //         }
    //     },
    //     {
    //         field: 'edit',
    //         headerName: 'Edit',
    //         // sortable: false,
    //         width: 100,
    //         renderCell: (params) => {
    //             return (
    //                 <>
    //                     <Link to={'/edit-school-admin/' + params.id}>
    //                         <button className='btn btn-primary studentListEdit'>Edit</button>
    //                     </Link>
    //                 </>
    //             )
    //         }
    //     },
    // ];


    // return (
    //     <div className='container-fluid'>
    //         <div className="row">
    //             <div className="col-12">
    //                 <div className="singleSchool-topSection">
    //                     <div className="singleSchool-title-wrapper">
    //                         <h2 className="page-heading text-capitalize"> Franchise Owner : {currentFranchise.firstName + " " + currentFranchise.lastName}</h2>
    //                     </div>
    //                 </div>
    //             </div>

    //             <div className="col-12">
    //                 {/* {
    //                     activeTable === "admin" && ( */}
    //                         <div className="schoolAdminTableWrapper">
    //                             <div className="singleSchoolHeadingWrapper singleSchoolHeader mb-3">
    //                                 <h2>School List</h2>
    //                                 <Link to={`/add-franchise-school/${franchiseId}`}>
    //                                     <button className='custom-btn purple-bg'>Create School <AddCircleOutlineOutlinedIcon className='newStudentIcon' /></button>
    //                                 </Link>
    //                             </div>
    //                             <DataGrid
    //                                 rows={schools}
    //                                 getRowId={(rows) => rows._id}
    //                                 columns={schoolColumn}
    //                                 initialState={{
    //                                     pagination: {
    //                                         paginationModel: {
    //                                             pageSize: 10,
    //                                         },
    //                                     },
    //                                 }}
    //                                 pageSizeOptions={[10]}
    //                                 checkboxSelection
    //                                 disableRowSelectionOnClick
    //                             />
    //                         </div>
    //                     {/* )
    //                 } */}
    //             </div>
    //         </div>
    //     </div>
    // )
}

export default ViewFranchise
