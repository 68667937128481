import React, { useEffect, useState } from 'react';
import './EditNews.css';
import { userRequest } from '../../../requestMethod';
import { useDispatch, useSelector } from 'react-redux';
import { validateRequired } from '../../../formValidation';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { updateNewsFailure, updateNewsStart, updateNewsSuccess } from '../../../redux/newsRedux';
import { useLocation } from 'react-router-dom';
import { getAllNews } from '../../../redux/apiCalls';
import CircleLoader from '../../../components/circleLoader/CircleLoader';
import { MEDIA_URL, allowedImageFormats, isUrl, uploadProductImageToSpace } from '../../../config';
import Popup from '../../../global/Popup';

const EditNews = () => {

    const dispatch = useDispatch();

    // fetch all existing categories
    useEffect(() => {
        getAllNews(dispatch)
    }, [dispatch]);

    const location = useLocation();
    const newsId = location.pathname.split('/')[2];

    const newsDataFetching = useSelector((state) => state.news.isFetching);
    const newsData = useSelector((state) => state.news?.news?.find((item) => item._id == newsId));
    // const blogCategories = useSelector((state) => state.blogCategory.blogCategory);
    useEffect(() => {
        setBlog(newsData)
        // setTitle(blog.title);
        // setDescription(blog.description);
        // setCategory(blog.category);
        // setAuthor(blog.author);
        // setStatus(blog.status);
    }, [])
    const [blog, setBlog] = useState({});
    const [title, setTitle] = useState(newsData?.title);
    const [description, setDescription] = useState(newsData?.description);
    const [metaTitle, setMetaTitle] = useState(newsData?.metaTitle);
    const [metaDescription, setMetaDescription] = useState(newsData?.metaDescription);
    const [metaKeywords, setMetaKeywords] = useState(newsData?.metaKeywords);
    // const [category, setCategory] = useState(newsData?.category);
    const [author, setAuthor] = useState(newsData?.author);
    const [newsImage, setNewsImage] = useState(newsData?.newsImage);
    const [newsImagePreview, setNewsImagePreview] = useState(newsData?.newsImage);
    const [status, setStatus] = useState(newsData?.status);
    const [titleError, setTitleError] = useState(false);
    const [titleErrorMessage, setTitleErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [activeLoader, setActiveLoader] = useState(false);
    const [loaderText, setLoaderText] = useState("");
    const [popupStatus, setPopupStatus] = useState(false);
    const [activePopup, setActivePopup] = useState(false);
    const [popupText, setPopuptext] = useState("");

    useEffect(() => {
        setTitle(newsData?.title);
        setDescription(newsData?.description);
        setMetaTitle(newsData?.metaTitle);
        setMetaDescription(newsData?.metaDescription);
        setMetaKeywords(newsData?.metaKeywords);
        setAuthor(newsData?.author);
        setNewsImage(newsData?.newsImage);
        setStatus(newsData?.status);
        setNewsImagePreview(newsData?.newsImage);
    }, [newsData])

    const handleDescriptionChange = (value) => {
        setDescription(value);
    };


    const updateNews = async (dispatch, newsId, blog) => {
        dispatch(updateNewsStart());
        try {
            const res = await userRequest.put(`/news/${newsId}`, blog);
            if (res.status === 200) {
                setActiveLoader(false);
                setActivePopup(true)
                setPopupStatus(true);
                setPopuptext("News has been updated!")
                // setTitle('');
                // setDescription('');
                // setCategory('');
                // setAuthor('');
                // setStatus(false);
                // setnewsImage(null);
            }
            dispatch(updateNewsSuccess(res.data));
        } catch (error) {
            dispatch(updateNewsFailure());
            if (error.response?.status === 409) {
                setTitleError(true);
                setTitleErrorMessage(error.response.data);
            }

            else {
                // setError(true);
                // setErrorMessage("Something went wrong!");
                setActivePopup(true)
                setPopupStatus(false);
                setPopuptext("Request failed.")
            }
        }
    };

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const titleErr = validateRequired(title);
        const descErr = validateRequired(description);
        // const categoryErr = validateRequired(category);
        const authorErr = validateRequired(author);
        const newsImageErr = validateRequired(newsImage);

        if (titleErr) {
            formIsValid = false;
            newErrors.title = titleErr;
        }
        if (descErr) {
            formIsValid = false;
            newErrors.desc = descErr;
        }
        // if (categoryErr) {
        //     formIsValid = false;
        //     newErrors.category = categoryErr;
        // }

        if (authorErr) {
            formIsValid = false;
            newErrors.author = authorErr;
        }
        if (newsImageErr) {
            formIsValid = false;
            newErrors.newsImage = newsImageErr;
        }

        setErrors(newErrors);
        return formIsValid;
    }

    const handleNewsImageChange = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            // Check if the selected file is an image
            const isImage = allowedImageFormats.includes(files[0].type);
            if (!isImage) {
                // Show an error message or perform some action for invalid image format
                alert('Please select a valid image file (JPEG, PNG, or JPG)');
                event.target.value = null;
                setNewsImage('');
                return;
            } else {
                setNewsImage(files[0]);
                setNewsImagePreview(URL.createObjectURL(files[0]));
            }
        }
        else {
            setNewsImage(null);
            setNewsImagePreview(newsData && newsData.newsImage);
        }
    };


    const handleUpdate = async () => {
        setSuccess(false);
        setError(false);
        setTitleError(false);
        setActiveLoader(true)
        setLoaderText("Updating")
        const trimmedTitle = title.trim();
        let newsImageURL;

        if (newsImage?.name) {
            newsImageURL = await uploadProductImageToSpace(newsImage)
        }

        else {
            newsImageURL = newsImagePreview;
        }

        if (validateForm()) {
            updateNews(dispatch, newsId, { title: trimmedTitle, description, newsImage: newsImageURL, author, status, metaTitle, metaDescription,metaKeywords });
        }
    }



    const hidePopup = () => {
        setActivePopup(false);
    }

    if (newsDataFetching) {
        return (
            <div className='loader-wrapper'>
                <CircleLoader />
            </div>
        )
    }

    if (activePopup) {
        return (
            <Popup status={popupStatus} message={popupText} onClickAction={hidePopup} />
        )
    };

    return (
        <div className='container'>
            <div className="row mt-5 mb-3">
                <div className="col-12">
                    <div className="pageTitleWrapper">
                        <h2 className="page-heading">Update News</h2>
                    </div >
                </div >
            </div >
            <div className="row">
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>title</label>
                        <input type="text" name='title' value={title} className='newUserInput' onChange={(e) => setTitle(e.target.value)} placeholder='Title' required />
                        {
                            titleError && (
                                <p className='error-text text-danger'>{titleErrorMessage}</p>
                            )
                        }
                        <span className='error-text'>{errors.title}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>meta title</label>
                        <input type="text" name='meta title' value={metaTitle} className='newCourseInput' onChange={(e) => setMetaTitle(e.target.value)} placeholder='Meta Title' />
                    </div>
                </div>
                {/* <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Category</label>
                        <select name="ageGroup" value={category} onChange={(e) => setCategory(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value="latest">Latest News</option>
                            <option value="past">Past News</option>
                        </select>
                        <span className='error-text'>{errors.category}</span>
                    </div>
                </div> */}
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Author</label>
                        <input type="text" name='price' value={author} className='newUserInput' onChange={(e) => setAuthor(e.target.value)} placeholder='Author' />
                        <span className='error-text'>{errors.author}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Status</label>
                        <select name="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value="true" >Active</option>
                            <option value="false" >Draft</option>
                        </select>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Image</label>
                        <input type="file" name='newsImage' className='newUserInput' onChange={handleNewsImageChange} placeholder='Image' />
                        <span className='error-text'>{errors.newsImage}</span>
                    </div>
                    <div className="course-img-wrapper">
                        {/* <img src={newsImage} alt="blog-img" /> */}
                        {
                            isUrl(newsImagePreview) ? (
                                <img src={newsImagePreview || newsImage} alt="news-img" />
                            ) : (newsImage?.name) ? (
                                <img src={newsImagePreview || newsImage} alt="news-img" />
                            ) : (
                                <img src={MEDIA_URL + newsImagePreview} alt="news-img" />
                            )
                        }
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>description</label>
                        {/* <input type="text" name='description' value={description} className='newUserInput' onChange={(e) => setDescription(e.target.value)} placeholder='Description' /> */}
                        <ReactQuill value={description} onChange={handleDescriptionChange} />

                        <span className='error-text'>{errors.desc}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>meta description</label>
                        <input type="text" name='meta description' value={metaDescription} className='newCourseInput' onChange={(e) => setMetaDescription(e.target.value)} placeholder='Meta Description' />
                        {/* <span className='error-text'>{errors.desc}</span> */}
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>meta keywords</label>
                        <input type="text" name='meta description' value={metaKeywords} className='newCourseInput' onChange={(e) => setMetaKeywords(e.target.value)} placeholder='Meta Keywords' />
                        {/* <span className='error-text'>{errors.desc}</span> */}
                    </div>
                </div>
                <div className="col-12">
                    <div className="newStudentSubmitbtn mt-4">
                        <button className="custom-btn purple-bg" onClick={handleUpdate}>update</button>
                    </div>
                </div>

                <div className="col-12 mt-3">
                    {
                        success ? (
                            <div className='alert alert-success'>
                                {successMessage}
                            </div>
                        ) : error && (
                            <div className='alert alert-danger'>
                                {errorMessage}
                            </div>
                        )
                    }
                </div>
            </div>
            {
                activeLoader && (
                    <CircleLoader title={loaderText} />
                )
            }
        </div>
    )
}

export default EditNews
