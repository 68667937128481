// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.courseTable-img{
    width: auto;
    height: 80%;
    object-fit: contain;
}

.loader-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    justify-content: center;
    /* width: 100%; */
    height: 100vh;
    color: #ffffff;
    /* background: var(--purple); */
}

.deletePopupAlert{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
    width: 300px;
    margin: 0 auto;
    padding: 15px;
    box-shadow: var(--boxShadow);
    border-radius: 5px;
    /* position: relative;
    top: 50%;
    left: 50%; */
    /* transform: translate(-50%, -50%); */
    border-top: 10px solid var(--red);
    background: #fff;
}

.deletePopupAlert .deleteBtnsWrapper{
    display: flex;
    column-gap: 20px;
}

.deletePopupAlertWrapper{
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    background: #00000059;
}

.buttonsWrapper{
    column-gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/course/courseTable/CourseTable.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,iBAAiB;IACjB,aAAa;IACb,cAAc;IACd,+BAA+B;AACnC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,cAAc;IACd,aAAa;IACb,4BAA4B;IAC5B,kBAAkB;IAClB;;gBAEY;IACZ,sCAAsC;IACtC,iCAAiC;IACjC,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;IACV,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".courseTable-img{\n    width: auto;\n    height: 80%;\n    object-fit: contain;\n}\n\n.loader-wrapper{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    row-gap: 10px;\n    justify-content: center;\n    /* width: 100%; */\n    height: 100vh;\n    color: #ffffff;\n    /* background: var(--purple); */\n}\n\n.deletePopupAlert{\n    display: flex;\n    flex-direction: column;\n    row-gap: 10px;\n    align-items: center;\n    width: 300px;\n    margin: 0 auto;\n    padding: 15px;\n    box-shadow: var(--boxShadow);\n    border-radius: 5px;\n    /* position: relative;\n    top: 50%;\n    left: 50%; */\n    /* transform: translate(-50%, -50%); */\n    border-top: 10px solid var(--red);\n    background: #fff;\n}\n\n.deletePopupAlert .deleteBtnsWrapper{\n    display: flex;\n    column-gap: 20px;\n}\n\n.deletePopupAlertWrapper{\n    height: 100vh;\n    width: 100%;\n    position: absolute;\n    top: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 9;\n    background: #00000059;\n}\n\n.buttonsWrapper{\n    column-gap: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
