import React, { useEffect, useState } from 'react';
import '../../course/newCourse/NewCourse.css'
import { fileUploadRequest, userRequest } from '../../../requestMethod';
import { useDispatch, useSelector } from 'react-redux';
import { addProductStart, addProductSuccess, addProductFailure } from '../../../redux/productRedux';
import { validateRequired } from '../../../formValidation';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getAllAgeGroups, getAllCourseTypes, getAllLectureLibraries, getAllQuiz } from '../../../redux/apiCalls';
import { getLecturesFailure, getLecturesStart, getLecturesSuccess } from '../../../redux/lectureRedux';
import CircleLoader from '../../../components/circleLoader/CircleLoader';
import Popup from '../../../global/Popup';
// import { addRobotixProductFailure, addRobotixProductStart, addRobotixProductSuccess } from '../../../redux/robotixProductRedux';

// import 'firebase/storage';



const NewRobotixProduct = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeywords, setMetaKeywords] = useState('');
    const [ageGroup, setAgeGroup] = useState([]);
    const [status, setStatus] = useState(false);
    const [price, setPrice] = useState(null);
    const [priceVisibility, setPriceVisibility] = useState(true);
    const [priceInDollar, setPriceInDollar] = useState(null);
    const [freeInIndia, setFreeInIndia] = useState(false);
    const [freeOutOfIndia, setFreeOutOfIndia] = useState(false);
    const [isForSubscription, setIsForSubscription] = useState(false);
    const [titleError, setTitleError] = useState(false);
    const [titleErrorMessage, setTitleErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [productImage, setProductImage] = useState([null]);
    // const [productImage, setProductImage] = useState('');
    const [whatIsInTheBox, setWhatIsInTheBox] = useState('');
    const [whatsInTheBoxImage, setWhatsInTheBoxImage] = useState('');
    const [whatWillChildLearn, setWhatWillChildLearn] = useState('');
    const [whatsWillChildLearnImage, setWhatsWillChildLearnImage] = useState('');
    const [overview, setOverview] = useState(null);
    const [topics, setTopics] = useState([{ topicName: '', topicDetails: '' }]);
    const [skills, setSkills] = useState([{ skillName: '', skillDetails: '' }]);
    const [libraries, setLibraries] = useState([]);
    const [selectedLibrary, setSelectedLibrary] = useState('');
    const [videos, setVideos] = useState([]);
    const [selectedQuiz, setSelectedQuiz] = useState([]);
    const [documentFile, setDocumentFile] = useState([{ fileTitle: '', fileLocation: '' }]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [activeLoader, setActiveLoader] = useState(false);
    const [loaderText, setLoaderText] = useState("");
    const [activePopup, setActivePopup] = useState(false);
    const [popupText, setPopuptext] = useState('');
    const [numberOfSessions, setNumberOfSessions] = useState(null);
    const [popupStatus, setPopupStatus] = useState(true);
    const [fileInputs, setFileInputs] = useState([{ title: '', file: null }]);
    const [isBestSeller, setIsBestSeller] = useState(false)
    const [isRobotixProduct, setIsRobotixProduct] = useState(true)
    const [overviewImages, setOverviewImages] = useState([null]);
    const [videoThumbnail, setVideoThumbnail] = useState('');
    const [availability, setAvailability] = useState("all");

    const dispatch = useDispatch();

    useEffect(() => {
        getAllLectureLibraries(dispatch);
    }, [dispatch]);

    // fetch all existing course type
    useEffect(() => {
        getAllCourseTypes(dispatch);
    }, [dispatch]);

    // fetch all existing age groups
    useEffect(() => {
        getAllAgeGroups(dispatch);
    }, [dispatch]);

    // fetch all quiz
    useEffect(() => {
        getAllQuiz(dispatch);
    }, [dispatch]);

    const state = useSelector((state) => state)

    const lectureLibraries = useSelector((state) => state.lectureLibrary.lectureLibrary);
    const allQuiz = useSelector((state) => state.quiz.quiz);

    useEffect(() => {
        if (lectureLibraries) {
            setLibraries(lectureLibraries);
        }
    }, [lectureLibraries]);

    // handle library select 
    const handleLibrarySelect = async (event) => {
        const libraryId = event.target.value;
        setSelectedLibrary(libraryId);
        dispatch(getLecturesStart());
        setActiveLoader(true)
        setLoaderText("fetching")
        try {
            const res = await userRequest.get(`/product/all-lectures/${libraryId}`);
            setVideos(res.data)
            dispatch(getLecturesSuccess(res.data));
            if (res.data) {
                setActiveLoader(false)
            }
        } catch (error) {
            setActiveLoader(false)
            dispatch(getLecturesFailure());
        }
    };

    // handle price according to free in india property
    const handleFreeInIndia = (value) => {
        if (value === "true") {
            setFreeInIndia(true);
            setPrice("0");
        } else {
            setFreeInIndia(false)
        }
    }

    // handle price according to free out of india property
    const handleFreeOutOfIndia = (value) => {
        if (value === "true") {
            setFreeOutOfIndia(true);
            setPriceInDollar("0")
        } else {
            setFreeOutOfIndia(false)
        }
    }

    // Handle topic input
    const handleTopicChange = (index, event) => {
        const { name, value } = event.target;
        const updatedTopics = [...topics];
        updatedTopics[index][name] = value;
        setTopics(updatedTopics);
    };

    const addTopicField = () => {
        setTopics([...topics, { topicName: '', topicDetails: '' }]);
    };

    const removeTopicField = (index) => {
        if (topics.length > 1) {
            const updatedTopics = [...topics];
            updatedTopics.splice(index, 1);
            setTopics(updatedTopics);
        }
    };


    const removeDocumentFileField = (index) => {
        if (fileInputs.length > 1) {
            const updatedfileInputs = [...fileInputs];
            updatedfileInputs.splice(index, 1);
            setFileInputs(updatedfileInputs);
        }
    };

    // Handle skill input field
    const handleSkillChange = (index, event) => {
        const { name, value } = event.target;
        const updatedSkills = [...skills];
        updatedSkills[index][name] = value;
        setSkills(updatedSkills);
    };

    const addSkillField = () => {
        setSkills([...skills, { skillName: '', skillDetails: '' }]);
    };

    const removeSkillField = (index) => {
        if (skills.length > 1) {
            const updatedSkills = [...skills];
            updatedSkills.splice(index, 1);
            setSkills(updatedSkills);
        };
    };

    const allowedImageFormats = ['image/jpeg', 'image/png', 'image/gif'];
    const allowedDocumentFormats = ['application/pdf', 'application/vnd.ms-powerpoint', 'application/zip', 'application/octet-stream', 'application/vnd.h5p', 'application/octet-stream'];
    const allowedDocumentExtensions = ['.pdf', '.ppt', '.zip', '.h5p', '.pptx', '.sb3'];


    const handleFileChange = (event, fieldName) => {
        const files = event.target.files;
        const updatedSelectedFiles = { ...selectedFiles };
        if (files.length > 0) { // Check if any file is selected
            if (fieldName === 'forImage') {
                // Check if the selected file is an image
                const isImage = allowedImageFormats.includes(files[0].type);
                if (!isImage) {
                    // Show an error message or perform some action for invalid image format
                    alert('Please select a valid image file (JPEG, PNG, or GIF).');
                    event.target.value = null;
                    setProductImage('');
                    return;
                }
                else {
                    setProductImage(files[0].name);
                }
            } else if (fieldName === 'forDocument') {
                const selectedFile = files[0];
                const fileExtension = selectedFile.name.slice(((selectedFile.name.lastIndexOf(".") - 1) >>> 0) + 2); // Extract file extension


                // Check if the selected file is a document
                const isDocument = allowedDocumentFormats.includes(selectedFile.type) || allowedDocumentExtensions.includes(`.${fileExtension}`);
                if (!isDocument) {
                    // Show an error message or perform some action for invalid document format
                    alert('Please select a valid document file (PDF, PPT, or H5P).');
                    event.target.value = null;
                    setDocumentFile('');
                    return;
                }
                else {
                    setDocumentFile({ fileName: event.target.value, fileLocation: selectedFile });
                }
            }
            updatedSelectedFiles[fieldName] = [...files];
            setSelectedFiles(updatedSelectedFiles);
        };
    };


    const handleProductImageChange = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            // Check if the selected file is an image
            const isImage = allowedImageFormats.includes(files[0].type);
            if (!isImage) {
                // Show an error message or perform some action for invalid image format
                alert('Please select a valid image file (JPEG, PNG, or GIF).');
                event.target.value = null;
                setProductImage('');
                return;
            } else {
                setProductImage(files[0]);
            }
        }
    }
    const handleWhatsInBoxImageChange = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            // Check if the selected file is an image
            const isImage = allowedImageFormats.includes(files[0].type);
            if (!isImage) {
                // Show an error message or perform some action for invalid image format
                alert('Please select a valid image file (JPEG, PNG, or GIF).');
                event.target.value = null;
                setWhatsInTheBoxImage('');
                return;
            } else {
                setWhatsInTheBoxImage(files[0]);
            }
        }
    }
    const handleWhatWillChildLearnImageChange = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            // Check if the selected file is an image
            const isImage = allowedImageFormats.includes(files[0].type);
            if (!isImage) {
                // Show an error message or perform some action for invalid image format
                alert('Please select a valid image file (JPEG, PNG, or GIF).');
                event.target.value = null;
                setWhatsWillChildLearnImage('');
                return;
            } else {
                setWhatsWillChildLearnImage(files[0]);
            }
        }
    }



    const courseTypes = useSelector((state) => state.courseType.courseTypes);
    const ageGroups = useSelector((state) => state.ageGroup.ageGroups);

    const addProducts = async (dispatch, product) => {
        dispatch(addProductStart());
        setActiveLoader(true);
        try {
            const res = await userRequest.post('/product/create', product);

            if (res?.status === 200) {
                // setSuccess(true);
                // setSuccessMessage("Course has been added!");
                setActiveLoader(false);
                setActivePopup(true)
                setPopuptext("Product has been added.");
                setPopupStatus(true);
            }
            dispatch(addProductSuccess(res.data));
        } catch (error) {
            dispatch(addProductFailure());
            setActiveLoader(false);
            if (error?.response?.status === 409) {
                setTitleError(true);
                setTitleErrorMessage(error.response.data);
            }

            else {
                // setError(true);
                // setErrorMessage("Something went wrong!");
                setActivePopup(true)
                // setPopuptext(error.response.data);
                setPopupStatus(false);
            }
        }
    };


    const handleDocumentTitleChange = (index, value) => {
        const updatedInputs = [...fileInputs];
        updatedInputs[index].title = value;
        setFileInputs(updatedInputs);
    };

    const handleDocumentFileChange = (index, file) => {
        // Check if the selected file's format is allowed
        if (allowedDocumentFormats.includes(file.type) || allowedDocumentExtensions.some(ext => file.name.endsWith(ext))) {
            const updatedInputs = [...fileInputs];
            updatedInputs[index].file = file;
            setFileInputs(updatedInputs);
        } else {
            // Show an error message or perform some action for invalid file format
            alert('Please select a valid document file (PDF, PPT, or H5P).');
        }
    };

    const handleDocumentAddFileInput = () => {
        setFileInputs([...fileInputs, { title: '', file: null }]);
    };

    const [errors, setErrors] = useState({});


    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const titleErr = validateRequired(title);
        const descErr = validateRequired(description);
        const priceErr = validateRequired(price);
        const ageGroupErr = validateRequired(ageGroup);
        const overviewErr = validateRequired(overview);
        // const topicsErr = validateRequired(topics);
        const productImageErr = validateRequired(productImage);
        const shortDescriptionErr = validateRequired(shortDescription);

        if (titleErr) {
            formIsValid = false;
            newErrors.title = titleErr;
        }
        if (descErr) {
            formIsValid = false;
            newErrors.desc = descErr;
        }

        if (shortDescriptionErr) {
            formIsValid = false;
            newErrors.shortDescription = shortDescriptionErr;
        }

        if (priceErr) {
            formIsValid = false;
            newErrors.price = priceErr;
        }

        if (ageGroupErr) {
            formIsValid = false;
            newErrors.ageGroup = ageGroupErr;
        }

        if (overviewErr) {
            formIsValid = false;
            newErrors.overview = overviewErr;
        }

        if (productImageErr) {
            formIsValid = false;
            newErrors.productImage = productImageErr;
        }

        setErrors(newErrors);
        return formIsValid;
    }

    const handleDescriptionChange = (value) => {
        setOverview(value);
    };

    const handleWhatIsInTheBox = (value) => {
        setWhatIsInTheBox(value);
    };

    const handleWhatWillChildLearn = (value) => {
        setWhatWillChildLearn(value);
    };

    const uploadDocumentFileToSpace = async (file, title) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fileUploadRequest.post('/upload/file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response && response.status === 200) {
                const fileUrl = response.data.fileUrl;
                return { title, fileUrl };
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    // const handleAddOverviewImages = (e, index) => {
    //     const newImages = [...overviewImages];
    //     newImages[index] = e.target.files[0];
    //     setOverviewImages(newImages);
    // };

    const handleAddImageField = () => {
        setOverviewImages([...overviewImages, null]); // Add a new empty item to the state
    };

    const handleRemoveOverviewImage = (indexToRemove) => {
        // const newImages = [...overviewImages];
        // const filteredOverviewImages = newImages?.filter((_, index) => index !== indexToRemove);
        // setOverviewImages(filteredOverviewImages);
        if (indexToRemove >= 0 && indexToRemove < overviewImages.length) {
            // const newImages = [...overviewImages];
            // const filteredOverviewImages = newImages.filter((_, index) => index !== indexToRemove);
            // setOverviewImages(filteredOverviewImages);
            const newImages = [...overviewImages];
            const filteredOverviewImages = newImages.filter((_, index) => index !== indexToRemove);


            // Reset input field value to an empty string
            const inputField = document.getElementById(`imageInput-${indexToRemove}`);
            if (inputField) {
                inputField.value = '';
                setOverviewImages(filteredOverviewImages);
            }

        } else {
            console.error("Invalid index to remove:", indexToRemove);
        }
    };

    const uploadDocumentFiles = async () => {
        const uploadedFiles = await Promise.all(
            fileInputs.map((input) => uploadDocumentFileToSpace(input.file, input.title))
        );
        // Now you have an array of objects containing titles and file URLs
        // You can handle storing these URLs in your database or wherever needed.
        return uploadedFiles;
    };

    const handleAgeGroupChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
        setAgeGroup(selectedOptions);
    };


    const handleAddOverviewImages = (e) => {
        const files = e.target.files;
        const fileList = [];
        for (let i = 0; i < files.length; i++) {
            fileList.push(files[i]);
        }
        setOverviewImages(fileList);
    };

    const handleAddProductImages = (e) => {
        const files = e.target.files;
        const fileList = [];
        for (let i = 0; i < files.length; i++) {
            fileList.push(files[i]);
        }
        setProductImage(fileList);
    };


    const uploadProductImageToSpace = async (files) => {
        const formData = new FormData();

        if (files?.length > 0) {
            files.map((file) => {
                formData.append('files', file);
            });

            try {
                const response = await fileUploadRequest.post('/upload/multiple-files', formData);
                if (response && response.status === 200) {
                    const fileUrl = response.data.fileUrls;
                    // setProductImage(fileUrl); // Set the product image URL in state
                    return fileUrl
                }
            } catch (error) {
                console.error('Error uploading product image:', error);
            }
        } else {
            formData.append('file', files);
            try {
                const response = await fileUploadRequest.post('/upload/file', formData);
                if (response && response.status === 200) {
                    const fileUrl = response.data.fileUrl;
                    // setProductImage(fileUrl); // Set the product image URL in state
                    return fileUrl
                }
            } catch (error) {
                console.error('Error uploading product image:', error);
            }
        }
    };

    const uploadMultipleImages = async () => {
        const urls = await uploadProductImageToSpace(overviewImages);
        // const urls = await uploadProductImageToSpace(productImage);
    }




    const handleAddProduct = async () => {
        setSuccess(false);
        setError(false);
        setTitleError(false);
        const trimmedTitle = title.trim();
        setActiveLoader(true);
        setLoaderText('uploading');

        try {
            // const lectureData = combineData(videoTitles, selectedVideos, selectedQuizzes);
            if (validateForm()) {
                try {
                    const res = await userRequest.post('/robotix/product/search', trimmedTitle);
                    if (res && res.status == 200) {
                        // const documentFiles = await uploadDocumentFiles();
                        let productImageURL;
                        let overviewImagesURL;
                        if (overviewImages.length > 0) {
                            overviewImagesURL = await uploadProductImageToSpace(overviewImages);
                        }
                        if (productImage.length > 0) {
                            productImageURL = await uploadProductImageToSpace(productImage);
                        }

                        addProducts(dispatch, {
                            title: trimmedTitle,
                            description,
                            ageGroup,
                            status,
                            price,
                            productImg: productImageURL,
                            whatIsInTheBox,
                            whatWillChildLearn,
                            overview,
                            overviewImages: overviewImagesURL,
                            priceVisibility,
                            isForSubscription,
                            freeInIndia,
                            freeOutOfIndia,
                            priceInDollar,
                            bestSeller: isBestSeller,
                            shortDescription,
                            isRobotixProduct,
                            videoThumbnail,
                            availability,
                            metaTitle,
                            metaDescription,
                            metaKeywords
                        });
                    }
                } catch (error) {
                    console.error(error)
                    if (error.response && error.response.status === 409) {
                        setTitleError(true);
                        setTitleErrorMessage(error.response.data);
                    }

                    else {
                        setError(true);
                        setErrorMessage("Something went wrong!");
                    }
                }
            }
            setActiveLoader(false);
        } catch (error) {
            setActiveLoader(false);
            console.error('Error uploading files:', error);
            setError(true);
        }
    };

    const hidePopup = () => {
        setActivePopup(false);
    }

    if (activePopup) {
        return (
            <Popup status={popupStatus} message={popupText} onClickAction={hidePopup} />
        )
    }


    return (
        <div className='container'>
            <div className="row mt-5 mb-3">
                <div className="col-12">
                    <div className="pageTitleWrapper">
                        <h2 className="page-heading">Add Product</h2>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>title</label>
                        <input type="text" name='title' value={title} className='newCourseInput' onChange={(e) => setTitle(e.target.value)} placeholder='Title' required />
                        {
                            titleError && (
                                <p className='error-text text-danger'>{titleErrorMessage}</p>
                            )
                        }
                        <span className='error-text'>{errors.title}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>meta title</label>
                        <input type="text" name='meta title' value={metaTitle} className='newCourseInput' onChange={(e) => setMetaTitle(e.target.value)} placeholder='Meta Title' />
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>short description</label>
                        <input type="text" name='description' value={shortDescription} className='newCourseInput' onChange={(e) => setShortDescription(e.target.value)} placeholder='Short Description' />
                        <span className='error-text'>{errors.shortDescription}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>description</label>
                        <input type="text" name='description' value={description} className='newCourseInput' onChange={(e) => setDescription(e.target.value)} placeholder='Description' />
                        <span className='error-text'>{errors.desc}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>meta description</label>
                        <input type="text" name='meta description' value={metaDescription} className='newCourseInput' onChange={(e) => setMetaDescription(e.target.value)} placeholder='Meta Description' />
                        {/* <span className='error-text'>{errors.desc}</span> */}
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>meta keywords</label>
                        <input type="text" name='meta description' value={metaKeywords} className='newCourseInput' onChange={(e) => setMetaKeywords(e.target.value)} placeholder='Meta Keywords' />
                        {/* <span className='error-text'>{errors.desc}</span> */}
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Age Group</label>
                        <select name="ageGroup" value={ageGroup} onChange={handleAgeGroupChange} multiple>
                            <option value="" selected disabled>Select</option>
                            {
                                ageGroups.map((item, index) => {
                                    return <option value={item.ageGroup} key={index} >{item.ageGroup}</option>
                                })
                            }
                        </select>
                        <span className='error-text'>{errors.ageGroup}</span>

                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Price Visibility</label>
                        <select name="priceVisibility" value={priceVisibility} onChange={(e) => setPriceVisibility(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value="true" >Visible</option>
                            <option value="false" >Hidden</option>
                        </select>
                    </div>
                </div>
                {/* <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Add to best seller</label>
                        <select name="status" value={isBestSeller} onChange={(e) => setIsBestSeller(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value="true" >Yes</option>
                            <option value="false" >No</option>
                        </select>
                    </div>
                </div> */}
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Status</label>
                        <select name="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value="true" >Active</option>
                            <option value="false" >Draft</option>
                        </select>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Free In India</label>
                        <select name="status" value={freeInIndia} onChange={(e) => handleFreeInIndia(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value={"true"} >Yes</option>
                            <option value={"false"} >No</option>
                        </select>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Free Out Of India</label>
                        <select name="status" value={freeOutOfIndia} onChange={(e) => handleFreeOutOfIndia(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value={"true"} >Yes</option>
                            <option value={"false"} >No</option>
                        </select>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Course is available</label>
                        <select name="availability" value={availability} onChange={(e) => setAvailability(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value={"all"} >For All </option>
                            <option value={"onlyForIndia"} >Only For India</option>
                            <option value={"onlyForOutOfIndia"} >Only For Out Of India</option>
                        </select>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Subscription</label>
                        <select name="status" value={isForSubscription} onChange={(e) => setIsForSubscription(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value={true} >Yes</option>
                            <option value={false} >No</option>
                        </select>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>{isForSubscription === "true" ? "Price Per Month (In Rupees)" : "Price (In Rupees)"}</label>
                        <input type="text" name='price' value={price} className='newCourseInput' onChange={(e) => setPrice(e.target.value)} placeholder='Price' disabled={freeInIndia === true ? true : false} />
                        <span className='error-text'>{errors.price}</span>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>{isForSubscription === "true" ? "Price Per Month (In Dollar)" : "Price (In Dollar)"}</label>
                        <input type="text" name='price' value={priceInDollar} className='newCourseInput' onChange={(e) => setPriceInDollar(e.target.value)} disabled={freeOutOfIndia === true ? true : false} placeholder='Price' />
                        <span className='error-text'>{errors.priceInDollar}</span>
                    </div>
                </div>
                {/* <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Add to best seller</label>
                        <select name="status" value={isBestSeller} onChange={(e) => setIsBestSeller(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value="true" >Yes</option>
                            <option value="false" >No</option>
                        </select>
                    </div>
                </div> */}
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Image</label>
                        {/* <input type="file" name='file' className='newCourseInput' onChange={handleProductImageChange} placeholder='Price' /> */}
                        <input
                            type="file"
                            name={`files`}
                            className="newCourseInput"
                            onChange={handleAddProductImages}
                            multiple
                            accept='image/*'
                        />
                        <span className='error-text'>{errors.productImage}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Video Thumbnail</label>
                        <input type="text" name='video-url' className='newCourseInput' onChange={(e) => setVideoThumbnail(e.target.value)} placeholder='Video URL' />
                        {/* <span className='error-text'>{errors.productImage}</span> */}
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>overview</label>
                        <ReactQuill value={overview} onChange={handleDescriptionChange} />
                        <span className='error-text'>{errors.overview}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Overview Images</label>
                        <input
                            type="file"
                            name={`files`}
                            className="newCourseInput"
                            onChange={handleAddOverviewImages}
                            multiple
                            accept='image/*'
                        />
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>What is in the box </label>
                        <ReactQuill modules={{
                            toolbar: [
                                [{ header: [1, 2, false] }],
                                ['bold', 'italic', 'underline'],
                                ['image', 'code-block'],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                            ]
                        }} theme="snow" value={whatIsInTheBox} onChange={handleWhatIsInTheBox} />
                        {/* <span className='error-text'>{errors.overview}</span> */}
                    </div>
                </div>
                {/* <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>What is in the box (Image) </label>
                        <input type='file' className='newCourseInput'
                            onChange={handleWhatsInBoxImageChange}
                            // placeholder="Type here..."
                        />
                        <span className='error-text'>{errors.overview}</span>
                    </div>
                </div> */}
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>What will child learn</label>
                        <ReactQuill modules={{
                            toolbar: [
                                [{ header: [1, 2, false] }],
                                ['bold', 'italic', 'underline'],
                                ['image', 'code-block'],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                            ]
                        }} theme="snow" value={whatWillChildLearn} onChange={handleWhatWillChildLearn} />
                        {/* <textarea className='newCourseInput'
                            name="whatsWillChildLearnText"
                            value={whatsWillChildLearn}
                            onChange={(e) => setWhatsWillChildLearn(e.target.value)}
                            placeholder="Type here..."
                        /> */}
                        {/* <span className='error-text'>{errors.overview}</span> */}
                    </div>
                </div>
                {/* <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>What will child learn (Image) </label>
                        <input type='file' className='newCourseInput'
                            onChange={handleWhatWillChildLearnImageChange}
                        />
                        <span className='error-text'>{errors.overview}</span>
                    </div>
                </div> */}
                {/* <div className="col-12">
                    <div className="form-input-wrapper">
                        <div className="topics-header">
                            <label>Topic</label>
                            <button type="button" className='custom-btn' onClick={addTopicField}>
                                Add Topic
                            </button>
                        </div>

                        {topics.map((topic, index) => {
                            return (<>
                                <div key={index} className='row'>
                                    <div className="col-12 pb-3">
                                        <input className='newCourseInput'
                                            type="text"
                                            name="topicName"
                                            value={topic.topicName}
                                            onChange={(event) => handleTopicChange(index, event)}
                                            placeholder="Enter topic name"
                                        />
                                    </div>
                                    <div className="col-11">
                                        <textarea className='newCourseInput'
                                            name="topicDetails"
                                            value={topic.topicDetails}
                                            onChange={(event) => handleTopicChange(index, event)}
                                            placeholder="Enter topic details"
                                        />
                                    </div>
                                    <div className="col-1">
                                        <div className="remove-btn-wrapper">
                                            <CancelIcon className={`${topics.length > 1 ? "activeRemoveIcon" : "inactiveRemoveIcon"}`}
                                                onClick={() => removeTopicField(index)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>)
                        })}
                    </div>
                </div> */}
                {/* <div className="col-12">
                    <div className="form-input-wrapper">
                        <div className="topics-header">
                            <label>Skill</label>
                            <button type="button" className='custom-btn' onClick={addSkillField}>
                                Add Skill
                            </button>
                        </div>

                        {skills.map((skill, index) => {
                            return (<>
                                <div key={index} className='row'>
                                    <div className="col-12 pb-3">
                                        <input className='newCourseInput'
                                            type="text"
                                            name="skillName"
                                            value={skill.skillName}
                                            onChange={(event) => handleSkillChange(index, event)}
                                            placeholder="Enter skill name"
                                        />
                                    </div>
                                    <div className="col-11">
                                        <textarea className='newCourseInput'
                                            name="skillDetails"
                                            value={skill.skillDetails}
                                            onChange={(event) => handleSkillChange(index, event)}
                                            placeholder="Enter skill details"
                                        />
                                    </div>
                                    <div className="col-1">
                                        <div className="remove-btn-wrapper">
                                            <CancelIcon className={`${skills.length > 1 ? "activeRemoveIcon" : "inactiveRemoveIcon"}`}
                                                onClick={() => removeSkillField(index)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>)
                        })}
                    </div>
                </div> */}

                {/* <div className="col-12">
                    <div className="form-input-wrapper">
                        <div className="topics-header">
                            <label>Chapter</label>
                            <button type="button" className='custom-btn' >
                                Add Chapter
                            </button>
                        </div>
                    </div>
                </div> */}

                {/* <div className="col-12">
                    <div className="form-input-wrapper">
                        <label htmlFor="library">Video Library</label>
                        <select id="library" value={selectedLibrary} onChange={handleLibrarySelect}>
                            <option value="">Select Library</option>
                            {
                                libraries && (
                                    libraries.map(library => {
                                        const id = library.uri.split('/')[4]
                                        return (
                                            <option key={library.id} value={id}>{library.name}</option>
                                        )
                                    }))
                            }
                        </select>
                    </div>
                </div> */}

                {/* <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Select Videos</label>
                        <ul className='select-video-ul'>
                            {videos.map((video, index) => {
                                const id = video.uri.split('/')[2];
                                const isSelected = selectedVideos.includes(id);
                                return (
                                    <li key={index}
                                        onClick={() => handleVideoClick(id, index)} className='mb-3'>
                                        <p className={isSelected ? 'select-video selected-video' : 'select-video'}>{video.name}</p>
                                        {isSelected && (
                                            <>
                                                <input
                                                    className='newCourseInput mb-3'
                                                    type="text"
                                                    value={videoTitles[index]}
                                                    placeholder="Video Title"
                                                    onChange={(e) => handleVideoTitleChange(e, index)}
                                                    onClick={(e) => e.stopPropagation()}
                                                />
                                                <select
                                                    multiple
                                                    value={selectedQuizzes[index] || []}
                                                    onChange={(e) => handleQuizSelect(e, index)} onClick={(e) => e.stopPropagation()}>
                                                    {
                                                        allQuiz && allQuiz.map((quiz, index) => {
                                                            return (
                                                                <option key={index} value={quiz._id}>{quiz.title}</option>
                                                            )
                                                        })}
                                                </select>
                                            </>
                                        )}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div> */}

                {/* <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Number of sessions</label>
                        <input type="text" className='newCourseInput' onChange={(e) => setNumberOfSessions(e.target.value)} placeholder='No. of sessions' />
                        <span className='error-text'>{errors.numberOfSessions && errors.numberOfSessions}</span>
                    </div>
                </div> */}

                {/* <div className="col-12">
                    <label>Document File (PPT, PDF, H5P)</label>
                    <div className="form-input-wrapper">
                        <label>Choose file</label>
                        <input className='newCourseInput'
                            type="text"
                            name='file'
                            placeholder='Title for file'
                        />
                        <input className='newCourseInput'
                            type="file"
                            id="documentFile"
                            // accept=".ppt,.pptx,application/pdf,.h5p"
                            // onChange={(event) => handleFileChange(event, 'forDocument')}
                            name='file'
                        />
                        <span className='error-text'>{errors.documentFile}</span>
                    </div>
                </div> */}
                {/*  */}


                {/* <button onClick={handleUpload}>Upload Files</button> */}

                <div className="col-12">
                    <div className="newStudentSubmitbtn">
                        <button className="custom-btn purple-bg" onClick={handleAddProduct}>
                            {/* <button className="custom-btn purple-bg" onClick={handleAddProduct}> */}
                            Submit
                        </button>
                    </div>
                </div>

                <div className="col-12 mt-3">
                    {
                        success ? (
                            <div className='alert alert-success'>
                                {successMessage}
                            </div>
                        ) : error && (
                            <div className='alert alert-danger'>
                                {errorMessage}
                            </div>
                        )
                    }
                </div>
            </div>
            {
                activeLoader && (
                    <CircleLoader title={loaderText} />
                )
            }
        </div>

    )
}

export default NewRobotixProduct
