import React, { useEffect, useState } from 'react';
// import './CourseTable.css';
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline, Visibility } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useDispatch, useSelector } from 'react-redux';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CircleLoader from '../../../../../components/circleLoader/CircleLoader';
import { userRequest } from '../../../../../requestMethod';


const Attendance = () => {

    const [deletePopup, setDeletePopup] = useState(false);
    const [deletingItem, setDeletingItem] = useState(null);
    const [productImageName, setProductImageName] = useState(null);
    const [userId, setUserId] = useState(null);
    const [attendance, setAttendance] = useState([]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const userParam = params.get('user');
        setUserId(userParam);
    }, [userId]);



    const handlePopup = (id, filename) => {
        setDeletePopup(true)
        setDeletingItem(id)
        setProductImageName(filename);
    };

    const handlePopupCancel = () => {
        setDeletePopup(false);
    }


    const getAttendanceData = async () => {
        try {
            const res = await userRequest.get(`/attendance/find`, {
                params: {
                    user: userId
                }
            });

            if (res?.status === 200) {
                setAttendance(res.data)
            }
        } catch (error) {
        }
    }

    useEffect(() => {
        if (userId) {
            getAttendanceData()
        }
    }, [userId]);

    const { isFetching, products: course } = useSelector((state) => state.products);

    // Add index numbers to the course array
    const attendanceWithIndex = attendance?.map((attendance, index) => ({
        ...attendance,
        index: index + 1, // Add 1 to the index to start from 1 instead of 0
    }));


    // const [courseWithIndex, setCourseWithIndex] = useState([]);

    // useEffect(() => {
    //     setCourseWithIndex(addRowIndex(course));
    // }, [course, dispatch]);


    // const deleteCourse = (productId, filename) => {
    //     deleteProducts(productId, filename, dispatch);
    //     setDeletePopup(false);
    // }

    const columns = [
        {
            field: 'index', headerName: '#', width: 50,
        },
        // {
        //     field: 'productImg',
        //     headerName: 'Image',
        //     width: 100,
        //     editable: false,
        //     sortable: false,
        //     renderCell: (params) => {
        //         if(isUrl(params.value)){
        //             return <img src={params.value} className='courseTable-img' alt="product-img" />
        //         }
        //         else{
        //             return <img src={ MEDIA_URL + params.value} className='courseTable-img' alt="product-img" />
        //         }
        //     }
        // },
        {
            field: 'firstName',
            headerName: 'Student Name',
            width: 200,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 200,
            editable: false,
        },

        {
            field: 'attendanceDate',
            headerName: 'Date',
            description: 'This column has a value getter and is not sortable.',
            sortable: true,
            width: 100,
        },

        {
            field: 'attendanceStatus',
            headerName: 'Status',
            description: 'This column has a value getter and is not sortable.',
            sortable: true,
            width: 100,
            renderCell: (params) => {
                return params.row.status ? "Present" : "Absent";
            }
        },
        // {
        //     field: 'status',
        //     headerName: 'Status',
        //     // description: 'This column has a value getter and is not sortable.',
        //     sortable: false,
        //     width: 100,
        //     renderCell: (params) => {
        //         return params.row.status ? "Active" : "Draft";
        //     }
        // },
        // {
        //     field: 'edit',
        //     headerName: 'Edit',
        //     sortable: false,
        //     width: 100,
        //     renderCell: (params) => {
        //         return (
        //             <>
        //                 <Link to={'/edit-course/' + params.id}>
        //                     <button className='btn btn-primary studentListEdit'>Edit</button>
        //                 </Link>
        //             </>
        //         )
        //     }
        // },
        // {
        //     field: 'delete',
        //     headerName: 'Delete',
        //     sortable: false,
        //     width: 100,
        //     renderCell: (params) => {
        //         const courseId = params.row._id;
        //         const productImageURL = params.row.productImg;
        //         const productDocumentURL = params.row.document;

        //         // const productImageName = extractFileName(productImageURL);
        //         // const documentFileName = extractFileName(productDocumentURL[0]);
        //         // Create a URL object
        //         // const url = new URL(productImageURL);
        //         // // Extract the filename
        //         // const filename = url.pathname.split('/').pop();

        //         return (
        //             <>
        //                 <DeleteOutline onClick={() => handlePopup(courseId, productImageName)} className='courseListDelete text-danger' />
        //             </>
        //         )
        //     }
        // },
    ];

    if (isFetching) {
        return (
            <div className='loader-wrapper'>
                <CircleLoader />
            </div>
        )
    }

    return (

        <div className='py-5'>
            <div className="row">
                <div className="col-12">
                    <div className="tableButtonWrapper d-flex justify-content-between mb-3">
                        <h2>Attendance Table</h2>
                        {/* <div className="buttonsWrapper d-flex gx-2">
                            <Link to='/course-age-groups'>
                                <button className='custom-btn purple-bg'>View Age Groups <Visibility className='newStudentIcon' /></button>
                            </Link>
                            <Link to='/course-types'>
                                <button className='custom-btn purple-bg'>View Course Type <Visibility className='newStudentIcon' /></button>
                            </Link>
                            <Link to='/add-course'>
                                <button className='custom-btn purple-bg'>New Course <AddCircleOutlineOutlinedIcon className='newStudentIcon' /></button>
                            </Link>
                        </div> */}

                    </div>
                </div>
            </div>
            {/* {
                deletePopup && (
                    <div className="deletePopupAlertWrapper">
                        <div className="deletePopupAlert">
                            <WarningAmberIcon style={{ color: "var(--orange)", fontSize: "40px" }} />
                            <h4>Warning</h4>
                            <div className="deleteAlertTextWrapper text-center">
                                <p>This course will be deleted permanently.</p>
                                <p>Are you sure?</p>
                            </div>

                            <div className="deleteBtnsWrapper">
                                <button className='btn' onClick={handlePopupCancel} style={{ background: "var(--green)", color: "var(--white)" }}>Cancel</button>
                                <button className='btn' onClick={() => deleteCourse(deletingItem, productImageName)} style={{ background: "var(--red)", color: "var(--white)" }}>Delete</button>
                            </div>
                        </div>
                    </div>
                )
            } */}

            {
                attendance && (
                    <DataGrid
                        rows={attendanceWithIndex}
                        getRowId={(rows) => rows._id}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[10]}
                        checkboxSelection={false}
                        disableRowSelectionOnClick
                    />
                )
            }

        </div>
    )
}

export default Attendance
