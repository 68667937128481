export const validateRequired = (value, type) => {
    if (!value) {
        return 'This field is required!';
    }
    if (type === 'image' || type == 'file') {
        if(value.name === null || value.name === '' || value.name === 'undefined'){
            return 'This field is required!';
        }
    }

    if(typeof value === "string"){
        if(value.trim() === ''){
            return 'Only white space not allowed!';
        }
    }

    if(typeof value === "object"){
        if(value.length < 1){
            return 'This field is required!';
        }
    }

    if(type === "number"){
        if (isNaN(value)) {
            return 'Value should be numeric only';
        }
    }



    // if(typeof value === "number"){
    //     if(value.length > 10){
    //         return 'Phone number can have 10 digits only';
    //     }
    // }

};