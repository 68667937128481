import React, { useState } from 'react';
import './ForgotPassword.css';
import { Link } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';
import { validateRequired } from '../../formValidation';
import { publicRequest } from '../../requestMethod';

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [isLoaderActive, setIsLoaderActive] = useState(false);
    const [userNotFoundError, setUserNotFoundError] = useState(false);
    const [userNotFoundErrorMessage, setUserNotFoundErrorMessage] = useState("");
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [otp, setOtp] = useState(null);
    const [isEnteredOtpCorrect, setIsEnteredOtpCorrect] = useState(true);
    const [newPassword, setNewPassword] = useState(null);
    const [confimNewPassword, setConfimNewPassword] = useState(null);
    const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
    const [resetPasswordError, setResetPasswordError] = useState(false);
    const [resetPasswordErrorMessage, setResetPasswordErrorMessage] = useState("");

    const [showResetBlock, setShowResetBlock] = useState(false);
    const [showForgotBlock, setShowForgotBlock] = useState(true);


    // Validate function for form validation start=======
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const emailValidErr = validateRequired(email, "email");
        // const passwordValidErr = validateRequired(password);
        const newPasswordValidErr = validateRequired(newPassword);
        const confimNewPasswordValidErr = validateRequired(confimNewPassword);
        const otpValidErr = validateRequired(otp);

        if (emailValidErr && !isOtpSent) {
            formIsValid = false;
            newErrors.email = "Please enter your email";
        }
        if (newPasswordValidErr && isOtpSent) {
            formIsValid = false;
            newErrors.newPassword = "Please enter new password";
        }
        if (confimNewPasswordValidErr && isOtpSent) {
            formIsValid = false;
            newErrors.confimNewPassword = "Please enter new password again";
        }

        if ((newPassword !== confimNewPassword) && isOtpSent) {
            formIsValid = false;
            newErrors.confimNewPassword = "Password did not matched";
        }

        if (otpValidErr && isOtpSent) {
            formIsValid = false;
            newErrors.otp = "Please enter otp";
        }

        setErrors(newErrors);
        return formIsValid;
    }

    const getOtpToResetPassword = async (email) => {
        setIsLoaderActive(true);
        try {
            const res = await publicRequest.post('/auth/forgot-password', { email: email });
            if (res?.status === 200) {
                setShowForgotBlock(false)
                setIsOtpSent(true);
                setIsLoaderActive(false);
                setUserNotFoundError(false);
            }
        } catch (error) {
            setIsLoaderActive(false);
            if (error.response.status === 404) {
                setUserNotFoundError(true);
                setUserNotFoundErrorMessage(error.response.data);
            }
        }
    }

    const submitNewPassword = async (data) => {
        setIsLoaderActive(true);
        try {
            const res = await publicRequest.post('/auth/reset-password', data);
            if (res.status === 200) {
                setResetPasswordSuccess(true);
                setIsLoaderActive(false);
                setShowForgotBlock(true);
                setIsOtpSent(false);
                // setIsSignInFormVisible(true);
                // setIsForgotPasswordFormVisible(false);
            }
        } catch (error) {
            setIsLoaderActive(false);
            if (error?.response?.status === 400) {
                setResetPasswordError(true)
                setResetPasswordErrorMessage(error?.response?.data)
            }
            setResetPasswordSuccess(false)
        }
    }

    const handleGetOtp = () => {
        if (validateForm()) {
            getOtpToResetPassword(email)
        }
    }

    const handleResetPassword = () => {
        if (validateForm()) {
            submitNewPassword({ newPassword, email, otp })
        }
    }


    // const handleForgotSubmit = () => {

    // }

    //   const handleSubmit = async () => {
    //     setUserNotFoundError(false);
    //     setPasswordError(false);
    //   };

    return (
        <div className='w-100'>
            <div className="row m-0">
                <div className="col-12 p-0">
                    <div className="login-topbar">
                        <div className="loginPageLogoWrapper">
                            <img src={`${process.env.PUBLIC_URL}/images/logo/brand-logo.svg`} alt="" />
                        </div>
                    </div>

                </div>
            </div>
            <div className="container pt-50 pb-50">
                <div className="row d-flex align-items-center">
                    <div className="col-md-6">
                        <img src="../../images/pages/forgot-password.jpg" />
                    </div>
                    <div className="col-md-6">
                        {
                            showForgotBlock && (
                                <div className="loginContainer">
                                    <div className="login-wrapper">
                                        <h1 className="text-uppercase my-3">
                                            Forgot <span>Password</span>
                                        </h1>
                                        {
                                            resetPasswordSuccess && (
                                                <div class="alert alert-success w-100" role="alert">
                                                    Your password has been updated!
                                                </div>
                                            )
                                        }
                                        <div className="w-100">
                                            <label for="email" className="form-label" style={{ paddingLeft: "10px" }}>
                                                Email
                                            </label>
                                            <input
                                                type="text"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                placeholder="Enter your email"
                                                id='email'
                                            />
                                            <div className="error-text">{errors.email}</div>

                                            {userNotFoundError && (
                                                <p className="error-text text-danger pl-3 mt-2 mb-0">
                                                    {userNotFoundErrorMessage}
                                                </p>
                                            )}
                                        </div>
                                        <div className="login-btn-wrapper">
                                            <div className="coupon-btn-wrapper ">
                                                <button type="submit" className={`${isLoaderActive ? 'button-loader load' : 'submit-coupon'}`} onClick={handleGetOtp}><span>Get OTP</span></button>
                                            </div>
                                            <Link to={'/'}>
                                            <button>
                                                Login
                                            </button>
                                            </Link>
                                            {/* <button type="submit" onClick={handleSubmit}>
                                        Submit
                                    </button> */}
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        {
                            isOtpSent && (
                                <>
                                    <div className="loginContainer">
                                        <div className="login-wrapper">
                                            <h1 className="text-uppercase my-3">
                                                Reset <span>Password</span>
                                            </h1>
                                            <div className="login-inner-wrapper w-100">
                                                <input type="text" placeholder="Enter your email" disabled={true} value={email} onChange={(e) => setEmail(e.target.value)} />
                                                <p className="mt-3">We've sent an OTP on your email.</p>
                                                <input type="text" placeholder="Enter otp here" value={otp} onChange={(e) => setOtp(e.target.value)} />
                                                <div className="error-text">{errors.otp}</div>
                                                {
                                                    resetPasswordError && (
                                                        <div className="error-text mb-1">{resetPasswordErrorMessage}</div>
                                                    )
                                                }
                                                <input type="text" placeholder="New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                                <div className="error-text">{errors.newPassword}</div>
                                                <input type="text" placeholder="Confirm Password" value={confimNewPassword} onChange={(e) => setConfimNewPassword(e.target.value)} />
                                                <div className="error-text">{errors.confimNewPassword}</div>
                                                <div className="error-text">{errors.password}</div>
                                                <div className="login-btn-wrapper">
                                                    <div className="coupon-btn-wrapper ">
                                                        <button type="submit" className={`${isLoaderActive ? 'button-loader load' : 'submit-coupon'}`} onClick={handleResetPassword}><span>Submit</span></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </>
                            )
                        }

                        {
                            showResetBlock && (
                                <div className="loginContainer">
                                    <div className="login-wrapper">
                                        <h1 className="text-uppercase my-3">
                                            Forgot <span>Password</span>
                                        </h1>
                                        <div className="w-100">
                                            <label for="email" className="form-label" style={{ paddingLeft: "10px" }}>
                                                Email
                                            </label>
                                            <input
                                                type="text"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                placeholder="Enter your email"
                                                required
                                                id='email'
                                            />
                                            {/* {userNotFoundError && (
                                        <p className="error-text text-danger">
                                            {userNotFoundErrorMessage}
                                        </p>
                                    )} */}
                                        </div>
                                        <div className="login-btn-wrapper">
                                            {/* <button type="submit">
                                                Submit
                                            </button> */}
                                            <button type="submit" onClick={getOtpToResetPassword}>
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <section class="pb-100 pt-100 loginPage_login_signup">
                <div class="row d-flex justify-content-center m-5">
                    <div class="col-md-4">
                        <div class="text-center">
                            <Link to={'/login'}>
                                <div class="loginPage_icons">
                                    <div>
                                        <EmailIcon size={40} />
                                    </div>
                                    <h5>Support</h5>
                                    <p>Raise a support token</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="text-center">
                            <Link href={'https://meritus.ai'} target="_blank">
                                <div class="loginPage_icons">
                                    <div>
                                        <LanguageIcon size={40} />
                                    </div>
                                    <h5>Browse Website</h5>
                                    <p>Visit the website</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ForgotPassword
