import { createSlice } from "@reduxjs/toolkit";

const robotixProductReducer = createSlice({
    name: 'robotixProduct',
    initialState: {
        robotixProducts: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get All Product
        getRobotixProductStart: (state) => {
            state.isFetching = true;
        },
        getRobotixProductSuccess: (state, action) => {
            state.isFetching = false;
            state.robotixProducts = action.payload;
        },
        getRobotixProductFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Delete Product                         
        deleteRobotixProductStart: (state) => {
            state.isFetching = true;
        },
        deleteRobotixProductSuccess: (state, action) => {
            state.isFetching = false;
            state.robotixProducts.splice(
                state.robotixProducts.findIndex((item) => item._id === action.payload), 1
            );
        },
        deleteRobotixProductFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Update Product
        updateRobotixProductStart: (state) => {
            state.isFetching = true;
        },
        updateRobotixProductSuccess: (state, action) => {
            state.isFetching = false;
            const productIndex = state.robotixProducts.findIndex(product => product._id === action.payload._id);
            if (productIndex !== -1) {
                state.robotixProducts[productIndex] = action.payload;
            }
        },
        updateRobotixProductFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        //  Add Product
        addRobotixProductStart: (state) => {
            state.isFetching = true;
        },
        addRobotixProductSuccess: (state, action) => {
            state.isFetching = false;
            // state.products = action.payload;
            const newData = action.payload;
            state.robotixProducts = [ ...state.robotixProducts, newData ];
        },
        addRobotixProductFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const { getRobotixProductStart, getRobotixProductSuccess, getRobotixProductFailure, deleteRobotixProductStart, deleteRobotixProductSuccess, deleteRobotixProductFailure, updateRobotixProductStart, updateRobotixProductSuccess, updateRobotixProductFailure, addRobotixProductStart, addRobotixProductSuccess, addRobotixProductFailure } = robotixProductReducer.actions;
export default robotixProductReducer.reducer;