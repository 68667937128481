import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

const AllMeritusAdminComponents = () => {
    const { isFetching, currentUser: userType } = useSelector((state) => state.user);
    let auth;

    if (userType) {
        if (userType.isMeritusSuperAdmin || userType.isMeritusAdmin) {
            auth = true;
        }
        else{
            auth = false;
        }
    }

    return auth ? <Outlet /> : <Navigate to='/' />
}

export default AllMeritusAdminComponents
