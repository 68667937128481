import { createSlice } from "@reduxjs/toolkit";

const courseTypeReducer = createSlice({
    name : "courseType",
    initialState : {
        courseTypes : [],
        isFetching : false,
        error : false,
    },

    reducers : {

        // action for add course type
        addCourseTypeStart : (state) => {
            state.isFetching = true;
        },

        addCourseTypeSuccess : (state, action) => {
            state.isFetching = false;
            const newData = action.payload;
            state.courseTypes = [ ...state.courseTypes, newData ];
        },
        addCourseTypeFailure : (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // action to get all course type
        getCourseTypeStart : (state) => {
            state.isFetching  = true;
        },

        getCourseTypeSuccess : (state, action) => {
            state.isFetching = false;
            state.courseTypes = action.payload;
        },

        getCourseTypeFailure : (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // action to delete age groups
        deleteCourseTypeStart : (state) => {
            state.isFetching = true;
        },

        deleteCourseTypeSuccess : (state, action) => {
            state.isFetching = false;
            state.courseTypes.splice(
                state.courseTypes.findIndex((item) => item._id === action.payload), 1
            );
        },
        deleteCourseTypeFailure : (state) => {
            state.isFetching = false;
            state.error = true
        },
    }
 });

export const { addCourseTypeStart, addCourseTypeSuccess, addCourseTypeFailure, getCourseTypeStart, getCourseTypeSuccess, getCourseTypeFailure, deleteCourseTypeStart, deleteCourseTypeSuccess, deleteCourseTypeFailure  } = courseTypeReducer.actions;

export default courseTypeReducer.reducer;