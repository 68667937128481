import { createSlice } from "@reduxjs/toolkit";

const blogCategoryReducer = createSlice({
    name: 'blogCategory',
    initialState: {
        blogCategory: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // action for add blog category
        addBlogCategoryStart: (state) => {
            state.isFetching = true;
        },

        addBlogCategorySuccess: (state, action) => {
            state.isFetching = false;
            const newData = action.payload;
            state.blogCategory = [...state.blogCategory, newData];
        },

        addBlogCategoryFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Update Blog
        updateBlogCategoryStart: (state) => {
            state.isFetching = true;
        },
        updateBlogCategorySuccess: (state, action) => {
            state.isFetching = false;
            const blogCategoryIndex = state.blogCategory.findIndex(BlogCategory => BlogCategory._id === action.payload._id);

            if (blogCategoryIndex !== -1) {
                state.blogCategory[blogCategoryIndex] = action.payload;
            }
        },
        updateBlogCategoryFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // action to get all blog category

        getBlogCategoryStart: (state) => {
            state.isFetching = true;
        },

        getBlogCategorySuccess: (state, action) => {
            state.isFetching = false;
            state.blogCategory = action.payload;
        },

        getBlogCategoryFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // action to delete blog category
        deleteBlogCategoryStart: (state) => {
            state.isFetching = true;
        },

        deleteBlogCategorySuccess: (state, action) => {
            state.isFetching = false;
            state.blogCategory.splice(
                state.blogCategory.findIndex((item) => item._id === action.payload), 1
            );
        },
        deleteBlogCategoryFailure: (state) => {
            state.isFetching = false;
            state.error = true
        },
    }
});

export const { addBlogCategoryStart, addBlogCategorySuccess, addBlogCategoryFailure, getBlogCategoryStart, getBlogCategorySuccess, getBlogCategoryFailure, deleteBlogCategoryStart, deleteBlogCategorySuccess, deleteBlogCategoryFailure, updateBlogCategoryStart, updateBlogCategorySuccess, updateBlogCategoryFailure } = blogCategoryReducer.actions;
export default blogCategoryReducer.reducer;