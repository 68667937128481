
import { createSlice } from "@reduxjs/toolkit";

const schoolFormQueriesReducer = createSlice({
    name: 'schoolFormQueries',
    initialState: {
        queries: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get All Blog
        getSchoolQueriesStart: (state) => {
            state.isFetching = true;
        },
        getSchoolQueriesSuccess: (state, action) => {
            state.isFetching = false;
            state.queries = action.payload;
        },
        getSchoolQueriesFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const { getSchoolQueriesStart, getSchoolQueriesSuccess, getSchoolQueriesFailure } = schoolFormQueriesReducer.actions;
export default schoolFormQueriesReducer.reducer;