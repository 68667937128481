import React, { useEffect, useState } from 'react';
import { userRequest } from '../../../requestMethod';
import { useDispatch, useSelector } from 'react-redux';
import { validateRequired } from '../../../formValidation';
import Popup from '../../../global/Popup';
import { getAllMilestones, getProducts } from '../../../redux/apiCalls';
import { addSectionFailure, addSectionStart, addSectionSuccess } from '../../../redux/customSectionRedux';
import CircleLoader from '../../../components/circleLoader/CircleLoader';
import { MEDIA_URL, allowedImageFormats, uploadProductImageToSpace } from '../../../config';
import { useLocation } from 'react-router-dom';
import { updateMilestoneFailure, updateMilestoneStart, updateMilestoneSuccess } from '../../../redux/milestoneRedux';

const EditMilestone = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        getAllMilestones(dispatch)
    }, [dispatch]);

    const location = useLocation();
    const currentItemId = location.pathname.split('/')[2];

    const milestone = useSelector((state) => state.milestones?.milestones?.find((item) => item._id === currentItemId));



    const [title, setTitle] = useState(milestone && milestone.title);
    const [titleError, setTitleError] = useState(false);
    const [titleErrorMessage, setTitleErrorMessage] = useState('');
    const [image, setImage] = useState(milestone?.milestoneImage);
    const [imagePreview, setImagePreview] = useState(milestone?.milestoneImage);
    const [description, setDescription] = useState(milestone?.description);
    const [isActive, setIsActive] = useState(milestone?.status);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [discountTypeError, setDiscountTypeError] = useState(false);
    const [discountTypeErrorMessage, setDiscountTypeErrorMessage] = useState('');
    const [activePopup, setActivePopup] = useState(false);
    const [popupText, setPopuptext] = useState('');
    const [popupStatus, setPopupStatus] = useState(true);
    const [activeLoader, setActiveLoader] = useState(false);
    const [loaderText, setLoaderText] = useState("");

    useEffect(() => {
        setTitle(milestone && milestone.title);
        setImage(milestone && milestone.milestoneImage);
        setDescription(milestone && milestone.description);
        setIsActive(milestone && milestone.isActive);
        setImagePreview(milestone && milestone.milestoneImage);
    }, [milestone])






    const handleStatusChange = (e) => {
        // Handle the change of the "For Specific Product" dropdown
        const status = e.target.value;
        // Do something with the selected status (true or false)
    };


    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleProductImageChange = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            // Check if the selected file is an image
            const isImage = allowedImageFormats.includes(files[0].type);
            if (!isImage) {
                // Show an error message or perform some action for invalid image format
                alert('Please select a valid image file (JPEG, PNG, or GIF).');
                event.target.value = null;
                setImage('');
                return;
            } else {
                setImage(files[0]);
                setImagePreview(URL.createObjectURL(files[0]));
            }
        }
        else {
            setImage(null);
            setImagePreview(milestone && milestone.milestoneImage);
        }
    };

    const updateMilestone = async (dispatch, milestone) => {
        dispatch(updateMilestoneStart());
        try {
            const res = await userRequest.put(`/milestone/${currentItemId}`, milestone);
            if (res.status === 200) {
                setError(false);
                // setSuccess(true);
                // setSuccessMessage("Milestone has been added!");
                setActivePopup(true)
                setPopuptext("Milestone has been updated.");
                setPopupStatus(true);
                setTitle('');
                setActiveLoader(false)

            }
            dispatch(updateMilestoneSuccess(res.data));

        } catch (error) {
            dispatch(updateMilestoneFailure());
            setActiveLoader(false)
            if (error.response.status === 409) {
                setTitleError(true);
                setTitleErrorMessage(error.response.data);
            }

            else {
                setActivePopup(true)
                setPopuptext(error.response.data);
                setPopupStatus(false);
            }
        }
    };

    // Validate function for form validation start=======
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const titleValidErr = validateRequired(title);

        if (titleValidErr) {
            formIsValid = false;
            newErrors.title = titleValidErr;
        }


        setErrors(newErrors);
        return formIsValid;
    }
    // Validate function for form validation end======

    const handleUpdate = async () => {
        // setDiscountTypeError(false);
        // setSuccess(false);
        setActiveLoader(true);
        setLoaderText('uploading');

        if (validateForm()) {
            const trimmmedTitle = title.trim();
            if (image.name) {
                const imageUrl = await uploadProductImageToSpace(image);
                updateMilestone(dispatch, { title: trimmmedTitle, status: isActive, milestoneImage: imageUrl, description: description });
            } else {
                updateMilestone(dispatch, { title: trimmmedTitle, status: isActive, description: description });
            }
        }
    }

    const hidePopup = () => {
        setActivePopup(false);
    }

    if (activePopup) {
        return (
            <Popup status={popupStatus} message={popupText} onClickAction={hidePopup} />
        )
    }

    return (
        <div className='container'>
            <div className="row">
                <div className="col-12">
                    <div className="discountCardWrapper">
                        <div className="discountHeader">
                            <h2>Update Milestone</h2>
                        </div>
                        <div className="discountMiddleWrapper pt-2">
                            <div className="row gy-3">
                                <div className="col-12">
                                    <div className="discountInputFieldWrapper">
                                        <label>Enter Title</label>
                                        <div className="dicountInfoWrapper">
                                            <input type="text" value={title} onChange={handleTitleChange} placeholder="Title" />
                                            {
                                                titleError && (
                                                    <p className='error-text text-danger'>{titleErrorMessage}</p>
                                                )
                                            }
                                            <span className='error-text'>{errors.title}</span>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-12">
                                    <div className="discountInputFieldWrapper form-input-wrapper">
                                        <label>Image</label>
                                        <div className="dicountInfoWrapper">
                                            <input type="file" name='file' className='newUserInput' onChange={(e) => setImage(e.target.files[0])} placeholder='Image' />
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-12">
                                    <div className="form-input-wrapper">
                                        <label>Image</label>
                                        <input type="file" name='image' className='newUserInput' onChange={handleProductImageChange} placeholder='Image' />
                                        <span className='error-text'>{errors.image}</span>
                                    </div>
                                    <div className="blog-img-wrapper">
                                        {/* <img src={imagePreview || blogImage} alt="blog-img" /> */}
                                        {
                                            (image?.name) ? (
                                                <img src={imagePreview || image} alt="course-img" />
                                            ) : (
                                                <img src={MEDIA_URL + imagePreview} alt="course-img" />
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="discountInputFieldWrapper">
                                        <label>Description</label>
                                        <div className="dicountInfoWrapper">
                                            <textarea name="description" value={description} onChange={(e) => setDescription(e.target.value)}>
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="discountInputFieldWrapper">
                                        <label>Status</label>
                                        <div className="dicountInfoWrapper">
                                            <select name="status" value={isActive} onChange={(e) => setIsActive(e.target.value)}>
                                                <option value={true}>Active</option>
                                                <option value={false}>Inactive</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="discountSubmitBtnWrapper">
                                        <button className='custom-btn btn' onClick={handleUpdate}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                activeLoader && (
                    <CircleLoader title={loaderText} />
                )
            }
        </div>
    )
}

export default EditMilestone