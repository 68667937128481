import React, { useEffect, useRef, useState } from 'react';
// import './NewDiscount.css';
import { addDiscountStart, addDiscountSuccess, addDiscountfailure } from '../../../redux/discountRedux';
import { userRequest } from '../../../requestMethod';
import { useDispatch, useSelector } from 'react-redux';
import { validateRequired } from '../../../formValidation';
import Popup from '../../../global/Popup';
import { getAllCustomSections, getProducts } from '../../../redux/apiCalls';
import { addSectionFailure, addSectionStart, addSectionSuccess, updateSectionFailure, updateSectionStart, updateSectionSuccess } from '../../../redux/customSectionRedux';
import { useLocation } from 'react-router-dom';
import CircleLoader from '../../../components/circleLoader/CircleLoader';
import MiniLoader from '../../../global/MiniLoader';

const EditCustomSection = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        getProducts(dispatch)
    }, [dispatch]);

    useEffect(() => {
        getAllCustomSections(dispatch)
    }, [dispatch]);

    const { isFetching, sections } = useSelector((state) => state.customSections);
    const { isFetching: isProductFetching, products } = useSelector((state) => state.products);
    const [sectionData, setSectionData] = useState(null);



    const location = useLocation();
    const currentSectionTitle = decodeURIComponent(location.pathname.split('/')[2]);
    // useEffect(() => {
    //     currentSectionTitle
    // },[location.pathname]);

    const [sectionId, setSectionId] = useState(null);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const userParam = params.get('section');
        setSectionId(userParam);
    }, [sectionId]);


    const getSectionDetails = async () => {
        try {
            const res = await userRequest.get(`/customSection/find`, {
                params: {
                    id: sectionId
                }
            });
            if (res?.status === 200) {
                setSectionData(res?.data)
            }
        } catch (error) {
        }
    };

    useEffect(() => {
        getSectionDetails()
    }, [sectionId, dispatch])
    const section = useSelector((state) => state.customSections.sections.find((item) => item._id === sectionId));

    const [sectionTitle, setSectionTitle] = useState(sectionData && sectionData.sectionTitle);
    const [isActive, setIsActive] = useState(sectionData && sectionData.status);
    const [selectedProducts, setSelectedProducts] = useState(sectionData && sectionData.products);
    const [searchTerm, setSearchTerm] = useState('');
    const [sectionTitleError, setSectionTitleError] = useState(false);
    const [sectionTitleErrorMessage, setSectionTitleErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [discountTypeError, setDiscountTypeError] = useState(false);
    const [discountTypeErrorMessage, setDiscountTypeErrorMessage] = useState('');
    const [activePopup, setActivePopup] = useState(false);
    const [popupText, setPopuptext] = useState('');
    const [loaderText, setLoaderText] = useState('');
    const [popupStatus, setPopupStatus] = useState(true);



    useEffect(() => {
        setSectionTitle(sectionData && sectionData.sectionTitle);
        setIsActive(sectionData && sectionData.status);
        setSelectedProducts(sectionData && sectionData.products);
    }, [sectionData])








    const handleStatusChange = (e) => {
        // Handle the change of the "For Specific Product" dropdown
        const status = e.target.value;
        // Do something with the selected status (true or false)
    };

    const handleSearchChange = (e) => {
        // Handle the change of the search input
        setSearchTerm(e.target.value);
    };

    // const handleProductSelect = (e) => {
    //     // Handle the change of selected products in the multi-select dropdown
    //     const selectedProductIds = Array.from(e.target.selectedOptions, option => option.value);
    //     setSelectedProducts(selectedProductIds);
    // };

    const handleProductToggle = (productId) => {
        setSelectedProducts((prevSelectedProducts) => {
            if (prevSelectedProducts.includes(productId)) {
                // Remove the product if it's already selected
                return prevSelectedProducts.filter((id) => id !== productId);
            } else {
                // Add the product if it's not selected
                return [...prevSelectedProducts, productId];
            }
        });
    };


    // Filter products based on the search term
    const filteredProducts = products?.filter(product =>
        product.title.toLowerCase().includes(searchTerm.toLowerCase())
    );


    const handleSectionTitleChange = (event) => {
        setSectionTitle(event.target.value);
    };

    const updateSection = async (dispatch, section) => {
        setLoaderText("Updating")
        dispatch(updateSectionStart());
        try {
            const res = await userRequest.put(`/customSection/${sectionData?._id}`, section);
            if (res.status === 200) {
                setError(false);
                // setSuccess(true);
                // setSuccessMessage("Section has been updated!");
                setActivePopup(true)
                setPopuptext("Section has been updated.");
                setLoaderText('')
                setPopupStatus(true);
                setSectionTitle('');
            }
            dispatch(updateSectionSuccess(res.data));

        } catch (error) {
            dispatch(updateSectionFailure());
            if (error.response.status === 409) {
                setSectionTitleError(true);
                setSectionTitleErrorMessage(error.response.data);
            }

            else {
                setActivePopup(true)
                setLoaderText('')
                setPopuptext(error.response.data);
                setPopupStatus(false);
            }
        }
    };

    // Validate function for form validation start=======
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const sectionTitleValidErr = validateRequired(sectionTitle);

        if (sectionTitleValidErr) {
            formIsValid = false;
            newErrors.sectionTitle = sectionTitleValidErr;
        }

        if (selectedProducts.length < 1) {
            formIsValid = false;
            newErrors.selectProductError = "Please select at least one product"
        }

        setErrors(newErrors);
        return formIsValid;
    }
    // Validate function for form validation end======

    // const handleAmountChange = (e) => {
    //     const inputValue = e.target.value;
    //     // Check if the discount type is "percentage" and the entered value is greater than 100
    //     if (discountType === 'percentage' && inputValue > 100) {
    //         setErrors({ amount: 'Percentage must be 100 or less' });
    //     } else {
    //         setErrors({}); // Clear any previous error
    //     }
    //     setAmount(inputValue); // Update the amount state
    // };

    const dragItem = useRef(0);
    const draggedOverItem = useRef(0);

    // const onDragEnd = () => {
    //     setSelectedProducts((prevProducts) => {
    //         const selectedProductsClone = JSON.parse(JSON.stringify(prevProducts));

    //         const tempChapter = selectedProductsClone[dragItem.current];
    //         selectedProductsClone[dragItem.current] = selectedProductsClone[draggedOverItem.current];
    //         selectedProductsClone[draggedOverItem.current] = tempChapter;

    //         return selectedProductsClone;
    //     });
    // };

    const handleChapterAndResourceSort = (dragType) => {
        setSelectedProducts((prevChapters) => {
            const chaptersClone = JSON.parse(JSON.stringify(prevChapters));

            if (dragType === 'chapter') {
                // Chapter drag-and-drop logic
                const tempChapter = chaptersClone[dragItem.current];
                chaptersClone[dragItem.current] = chaptersClone[draggedOverItem.current];
                chaptersClone[draggedOverItem.current] = tempChapter;
            } 
            // Log the updated values for debugging

            return chaptersClone;
        });
    };

    const onDragEnd = (dragType) => {
        // Call handleChapterAndResourceSort with the appropriate arguments
        if (dragType === 'chapter') {
            handleChapterAndResourceSort(dragType);
        }
    };

    // const dragItem = useRef(null);
    // const draggedOverItem = useRef(null);

    // const onDragStart = (index) => {
    //     dragItem.current = index;
    // };

    // const onDragOver = (index) => {
    //     draggedOverItem.current = index;
    // };

    // const onDragEnd = () => {
    //     if (dragItem.current !== draggedOverItem.current) {
    //         setSelectedProducts((prevProducts) => {
    //             const selectedProductsClone = [...prevProducts];
    //             const tempItem = selectedProductsClone[dragItem.current];
    //             selectedProductsClone[dragItem.current] = selectedProductsClone[draggedOverItem.current];
    //             selectedProductsClone[draggedOverItem.current] = tempItem;
    //             return selectedProductsClone;
    //         });
    //     }
    //     dragItem.current = null;
    //     draggedOverItem.current = null;
    // };


    const handleUpdateSection = () => {
        if (validateForm()) {
            const trimmmedSectionTitle = sectionTitle.trim();
            updateSection(dispatch, { sectionTitle: trimmmedSectionTitle, products: selectedProducts, status: isActive });
        }
    }

    const hidePopup = () => {
        setActivePopup(false);
    }

    if (activePopup) {
        return (
            <Popup status={popupStatus} message={popupText} onClickAction={hidePopup} />
        )
    }

    if (isFetching) {
        return (
            <div className='loader-wrapper'>
                <CircleLoader />
            </div>
        )
    }

    return (
        <div className='container'>
            <div className="row">
                <div className="col-12">
                    <div className="discountCardWrapper">
                        <div className="discountHeader">
                            <h2>Update Section</h2>
                        </div>
                        <div className="discountMiddleWrapper pt-2">
                            <div className="row gy-3">
                                <div className="col-12">
                                    <div className="discountInputFieldWrapper">
                                        <label>Enter Section Title</label>
                                        <div className="dicountInfoWrapper">
                                            <input type="text" value={sectionTitle} onChange={handleSectionTitleChange} placeholder="e.g. new arrivals" />
                                            {
                                                sectionTitleError && (
                                                    <p className='error-text text-danger'>{sectionTitleErrorMessage}</p>
                                                )
                                            }
                                            <span className='error-text'>{errors.sectionTitle}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="discountInputFieldWrapper">
                                        <label>Status</label>
                                        <div className="dicountInfoWrapper">
                                            <select name="status" id="" value={isActive} onChange={(e) => setIsActive(e.target.value)}>
                                                <option value="true">Active</option>
                                                <option value="false">Inactive</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="discountInputFieldWrapper">
                                        <label>Existing Products</label>
                                        <div className="dicountInfoWrapper">
                                            {/* <input type="text" placeholder='Search here...' value={searchTerm}
                                                onChange={handleSearchChange} /> */}
                                            <div className="product-list-wrapper">
                                                {
                                                    isProductFetching ? (
                                                        <MiniLoader />
                                                    ) : (
                                                        selectedProducts?.map((selectedProduct, index) => {
                                                            // if (selectedProducts?.includes(product?._id)) {
                                                                const product = products.find((item) => item._id === selectedProduct);
                                                                if(product){
                                                                     return (
                                                                    <div className="product-option-wrapper "
                                                                        draggable
                                                                        onDragStart={() => (dragItem.current = index)}
                                                                        onDragEnter={() => (draggedOverItem.current = index)}
                                                                        onDragEnd={() => onDragEnd("chapter")}
                                                                        onDragOver={(e) => e.preventDefault()}
                                                                    >
                                                                        <div className="option-img-wrapper">
                                                                            <img src={product.productImg} alt='img' />
                                                                        </div>
                                                                        <p>{product.title}</p>
                                                                        <span className={`${product.status === true ? "green-bg" : "red-bg"} product-status`}>{product.status === true ? "active" : "draft"}</span>
                                                                    </div>
                                                                )
                                                                }
                                                               
                                                            // }
                                                        })
                                                        // products?.map((product, index) => {
                                                        //     if (selectedProducts?.includes(product?._id)) {
                                                        //         return (
                                                        //             // <button className={`product-option-button ${selectedProducts?.includes(product?._id) ? 'selected' : ''
                                                        //             //     }`} key={index} onClick={() => handleProductToggle(product._id)}>
                                                        //             <div className="product-option-wrapper"
                                                        //                 draggable
                                                        //                 onDragStart={() => (dragItem.current = index)}
                                                        //                 onDragEnter={() => (draggedOverItem.current = index)}
                                                        //                 onDragEnd={() => onDragEnd("chapter")}
                                                        //                 onDragOver={(e) => e.preventDefault()}
                                                        //             >
                                                        //                 <div className="option-img-wrapper">
                                                        //                     <img src={product.productImg} alt='img' />
                                                        //                 </div>
                                                        //                 <p>{product.title}</p>
                                                        //                 <span className={`${product.status === true ? "green-bg" : "red-bg"} product-status`}>{product.status === true ? "active" : "draft"}</span>
                                                        //             </div>
                                                        //             // </button>
                                                        //         )
                                                        //     }
                                                        // })
                                                    )
                                                }


                                            </div>
                                            <span className='error-text'>{errors.selectProductError}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="discountInputFieldWrapper">
                                        <label>Select Product</label>
                                        <div className="dicountInfoWrapper">
                                            <input type="text" placeholder='Search here...' value={searchTerm}
                                                onChange={handleSearchChange} />
                                            <div className="product-list-wrapper">
                                                {
                                                    isProductFetching ? (
                                                        <MiniLoader />
                                                    ) : (
                                                        filteredProducts?.map((product, index) => {

                                                            return (
                                                                <button className={`product-option-button ${selectedProducts?.includes(product?._id) ? 'selected' : ''
                                                                    }`} key={index} onClick={() => handleProductToggle(product._id)}>
                                                                    <div className="product-option-wrapper">
                                                                        <div className="option-img-wrapper">
                                                                            <img src={product.productImg} alt='img' />
                                                                        </div>
                                                                        <p>{product.title}</p>
                                                                        <span className={`${product.status === true ? "green-bg" : "red-bg"} product-status`}>{product.status === true ? "active" : "draft"}</span>
                                                                    </div>
                                                                </button>
                                                            )
                                                        })
                                                    )
                                                }


                                            </div>
                                            <span className='error-text'>{errors.selectProductError}</span>
                                        </div>
                                    </div>
                                </div>
                                {/* )
                                } */}
                                <div className="col-12">
                                    <div className="discountSubmitBtnWrapper">
                                        <button className='custom-btn btn' onClick={handleUpdateSection}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditCustomSection
