import React, { useEffect, useState } from 'react';
import './EditEvent.css';
import { userRequest } from '../../../requestMethod';
import { useDispatch, useSelector } from 'react-redux';
import { validateRequired } from '../../../formValidation';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { updateEventFailure, updateEventStart, updateEventSuccess } from '../../../redux/eventRedux';
import { useLocation } from 'react-router-dom';
import { getAllBlogCategory, getAllEvents } from '../../../redux/apiCalls';
import CircleLoader from '../../../components/circleLoader/CircleLoader';
import { MEDIA_URL, allowedImageFormats, isUrl, uploadProductImageToSpace } from '../../../config';
import Popup from '../../../global/Popup';

const EditEvent = () => {

    const dispatch = useDispatch();

    // fetch all existing categories
    useEffect(() => {
        getAllEvents(dispatch)
    }, [dispatch]);

    const location = useLocation();
    const eventId = location.pathname.split('/')[2];
    const eventDataFetching = useSelector((state) => state.event.isFetching);
    const eventData = useSelector((state) => state.event?.events?.find((item) => item._id == eventId));
    // const blogCategories = useSelector((state) => state.blogCategory.blogCategory);

    const [blog, setBlog] = useState({});
    const [title, setTitle] = useState(eventData?.title);
    const [description, setDescription] = useState(eventData?.description);
    const [metaTitle, setMetaTitle] = useState(eventData?.metaTitle);
    const [metaDescription, setMetaDescription] = useState(eventData?.metaDescription);
    const [metaKeywords, setMetaKeywords] = useState(eventData?.metaKeywords);
    const [category, setCategory] = useState(eventData?.category);
    const [author, setAuthor] = useState(eventData?.author);
    const [eventImage, setEventImage] = useState(eventData?.eventImage);
    const [eventImagePreview, setEventImagePreview] = useState(eventData?.eventImage);
    const [status, setStatus] = useState(eventData?.status);
    const [titleError, setTitleError] = useState(false);
    const [titleErrorMessage, setTitleErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [activeLoader, setActiveLoader] = useState(false);
    const [loaderText, setLoaderText] = useState("");
    const [popupStatus, setPopupStatus] = useState(false);
    const [activePopup, setActivePopup] = useState(false);
    const [popupText, setPopuptext] = useState("");


    useEffect(() => {
        setTitle(eventData?.title);
        setDescription(eventData?.description);
        setMetaTitle(eventData?.metaTitle);
        setDescription(eventData?.metaDescription);
        setMetaKeywords(eventData?.metaKeywords);
        setCategory(eventData?.category);
        setAuthor(eventData?.author);
        setStatus(eventData?.status);
        setEventImagePreview(eventData?.eventImage);
        setEventImage(eventData?.eventImage);
    }, [eventData])

    const handleDescriptionChange = (value) => {
        setDescription(value);
    };

    const handleNewsImageChange = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            // Check if the selected file is an image
            const isImage = allowedImageFormats.includes(files[0].type);
            if (!isImage) {
                // Show an error message or perform some action for invalid image format
                alert('Please select a valid image file (JPEG, PNG, or JPG)');
                event.target.value = null;
                setEventImage('');
                return;
            } else {
                setEventImage(files[0]);
                setEventImagePreview(URL.createObjectURL(files[0]));
            }
        }
        else {
            setEventImage(null);
            setEventImagePreview(eventData && eventData.newsImage);
        }
    };


    const updateEvent = async (dispatch, eventId, blog) => {
        setActiveLoader(true)
        setLoaderText("Updating")
        dispatch(updateEventStart());
        try {
            const res = await userRequest.put(`/event/${eventId}`, blog);
            if (res.status === 200) {
                setActiveLoader(false);
                setActivePopup(true)
                setPopupStatus(true);
                setPopuptext("Event has been updated!")
                // setTitle('');
                // setDescription('');
                // setCategory('');
                // setAuthor('');
                // setStatus(false);
                // seteventImage(null);
            }
            dispatch(updateEventSuccess(res.data));
        } catch (error) {
            dispatch(updateEventFailure());
            if (error.response.status === 409) {
                setTitleError(true);
                setTitleErrorMessage(error.response.data);
            }

            else {
                setActivePopup(true)
                setPopupStatus(false);
                setPopuptext("Request failed.")
            }
        }
    };

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const titleErr = validateRequired(title);
        const descErr = validateRequired(description);
        const categoryErr = validateRequired(category);
        const authorErr = validateRequired(author);
        const eventImageErr = validateRequired(eventImage);

        if (titleErr) {
            formIsValid = false;
            newErrors.title = titleErr;
        }
        if (descErr) {
            formIsValid = false;
            newErrors.desc = descErr;
        }
        if (categoryErr) {
            formIsValid = false;
            newErrors.category = categoryErr;
        }

        if (authorErr) {
            formIsValid = false;
            newErrors.author = authorErr;
        }
        if (eventImageErr) {
            formIsValid = false;
            newErrors.eventImage = eventImageErr;
        }

        setErrors(newErrors);
        return formIsValid;
    }

    const handleUpdate = async () => {
        setSuccess(false);
        setError(false);
        setTitleError(false);

        const trimmedTitle = title.trim();
        let eventImageURL;

        if (eventImage?.name) {
            eventImageURL = await uploadProductImageToSpace(eventImage)
        }

        else {
            eventImageURL = eventImagePreview;
        }

        if (validateForm()) {
            updateEvent(dispatch, eventId, { title: trimmedTitle, eventImage: eventImageURL, description, category, author, status, metaTitle, metaDescription, metaKeywords });
        }
    }


    const hidePopup = () => {
        setActivePopup(false);
    }

    if (activePopup) {
        return (
            <Popup status={popupStatus} message={popupText} onClickAction={hidePopup} />
        )
    };


    if (eventDataFetching) {
        return (
            <div className='loader-wrapper'>
                <CircleLoader />
            </div>
        )
    }

    return (
        <div className='container'>
            <div className="row mt-5 mb-3">
                <div className="col-12">
                    <div className="pageTitleWrapper">
                        <h2 className="page-heading">Update Event</h2>
                    </div >
                </div >
            </div >
            <div className="row">
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>title</label>
                        <input type="text" name='title' value={title} className='newUserInput' onChange={(e) => setTitle(e.target.value)} placeholder='Title' required />
                        {
                            titleError && (
                                <p className='error-text text-danger'>{titleErrorMessage}</p>
                            )
                        }
                        <span className='error-text'>{errors.title}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>meta title</label>
                        <input type="text" name='meta title' value={metaTitle} className='newCourseInput' onChange={(e) => setMetaTitle(e.target.value)} placeholder='Meta Title' />
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Category</label>
                        <select name="ageGroup" value={category} onChange={(e) => setCategory(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            {/* {
                                blogCategories ? (
                                    blogCategories.map((item, index) => {
                                        return <option value={item.category} key={index} >{item.category}</option>
                                    })
                                ) : (
                                    <option value="" selected disabled>Not Available</option>
                                )
                            } */}
                            {/* <option value="first">first</option> */}
                            <option value="latest">Latest event</option>
                            <option value="past">Past event</option>
                        </select>
                        <span className='error-text'>{errors.category}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Author</label>
                        <input type="text" name='price' value={author} className='newUserInput' onChange={(e) => setAuthor(e.target.value)} placeholder='Author' />
                        <span className='error-text'>{errors.author}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Status</label>
                        <select name="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value="true" >Active</option>
                            <option value="false" >Draft</option>
                        </select>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Image</label>
                        <input type="file" name='eventImage' className='newUserInput' onChange={handleNewsImageChange} placeholder='Image' />
                        <span className='error-text'>{errors.eventImage}</span>
                    </div>
                    <div className="course-img-wrapper">
                        {/* <img src={eventImage} alt="blog-img" /> */}
                        {
                            isUrl(eventImagePreview) ? (
                                <img src={eventImagePreview || eventImage} alt="event-img" />
                            ) : (eventImage?.name) ? (
                                <img src={eventImagePreview || eventImage} alt="event-img" />
                            ) : (
                                <img src={MEDIA_URL + eventImagePreview} alt="event-img" />
                            )
                        }
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>description</label>
                        {/* <input type="text" name='description' value={description} className='newUserInput' onChange={(e) => setDescription(e.target.value)} placeholder='Description' /> */}
                        <ReactQuill value={description} onChange={handleDescriptionChange} />

                        <span className='error-text'>{errors.desc}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>meta description</label>
                        <input type="text" name='meta description' value={metaDescription} className='newCourseInput' onChange={(e) => setMetaDescription(e.target.value)} placeholder='Meta Description' />
                        {/* <span className='error-text'>{errors.desc}</span> */}
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>meta keywords</label>
                        <input type="text" name='meta description' value={metaKeywords} className='newCourseInput' onChange={(e) => setMetaKeywords(e.target.value)} placeholder='Meta Keywords' />
                        {/* <span className='error-text'>{errors.desc}</span> */}
                    </div>
                </div>
                <div className="col-12">
                    <div className="newStudentSubmitbtn mt-4">
                        <button className="custom-btn purple-bg" onClick={handleUpdate}>update</button>
                    </div>
                </div>

                <div className="col-12 mt-3">
                    {
                        success ? (
                            <div className='alert alert-success'>
                                {successMessage}
                            </div>
                        ) : error && (
                            <div className='alert alert-danger'>
                                {errorMessage}
                            </div>
                        )
                    }
                </div>
            </div>
            {
                activeLoader && (
                    <CircleLoader title={loaderText} />
                )
            }
        </div>
    )
}

export default EditEvent
