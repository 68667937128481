import React, { useEffect, useState } from 'react';
import './CourseAgeGroup.css';
import { addAgeGroupFailure, addAgeGroupStart, addAgeGroupSuccess, deleteAgeGroupFailure, deleteAgeGroupStart, deleteAgeGroupSuccess } from '../../../redux/ageGroupRedux';
import { userRequest } from '../../../requestMethod';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAgeGroups } from '../../../redux/apiCalls';
import { Delete } from '@mui/icons-material';
import Loader from '../../../global/Loader';
import { validateRequired } from '../../../formValidation';
import CircleLoader from '../../../components/circleLoader/CircleLoader';

const CourseAgeGroup = () => {

    const dispatch = useDispatch();

    const [ageGroup, setAgeGroup] = useState("");
    const [ageGroupTitle, setAgeGroupTitle] = useState("");
    const [titleError, setTitleError] = useState(false);
    const [titleErrorMessage, setTitleErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');


    //add api call action
    const addAgeGroups = async (dispatch, ageGroup) => {
        dispatch(addAgeGroupStart());
        try {
            const res = await userRequest.post('/ageGroup/create', ageGroup);
            if (res.status === 200) {
                setSuccess(true);
                setSuccessMessage("Age group has been added!")
            }
            dispatch(addAgeGroupSuccess(res.data));
        } catch (error) {
            dispatch(addAgeGroupFailure());
            if (error.response.status === 409) {
                setTitleError(true);
                setTitleErrorMessage(error.response.data);
            }

            else {
                setError(true);
                setErrorMessage("Something went wrong!");
            }
        }
    };

    // Delete api call action
    const deletAgeGroup = async (dispatch, ageGroupId) => {
        dispatch(deleteAgeGroupStart());
        try {
            const res = await userRequest.delete(`/ageGroup/${ageGroupId}`);
            dispatch(deleteAgeGroupSuccess(ageGroupId));
        } catch (error) {
            dispatch(deleteAgeGroupFailure());
        }
    };

    const handleDeleteGroup = (ageGroupId) => {
        setError(false);
        setSuccess(false);
        setTitleError(false);
        deletAgeGroup(dispatch, ageGroupId)
    };


    // Validate function for form validation start=======
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const titleErr = validateRequired(ageGroup);
        const ageGroupTitleErr = validateRequired(ageGroupTitle);
        if (titleErr) {
            formIsValid = false;
            newErrors.title = titleErr;
        }
        if (ageGroupTitleErr) {
            formIsValid = false;
            newErrors.ageGroupTitle = ageGroupTitleErr;
        }

        setErrors(newErrors);
        return formIsValid;
    }
    // Validate function for form validation end======


    const handleSubmitData = () => {
        setError(false);
        setSuccess(false);
        setTitleError(false);
        const trimmedValue = ageGroup.trim()
        if (validateForm()) {
            addAgeGroups(dispatch, { ageGroup: trimmedValue, ageGroupTitle })
        }

    };

    // fetch all existing age groups
    useEffect(() => {
        getAllAgeGroups(dispatch)
    }, [dispatch]);

    const { isFetching, ageGroups } = useSelector((state) => state.ageGroup);


    if (isFetching) {
        return (
            <div className='loader-wrapper'>
                <CircleLoader />
            </div>
        )
    }


    return (
        <div className='container'>
            <div className="row">
                <div className="col-12">
                    <div className="courseAgeGroupWrapper">
                        <h1>Age Group</h1>
                        <div className="newAgeGroupWrapper">
                            <div className="newAgeGroupInputWrapper">
                                <input type="text" placeholder='Enter age group here' value={ageGroup} onChange={(e) => setAgeGroup(e.target.value)} />
                                {
                                    titleError && (
                                        <p className='error-text text-danger'>{titleErrorMessage}</p>
                                    )
                                }
                                <span className='error-text'>{errors.title}</span>
                                <input type="text" placeholder='Age group title' value={ageGroupTitle} onChange={(e) => setAgeGroupTitle(e.target.value)} />
                                <span className='error-text'>{errors.ageGroupTitle}</span>
                                <button className="custom-btn" onClick={handleSubmitData}>Add</button>
                            </div>


                            <div className="col-12 mt-3">
                                {
                                    success ? (
                                        <div className='alert alert-success'>
                                            {successMessage}
                                        </div>
                                    ) : error && (
                                        <div className='alert alert-danger'>
                                            {errorMessage}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="avilableAgeGroupWrapper">
                            <h2>Existing: </h2>
                            <div className="ageGroupContainer">

                                {
                                    ageGroups.length > 0 ? ageGroups.map((item, index) => {
                                        return (
                                            <div className="availableAgeGroup" key={index}>
                                                <span className="groupItem">{item.ageGroup}</span>
                                                <Delete className='ageGroupDelete' onClick={() => handleDeleteGroup(item._id)} />
                                            </div>
                                        )
                                    }) : (
                                        <p>No age groups available!</p>
                                    )
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CourseAgeGroup
