// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.changePasswordCardContent {
    width: 400px;
    padding: 40px;
    box-shadow: var(--boxShadow);
    margin: 0 auto;
}

.changePasswordCardContent input{
    border: none;
    border-bottom: 1px solid #000;
    padding: 5px;
}

.changePasswordCardWrapper{
    width: 100%;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.changePasswordCardContent .heading{
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/pages/updateProfile/ChangePassword.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,4BAA4B;IAC5B,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,6BAA6B;IAC7B,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".changePasswordCardContent {\n    width: 400px;\n    padding: 40px;\n    box-shadow: var(--boxShadow);\n    margin: 0 auto;\n}\n\n.changePasswordCardContent input{\n    border: none;\n    border-bottom: 1px solid #000;\n    padding: 5px;\n}\n\n.changePasswordCardWrapper{\n    width: 100%;\n    height: 85vh;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.changePasswordCardContent .heading{\n    margin-bottom: 10px;\n    font-weight: 600;\n    font-size: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
