import React, { useEffect, useState } from 'react';
import './EditBlog.css';
import { fileUploadRequest, userRequest } from '../../../requestMethod';
import { useDispatch, useSelector } from 'react-redux';
import { validateRequired } from '../../../formValidation';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { updateBlogFailure, updateBlogStart, updateBlogSuccess } from '../../../redux/blogRedux';
import { useLocation } from 'react-router-dom';
import { getAllBlogCategory, getAllBlogs } from '../../../redux/apiCalls';
import CircleLoader from '../../../components/circleLoader/CircleLoader';
import Popup from '../../../global/Popup';
import { MEDIA_URL, allowedImageFormats, isUrl } from '../../../config';

const EditBlog = () => {

    const dispatch = useDispatch();

    // fetch all existing categories
    useEffect(() => {
        getAllBlogCategory(dispatch)
    }, [dispatch]);

    useEffect(() => {
        getAllBlogs(dispatch)
    }, [dispatch]);

    const location = useLocation();
    const blogId = location.pathname.split('/')[2];

    const blogData = useSelector((state) => state.blog.blogs.find((item) => item._id == blogId));
    const blogCategories = useSelector((state) => state.blogCategory.blogCategory);
    // useEffect(() => {
    //     setBlog(blogData)
    //     // setTitle(blog.title);
    //     // setDescription(blog.description);
    //     // setCategory(blog.category);
    //     // setAuthor(blog.author);
    //     // setStatus(blog.status);
    // }, [])
    const [blog, setBlog] = useState({});
    const [title, setTitle] = useState(blogData && blogData.title);
    const [description, setDescription] = useState(blogData && blogData.description);
    const [metaTitle, setMetaTitle] = useState(blogData && blogData.metaTitle);
    const [metaDescription, setMetaDescription] = useState(blogData && blogData.metaDescription);
    const [metaKeywords, setMetaKeywords] = useState(blogData && blogData.metaKeywords);
    const [category, setCategory] = useState(blogData && blogData.category);
    const [author, setAuthor] = useState(blogData && blogData.author);
    const [blogImage, setBlogImage] = useState(blogData && blogData.blogImage);
    const [status, setStatus] = useState(blogData && blogData.status);
    const [titleError, setTitleError] = useState(false);
    const [titleErrorMessage, setTitleErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [activeLoader, setActiveLoader] = useState(false);
    const [loaderText, setLoaderText] = useState("");
    const [imagePreview, setImagePreview] = useState(blogData && blogData.blogImage);
    const [popupText, setPopuptext] = useState('');
    const [popupStatus, setPopupStatus] = useState(true);
    const [activePopup, setActivePopup] = useState(false);


    useEffect(() => {
        setTitle(blogData && blogData.title);
        setDescription(blogData && blogData.description);
        setMetaTitle(blogData && blogData.metaTitle);
        setMetaDescription(blogData && blogData.metaDescription);
        setMetaKeywords(blogData && blogData.metaKeywords);
        setCategory(blogData && blogData.category);
        setAuthor(blogData && blogData.author);
        setBlogImage(blogData && blogData.blogImage);
        setStatus(blogData && blogData.status);
        setImagePreview(blogData && blogData.blogImage);
    }, [blogData])

    const handleDescriptionChange = (value) => {
        setDescription(value);
    };

    // const allowedImageFormats = ['image/jpeg', 'image/png', 'image/gif'];

    const handleProductImageChange = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            // Check if the selected file is an image
            const isImage = allowedImageFormats.includes(files[0].type);
            if (!isImage) {
                // Show an error message or perform some action for invalid image format
                alert('Please select a valid image file (JPEG, PNG, or GIF).');
                event.target.value = null;
                setBlogImage('');
                return;
            } else {
                setBlogImage(files[0]);
                setImagePreview(URL.createObjectURL(files[0]));
            }
        }
        else {
            setBlogImage(null);
            setImagePreview(blogData && blogData.blogImage);
        }
    };

    const uploadFileToSpace = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        setActiveLoader(true)
        setLoaderText("Uploading Image");
        try {
            const response = await fileUploadRequest.post('/upload/file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response && response.status === 200) {
                setActiveLoader(false)
                setLoaderText("");
                return response.data.fileUrl;
            }
            // You can now use the file URL as needed, e.g., storing it in your database.
        } catch (error) {
            setActiveLoader(false)
            setLoaderText("");
            console.error('Error uploading file:', error);
        }
    };



    const updateBlog = async (dispatch, blogId, blog) => {
        dispatch(updateBlogStart());
        setActiveLoader(true);
        setLoaderText("updating");
        try {
            const res = await userRequest.put(`/blog/${blogId}`, blog);
            if (res.status === 200) {
                // setSuccess(true);
                // setSuccessMessage("Blog has been updated!")
                setActivePopup(true);
                setPopupStatus(true)
                setPopuptext("Blog has been updated")
                // setTitle('');
                // setDescription('');
                // setCategory('');
                // setAuthor('');
                // setStatus(false);
                // setBlogImage(null);
            }
            dispatch(updateBlogSuccess(res.data));
            setActiveLoader(false);
            setLoaderText("");
        } catch (error) {
            dispatch(updateBlogFailure());
            setActiveLoader(false);
            setLoaderText("");
            if (error.response?.status === 409) {
                setTitleError(true);
                setTitleErrorMessage(error.response.data);
            }

            else {
                // setError(true);
                // setErrorMessage("Something went wrong!");
                setActivePopup(true);
                setPopupStatus(true)
                setPopuptext("Something went wrong!")
            }
        }
    };

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const titleErr = validateRequired(title);
        const descErr = validateRequired(description);
        const categoryErr = validateRequired(category);
        const authorErr = validateRequired(author);
        const blogImageErr = validateRequired(blogImage);

        if (titleErr) {
            formIsValid = false;
            newErrors.title = titleErr;
        }
        if (descErr) {
            formIsValid = false;
            newErrors.desc = descErr;
        }
        if (categoryErr) {
            formIsValid = false;
            newErrors.category = categoryErr;
        }

        if (authorErr) {
            formIsValid = false;
            newErrors.author = authorErr;
        }
        if (blogImageErr) {
            formIsValid = false;
            newErrors.blogImage = blogImageErr;
        }

        setErrors(newErrors);
        return formIsValid;
    }

    const handleUpdate = async () => {
        setSuccess(false);
        setError(false);
        setTitleError(false);
        const trimmedTitle = title.trim();
        if (validateForm()) {
            if (blogImage.type) {
                const blogImgUrl = await uploadFileToSpace(blogImage);
                // setBlogImage(blogImgUrl);
                updateBlog(dispatch, blogId, { title: trimmedTitle, description, category, author, status, blogImage: blogImgUrl, metaTitle, metaDescription, metaKeywords });
            }
            // else{
            // }
            updateBlog(dispatch, blogId, { title: trimmedTitle, description, category, author, status, blogImage, metaTitle, metaDescription, metaKeywords });
        }
    }


    const hidePopup = () => {
        setActivePopup(false);
    }


    if (activePopup) {
        return (
            <Popup status={popupStatus} message={popupText} onClickAction={hidePopup} />
        )
    }

    return (
        <div className='container'>
            <div className="row mt-5 mb-3">
                <div className="col-12">
                    <div className="pageTitleWrapper">
                        <h2 className="page-heading">Update blog</h2>
                    </div >
                </div >
            </div >
            <div className="row">
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>title</label>
                        <input type="text" name='title' value={title} className='newUserInput' onChange={(e) => setTitle(e.target.value)} placeholder='Title' required />
                        {
                            titleError && (
                                <p className='error-text text-danger'>{titleErrorMessage}</p>
                            )
                        }
                        <span className='error-text'>{errors.title}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>meta title</label>
                        <input type="text" name='meta title' value={metaTitle} className='newCourseInput' onChange={(e) => setMetaTitle(e.target.value)} placeholder='Meta Title' />
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Category</label>
                        <select name="ageGroup" value={category} onChange={(e) => setCategory(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            {
                                blogCategories ? (
                                    blogCategories.map((item, index) => {
                                        return <option value={item.category} key={index} >{item.category}</option>
                                    })
                                ) : (
                                    <option value="" selected disabled>Not Available</option>
                                )
                            }
                            {/* <option value="first">first</option> */}
                        </select>
                        <span className='error-text'>{errors.category}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Author</label>
                        <input type="text" name='price' value={author} className='newUserInput' onChange={(e) => setAuthor(e.target.value)} placeholder='Author' />
                        <span className='error-text'>{errors.author}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Status</label>
                        <select name="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value="true" >Active</option>
                            <option value="false" >Draft</option>
                        </select>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Image</label>
                        <input type="file" name='blogImage' className='newUserInput' onChange={handleProductImageChange} placeholder='Image' />
                        <span className='error-text'>{errors.blogImage}</span>
                    </div>
                    <div className="course-img-wrapper">
                        {/* <img src={imagePreview || blogImage} alt="blog-img" /> */}
                        {
                            isUrl(imagePreview) ? (
                                <img src={imagePreview || blogImage} alt="course-img" />
                            ) : (blogImage?.type) ? (
                                <img src={imagePreview || blogImage} alt="course-img" />
                            ) : (
                                <img src={MEDIA_URL + imagePreview} alt="course-img" />
                            )
                        }
                    </div>
                    {/* <div className="course-img-wrapper"> */}
                        {/* <img src={ MEDIA_URL + imagePreview || productImage} alt="course-img" /> */}
                        {/* {
                                isUrl(imagePreview) ? (
                                    <img src={imagePreview || productImage} alt="product-img" />
                                ) : (productImage?.name) ? (
                                    <img src={imagePreview || productImage} alt="product-img" />
                                ) : (
                                    <img src={MEDIA_URL + imagePreview} alt="product-img" />
                                )
                            } */}
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>description</label>
                        {/* <input type="text" name='description' value={description} className='newUserInput' onChange={(e) => setDescription(e.target.value)} placeholder='Description' /> */}
                        <ReactQuill modules={{
                            toolbar: [
                                [{ header: [1, 2, false] }],
                                ['bold', 'italic', 'underline'],
                                ['image', 'code-block']
                            ]
                        }} value={description} onChange={handleDescriptionChange} />

                        <span className='error-text'>{errors.desc}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>meta description</label>
                        <input type="text" name='meta description' value={metaDescription} className='newCourseInput' onChange={(e) => setMetaDescription(e.target.value)} placeholder='Meta Description' />
                        {/* <span className='error-text'>{errors.desc}</span> */}
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>meta keywords</label>
                        <input type="text" name='meta description' value={metaKeywords} className='newCourseInput' onChange={(e) => setMetaKeywords(e.target.value)} placeholder='Meta Keywords' />
                        {/* <span className='error-text'>{errors.desc}</span> */}
                    </div>
                </div>
                <div className="col-12">
                    <div className="newStudentSubmitbtn mt-4">
                        <button className="custom-btn purple-bg" onClick={handleUpdate}>update</button>
                    </div>
                </div>

                <div className="col-12 mt-3">
                    {
                        success ? (
                            <div className='alert alert-success'>
                                {successMessage}
                            </div>
                        ) : error && (
                            <div className='alert alert-danger'>
                                {errorMessage}
                            </div>
                        )
                    }
                </div>

                {
                    activeLoader && (
                        <CircleLoader title={loaderText} />
                    )
                }
            </div>
        </div>
    )
}

export default EditBlog
