// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.studentListEdit{
    padding: 5px 20px;
    background-color: var(--green);
    color: #fff;
    margin-right: 20px;
}

.newStudentIcon{
    font-size: 15px !important;
    margin-left: 2px;
}

.viewSchoolBtn{
    background: var(--pink);
}`, "",{"version":3,"sources":["webpack://./src/pages/B2B/franchise/franchiseTable/FranchiseTable.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,8BAA8B;IAC9B,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,0BAA0B;IAC1B,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".studentListEdit{\n    padding: 5px 20px;\n    background-color: var(--green);\n    color: #fff;\n    margin-right: 20px;\n}\n\n.newStudentIcon{\n    font-size: 15px !important;\n    margin-left: 2px;\n}\n\n.viewSchoolBtn{\n    background: var(--pink);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
