// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inh2, .inh2 span {
    font-weight: 700;
    font-size: 45px;
    /* font-size: var(--f45); */
    /* color: #264ec8; */
    color: var(--purple);
    text-transform: uppercase;
    text-align: left;
}

.coming-soon-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: -50px;
    /* padding-bottom: 100px; */
}

.inh2 span {
    color: var(--dPink);
}

.Unigray {
    color: #112f48;
    color: var(--Unigray);
}`, "",{"version":3,"sources":["webpack://./src/pages/coming-soon/ComingSoon.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,2BAA2B;IAC3B,oBAAoB;IACpB,oBAAoB;IACpB,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,2BAA2B;AAC/B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB","sourcesContent":[".inh2, .inh2 span {\n    font-weight: 700;\n    font-size: 45px;\n    /* font-size: var(--f45); */\n    /* color: #264ec8; */\n    color: var(--purple);\n    text-transform: uppercase;\n    text-align: left;\n}\n\n.coming-soon-container{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n    margin-top: -50px;\n    /* padding-bottom: 100px; */\n}\n\n.inh2 span {\n    color: var(--dPink);\n}\n\n.Unigray {\n    color: #112f48;\n    color: var(--Unigray);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
