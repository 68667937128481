import React from 'react';
import './SchoolTeacherTable.css';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

const SchoolTeacherTable = () => {

    // const currentSchool = useSelector((state) => state.user.currentUser);
    // const schoolId = currentSchool._id;
    const user = useSelector((state) => state.user.currentUser);

    let schoolId;
    
    user.isSchoolPrincipal ? schoolId =  user._id : schoolId = user.schoolId;

    // School Teacher variable
    const schoolTeacher = useSelector((state) => state.allUsers.users.filter((item) => item.schoolId === schoolId && item.isSchoolTeacher === true));



    // Coulmn for teacher
    const teacherColumn = [
        { field: '_id', headerName: 'ID', width: 200 },
        {
            field: 'schoolName',
            headerName: 'School',
            width: 200,
            editable: false,
            renderCell: (params) => {
                return params.row.schoolId === schoolId ? user.schoolName : "Not associated with this school"
            }
        },
        {
            field: 'firstName',
            headerName: 'Teacher Name',
            width: 150,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 200,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            // description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 100,
        },
        {
            field: 'edit',
            headerName: 'Edit',
            sortable: false,
            width: 100,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={'/edit-school-teacher/' + params.id}>
                            <button className='btn btn-primary studentListEdit'>Edit</button>
                        </Link>
                        {/* <DeleteOutline className='studentListDelete text-danger' /> */}
                    </>
                )
            }
        },
    ];

    return (
        <div className='container'>
            <div className="row py-5">
                <div className="col-12">
                    <div className="schoolTeacherTableWrapper">
                        <div className="singleSchoolHeadingWrapper singleSchoolHeader mb-3">
                            <h2>School Teachers</h2>
                            <Link to={`/add-school-teacher/${schoolId}`}>
                                <button className='custom-btn purple-bg'>Create Teacher <AddCircleOutlineOutlinedIcon className='newStudentIcon' /></button>
                            </Link>
                        </div>
                        <DataGrid
                            rows={schoolTeacher}
                            getRowId={(rows) => rows._id}
                            columns={teacherColumn}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                            checkboxSelection
                            disableRowSelectionOnClick
                        />
                        {
                            schoolTeacher.length < 1 && (
                                <p className='text-center'>No teacher found</p>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SchoolTeacherTable
