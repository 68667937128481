import React from 'react';
import './SchoolAdminTable.css';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

const SchoolAdminTable = () => {

    const currentSchool = useSelector((state) => state.user.currentUser);
    const schoolId = currentSchool._id;


    // School Teacher variable
    const schoolAdmin = useSelector((state) => state.allUsers.users.filter((item) => item.schoolId === schoolId && item.isSchoolAdmin === true));

    // const schoolAdmin = useSelector((state) => state.allUsers.users)



    // column for admin
    const adminColumn = [
        { field: '_id', headerName: 'ID', width: 200 },
        {
            field: 'schoolName',
            headerName: 'School',
            width: 200,
            editable: false,
            renderCell: (params) => {
                return params.row.schoolId === schoolId ? currentSchool.schoolName : "Not associated with this school"
            }
        },
        {
            field: 'firstName',
            headerName: 'Admin Name',
            width: 150,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 200,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 100,
        },
        {
            field: 'edit',
            headerName: 'Edit',
            // sortable: false,
            width: 100,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={'/edit-school-admin/' + params.id}>
                            <button className='btn btn-primary studentListEdit'>Edit</button>
                        </Link>
                        {/* <DeleteOutline className='studentListDelete text-danger' /> */}
                    </>
                )
            }
        },
    ];

    return (
        <div className='container'>
            <div className="row py-5">
                <div className="col-12">
                    <div className="schoolTeacherTableWrapper">
                        <div className="singleSchoolHeadingWrapper singleSchoolHeader mb-3">
                            <h2>School Admins</h2>
                            <Link to={`/add-school-admin/${schoolId}`}>
                                <button className='custom-btn purple-bg'>Create Admin <AddCircleOutlineOutlinedIcon className='newStudentIcon' /></button>
                            </Link>
                        </div>
                        <DataGrid
                            rows={schoolAdmin}
                            getRowId={(rows) => rows._id}
                            columns={adminColumn}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                            checkboxSelection = {false}
                            disableRowSelectionOnClick
                        />
                        {
                            schoolAdmin.length < 1 && (
                                <p className='text-center'>No admin found</p>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SchoolAdminTable
