export const salesData = [
    {
      name: 'Jan',
      orders: 4000,
      income: 2400,
      amt: 2400,
    },
    {
      name: 'Feb',
      orders: 3000,
      income: 1398,
      amt: 2210,
    },
    {
      name: 'Mar',
      orders: 2000,
      income: 9800,
      amt: 2290,
    },
    {
      name: 'Apr',
      orders: 2780,
      income: 3908,
      amt: 2000,
    },
    {
      name: 'May',
      orders: 2390,
      income: 3800,
      amt: 2500,
    },
  ];

// student table data =============

export const rows = [
    { id: 1, Name: 'Jon', Email: 'jonaliya@gmail.com', Level: 'A', Grade: 'A' },
    { id: 2, Name: 'Jon', Email: 'jonaliya@gmail.com', Level: 'A', Grade: 'A' },
    { id: 3, Name: 'Jon', Email: 'jonaliya@gmail.com', Level: 'A', Grade: 'A' },
    { id: 4, Name: 'Jon', Email: 'jonaliya@gmail.com', Level: 'A', Grade: 'A' },
    { id: 5, Name: 'Jon', Email: 'jonaliya@gmail.com', Level: 'A', Grade: 'A' },
    { id: 6, Name: 'Jon', Email: 'jonaliya@gmail.com', Level: 'A', Grade: 'A' },
    { id: 7, Name: 'Jon', Email: 'jonaliya@gmail.com', Level: 'A', Grade: 'A' },
    { id: 8, Name: 'Jon', Email: 'jonaliya@gmail.com', Level: 'A', Grade: 'A' },
    { id: 9, Name: 'Jon', Email: 'jonaliya@gmail.com', Level: 'A', Grade: 'A' },
    { id: 10, Name: 'Jon', Email: 'jonaliya@gmail.com', Level: 'A', Grade: 'A' },
    { id: 11, Name: 'Jon', Email: 'jonaliya@gmail.com', Level: 'A', Grade: 'A' },
    { id: 12, Name: 'Jon', Email: 'jonaliya@gmail.com', Level: 'A', Grade: 'A' },
    { id: 13, Name: 'Jon', Email: 'jonaliya@gmail.com', Level: 'A', Grade: 'A' },
    { id: 14, Name: 'moin', Email: 'moin@gmail.com', Level: 'M', Grade: 'M' },
];



// School table data
export const schoolRows = [
  { id: 1, School: 'Meritus Public School', Principal : 'Mr Prasad',  Email: 'meritus@gmail.com', Phone : 9876543212 },
  { id: 2, School: 'Modern School Faridabad', Principal : 'Anita Garg', Email: 'msf@gmail.com', Phone : 9567876544},
  { id: 3, School: 'FBD One School Delhi', Principal : 'AR Rehman', Email: 'rehman@gmail.com', Phone : 9567876542},
  { id: 4, School: 'SVN School Gurgaon', Principal : 'Shahrukh Khan', Email: 'srk@gmail.com', Phone : 9567873434},
  { id: 5, School: 'Kendriya School Faridabad', Principal : 'Imran PK', Email: 'impk@gmail.com', Phone : 9567876545},
  { id: 6, School: 'Stanford School Delhi', Principal : 'Alexendra Daddario', Email: 'alex@gmail.com', Phone : 9567876523},
  { id: 7, School: 'Marry Children School Faridabad', Principal : 'Scarlet Johnson', Email: 'scarjohn@gmail.com', Phone : 9567876511},
  { id: 8, School: 'Ft. Hitler School Surat', Principal : 'Amber Heard', Email: 'am@gmail.com', Phone : 9567876597},
];



export const lastYearData = [
  { name: 'Jan', orders: 0, income: 0, amt: 0 },
  { name: 'Feb', orders: 0, income: 0, amt: 0 },
  { name: 'Mar', orders: 0, income: 0, amt: 0 },
  { name: 'Apr', orders: 0, income: 0, amt: 0 },
  { name: 'May', orders: 0, income: 0, amt: 0 },
  { name: 'Jun', orders: 0, income: 0, amt: 0 },
  { name: 'Jul', orders: 0, income: 0, amt: 0 },
  { name: 'Aug', orders: 0, income: 0, amt: 0 },
  { name: 'Sep', orders: 0, income: 0, amt: 0 },
  { name: 'Oct', orders: 0, income: 0, amt: 0 },
  { name: 'Nov', orders: 0, income: 0, amt: 0 },
  { name: 'Dec', orders: 0, income: 0, amt: 0 }
];
