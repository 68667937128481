import React, { useState } from 'react';
import './NewGiftCard.css';
import { addDiscountStart, addDiscountSuccess, addDiscountfailure } from '../../../redux/discountRedux';
import { fileUploadRequest, userRequest } from '../../../requestMethod';
import { useDispatch } from 'react-redux';
import { validateRequired } from '../../../formValidation';
import Popup from '../../../global/Popup';
import { addGiftCardStart, addGiftCardSuccess, addGiftCardfailure } from '../../../redux/giftCardRedux';

const NewGiftCard = () => {

    const [giftCard, setGiftCard] = useState('');
    const [amount, setAmount] = useState(0);
    const [giftCardImage, setGiftCardImage] = useState('')
    const [isActive, setIsActive] = useState(false);
    const [giftCardError, setGiftCardError] = useState(false);
    const [giftCardErrorMessage, setGiftCardErrorMessage] = useState('');
    const [activePopup, setActivePopup] = useState(false);
    const [popupText, setPopuptext] = useState('');
    const [popupStatus, setPopupStatus] = useState(true);


    const dispatch = useDispatch();

    const handleGiftCardChange = (event) => {
        setGiftCard(event.target.value);
    };

    const addGiftCard = async (dispatch, giftCard) => {
        dispatch(addGiftCardStart());
        try {
            const res = await userRequest.post('/giftcard/create', giftCard);
            if (res.status === 200) {
                setActivePopup(true)
                setPopuptext("Gift Card has been added.");
                setPopupStatus(true);
                setGiftCard('');
                setAmount('');
                setIsActive(false);
            }
            dispatch(addGiftCardSuccess(res.data));

        } catch (error) {
            dispatch(addGiftCardfailure());
            if (error.response.status === 409) {
                setGiftCardError(true);
                setGiftCardErrorMessage(error.response.data);
            }

            else {
                setActivePopup(true)
                setPopuptext(error.response.data);
                setPopupStatus(false);
            }
        }
    };

    const allowedImageFormats = ['image/jpeg', 'image/png', 'image/jpg'];

    const handleGiftCardImageChange = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            // Check if the selected file is an image
            const isImage = allowedImageFormats.includes(files[0].type);
            if (!isImage) {
                // Show an error message or perform some action for invalid image format
                alert('Please select a valid image file (JPEG, PNG, or JPG).');
                event.target.value = null;
                setGiftCardImage('');
                return;
            } else {
                setGiftCardImage(files[0]);
            }
        }
    }

    // Validate function for form validation start=======
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const giftCardValidErr = validateRequired(giftCard);
        const amountValidErr = validateRequired(amount);
        const giftCardImageValidErr = validateRequired(giftCardImage);

        if (giftCardValidErr) {
            formIsValid = false;
            newErrors.giftCard = giftCardValidErr;
        }
        if (amountValidErr) {
            formIsValid = false;
            newErrors.amount = amountValidErr;
        }
        if (giftCardImageValidErr) {
            formIsValid = false;
            newErrors.giftCardImage = giftCardImageValidErr;
        }
        setErrors(newErrors);
        return formIsValid;
    }
    // Validate function for form validation end======

    // const handleAmountChange = (e) => {
    //     const inputValue = e.target.value;
    //     // Check if the discount type is "percentage" and the entered value is greater than 100
    //     if (discountType === 'percentage' && inputValue > 100) {
    //         setErrors({ amount: 'Percentage must be 100 or less' });
    //     } else {
    //         setErrors({}); // Clear any previous error
    //     }
    //     setAmount(inputValue); // Update the amount state
    // };

    const uploadProductImageToSpace = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fileUploadRequest.post('/upload/file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response && response.status === 200) {
                const fileUrl = response.data.fileUrl;
                // setProductImage(fileUrl); // Set the product image URL in state
                return fileUrl
            }
        } catch (error) {
            console.error('Error uploading product image:', error);
        }
    };

    const handleAddGiftCard = async () => {
        if (validateForm()) {
            const trimmmedGiftCard = giftCard.trim();
            const giftCardImgUrl = await uploadProductImageToSpace(giftCardImage);
            addGiftCard(dispatch, { giftCard: trimmmedGiftCard, giftCardImg : giftCardImgUrl, amount, isActive });
        }
    }

    const hidePopup = () => {
        setActivePopup(false);
    }

    if (activePopup) {
        return (
            <Popup status={popupStatus} message={popupText} onClickAction={hidePopup} />
        )
    }

    return (
        <div className='container'>
            <div className="row">
                <div className="col-12">
                    <div className="discountCardWrapper">
                        <div className="discountHeader">
                            <h2>New Gift Card</h2>
                        </div>
                        <div className="discountMiddleWrapper pt-2">
                            <div className="row gy-3">
                                <div className="col-12">
                                    <div className="discountInputFieldWrapper">
                                        <label>Enter a title</label>
                                        <div className="dicountInfoWrapper">
                                            <input type="text" value={giftCard} onChange={handleGiftCardChange} placeholder="e.g. Best Gift" />
                                            {
                                                giftCardError && (
                                                    <p className='error-text text-danger'>{giftCardErrorMessage}</p>
                                                )
                                            }
                                            <span className='error-text'>{errors.giftCard}</span>
                                            {/* <button className='btn couponGenerateBtn text-capitalize' onClick={generateCouponCode}>Generate a coupon</button> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="discountInputFieldWrapper">
                                        <label>Image</label>
                                        <div className="dicountInfoWrapper">
                                            <input type="file" accept={allowedImageFormats} name='file' onChange={handleGiftCardImageChange} />
                                            {/* {
                                                giftCardError && (
                                                    <p className='error-text text-danger'>{giftCardErrorMessage}</p>
                                                )
                                            } */}
                                            <span className='error-text'>{errors.giftCardImage}</span>
                                            {/* <button className='btn couponGenerateBtn text-capitalize' onClick={generateCouponCode}>Generate a coupon</button> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="discountInputFieldWrapper">
                                        <label>Gift Card Value (In Rs)</label>
                                        <div className="dicountInfoWrapper">
                                            <input type="text" value={amount} onChange={(e) => setAmount(e.target.value)} placeholder="e.g. 999" />
                                            <span className='error-text'>{errors.amount}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="discountInputFieldWrapper">
                                        <label>Status</label>
                                        <div className="dicountInfoWrapper">
                                            <select name="status" id="" value={isActive} onChange={(e) => setIsActive(e.target.value)}>
                                                <option value="true">Active</option>
                                                <option value="false">Inactive</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="discountSubmitBtnWrapper">
                                        <button className='custom-btn btn' onClick={handleAddGiftCard}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewGiftCard
