import React, { useEffect, useState } from 'react';
// import './NewBlog.css';
import { useDispatch, useSelector } from 'react-redux';
import { addBlogCategoryFailure, addBlogCategoryStart, addBlogCategorySuccess } from '../../../../redux/blogCategory';
import { fileUploadRequest, userRequest } from '../../../../requestMethod';
import { validateRequired } from '../../../../formValidation';
import CircleLoader from '../../../../components/circleLoader/CircleLoader';
import { allowedImageFormats } from '../../../../config';


const NewBlogCategory = () => {

    const dispatch = useDispatch();

    const [category, setCategory] = useState("");
    const [categoryImage, setCategoryImage] = useState("");
    const [status, setStatus] = useState("");
    // const [categoryImageErr, setCategoryImageErr] = useState("");
    const [titleError, setTitleError] = useState(false);
    const [titleErrorMessage, setTitleErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [activeLoader, setActiveLoader] = useState(false);
    const [loaderText, setLoaderText] = useState("");


    // Validate function for form validation start=======
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const titleErr = validateRequired(category);
        const categoryImageErr = validateRequired(categoryImage);
        if (titleErr) {
            formIsValid = false;
            newErrors.category = titleErr;
        }
        if (categoryImageErr) {
            formIsValid = false;
            newErrors.categoryImage = categoryImageErr;
        }

        setErrors(newErrors);
        return formIsValid;
    }
    // Validate function for form validation end======


    // const allowedImageFormats = ['image/jpeg', 'image/png', 'image/jpg'];


    // const uploadCategoryImage = async () => {
    //     const promises = uploadFile(categoryImage);
    //     return Promise.all(promises);
    // };


    const uploadFileToSpace = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        setActiveLoader(true)
        setLoaderText("Uploading Image");
        try {
            const response = await fileUploadRequest.post('/upload/file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response && response.status === 200) {
                setActiveLoader(false)
                setLoaderText("");
                return response.data.fileUrl;
            }
            // You can now use the file URL as needed, e.g., storing it in your database.
        } catch (error) {
            setActiveLoader(false)
            setLoaderText("");
            console.error('Error uploading file:', error);
        }
    };


    //add api call action
    const addBlogCategory = async (dispatch, category) => {
        dispatch(addBlogCategoryStart());
        try {
            const res = await userRequest.post('/blogCategory/create', category);
            if (res.status === 200) {
                setActiveLoader(false)
                setCategory("");
                setCategoryImage(null);
                setSuccess(true);
                setSuccessMessage("Blog Category has been added!");
            }
            dispatch(addBlogCategorySuccess(res.data));
        } catch (error) {
            dispatch(addBlogCategoryFailure());
            if (error.response.status === 409) {
                setTitleError(true);
                setTitleErrorMessage(error.response.data);
            }

            else {
                setError(true);
                setErrorMessage("Something went wrong!");
            }
        }
    };

    const handleSubmitData = async () => {
        setError(false);
        setSuccess(false);
        setTitleError(false);

        if (validateForm()) {
            setActiveLoader(true);
            setLoaderText("uploading");
            const trimmedValue = category.trim()
            const categoryImageURL = await uploadFileToSpace(categoryImage);
            addBlogCategory(dispatch, { category, categoryImage: categoryImageURL, status });
        }
    };


    return (
        <div className='container'>
            <div className="row mt-5 mb-3">
                <div className="col-12">
                    <div className="pageTitleWrapper">
                        <h2 className="page-heading">Add new category</h2>
                    </div >
                </div >
            </div >
            <div className="row">
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>title</label>
                        <input type="text" name='title' value={category} className='newUserInput' onChange={(e) => setCategory(e.target.value)} placeholder='Title' required />
                        {
                            titleError && (
                                <p className='error-text text-danger'>{titleErrorMessage}</p>
                            )
                        }
                        <span className='error-text'>{errors.category}</span>
                    </div>
                </div>
                {/* <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Category</label>
                        <select name="blogCategories" value={category} onChange={(e) => setCategory(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            {
                                blogCategories ? (
                                    blogCategories.map((item, index) => {
                                        return <option value={item.category} key={index} >{item.category}</option>
                                    })
                                ) : (
                                    <option value="" selected disabled>Not Available</option>
                                )
                            }
                        </select>
                        <span className='error-text'>{errors.category}</span>
                    </div>
                </div> */}
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Category Image</label>
                        {/* <input type="file" name='file' className='newUserInput' onChange={(e) => setBlogImage(e.target.files[0])} placeholder='Image' />
                        <span className='error-text'>{errors.blogImage}</span> */}
                        {/* <div className="col-12 p-0"> */}
                            <input type="file" placeholder='' className='newUserInput' onChange={(e) => setCategoryImage(e.target.files[0])} accept={allowedImageFormats} />
                            {
                                errors.categoryImage && (
                                    <p className='error-text'>{errors.categoryImage}</p>
                                )
                            }
                        {/* </div> */}
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Status</label>
                        <select name="status" value={status} className='newUserInput' onChange={(e) => setStatus(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value="true" >Active</option>
                            <option value="false" >Draft</option>
                        </select>
                    </div>
                </div>
                <div className="col-12">
                    <div className="newStudentSubmitbtn">
                        {
                            // isFetching ? (
                            //     <button disabled={true} className="custom-btn purple-bg">Processing...</button>
                            // ) :
                                <button className="custom-btn purple-bg" onClick={handleSubmitData}>create</button>
                        }
                    </div>
                </div>

                <div className="col-12 mt-3">
                    {
                        success ? (
                            <div className='alert alert-success'>
                                {successMessage}
                            </div>
                        ) : error && (
                            <div className='alert alert-danger'>
                                {errorMessage}
                            </div>
                        )
                    }
                </div>
                {
                    activeLoader && (
                        <CircleLoader title={loaderText} />
                    )
                }
            </div>


        </div>
    )
}

export default NewBlogCategory
