import React, { useState } from 'react';
import './Login.css';
import { useDispatch } from 'react-redux';
// import { login } from '../../redux/apiCalls';
import { loginFailure, loginStart, loginSuccess } from '../../redux/userRedux';
import { publicRequest } from '../../requestMethod';
import Cookies from 'js-cookie';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';
import { getCurrentYear } from '../../config';
import { validateRequired } from '../../formValidation';
import { Link } from 'react-router-dom';
const Login = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userNotFoundError, setUserNotFoundError] = useState(false);
  const [userNotFoundErrorMessage, setUserNotFoundErrorMessage] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [serverError, setServerError] = useState(false);
  const dispatch = useDispatch();

  const login = async (dispatch, user) => {
    dispatch(loginStart());
    setIsLoaderActive(true);
    try {
      const res = await publicRequest.post('/auth/login', user);
      dispatch(loginSuccess(res.data));
      // Cookies.set('userIn', res.data.accessToken, { expires: 3 });
      Cookies.set('userIn', res.data.accessToken, { domain: '.meritus.ai', expires: 3, sameSite: 'None', secure: true });
    } catch (error) {
      dispatch(loginFailure());
      setIsLoaderActive(false);
      if (error?.response?.status === 404) {
        setUserNotFoundError(true);
        setUserNotFoundErrorMessage(error.response.data);
      };

      if (error?.response?.status === 401) {
        setPasswordError(true);
        setPasswordErrorMessage(error.response.data);
      };

      if (error?.code === "ERR_NETWORK") {
        setServerError(true);
      };
    }
  };

  // const handleLogin = async () => {
  //   setUserNotFoundError(false);
  //   setPasswordError(false);
  //   login(dispatch, { email, password });
  // }

  // Validate function for form validation start=======
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    let formIsValid = true;
    const newErrors = {};

    const emailValidErr = validateRequired(email);
    const passwordValidErr = validateRequired(password);

    if (emailValidErr) {
      formIsValid = false;
      newErrors.email = "Please enter your email";
    }
    if (passwordValidErr) {
      formIsValid = false;
      newErrors.password = "Please enter your password";
    }

    setErrors(newErrors);
    return formIsValid;
  }

  const handleLogin = async () => {
    if (validateForm()) {
      setUserNotFoundError(false);
      setPasswordError(false);
      login(dispatch, { email, password });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && validateForm()) {
      handleLogin();
    }
  };




  return (
    // <>
    //   <div className="container-fluid p-0">
    //     <div className="row">
    //       <div className="col-12">
    //         <div className="login-topbar">
    //           <div className="loginPageLogoWrapper">
    //             <img src={`${process.env.PUBLIC_URL}/images/logo/brand-logo.svg`} alt="" />
    //           </div>
    //         </div>
    //         <div className="login-announcement-bar">
    //           <p>Kindly enter credentials to login</p>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="row">
    //       <div className="col-12">
    //         <div className='login-wrapper'>
    //           <h1>Login</h1>
    //           <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Enter your email' required />
    //           {
    //             userNotFoundError && (
    //               <p className='error-text text-danger'>{userNotFoundErrorMessage}</p>
    //             )
    //           }
    //           <input type="password" name="" value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Enter your password' />
    //           {
    //             passwordError && (
    //               <p className='error-text text-danger'>{passwordErrorMessage}</p>
    //             )
    //           }
    //           <button type="submit" onClick={handleLogin}>Login</button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </>

    <>
      {/* <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-12">
                        <div className="login-topbar">
                            <div className="loginPageLogoWrapper">
                                <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="" />
                            </div>
                        </div>
                        <div className="login-announcement-bar">
                            <p>Kindly enter credentials to login</p>
                        </div>
                    </div>
                </div>
            </div> */}
      <div className='w-100'>
        <div className="container-fluid px-0 pb-50">
          <div className="row m-0">
            <div className="col-12 p-0">
              <div className="login-topbar">
                <div className="loginPageLogoWrapper">
                  <img src={`${process.env.PUBLIC_URL}/images/logo/brand-logo.svg`} alt="" />
                </div>
              </div>

            </div>
          </div>
          <div className="row d-flex align-items-center">
            <div className="col-md-6">
              <img src={`${process.env.PUBLIC_URL}/images/pages/login.png`} />
            </div>
            <div className="col-md-6">
              <div className="loginContainer">
                <div className="login-wrapper">
                  <h1 className="text-uppercase my-5">
                    LogIn
                  </h1>
                  {
                    serverError && (
                      <p className='error-text'>Server is not live!</p>
                    )
                  }

                  <div className="w-100">
                    <label for="fullName" class="form-label">
                      Email
                    </label>
                    <input
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email"
                      onKeyDown={handleKeyPress}
                    />
                    <p className="error-text text-danger mb-0 px-3 py-2">
                      {errors.email}
                    </p>
                    {userNotFoundError && (
                      <p className="error-text text-danger">
                        {userNotFoundErrorMessage}
                      </p>
                    )}
                  </div>
                  <div className="w-100">
                    <label for="fullName" class="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      name=""
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter your password"
                      onKeyDown={handleKeyPress}
                    />
                    <p className="error-text text-danger mb-0 px-3 py-2">
                      {errors.password}
                    </p>
                    {passwordError && (
                      <p className="error-text text-danger">
                        {passwordErrorMessage}
                      </p>
                    )}
                  </div>
                  <div className="login-btn-wrapper">
                    <div className="coupon-btn-wrapper">
                      <button type="submit" className={`${isLoaderActive ? 'button-loader load' : 'submit-coupon'}`} onClick={handleLogin}><span>Login</span></button>
                    </div>
                  </div>
                  <div className="forgot-link-wrapper text-center">
                    <Link to={'/forgot-password'} className="text-capitalize">forgot password?</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section class="pb-100 pt-100 loginPage_login_signup">
          <div class="row d-flex justify-content-center m-5">
            <div class="col-md-4">
              <div class="text-center">
                <Link to={'/login'}>
                  <div class="loginPage_icons">
                    <div>
                      <EmailIcon size={40} />
                    </div>
                    <h5>Support</h5>
                    <p>Raise a support token</p>
                  </div>
                </Link>
              </div>
            </div>
            <div class="col-md-4">
              <div class="text-center">
                <Link href={'https://meritus.ai'} target="_blank">
                  <div class="loginPage_icons">
                    <div>
                      <LanguageIcon size={40} />
                    </div>
                    <h5>Browse Website</h5>
                    <p>Visit the website</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <div className="login-announcement-bar p-2">
          <p>&copy; {getCurrentYear() && '2023'}. All Rights Reserved || Meritus</p>
        </div>
      </div>
    </>

  )
}

export default Login