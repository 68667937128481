import { createSlice } from "@reduxjs/toolkit";

const userReducer = createSlice({
    name : 'user',
    initialState : {
        currentUser : null,
        isFetching : false,
        error : false,
    },

    reducers : {
        // login actions
        loginStart : (state) => {
            state.isFetching = true;
        },
        loginSuccess : (state, action) => {
            state.isFetching = false;
            state.currentUser =  action.payload;  
        },
        loginFailure : (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // logout action
        logoutUser : (state) => {
            state.currentUser = null;
            state.isFetching = true;
        },
    },
});

export const { loginStart, loginSuccess, loginFailure, logoutUser} = userReducer.actions;
export default userReducer.reducer;