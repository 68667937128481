import React from 'react';
import './ComingSoon.css'

const ComingSoon = () => {
    return (
        <div className="coming-soon-container text-center pt-100 pb-100">
            <h2 className="inh2 text-center ">
                Coming<span> Soon </span>
            </h2>
            <p className="Unigray">
                We are working hard to bring you something amazing!
            </p>
            <p className="Unigray">Stay tuned for updates.</p>
        </div>
    )
}

export default ComingSoon
