import React, { useEffect, useState } from 'react';
import './NewDiscount.css';
import { addDiscountStart, addDiscountSuccess, addDiscountfailure } from '../../../redux/discountRedux';
import { userRequest } from '../../../requestMethod';
import { useDispatch, useSelector } from 'react-redux';
import { validateRequired } from '../../../formValidation';
import Popup from '../../../global/Popup';
import { getProducts } from '../../../redux/apiCalls';

const NewDiscount = () => {

    const [couponCode, setCouponCode] = useState('');
    const [amount, setAmount] = useState(0);
    const [minOrder, setMinOrder] = useState(0);
    const [discountType, setDiscountType] = useState('fixed');
    const [validFrom, setValidFrom] = useState('');
    const [validTill, setValidTill] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [isForSpecificProduct, setIsForSpecificProduct] = useState(false);
    const [couponError, setCouponError] = useState(false);
    const [couponErrorMessage, setCouponErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [discountTypeError, setDiscountTypeError] = useState(false);
    const [discountTypeErrorMessage, setDiscountTypeErrorMessage] = useState('');
    const [activePopup, setActivePopup] = useState(false);
    const [popupText, setPopuptext] = useState('');
    const [popupStatus, setPopupStatus] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        getProducts(dispatch)
    }, [dispatch]);

    const { isFetching, products } = useSelector((state) => state.products);

    const [selectedProducts, setSelectedProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (e) => {
        // Handle the change of the search input
        setSearchTerm(e.target.value);
    };

    // const handleProductSelect = (e) => {
    //     // Handle the change of selected products in the multi-select dropdown
    //     const selectedProductIds = Array.from(e.target.selectedOptions, option => option.value);
    //     setSelectedProducts(selectedProductIds);
    // };

    const handleProductToggle = (productId) => {
        setSelectedProducts((prevSelectedProducts) => {
            if (prevSelectedProducts.includes(productId)) {
                // Remove the product if it's already selected
                return prevSelectedProducts.filter((id) => id !== productId);
            } else {
                // Add the product if it's not selected
                return [...prevSelectedProducts, productId];
            }
        });
    };


    // Filter products based on the search term
    const filteredProducts = products?.filter(product =>
        product.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const generateCouponCode = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const length = 16;
        let code = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            code += characters.charAt(randomIndex);
        }
        setCouponCode(code);
    };

    const handleCouponChange = (event) => {
        setCouponCode(event.target.value);
    };

    const addDiscount = async (dispatch, coupon) => {
        dispatch(addDiscountStart());
        try {
            const res = await userRequest.post('/discountCoupon/create', coupon);
            // console.log(res)
            if (res.status === 200) {
                setError(false);
                setSuccess(true);
                setSuccessMessage("Coupon has been added!");
                setActivePopup(true)
                setPopuptext("Coupon has been added.");
                setPopupStatus(true);
                setCouponCode('');
                setAmount('');
                setMinOrder('');
                setDiscountType('fixed');
                setValidFrom('');
                setValidTill('');
                setIsActive(false);
                setIsForSpecificProduct(false);
            }
            dispatch(addDiscountSuccess(res.data));

        } catch (error) {
            dispatch(addDiscountfailure());
            if (error.response.status === 409) {
                setCouponError(true);
                setCouponErrorMessage(error.response.data);
                // setActivePopup(true)
                // setPopuptext(error.response.data);
                // setPopupStatus(false);
            }

            else {
                // setSuccess(false);
                // setError(true);
                // setErrorMessage();
                setActivePopup(true)
                setPopuptext(error.response.data);
                setPopupStatus(false);
            }
        }
    };

    // Validate function for form validation start=======
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const couponValidErr = validateRequired(couponCode);
        const amountValidErr = validateRequired(amount, "number");
        const minOrderValidErr = validateRequired(minOrder, "number");
        const discountTypeValidErr = validateRequired(discountType);
        const validFromValidErr = validateRequired(validFrom);
        const validTillValidErr = validateRequired(validTill);

        if (couponValidErr) {
            formIsValid = false;
            newErrors.coupon = couponValidErr;
        }
        if (amountValidErr) {
            formIsValid = false;
            newErrors.amount = amountValidErr;
        }
        if (minOrderValidErr) {
            formIsValid = false;
            newErrors.minOrder = minOrderValidErr;
        }
        if (discountTypeValidErr) {
            formIsValid = false;
            newErrors.discountType = discountTypeValidErr;
        }
        if (validFromValidErr) {
            formIsValid = false;
            newErrors.validFrom = validFromValidErr;
        }
        if (validTillValidErr) {
            formIsValid = false;
            newErrors.validTill = validFromValidErr;
        }

        if (discountType === 'percentage' && amount > 100) {
            formIsValid = false;
            newErrors.amount = "Discount percentage should be less than or equal to 100";
        }

        if(selectedProducts.length < 1 && isForSpecificProduct === "true"){
            formIsValid = false;
            newErrors.selectProductError = "Please select at least one product"
        }

        setErrors(newErrors);
        return formIsValid;
    }
    // Validate function for form validation end======

    // const handleAmountChange = (e) => {
    //     const inputValue = e.target.value;
    //     // Check if the discount type is "percentage" and the entered value is greater than 100
    //     if (discountType === 'percentage' && inputValue > 100) {
    //         setErrors({ amount: 'Percentage must be 100 or less' });
    //     } else {
    //         setErrors({}); // Clear any previous error
    //     }
    //     setAmount(inputValue); // Update the amount state
    // };

    const handleDiscount = () => {
        setDiscountTypeError(false);
        setSuccess(false);

        if (validateForm()) {
            const trimmmedCoupon = couponCode.trim();
            if(isForSpecificProduct === "true"){
                addDiscount(dispatch, { couponCode: trimmmedCoupon, amount, minOrder, discountType, validFrom, validTill, isActive, isForSpecificProducts : isForSpecificProduct, eligibleProducts : selectedProducts });
            }
            else{
                addDiscount(dispatch, { couponCode: trimmmedCoupon, amount, minOrder, discountType, validFrom, validTill, isActive, isForSpecificProducts : isForSpecificProduct });
            }
        }
    }

    const hidePopup = () => {
        setActivePopup(false);
    }

    if (activePopup) {
        return (
            <Popup status={popupStatus} message={popupText} onClickAction={hidePopup} />
        )
    }

    return (
        <div className='container'>
            <div className="row">
                <div className="col-12">
                    <div className="discountCardWrapper">
                        <div className="discountHeader">
                            <h2>New Coupon</h2>
                        </div>
                        <div className="discountMiddleWrapper pt-2">
                            <div className="row gy-3">
                                <div className="col-12">
                                    <div className="discountInputFieldWrapper">
                                        <label>Enter a promocode</label>
                                        <div className="dicountInfoWrapper">
                                            <input type="text" value={couponCode} onChange={handleCouponChange} placeholder="e.g. coupon100" />
                                            {
                                                couponError && (
                                                    <p className='error-text text-danger'>{couponErrorMessage}</p>
                                                )
                                            }
                                            <span className='error-text'>{errors.coupon}</span>
                                            <button className='btn couponGenerateBtn text-capitalize' onClick={generateCouponCode}>Generate a coupon</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <div className="discountInputFieldWrapper">
                                        {
                                            discountType === 'fixed' && (
                                                <>
                                                    <label>Discount (In Rs)</label>
                                                    <div className="dicountInfoWrapper">
                                                        <input value={amount} onChange={(e) => setAmount(e.target.value)} placeholder="e.g. 999" />
                                                        <span className='error-text'>{errors.amount}</span>
                                                    </div>
                                                </>
                                            )
                                        }

                                        {
                                            discountType === 'percentage' && (
                                                <>
                                                    <label>Discount (In Percentage)</label>
                                                    <div className="dicountInfoWrapper">
                                                        <input value={amount} onChange={(e) => setAmount(e.target.value)} placeholder="e.g. 50%" />
                                                        <span className='error-text'>{errors.amount}</span>
                                                    </div>
                                                </>
                                            )
                                        }

                                    </div>
                                </div>
                                <div className="col-5">
                                    <div className="discountInputFieldWrapper">
                                        <label>Discount Type</label>
                                        <div className="dicountTypeBtnWrapper">
                                            <button className={`${discountType == "fixed" ? "active" : ""} discountTypeBtn btn`} value="fixed" type="btn" onClick={(e) => setDiscountType(e.target.value)} >fixed</button>
                                            <button className={`${discountType == "percentage" ? "active" : ""} discountTypeBtn btn`} value="percentage" type="btn" onClick={(e) => setDiscountType(e.target.value)} >percentage</button>
                                        </div>
                                        {
                                            discountTypeError && (
                                                <p className='error-text'>{discountTypeErrorMessage}</p>
                                            )
                                        }
                                        <span className='error-text'>{errors.discountType}</span>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="discountInputFieldWrapper">
                                        <label>Minimum Order</label>
                                        <div className="dicountInfoWrapper">
                                            <input value={minOrder} placeholder='e.g. 999' onChange={(e) => setMinOrder(e.target.value)} />
                                            <span className='error-text'>{errors.minOrder}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="discountInputFieldWrapper">
                                        <label>Valid From</label>
                                        <div className="dicountInfoWrapper">
                                            <input type="date" value={validFrom} onChange={(e) => setValidFrom(e.target.value)} />
                                            <span className='error-text'>{errors.validFrom}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="discountInputFieldWrapper">
                                        <label>Valid Till</label>
                                        <div className="dicountInfoWrapper">
                                            <input type="date" value={validTill} onChange={(e) => setValidTill(e.target.value)} />
                                            <span className='error-text'>{errors.validTill}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="discountInputFieldWrapper">
                                        <label>Status</label>
                                        <div className="dicountInfoWrapper">
                                            <select name="status" id="" value={isActive} onChange={(e) => setIsActive(e.target.value)}>
                                                <option value="true">Active</option>
                                                <option value="false">Inactive</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="discountInputFieldWrapper">
                                        <label>For Specific Product</label>
                                        <div className="dicountInfoWrapper">
                                            <select value={isForSpecificProduct} onChange={(e) => setIsForSpecificProduct(e.target.value)}>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {
                                    isForSpecificProduct === "true" && (
                                        <div className="col-12">
                                            <div className="discountInputFieldWrapper">
                                                <label>Select Product</label>
                                                <div className="dicountInfoWrapper">
                                                    <input type="text" placeholder='Search here...' value={searchTerm}
                                                        onChange={handleSearchChange} />
                                                    <div className="product-list-wrapper">
                                                        {filteredProducts.map((product, index) => {
                                                            return (
                                                                <button className={`product-option-button ${
                                                                    selectedProducts.includes(product._id) ? 'selected' : ''
                                                                  }`} key={index} onClick={() => handleProductToggle(product._id)}>
                                                                    <div className="product-option-wrapper">
                                                                        <div className="option-img-wrapper">
                                                                            <img src={product.productImg} alt='img' />
                                                                        </div>
                                                                        <p>{product.title}</p>
                                                                        <span className={`${product.status === true ? "green-bg" : "red-bg"} product-status`}>{product.status === true ? "active" : "draft"}</span>
                                                                    </div>
                                                                </button>
                                                            )
                                                        })}
                                                    </div>
                                                    <span className='error-text'>{errors.selectProductError}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                <div className="col-12">
                                    <div className="discountSubmitBtnWrapper">
                                        <button className='custom-btn btn' onClick={handleDiscount}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewDiscount
