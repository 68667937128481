import React from 'react';
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

const SchoolPrincipalAndAdminAndHODComponents = () => {
    const currentUser = useSelector((state) => state.user.currentUser);
    let auth;
    if (currentUser) {
        currentUser.isSchoolPrincipal || currentUser.isSchoolAdmin || currentUser.isSchoolHOD  ? auth = true : auth = false;
    }

    return auth ? <Outlet /> : <Navigate to='/' />
}

export default SchoolPrincipalAndAdminAndHODComponents