
import { createSlice } from "@reduxjs/toolkit";

const milestoneReducer = createSlice({
    name: 'milestone',
    initialState: {
        milestones: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get All milestones
        getMilestoneStart: (state) => {
            state.isFetching = true;
        },
        getMilestoneSuccess: (state, action) => {
            state.isFetching = false;
            state.milestones = action.payload;
        },
        getMilestoneFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Delete Milestones                         
        deleteMilestoneStart: (state) => {
            state.isFetching = true;
        },
        deleteMilestoneSuccess: (state, action) => {
            state.isFetching = false;
            state.milestones.splice(
                state.milestones.findIndex((item) => item._id === action.payload), 1
            );
        },
        deleteMilestoneFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Update Milestones
        updateMilestoneStart: (state) => {
            state.isFetching = true;
        },
        updateMilestoneSuccess: (state, action) => {
            state.isFetching = false;
            const milestonesIndex = state.milestones.findIndex(milestone => milestone._id === action.payload._id);

            if (milestonesIndex !== -1) {
                state.milestones[milestonesIndex] = action.payload;
            }
        },
        updateMilestoneFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        //  Add Milestones
        addMilestoneStart: (state) => {
            state.isFetching = true;
        },
        addMilestoneSuccess: (state, action) => {
            state.isFetching = false;
            const newData = action.payload;
            state.milestones = [...state.milestones, newData];
        },
        addMilestoneFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const { getMilestoneStart, getMilestoneSuccess, getMilestoneFailure, deleteMilestoneStart, deleteMilestoneSuccess, deleteMilestoneFailure, updateMilestoneStart, updateMilestoneSuccess, updateMilestoneFailure, addMilestoneStart, addMilestoneSuccess, addMilestoneFailure } = milestoneReducer.actions;
export default milestoneReducer.reducer;