import React, { useEffect, useState } from 'react';
import './EditCourse.css';
import { userRequest } from '../../../requestMethod';
import { useLocation } from 'react-router-dom';
import { Publish } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
// import { updateProducts } from '../../../redux/apiCalls';
import { validateRequired } from '../../../formValidation';
import { updateProductFailure, updateProductStart, updateProductSuccess } from '../../../redux/productRedux';


const EditCourse = () => {
    const location = useLocation();
    const courseId = location.pathname.split('/')[2];

    const productData = useSelector((state) => state.products.products.find(item => item._id === courseId));
    const courseTypes = useSelector((state) => state.courseType.courseTypes);
    const ageGroups = useSelector((state) => state.ageGroup.ageGroups);

    const dispatch = useDispatch();

    // const [course, setCourse] = useState({});
    const [title, setTitle] = useState(productData.title);
    const [description, setDescription] = useState(productData.description);
    const [ageGroup, setAgeGroup] = useState(productData.ageGroup);
    const [courseType, setCourseType] = useState(productData.courseType);
    const [snapAccess, setSnapAccess] = useState(productData.snapAccess);
    const [scratchAccess, setScratchAccess] = useState(productData.scratchAccess);
    const [price, setPrice] = useState(productData.price);
    const [status, setStatus] = useState(productData.status);
    const [productImage, setProductImage] = useState(productData.productImg);
    const [titleError, setTitleError] = useState(false);
    const [titleErrorMessage, setTitleErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    // useEffect(() => {
    //     setCourse(productData);
    //     setTitle(course.title);
    //     setDescription(course.description);
    //     setAgeGroup(course.ageGroup);
    //     setCourseType(course.courseType);
    //     setSnapAccess(course.snapAccess);
    //     setPrice(course.price);
    //     setStatus(course.status);
    // }, [])

    const updateProducts = async (dispatch, id, product) => {
        dispatch(updateProductStart());
        try {
            const res = await userRequest.put(`/product/${id}`, product);
            // dispatch(updateProductSuccess({_id : id, ...product}));
            dispatch(updateProductSuccess(res.data));
            if (res.status === 200) {
                setError(false);
                setSuccess(true);
                setSuccessMessage("Course has been updated!");
            }
        } catch (error) {
            dispatch(updateProductFailure());
            if (error && error.response?.status === 409) {
                setTitleError(true);
                setTitleErrorMessage(error.response.data);
            }

            else {
                setError(true);
                setErrorMessage("Something went wrong!");
            }
        }
    };

    const sendUpdate = (e) => {
        e.preventDefault();
        setSuccess(false);
        setError(false);
        setTitleError(false);
        if (validateForm()) {
            updateProducts(dispatch, courseId, { title, description, ageGroup, courseType, snapAccess, scratchAccess, status, price });
        }
    }

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const titleErr = validateRequired(title);
        const descErr = validateRequired(description);
        const priceErr = validateRequired(price);
        const ageGroupErr = validateRequired(ageGroup);
        const courseTypeErr = validateRequired(courseType);

        if (titleErr) {
            formIsValid = false;
            newErrors.title = titleErr;
        }
        if (descErr) {
            formIsValid = false;
            newErrors.desc = descErr;
        }
        if (priceErr) {
            formIsValid = false;
            newErrors.price = priceErr;
        }

        if (ageGroupErr) {
            formIsValid = false;
            newErrors.ageGroup = ageGroupErr;
        }
        if (courseTypeErr) {
            formIsValid = false;
            newErrors.courseType = courseTypeErr;
        }

        setErrors(newErrors);
        return formIsValid;
    }

    return (
        <>
            <div className='container'>
                <div className='row mt-100'>
                    <div className="col-12">
                        <h2 className='page-heading mb-4 pl-4'>Update Course</h2>
                    </div>
                    <div className="col-lg-4">
                        <div className="courseShowWrapper">
                            <div className="courseShowItems mb-2">
                                <p className='item-heading'>Title</p>
                                <p>{productData.title}</p>
                            </div>
                            <div className="courseShowItems mb-2">
                                <p className='item-heading'>Description</p>
                                <p>{productData.description}</p>
                            </div>
                            <div className="courseShowItems mb-2">
                                <p className='item-heading'>Age Group</p>
                                <p>{productData.ageGroup}</p>
                            </div>
                            <div className="courseShowItems mb-2">
                                <p className='item-heading'>Course Type</p>
                                <p>{productData.courseType}</p>
                            </div>
                            <div className="courseShowItems mb-2">
                                <p className='item-heading'>Snap Access</p>
                                <p>{productData.snapAccess ? "Yes" : "No"}</p>
                            </div>
                            <div className="courseShowItems mb-2">
                                <p className='item-heading'>Scratch Access</p>
                                <p>{productData.scratchAccess ? "Yes" : "No"}</p>
                            </div>
                            <div className="courseShowItems mb-2">
                                <p className='item-heading'>Price</p>
                                <p>{productData.price}</p>
                            </div>
                            <div className="courseShowItems mb-2">
                                <p className='item-heading'>Status</p>
                                <p>{productData.status ? "Active" : "Draft"}</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8">
                        <div className="courseUpdateWrapper">
                            <h2 className='courseUpdateTitle'>Edit</h2>
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="courseUpdateLeft">
                                        <div className="courseUpdateItem">
                                            <label>Title</label>
                                            <input type="text" placeholder={productData.title} value={title} className='courseUpdateInput' onChange={(e) => setTitle(e.target.value
                                            )} />
                                            <span className='error-text'>{errors.title}</span>
                                            {
                                                titleError && (
                                                    <p className='error-text text-danger'>{titleErrorMessage}</p>
                                                )
                                            }
                                        </div>
                                        <div className="courseUpdateItem">
                                            <label>Description</label>
                                            <input type="text" placeholder={productData.description} value={description} className='courseUpdateInput' onChange={(e) => setDescription(e.target.value
                                            )} />
                                            <span className='error-text'>{errors.desc}</span>
                                        </div>
                                        <div className="courseUpdateItem">
                                            <label>Age Group</label>
                                            {/* <input type="text" placeholder={productData.ageGroup} className='courseUpdateInput' onChange={(e) => setAgeGroup(e.target.value
                                            )} /> */}
                                            <select name="ageGroup" value={ageGroup} onChange={(e) => setAgeGroup(e.target.value)}>
                                                <option disabled>Select</option>
                                                {
                                                    ageGroups.map((item, index) => {
                                                        return <option value={item.ageGroup} key={index} >{item.ageGroup}</option>
                                                    })
                                                }
                                            </select>
                                            <span className='error-text'>{errors.ageGroup}</span>
                                        </div>
                                        <div className="courseUpdateItem">
                                            <label>Course Type</label>
                                            {/* <input type="text" placeholder={productData.courseType} className='courseUpdateInput' onChange={(e) => setCourseType(e.target.value
                                            )} /> */}
                                            <select name="courseType" value={courseType} onChange={(e) => setCourseType(e.target.value)}>
                                                <option disabled>Select</option>
                                                {
                                                    courseTypes.map((item, index) => {
                                                        return <option value={item.courseType} key={index} >{item.courseType}</option>
                                                    })
                                                }
                                            </select>
                                            <span className='error-text'>{errors.courseType}</span>
                                        </div>
                                        <div className="courseUpdateItem">
                                            <label>Snap Access</label>
                                            <select name="snapAccess" onChange={(e) => setSnapAccess(e.target.value)}>
                                                <option defaultValue={false} disabled >Select</option>
                                                <option value="true" >Yes</option>
                                                <option value="false" >No</option>
                                            </select>
                                        </div>
                                        <div className="courseUpdateItem">
                                            <label>Scratch Access</label>
                                            <select name="scratchAccess" onChange={(e) => setScratchAccess(e.target.value)}>
                                                <option defaultValue={false} disabled >Select</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                        <div className="courseUpdateItem">
                                            <label>Price</label>
                                            <input type="number" placeholder={price} value={price} className='courseUpdateInput' onChange={(e) => setPrice(e.target.value
                                            )} />
                                            <span className='error-text'>{errors.price}</span>
                                        </div>
                                        <div className="courseUpdateItem">
                                            <label>Status</label>
                                            <select name="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                                                <option defaultValue={false} disabled>Select</option>
                                                <option value="true" >Active</option>
                                                <option value="false" >Draft</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-5">
                                    <div className="courseUpdateRight">
                                        <div className="courseUpdateUpload">
                                            <div className="courseUploadImgWrapper">
                                                <img src={productImage} />
                                            </div>
                                            <label htmlFor="file"><Publish /></label>
                                            {/* <input type="file"   /> */}
                                            <input type="file" id='file' name='productImage' style={{ display: 'none' }} className='newCourseInput' onChange={(e) => setProductImage(e.target.files[0])} placeholder='Price' />

                                        </div>

                                        <div className="courseUpdateButtonWrapper">
                                            <button className='btn btn-primary' onClick={sendUpdate}>Update</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mt-3">
                                    {
                                        success ? (
                                            <div className='alert alert-success'>
                                                {successMessage}
                                            </div>
                                        ) : error && (
                                            <div className='alert alert-danger'>
                                                {errorMessage}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditCourse
