import './App.css';
import Topbar from './global/Topbar';
import { ProSidebarProvider } from 'react-pro-sidebar';
import Sidebar from './global/Sidebar';
import Dashboard from './pages/Dashboard/Dashboard';
import { Routes, Route } from 'react-router-dom';
// import B2C from './pages/B2C/B2C';
import StudentTable from './pages/D2C/student/studentTable/StudentTable';
import NewStudent from './pages/D2C/student/newStudent/NewStudent';
import TeacherTable from './pages/D2C/teacher/teacherTable/TeacherTable';
import NewTeacher from './pages/D2C/teacher/newTeacher/NewTeacher';
import EditStudent from './pages/D2C/student/editStudent/EditStudent';
import EditTeacher from './pages/D2C/teacher/editTeacher/EditTeacher';
import Login from './pages/Login/Login';
import AdminTable from './pages/admin/adminTable/AdminTable';
import EditAdmin from './pages/admin/editAdmin/EditAdmin';
import NewAdmin from './pages/admin/newAdmin/NewAdmin';
// import SchoolTable from './pages/B2C/school/schoolTable/SchoolTable';
// import NewSchool from './pages/B2C/school/newSchool/NewSchool';
import EditSchool from './pages/B2C/school/editSchool/EditSchool';
import SingleSchool from './pages/B2C/school/viewSchool/SingleSchool';
import CourseTable from './pages/course/courseTable/CourseTable';
import EditCourse from './pages/course/editCourse/EditCourse';
import NewCourse from './pages/course/newCourse/NewCourse';
import OrderTable from './pages/orders/orderTable/OrderTable';
import SingleOrder from './pages/orders/singleOrder/SingleOrder';
import Users from './pages/users/Users';
import { Navigate, Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import ViewUser from './pages/users/viewUser/ViewUser';
import DiscountTable from './pages/discount/discountTable/DiscountTable';
import EditDiscount from './pages/discount/editDiscount/EditDiscount';
import NewSchoolAdmin from './pages/B2C/school/viewSchool/newSchoolAdmin/NewSchoolAdmin';
import EditSchoolAdmin from './pages/B2C/school/viewSchool/editSchoolAdmin/EditSchoolAdmin';
import NewSchoolHOD from './pages/B2C/school/viewSchool/newSchoolHOD/NewSchoolHOD';
import EditSchoolHOD from './pages/B2C/school/viewSchool/editSchoolHOD/EditSchoolHOD';
import NewSchoolTeacher from './pages/B2C/school/viewSchool/newSchoolTeacher/NewSchoolTeacher';
import EditSchoolTeacher from './pages/B2C/school/viewSchool/editSchoolTeacher/EditSchoolTeacher';
import ChangePassword from './pages/updateProfile/ChangePassword';
import FranchiseTable from './pages/B2B/franchise/franchiseTable/FranchiseTable';
import NewFranchise from './pages/B2B/franchise/newFranchise/NewFranchise';
import SingleFranchise from './pages/B2B/franchise/SingleFranchise';
import ViewFranchise from './pages/B2B/franchise/viewFranchise/ViewFranchise';
import NewFranchiseSchool from './pages/B2B/franchise/viewFranchise/newFranchiseSchool/NewFranchiseSchool';
import FranchiseSchoolTable from './pages/B2B/franchise/viewFranchise/franchiseSchool/FranchiseSchool';
import ViewSchoolTable from './pages/B2C/school/viewSchoolTable/ViewSchoolTable';
import NewSchool from './components/newSchool/NewSchool';
import CourseType from './pages/course/courseType/CourseType';
import CourseAgeGroup from './pages/course/courseAgeGroup/CourseAgeGroup';
import EditFranchise from './pages/B2B/franchise/editFranchise/EditFranchise';
import SuperadminComponents from './components/privateComponents/SuperadminComponents';
import AllMeritusAdminComponents from './components/privateComponents/AllMeritusAdminComponents';
import SchoolComponents from './components/privateComponents/school/SchoolComponents';
import SchoolDashboard from './pages/school/schoolDashboard/SchoolDashboard';
import SchoolTeacherTable from './pages/school/schoolTeacherTable/SchoolTeacherTable';
import SchoolAdminTable from './pages/school/schoolAdminTable/SchoolAdminTable';
import SchoolHODTable from './pages/school/schoolHODTable/SchoolHODTable';
import SchoolStudentTable from './pages/school/schoolStudent/schoolStudentTable/SchoolStudentTable';
import NewSchoolStudent from './pages/school/schoolStudent/newSchoolStudent/NewSchoolStudent';
import SchoolAdminComponents from './components/privateComponents/school/SchoolAdminComponents';
import SchoolPrincipalAndAdminComponents from './components/privateComponents/school/SchoolPrincipalAndAdminComponents';
import SchoolAllUserComponents from './components/privateComponents/school/SchoolAllUserComponents';
import SchoolPrincipalAndAdminAndHODComponents from './components/privateComponents/school/SchoolPrincipalAndAdminAndHODComponents';
import BlogTable from './pages/Blog/blogTable/BlogTable';
import NewBlog from './pages/Blog/newBlog/NewBlog';
import BlogCategory from './pages/Blog/blogCategory/BlogCategory';
import EditBlog from './pages/Blog/editBlog/EditBlog';
import TestimonialTable from './pages/testimonial/testimonialTable/TestimonialTable';
import NewTestimonial from './pages/testimonial/newTestimonial/NewTestimonial';
import EditTestimonial from './pages/testimonial/editTestimonial/EditTestimonial';
import AllLibraries from './pages/course/lectures/allLibraries/AllLibraries';
import LectureTable from './pages/course/lectures/lectureTable/LectureTable';
import NewMeeting from './pages/meeting/newMeeting/NewMeeting';
import Gmeet from './pages/meeting/newMeeting/Gmeet';
import QuizTable from './pages/quiz/quizTable/QuizTable';
import NewQuiz from './pages/quiz/newQuiz/NewQuiz';
import ZoomMeeting from './pages/meeting/newMeeting/ZoomMeeting';
import io from 'socket.io-client';
import AttendanceTable from './pages/D2C/student/attendance/Attendance/AttendanceTable';
import { userRequest } from './requestMethod';
import ForgotPassword from './pages/forgotPassword/ForgotPassword';
import EditCourseNew from './pages/course/editCourse/EditCourseNew';
import EventsTable from './pages/Events/eventsTable/EventsTable';
import NewEvent from './pages/Events/newEvent/NewEvent';
import EditEvent from './pages/Events/editEvent/EditEvent';
import NewsTable from './pages/news/newsTable/NewsTable';
import AddNews from './pages/news/addNews/AddNews';
import EditNews from './pages/news/editNews/EditNews';
import GiftCardTable from './pages/GiftCard/giftCardTable/GiftCardTable';
import NewGiftCard from './pages/GiftCard/newGiftCard/NewGiftCard';
import EditQuiz from './pages/quiz/editQuiz/EditQuiz';
import RobotixProductTable from './pages/robotix-products/robotixProductTable/RobotixProductTable';
import NewRobotixProduct from './pages/robotix-products/newRobotixProduct/NewRobotixProduct';
import EditRobotixProduct from './pages/robotix-products/editRobotixProduct/EditRobotixProduct';
import BlogCategoryTable from './pages/Blog/blogCategory/blogCategoryTable/BlogCategoryTable';
import NewBlogCategory from './pages/Blog/blogCategory/newBlogCategory/NewBlogCategory';
import EditBlogCategory from './pages/Blog/blogCategory/editBlogCategory/EditBlogCategory';
import Referral from './pages/referral/Referral';
import NewDiscount from './pages/discount/newDiscount/NewDiscount';
import EditGiftCard from './pages/GiftCard/editDiscount/EditGiftCard';
import ComingSoon from './pages/coming-soon/ComingSoon';
import Attendance from './pages/D2C/student/attendance/attendanceTable/Attendance';
import CustomSection from './pages/custom-section/CustomSection';
import CustomSectionTable from './pages/custom-section/customSectionTable/CustomSectionTable';
import NewCustomSection from './pages/custom-section/newCustomSection/NewCustomSection';
import EditCustomSection from './pages/custom-section/editCustomSection/EditCustomSection';
import ContactFormQueries from './pages/contact-form-queries/ContactFormQueries';
import TokenExpirationInterceptor from './global/TokenExpirationInterceptor';
import axios from 'axios';
import SuccessStoriesTable from './pages/success-stories/SuccessStoriesTable';
import NewSuccessStory from './pages/success-stories/NewSuccessStory';
import EditSuccessStory from './pages/success-stories/EditSuccessStory';
import MilestoneTable from './pages/milestone/milestoneTable/MilestoneTable';
import NewMilestone from './pages/milestone/newMilestone/NewMilestone';
import EditMilestone from './pages/milestone/editMilestone/EditMilestone';
import SchoolFormQueries from './pages/SchoolFormQueries/SchoolFormQueries';
import ViewStudentProgress from './pages/school/schoolStudent/viewStudentProgress/ViewStudentProgress';
// import jwt from 'jsonwebtoken';

const App = () => {

  const [notifications, setNotifications] = useState([]);


  // Add a response interceptor
  // const inspector = axios.interceptors.response.use(function (response) {
  //   // Any status code that lie within the range of 2xx cause this function to trigger
  //   // Do something with response data
  //   return response;
  // }, function (error) {
  //   // Any status codes that falls outside the range of 2xx cause this function to trigger
  //   // Do something with response error
  //   return Promise.reject(error);
  // });


  useEffect(() => {
    const socket = io('http://localhost:3005');
    socket.on("newOrder", (order) => {
      // setNotifications(order);
      setNotifications((prevNotifications) => [...prevNotifications, order]);
    });
  }, []);

  // const user = JSON.parse(JSON.parse(localStorage.getItem('persist:root')).user.currentUser);


  // const user = JSON.parse(localStorage.getItem('persist:root')).user;

  // let TOKEN;

  // if(user.currentUser !== null){
  // //  TOKEN = user.accessToken;
  // }
  // else{
  // }


  const dispatch = useDispatch();
  const [isUser, setIsUser] = useState(false);
  const userDetail = useSelector((state) => state.user.currentUser);

  const state = useSelector((state) => state);

  useEffect(() => {
    setIsUser(!!userDetail);
  }, [userDetail]);

  // useEffect(() => {
  //   setIsUser(userDetail.length > 0);
  // }, [userDetail.length]);

  const currentUser = useSelector((state) => state.user.currentUser);
  const accessToken = currentUser && currentUser.accessToken;

  const checkTokenValidity = async () => {
    if (accessToken) {
      try {
        const response = await userRequest.get('/general/token-expiration')

        // Token is valid, you can set the user data in your app state
      } catch (error) {
        if (error && error.response?.status === 403) {
          console.error('Token verification failed:', error.response.data);
          // localStorage.removeItem("persist:root");
          // dispatch(logoutUser());
          // <Navigate to='/login' />
        }
      }
    } else {
      // Token doesn't exist, user is not authenticated, handle the logic here (e.g., redirect to login page)
      // <Navigate to='/login' />
    }
  };

  useEffect(() => {
    checkTokenValidity();
  }, [accessToken]);

  return (
    <ProSidebarProvider>
      <TokenExpirationInterceptor />
      <div className="app d-flex">
        {
          isUser === true && (
            <>
              <Sidebar />
              {/* <Topbar /> */}
            </>
          )
        }
        {
          isUser ? (
            <main className='main-container'>
              <Topbar orderNotification={notifications} />
              <Routes>
                {/* <Route element={<PrivateComponent />}> */}


                {/* <Route path='/D2C' element={<D2C />}></Route> */}
                <Route path='/student' element={<StudentTable />}></Route>
                {/* <Route path='/new-meeting' element={<Gmeet />}></Route> */}
                <Route path='/new-meeting' element={<ZoomMeeting />}></Route>
                {/* <Route path='/new-meeting' element={<NewMeeting />}></Route> */}
                {/* <Route path='/B2C' element={<B2C />}></Route> */}
                {/* <Route path='/B2B' element={<B2B />}></Route> */}
                <Route path='/edit-student/:id' element={<EditStudent />}></Route>
                <Route path='/new-student' element={<NewStudent />}></Route>
                <Route path='/teacher' element={<TeacherTable />}></Route>
                <Route path='/add-teacher' element={<NewTeacher />}></Route>
                <Route path='/edit-teacher/:id' element={<EditTeacher />}></Route>

                {/* meritus admin routes start */}
                <Route element={<SuperadminComponents />}>
                  <Route path='/our-admins' element={<AdminTable />}></Route>
                  <Route path='/edit-admin/:id' element={<EditAdmin />}></Route>
                  <Route path='/new-admin' element={<NewAdmin />}></Route>
                </Route>
                {/* meritus admin routes start */}



                {/* B2C Routes start here */}
                <Route path='/b2b-school' element={<ViewSchoolTable />}></Route>
                <Route path='/add-school' element={<NewSchool />}></Route>
                <Route path='/edit-school/:id' element={<EditSchool />}></Route>
                <Route path='/view-school/:id' element={<SingleSchool />}></Route>
                <Route path='/add-school-admin/:id' element={<NewSchoolAdmin />}></Route>
                <Route path='/edit-school-admin/:id' element={<EditSchoolAdmin />}></Route>
                <Route path='/add-school-hod/:id' element={<NewSchoolHOD />}></Route>
                <Route path='/edit-school-hod/:id' element={<EditSchoolHOD />}></Route>
                <Route path='/add-school-teacher/:id' element={<NewSchoolTeacher />}></Route>
                <Route path='/edit-school-teacher/:id' element={<EditSchoolTeacher />}></Route>
                {/* B2C Routes end here */}

                {/* B2B Routes start here */}
                <Route path='/franchises' element={<FranchiseTable />}></Route>
                <Route path='/add-franchise' element={<NewFranchise />}></Route>
                <Route path='/view-franchise/:id' element={<ViewFranchise />}></Route>
                <Route path='/add-franchise-school/:id' element={<NewFranchiseSchool />}></Route>
                <Route path='/view-franchise-school/:id' element={<FranchiseSchoolTable />}></Route>
                <Route path='/edit-franchise/:id' element={<EditFranchise />}></Route>
                {/* <Route path='/add-franchise-school/:id' element={<FranchiseSchoolTable />}></Route> */}
                {/* B2B Routes end here */}


                {/* Current user related Routes Start */}
                <Route path='/update-password' element={<ChangePassword />}></Route>
                {/* Current user related Routes End */}

                {/*========== components used in ecommerce start=========== */}
                <Route element={<AllMeritusAdminComponents />}>
                  <Route path='/' exact element={<Dashboard />}></Route>
                  <Route path='/course' element={<CourseTable />}></Route>
                  <Route path='/edit-course/:id' element={<EditCourseNew />}></Route>
                  {/* <Route path='/edit-course/:id' element={<EditCourse />}></Route> */}
                  <Route path='/add-course' element={<NewCourse />}></Route>
                  <Route path='/robotix-products' element={<RobotixProductTable />}></Route>
                  <Route path='/add-robotix-product' element={<NewRobotixProduct />}></Route>
                  <Route path='/edit-robotix-product/:id' element={<EditRobotixProduct />}></Route>
                  {/* <Route path='/add-robotix-product' element={<NewRo />}></Route> */}
                  <Route path='/course-types' element={<CourseType />}></Route>
                  <Route path='/course-age-groups' element={<CourseAgeGroup />}></Route>
                  <Route path='/quiz' element={<QuizTable />}></Route>
                  <Route path='/edit-quiz/:id' element={<EditQuiz />}></Route>
                  <Route path='/new-quiz' element={<NewQuiz />}></Route>
                  <Route path='/all-libraries' element={<AllLibraries />}></Route>
                  <Route path='/lectures/:id' element={<LectureTable />}></Route>
                  {/* <Route path='/micro-course' element={<MicroCourseTable />}></Route> */}
                  {/* <Route path='/edit-micro-course/:id' element={<EditMicroCourse />}></Route> */}
                  {/* <Route path='/add-micro-course' element={<NewMicroCourse />}></Route> */}
                  <Route path='/orders' element={< OrderTable />}></Route>
                  <Route path='/view-order/:id' element={< SingleOrder />}></Route>
                  <Route path='/discount' element={< DiscountTable />}></Route>
                  <Route path='/create-discount' element={< NewDiscount />}></Route>
                  <Route path='/giftCards' element={< GiftCardTable />}></Route>
                  {/* <Route path='/gift-card' element={< NewGiftCard />}></Route> */}
                  <Route path='/create-gift-card' element={< NewGiftCard />}></Route>
                  <Route path='/edit-gift-card/:id' element={< EditGiftCard />}></Route>
                  <Route path='/edit-discount/:id' element={< EditDiscount />}></Route>
                  <Route path='/users' element={< Users />}></Route>
                  <Route path='/view-user/:id' element={< ViewUser />}></Route>
                  <Route path='/blogs' element={< BlogTable />}></Route>
                  <Route path='/new-blog' element={< NewBlog />}></Route>
                  <Route path='/blog/view-category' element={< BlogCategoryTable />}></Route>
                  <Route path='/blog/new-blog-category' element={< NewBlogCategory />}></Route>
                  <Route path='/edit-blog-category/:id' element={< EditBlogCategory />}></Route>
                  <Route path='/edit-blog/:id' element={< EditBlog />}></Route>
                  <Route path='/testimonials' element={< TestimonialTable />}></Route>
                  <Route path='/new-testimonial' element={< NewTestimonial />}></Route>
                  <Route path='/edit-testimonial/:id' element={< EditTestimonial />}></Route>
                  <Route path='/attendance-table' element={<AttendanceTable />}></Route>
                  <Route path='/view-attendance/user' element={<Attendance />}></Route>
                  <Route path='/events' element={<EventsTable />}></Route>
                  <Route path='/add-event' element={<NewEvent />}></Route>
                  <Route path='/edit-event/:id' element={<EditEvent />}></Route>
                  <Route path='/news' element={<NewsTable />}></Route>
                  <Route path='/add-news' element={<AddNews />}></Route>
                  <Route path='/edit-news/:id' element={<EditNews />}></Route>
                  <Route path='/referral' element={<Referral />}></Route>
                  <Route path='/coming-soon' element={<ComingSoon />}></Route>
                  <Route path='/custom-section' element={<CustomSectionTable />}></Route>
                  <Route path='/add-section' element={<NewCustomSection />}></Route>
                  <Route path='/edit-section/:title' element={<EditCustomSection />}></Route>
                  <Route path='/contact-form-queries' element={<ContactFormQueries />}></Route>
                  <Route path='/school-form-queries' element={<SchoolFormQueries />}></Route>
                  <Route path='/success-stories' element={<SuccessStoriesTable />}></Route>
                  <Route path='/add-story-video' element={<NewSuccessStory />}></Route>
                  <Route path='/edit-story-video/:id' element={<EditSuccessStory />}></Route>
                  <Route path='/milestone' element={<MilestoneTable />}></Route>
                  <Route path='/add-milestone' element={<NewMilestone />}></Route>
                  <Route path='/edit-milestone/:id' element={<EditMilestone />}></Route>

                </Route>
                {/*========== components used in ecommerce end=========== */}
                {/* </Route> */}

                {/* School principal components only start */}
                <Route element={<SchoolComponents />}>
                  <Route path='/school-admins' element={<SchoolAdminTable />}></Route>
                </Route>
                {/* School principal components only end */}




                <Route element={<SchoolPrincipalAndAdminComponents />}>
                  {/* <Route path='/school-dashboard' element={<SchoolDashboard />}></Route> */}
                  <Route path='/' element={<SchoolHODTable />}></Route>
                  <Route path='/school-hod' element={<SchoolHODTable />}></Route>
                </Route>

                {/* <Route element={<SchoolAdminComponents />}>
                  <Route path='/school-teacher' element={<SchoolTeacherTable />}></Route>
                  <Route path='/school-hod' element={<SchoolHODTable />}></Route>
                  <Route path='/school-students' element={<SchoolStudentTable />}></Route>
                  <Route path='/add-school-student/:id' element={<NewSchoolStudent />}></Route>
                </Route> */}

                <Route element={<SchoolPrincipalAndAdminAndHODComponents />}>
                  <Route path='/school-teacher' element={<SchoolTeacherTable />}></Route>
                </Route>
                <Route element={<SchoolAllUserComponents />}>
                  <Route path='/school-students' element={<SchoolStudentTable />}></Route>
                  <Route path='/add-school-student/:id' element={<NewSchoolStudent />}></Route>
                  <Route path='/view-progress/:id' element={<ViewStudentProgress />}></Route>
                </Route>
              </Routes>
            </main>
          ) :
            <Routes>
              <Route path='/' element={<Login />}></Route>
              <Route path="/forgot-password" element={<ForgotPassword />}></Route>
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        }
      </div>
    </ProSidebarProvider>
  );
}

export default App;
