import axios from 'axios';
import React, { useState } from 'react'
import { userRequest } from '../../../requestMethod';

const ZoomMeeting = () => {

    const [topic, setTopic] = useState('');
    const [startTime, setStartTime] = useState('');
    const [duration, setDuration] = useState('');

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await userRequest.post('/meeting/create', { topic, startTime, duration });

            // Reset form inputs
            setTopic('');
            setStartTime('');
            setDuration('');
        } catch (error) {
            console.error('Error creating Zoom meeting:', error);
        }
    };

    return (
        <div>
            <h1>Create Zoom Meeting</h1>
            <form onSubmit={handleFormSubmit}>
                <label>
                    Topic:
                    <input type="text" value={topic} onChange={(e) => setTopic(e.target.value)} />
                </label>
                <label>
                    Start Time:
                    <input type="text" value={startTime} onChange={(e) => setStartTime(e.target.value)} />
                </label>
                <label>
                    Duration:
                    <input type="text" value={duration} onChange={(e) => setDuration(e.target.value)} />
                </label>
                <button type="submit">Create Meeting</button>
            </form>
        </div>
    )
}

export default ZoomMeeting
