import React, { useEffect, useState } from 'react'
import './Dashboard.css'
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import SellSharpIcon from '@mui/icons-material/SellSharp';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import Chart from '../../components/chart/Chart';
import { lastYearData, salesData } from '../../dumyData';
import WidgetSm from '../../components/widgetSm/WidgetSm';
import WidgetLg from '../../components/widgetLg/WidgetLg';
import { useDispatch, useSelector } from 'react-redux';
import { getAllOrders, getAllUsers, getProducts } from '../../redux/apiCalls';
import { userRequest } from '../../requestMethod';
import { convertMonthNumberToName, formatNumberInThousandsAndMillions } from '../../config';
import CircleLoader from '../../components/circleLoader/CircleLoader';

const Dashboard = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        getAllOrders(dispatch)
    }, [dispatch]);

    useEffect(() => {
        getAllUsers(dispatch);
    }, [dispatch]);

    useEffect(() => {
        getProducts(dispatch)
    }, [dispatch]);

    const { isFetching, products: course } = useSelector((state) => state.products);

    // const isFetching = useSelector((state) => state.allUsers.isFetching);
    const users = useSelector((state) => state.allUsers.users);
    const schools = users && users.filter((item) => item.isSchoolPrincipal === true);
    const franchises = users && users.filter((item) => item.isFranchiseOwner === true);
    const students = users && users.filter((item) => item.isMeritusStudent === true);

    const orders = useSelector((state) => state.orders.orders);
    // const [monthlyIncome, setMonthlyIncome] = useState(0);
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [currentMonthIncome, setCurrentMonthIncome] = useState(0);
    const [lastYearSalesData, setLastYearSalesData] = useState([]);
    const [isLastMonthData, setIsLastMonthData] = useState(false);
    const [isLastYearData, setIsLastYearData] = useState(true);

    const getCurrentMonthIncome = async () => {
        try {
            const response = await userRequest.get('/order/income/thisMonth');
            if (response?.status === 200) {
                setCurrentMonthIncome(response.data?.totalIncome)
            }
        } catch (error) {
        }
    };

    const getLastYearIncome = async () => {
        try {
            const response = await userRequest.get('/order/income/lastyear');
            if (response?.status === 200) {
                setLastYearSalesData(response.data)
            }
        } catch (error) {
        }
    };

    useEffect(() => {
        getLastYearIncome()
    }, []);

    // Convert month numbers to month names in the array
    const newLastYearData = lastYearSalesData?.map(item => {
        return {
            ...item,
            monthName: convertMonthNumberToName(item.monthName)
        };
    });




    // const getMonthlyIncome = async () => {
    //     try {
    //         const response = await userRequest.get('/order/income/lastmonth');
    //         if(response?.status === 200){
    //             setMonthlyIncome(response.data[1]?.total)
    //         }
    //     } catch (error) {
    //     }
    // };

    const getTotalRevenue = async () => {
        try {
            const response = await userRequest.get('/order/income/total');
            if (response?.status === 200) {
                setTotalRevenue(response.data?.totalIncome)
            }
        } catch (error) {
        }
    };

    useEffect(() => {
        // getMonthlyIncome();
        getTotalRevenue();
        getCurrentMonthIncome();
    }, [totalRevenue, currentMonthIncome]);


    const handleLastMonthChart = () => {
        setIsLastMonthData(true);
        setIsLastYearData(false);
    }

    const handleLastYearChart = () => {
        setIsLastMonthData(false);
        setIsLastYearData(true);
    }


    if (isFetching) {
        return (
            <div className='loader-wrapper'>
                <CircleLoader />
            </div>
        )
    }

    return (
        <>
            <div className='Dashboard-container'>
                <div className='container-fluid'>
                    <div className='row py-3'>
                        <div className='col-lg-7'>
                            <div className='sales-wrapper'>
                                <div className="sales-header">
                                    <h2 className='heading'>Sales Overview</h2>
                                    <div className='sales-button-wrapper'>
                                        <button className={isLastMonthData ? "active" : ""} onClick={handleLastMonthChart} >last month</button>
                                        <button className={isLastYearData ? "active" : ""} onClick={handleLastYearChart}>last year</button>
                                    </div>
                                </div>
                                <div className='sales-'></div>
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <div className='sales-revenue-wrapper'>
                                            <DonutSmallIcon style={{ color: 'red', fontSize: '50px' }} />
                                            <div className='total-revenue-wrapper'>
                                                <h2>&#8377; {formatNumberInThousandsAndMillions(currentMonthIncome)}</h2>
                                                <p>This month</p>
                                            </div>
                                            {/* <div className="revenue-graph-wrapper">
                                                <div className="revenue-graph-img-wrapper">
                                                    <img src={`${process.env.PUBLIC_URL}/images/sine.png`} width="100%" alt="" />
                                                </div>
                                                <div className='revenue-growth-wrapper'>
                                                    <ArrowUpwardIcon /> 
                                                    <p>10%</p>
                                                </div>
                                            </div> */}
                                            <div className='sales-report-btn-wrapper'>
                                                <button className='transparent-btn'><ArrowCircleDownIcon style={{ marginRight: '10px', color: '#ccc' }} />download report</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-8'>
                                        {/* sales live graph will come here */}
                                        {
                                            isLastYearData && (
                                                isLastYearData?.length > 0 ? (
                                                    <Chart data={newLastYearData} dataKeyForIncome="income" dataKeyForXAxis="monthName" chartType="year" />
                                                ) : (
                                                    <Chart data={lastYearData} dataKeyForIncome="income" dataKeyForXAxis="monthName" chartType="year" />
                                                )
                                            )
                                        }
                                        {
                                            isLastMonthData && (
                                                <Chart data={newLastYearData} dataKeyForIncome="income" dataKeyForXAxis="monthName" chartType="month" />
                                            )
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className='col-lg-5'>
                            <div className='data-collection-wrapper'>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className='data-collection-card'>
                                            <div className='data-card-content'>
                                                <h4>&#8377; {formatNumberInThousandsAndMillions(totalRevenue)}</h4>
                                                <SellSharpIcon style={{ color: "var(--purple" }} />
                                            </div>
                                            <p>Revenue</p>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='data-collection-card'>
                                            <div className='data-card-content'>
                                                <h4>{students?.length}</h4>
                                                <Diversity3OutlinedIcon style={{ color: "var(--green" }} />
                                            </div>
                                            <p>Students</p>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='data-collection-card'>
                                            <div className='data-card-content'>
                                                <h4>{schools?.length}</h4>
                                                <SchoolOutlinedIcon style={{ color: "var(--orange" }} />
                                            </div>
                                            <p>Schools</p>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='data-collection-card'>
                                            <div className='data-card-content'>
                                                <h4>{orders?.length}</h4>
                                                <ShoppingCartOutlinedIcon style={{ color: "var(--skyBlue" }} />
                                            </div>
                                            <p>Orders</p>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='data-collection-card'>
                                            <div className='data-card-content'>
                                                <h4>{course?.length}</h4>
                                                <Inventory2OutlinedIcon style={{ color: "var(--pink" }} />
                                            </div>
                                            <p>Courses</p>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='data-collection-card'>
                                            <div className='data-card-content'>
                                                <h4>{franchises?.length}</h4>
                                                <AdminPanelSettingsOutlinedIcon style={{ color: "var(--darkOrange" }} />
                                            </div>
                                            <p>Franchise</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-lg-4 h-100">
                            <WidgetSm schools={schools} />
                        </div>
                        <div className='col-lg-8 h-100'>
                            <WidgetLg orders={orders} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard
