import { Visibility } from '@mui/icons-material';
import React from 'react';
import './WidgetSm.css';

const WidgetSm = (props) => {
    return (
        <div className='widgetSm'>
            <span className="widgetSmTitle ">New Schools</span>
            <ul className="widgetSmList p-0 mt-3">
                {
                    props.schools.map((school, key) => {
                        if (key <= 4) {
                            return (
                                <li className="widgetSmListItem">
                                    <div className="widgetSmImgWrapper">
                                        {
                                            school.profileImg ? (
                                                <img src={`${process.env.PUBLIC_URL}/images/profile.jpg`} alt="user_img" />
                                            ) : (
                                                <img src={`${process.env.PUBLIC_URL}/images/profile.png`} alt="user_img" />
                                            )
                                        }
                                    </div>
                                    <div className="widgetSmUser">
                                        <span className="widgetSmUserName">{school.schoolName}</span>
                                        <span className="widgetSmUserEmail">{school.email}</span>
                                    </div>
                                </li>
                            )
                        }
                        else{
                            return null;
                        }
                    })
                }

                {/* <li className="widgetSmListItem">
                    <div className="widgetSmImgWrapper">
                        <img src={`${process.env.PUBLIC_URL}/images/profile.jpg`} alt="user_img" />
                    </div>
                    <div className="widgetSmUser">
                        <span className="widgetSmUserName">Anna Keller</span>
                        <span className="widgetSmUserEmail">anna123@gmail.com </span>
                    </div>
                    <button className="widgetSmButton">
                        <Visibility />
                        Display
                    </button>
                </li>
                <li className="widgetSmListItem">
                    <div className="widgetSmImgWrapper">
                        <img src={`${process.env.PUBLIC_URL}/images/profile.jpg`} alt="user_img" />
                    </div>
                    <div className="widgetSmUser">
                        <span className="widgetSmUserName">Anna Keller</span>
                        <span className="widgetSmUserEmail">anna123@gmail.com </span>
                    </div>
                    <button className="widgetSmButton">
                        <Visibility />
                        Display
                    </button>
                </li>
                <li className="widgetSmListItem">
                    <div className="widgetSmImgWrapper">
                        <img src={`${process.env.PUBLIC_URL}/images/profile.jpg`} alt="user_img" />
                    </div>
                    <div className="widgetSmUser">
                        <span className="widgetSmUserName">Anna Keller</span>
                        <span className="widgetSmUserEmail">anna123@gmail.com </span>
                    </div>
                    <button className="widgetSmButton">
                        <Visibility />
                        Display
                    </button>
                </li>
                <li className="widgetSmListItem">
                    <div className="widgetSmImgWrapper">
                        <img src={`${process.env.PUBLIC_URL}/images/profile.jpg`} alt="user_img" />
                    </div>
                    <div className="widgetSmUser">
                        <span className="widgetSmUserName">Anna Keller</span>
                        <span className="widgetSmUserEmail">anna123@gmail.com </span>
                    </div>
                    <button className="widgetSmButton">
                        <Visibility />
                        Display
                    </button>
                </li> */}
            </ul>
        </div>
    )
}

export default WidgetSm
