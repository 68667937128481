import { createSlice } from "@reduxjs/toolkit";

const discountReducer = createSlice({
    name : 'discount',
    initialState : {
        discounts : [],
        isFetching : false,
        error : false,
    },

    reducers : {
        // Get discount actions
        getDiscountsStart : (state) => {
            state.isFetching = true;
        },

        getDiscountsSuccess : (state, action) => {
            state.isFetching = false;
            state.discounts = action.payload;
        },

        getDiscountsFailure : (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Delete discount actions
        deleteDiscountStart: (state) => {
            state.isFetching = true;
        },
        deleteDiscountSuccess: (state, action) => {
            state.isFetching = false;
            state.discounts.splice(
                state.discounts.findIndex((item) => item._id === action.payload), 1
            );
        },
        deleteDiscountFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Add discount actions
        addDiscountStart : (state) => {
            state.isFetching  = true;
        },

        addDiscountSuccess : (state, action) => {
            state.isFetching = false;
            // state.discounts = action.payload;
            const newData = action.payload;
            state.discounts = [ ...state.discounts, newData ];
        },

        addDiscountfailure : (state) => {
            state.isFetching = false;
            state.error = false;
        },

        // Update discount actions
        updateDiscountStart: (state) => {
            state.isFetching = true;
        },
        updateDiscountSuccess: (state, action) => {
            state.isFetching = false;
            const discountIndex = state.discounts.findIndex(discount => discount._id === action.payload._id);
            if (discountIndex !== -1) {
                state.discounts[discountIndex] = action.payload;
            }
        },
        updateDiscountFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

    },
});

export const { getDiscountsStart, getDiscountsSuccess, getDiscountsFailure, deleteDiscountStart, deleteDiscountSuccess,deleteDiscountFailure, addDiscountStart, addDiscountSuccess, addDiscountfailure, updateDiscountStart, updateDiscountSuccess, updateDiscountFailure } = discountReducer.actions;
export default discountReducer.reducer