import { createSlice } from "@reduxjs/toolkit";

const lectureLibraryReducer = createSlice({
    name : 'lectureLibrary',
    initialState : {
        lectureLibrary : [],
        isFetching : false,
        error : false,
    },

    reducers : {
        // Get lecture actions
        getlectureLibraryStart : (state) => {
            state.isFetching = true;
        },

        getlectureLibrarySuccess : (state, action) => {
            state.isFetching = false;
            state.lectureLibrary = action.payload;
            state.error = false;
        },

        getlectureLibraryFailure : (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Delete lecture actions
        deletelectureLibraryStart: (state) => {
            state.isFetching = true;
        },
        deletelectureLibrarySuccess: (state, action) => {
            state.isFetching = false;
            state.lectureLibrary.splice(
                state.lectureLibrary.findIndex((item) => item._id === action.payload), 1
            );
        },
        deletelectureLibraryFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Add lecture actions
        addlectureLibraryStart : (state) => {
            state.isFetching  = true;
        },

        addlectureLibrarySuccess : (state, action) => {
            state.isFetching = false;
            state.lectureLibrary = action.payload;
        },

        addlectureLibraryfailure : (state) => {
            state.isFetching = false;
            state.error = false;
        },

        // Update lecture actions
        updatelectureLibraryStart: (state) => {
            state.isFetching = true;
        },
        updatelectureLibrarySuccess: (state, action) => {
            state.isFetching = false;
            const lectureIndex = state.lectureLibrary.findIndex(lecture => lecture._id === action.payload._id);
            if (lectureIndex !== -1) {
                state.lectureLibrary[lectureIndex] = action.payload;
            }
        },
        updatelectureLibraryFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

    },
});

export const { getlectureLibraryStart, getlectureLibrarySuccess, getlectureLibraryFailure, deletelectureLibraryStart, deletelectureLibrarySuccess,deletelectureLibraryFailure, addlectureLibraryStart, addlectureLibrarySuccess, addlectureLibraryfailure, updatelectureLibraryStart, updatelectureLibrarySuccess, updatelectureLibraryFailure } = lectureLibraryReducer.actions;
export default lectureLibraryReducer.reducer