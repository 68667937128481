import React, { useEffect, useState } from 'react';
import './BlogCategory.css';
import ViewCard from '../../../components/viewCard/ViewCard';
import { fileUploadRequest, userRequest } from '../../../requestMethod';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBlogCategory } from '../../../redux/apiCalls';
// import { Delete } from '@mui/icons-material';
import Loader from '../../../global/Loader';
import { validateRequired } from '../../../formValidation';
import { addBlogCategoryFailure, addBlogCategoryStart, addBlogCategorySuccess, deleteBlogCategoryFailure, deleteBlogCategoryStart, deleteBlogCategorySuccess } from '../../../redux/blogCategory';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import app from '../../../firebase';

const BlogCategory = () => {

    const dispatch = useDispatch();

    const [category, setCategory] = useState("");
    const [categoryImage, setCategoryImage] = useState("");
    // const [categoryImageErr, setCategoryImageErr] = useState("");
    const [titleError, setTitleError] = useState(false);
    const [titleErrorMessage, setTitleErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [activeLoader, setActiveLoader] = useState(false);
    const [loaderText, setLoaderText] = useState("");

    //add api call action
    const addBlogCategory = async (dispatch, category) => {
        dispatch(addBlogCategoryStart());
        try {
            const res = await userRequest.post('/blogCategory/create', category);
            if (res.status === 200) {
                setActiveLoader(false)
                setCategory("");
                setCategoryImage(null);
                setSuccess(true);
                setSuccessMessage("Blog Category has been added!");
            }
            dispatch(addBlogCategorySuccess(res.data));
        } catch (error) {
            dispatch(addBlogCategoryFailure());
            if (error.response.status === 409) {
                setTitleError(true);
                setTitleErrorMessage(error.response.data);
            }

            else {
                setError(true);
                setErrorMessage("Something went wrong!");
            }
        }
    };

    // Delete api call action
    const deletBlogCategory = async (dispatch, blogCategoryId) => {
        dispatch(deleteBlogCategoryStart());
        try {
            const res = await userRequest.delete(`/blogCategory/${blogCategoryId}`);
            dispatch(deleteBlogCategorySuccess(blogCategoryId));
        } catch (error) {
            dispatch(deleteBlogCategoryFailure());
        }
    };

    const handleDeleteCategory = (blogCategoryId) => {
        setError(false);
        setSuccess(false);
        setTitleError(false);
        deletBlogCategory(dispatch, blogCategoryId)
    };


    // Validate function for form validation start=======
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const titleErr = validateRequired(category);
        const categoryImageErr = validateRequired(categoryImage);
        if (titleErr) {
            formIsValid = false;
            newErrors.category = titleErr;
        }
        if (categoryImageErr) {
            formIsValid = false;
            newErrors.categoryImage = categoryImageErr;
        }

        setErrors(newErrors);
        return formIsValid;
    }
    // Validate function for form validation end======

    // fetch all existing categories
    useEffect(() => {
        getAllBlogCategory(dispatch)
    }, [dispatch]);

    const blogCategories = useSelector((state) => state.blogCategory.blogCategory);


    const allowedImageFormats = ['image/jpeg', 'image/png', 'image/gif'];


    // const uploadCategoryImage = async () => {
    //     const promises = uploadFile(categoryImage);
    //     return Promise.all(promises);
    // };


    const uploadFileToSpace = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        setActiveLoader(true)
        setLoaderText("Uploading Image");
        try {
            const response = await fileUploadRequest.post('/upload/file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response && response.status === 200) {
                setActiveLoader(false)
                setLoaderText("");
                return response.data.fileUrl;
            }
            // You can now use the file URL as needed, e.g., storing it in your database.
        } catch (error) {
            setActiveLoader(false)
            setLoaderText("");
            console.error('Error uploading file:', error);
        }
    };


    const handleSubmitData = async () => {
        setError(false);
        setSuccess(false);
        setTitleError(false);

        if (validateForm()) {
            setActiveLoader(true);
            setLoaderText("uploading");
            const trimmedValue = category.trim()
            const categoryImageURL = await uploadFileToSpace(categoryImage);
            addBlogCategory(dispatch, { category, categoryImage: categoryImageURL });
        }
    };
    // if (isFetching) {
    //     return (
    //         <div className='loader-wrapper'>
    //             <Loader />
    //         </div>
    //     )
    // }

    return (
        <ViewCard cardTitle="Blog Category" handleSubmitData={handleSubmitData} value={category} setValue={setCategory} imgValue={categoryImage} setImgValue={setCategoryImage} titleError={titleError} titleErrorMessage={titleErrorMessage} error={error} errorMessage={errorMessage} success={success} successMessage={successMessage} errors={errors} data={blogCategories} deleteAction={handleDeleteCategory} pageType="blogCategory" activeLoader={activeLoader} loaderText = {loaderText}/>
    )
}

export default BlogCategory
