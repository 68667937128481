import React, { useEffect, useState } from 'react'
import MiniLoader from '../../../../global/MiniLoader';
import { userRequest } from '../../../../requestMethod';
import { useLocation } from 'react-router-dom';
import './ViewStudentProgress.css'

const ViewStudentProgress = () => {

    const [progressOfAllCourses, setProgressOfAllCourses] = useState(null);
    const [isProgressOfAllCoursesFetching, setIsProgressOfAllCoursesFetching] = useState(false);
    const location = useLocation();
    const currentUserId = location.pathname.split('/')[2];

    const getAllCoursesProgressOfSpecificUser = async (id) => {
        try {
            setIsProgressOfAllCoursesFetching(true)
            const res = await userRequest.get(`/update-course-progress/${id}/progress`);
            if (res?.status === 200) {
                setIsProgressOfAllCoursesFetching(false)
                const fetchedData = res.data
                setProgressOfAllCourses(fetchedData);
            }
        } catch (error) {
            setIsProgressOfAllCoursesFetching(false)
        }
    };

    useEffect(() => {
        getAllCoursesProgressOfSpecificUser(currentUserId)
    }, [currentUserId]);

    if (isProgressOfAllCoursesFetching) {
        <MiniLoader />
    }

    return (
        <div className="container pt-50">
            <div className="row pt-3">
                <div className="col-12">
                    <div className="course-progress-container">
                    <div className="course-progress-inner-wrapper">
                    <h1 className='mb-3'>Progress of courses</h1>
                    {
                        progressOfAllCourses?.length > 0 ? progressOfAllCourses.map((courseOuter, courseIndex) => {
                            return (
                                <div key={courseIndex}>
                                    {
                                        courseOuter?.chaptersProgress?.length > 0 ? courseOuter.chaptersProgress.map((progress, index) => {
                                            if (progress) {
                                                return (
                                                    <div className='progress-wrapper'>
                                                        <div className="progress-title-wrapper">
                                                            <p>{progress.title}</p>
                                                            <span>{progress.completionPercentage}%</span>
                                                        </div>
                                                        <div class="progress">
                                                            <div class="progress-bar" role="progressbar" style={{ width: `${progress.completionPercentage}%` }} aria-valuenow={progress.completionPercentage} aria-valuemin="0" aria-valuemax="100">{progress.completionPercentage}%</div>
                                                        </div></div>
                                                )
                                            }

                                        }) : (
                                            <p>it is not a array {courseIndex}</p>
                                        )
                                    }
                                </div>
                            )
                        }
                        ) : (
                            <h3>User has not purchased any course yet</h3>
                        )
                    }
                    </div>
                    
                </div>
                    </div>
                   
            </div>
        </div>

    )
}

export default ViewStudentProgress
