import React from 'react'

const CustomSection = () => {
  return (
    <div>
      <h1>Hello</h1>
    </div>
  )
}

export default CustomSection
