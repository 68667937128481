import React, { useEffect, useState } from 'react';
import './QuizTable.css';
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { deleteQuiz, getAllQuiz } from '../../../redux/apiCalls';
import CircleLoader from '../../../components/circleLoader/CircleLoader';

const QuizTable = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        getAllQuiz(dispatch);
    }, [dispatch]);

    const quiz = useSelector((state) => state.quiz.quiz);
    const isFetching = useSelector((state) => state.quiz.isFetching);


     // Add index numbers to the quiz array
     const quizWithIndex = quiz.map((quiz, index) => ({
        ...quiz,
        index: index + 1, // Add 1 to the index to start from 1 instead of 0
    }));



    // let quizWithId;
    // if (quiz) {
    //     let idCounter = 1;
    //     quizWithId = quiz && quiz.map((quiz) => ({
    //         ...quiz,
    //         id: idCounter++,
    //     }));
    // };

    const handleDelete = (quizId) => {
        deleteQuiz(quizId, dispatch);
        setDeletePopup(false);
    }

    const [deletePopup, setDeletePopup] = useState(false);
    const [deletingItem, setDeletingItem] = useState(null);

    const handlePopup = (id) => {
        setDeletePopup(true);
        setDeletingItem(id);
    };

    const handlePopupCancel = () => {
        setDeletePopup(false)
    }


    const columns = [
        { field: 'index', headerName: '#', width: 20 },
        {
            field: 'title',
            headerName: 'Quiz Title',
            width: 200,
            editable: false,
        },
        {
            field: 'questions',
            headerName: 'No. of question',
            sortable: false,
            width: 150,
            renderCell: (params) => {
                const lengthOfQuestions = params.row.questions.length;
                return lengthOfQuestions
            }
        },
        {
            field: 'Action',
            headerName: 'Action',
            sortable: false,
            width: 100,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={'/edit-quiz/' + params.id}>
                            <button className='btn btn-primary studentListEdit'>Edit</button>
                        </Link>
                    </>
                )
            }
        },
        {
            field: 'delete',
            headerName: 'Delete',
            sortable: false,
            width: 100,
            renderCell: (params) => {
                const quizId = params.row._id;
                return (
                    <>
                        <DeleteOutline onClick={() => handlePopup(quizId)} className='text-danger' />
                    </>
                )
            }
        },
    ];

    if(isFetching){
        return(
            <CircleLoader />
        )
    }

    return (
        <div className='studentTableWrapper py-5'>
            <div className="row">
                <div className="col-12">
                    <div className="tableButtonWrapper d-flex justify-content-between mb-3">
                        <h2>Quiz</h2>
                        <div className="btn-wrapper d-flex" style={{ columnGap: '20px' }}>
                            {/* <Link to='/new-meeting'>
                                <button className='custom-btn purple-bg'>Schedule a class <Groups3Icon className='newStudentIcon' /></button>
                            </Link> */}
                            <Link to='/new-quiz'>
                                <button className='custom-btn purple-bg'>New Quiz <AddCircleOutlineOutlinedIcon className='newStudentIcon' /></button>
                            </Link>
                        </div>

                    </div>
                </div>
            </div>

            {
                deletePopup && (
                    <div className="deletePopupAlertWrapper">
                        <div className="deletePopupAlert">
                            <WarningAmberIcon style={{ color: "var(--orange)", fontSize: "40px" }} />
                            <h4>Warning</h4>
                            <div className="deleteAlertTextWrapper text-center">
                                <p>This quiz will be deleted permanently.</p>
                                <p>Are you sure?</p>
                            </div>

                            <div className="deleteBtnsWrapper">
                                <button className='btn' onClick={handlePopupCancel} style={{ background: "var(--green)", color: "var(--white)" }}>Cancel</button>
                                <button className='btn' onClick={() => handleDelete(deletingItem)} style={{ background: "var(--red)", color: "var(--white)" }}>Delete</button>
                            </div>
                        </div>
                    </div>
                )
            }

            {
                quiz && (
                    <DataGrid
                        rows={quizWithIndex}
                        columns={columns}
                        getRowId={(rows) => rows._id}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[10]}
                        checkboxSelection = {false}
                        disableRowSelectionOnClick
                    />
                )
            }

        </div>
    )
}

export default QuizTable
