import { createSlice } from "@reduxjs/toolkit";

const productReducer = createSlice({
    name: 'product',
    initialState: {
        products: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get All Product
        getProductStart: (state) => {
            state.isFetching = true;
        },
        getProductSuccess: (state, action) => {
            state.isFetching = false;
            state.products = action.payload;
        },
        getProductFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Delete Product                         
        deleteProductStart: (state) => {
            state.isFetching = true;
        },
        deleteProductSuccess: (state, action) => {
            state.isFetching = false;
            state.products.splice(
                state.products.findIndex((item) => item._id === action.payload), 1
            );
        },
        deleteProductFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Update Product
        updateProductStart: (state) => {
            state.isFetching = true;
        },
        updateProductSuccess: (state, action) => {
            state.isFetching = false;
            const productIndex = state.products.findIndex(product => product._id === action.payload._id);
            if (productIndex !== -1) {
                state.products[productIndex] = action.payload;
            }
        },
        updateProductFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        //  Add Product
        addProductStart: (state) => {
            state.isFetching = true;
        },
        addProductSuccess: (state, action) => {
            state.isFetching = false;
            // state.products = action.payload;
            const newData = action.payload;
            state.products = [ ...state.products, newData ];
        },
        addProductFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const { getProductStart, getProductSuccess, getProductFailure, deleteProductStart, deleteProductSuccess, deleteProductFailure, updateProductStart, updateProductSuccess, updateProductFailure, addProductStart, addProductSuccess, addProductFailure } = productReducer.actions;
export default productReducer.reducer;