import React, { useState } from 'react';
import './EditTestimonial.css';
import { fileUploadRequest, userRequest } from '../../../requestMethod';
import { useDispatch, useSelector } from 'react-redux';
import { validateRequired } from '../../../formValidation';
import { addTestimonialFailure, addTestimonialStart, addTestimonialSuccess, updateTestimonialFailure, updateTestimonialStart, updateTestimonialSuccess } from '../../../redux/testimonialRedux';
import { useLocation } from 'react-router-dom';
import { extractFileName } from '../../../config';
import CircleLoader from '../../../components/circleLoader/CircleLoader';

const EditTestimonial = () => {


    const location = useLocation();
    const testimonialId = location.pathname.split('/')[2];

    const testimonialData = useSelector((state) => state.testimonial.testimonials.find((item) => item._id == testimonialId));
    const testimonials = useSelector((state) => state.testimonial.testimonials);


    const [parentName, setParentName] = useState(testimonialData.parentName);
    const [description, setDescription] = useState(testimonialData.description);
    const [childName, setChildName] = useState(testimonialData.childName);
    const [previewImage, setPreviewImage] = useState(testimonialData.testimonialImg);
    const [status, setStatus] = useState(testimonialData.status);
    const [parentNameError, setParentNameError] = useState(false);
    const [parentNameErrorMessage, setParentNameErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [testimonialImage, setTestimonialImage] = useState(null);
    const [oldImage, setOldImage] = useState(testimonialData.testimonialImg);
    const [activeLoader, setActiveLoader] = useState(false);
    const [loaderText, setLoaderText] = useState("");
    // const { isFetching, products } = useSelector((state) => state.products)


    const handleImage = (e) => {
        const selectedFile = e.target.files[0];

        if (selectedFile) {
            setTestimonialImage(selectedFile);
            setPreviewImage(URL.createObjectURL(selectedFile));
        } else {
            // Handle the case when no file is selected
            // You can display an error message or perform other actions
            setPreviewImage(testimonialData.testimonialImage)
        }
    }

    const dispatch = useDispatch();

    const updateTestimonial = async (dispatch, testimonialId, testimonial) => {
        dispatch(updateTestimonialStart());
        try {
            const res = await userRequest.put(`/testimonial/${testimonialId}`, testimonial);
            if (res.status === 200) {
                setSuccess(true);
                setSuccessMessage("Testimonial has been updated!")
                setParentName('');
                setDescription('');
                setChildName('');
                setStatus(false);
                setTestimonialImage(null);
            }
            dispatch(updateTestimonialSuccess(res.data));
        } catch (error) {
            dispatch(updateTestimonialFailure());
            if (error.response.status === 409) {
                setParentNameError(true);
                setParentNameErrorMessage(error.response.data);
            }

            else {
                setError(true);
                setErrorMessage("Something went wrong!");
            }
        }
    };


    const uploadFileToSpace = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        setActiveLoader(true)
        setLoaderText("Uploading Image");
        try {
            const response = await fileUploadRequest.post('/upload/file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });


            if (response && response.status === 200) {
                setActiveLoader(false)
                setLoaderText("");
                return response.data.fileUrl;
            }
            // You can now use the file URL as needed, e.g., storing it in your database.
        } catch (error) {
            setActiveLoader(false)
            setLoaderText("");
            console.error('Error uploading file:', error);
        }
    };

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const parentNameErr = validateRequired(parentName);
        const descErr = validateRequired(description);
        const childNameErr = validateRequired(childName);
        const testimonialImageErr = validateRequired(testimonialImage);

        if (parentNameErr) {
            formIsValid = false;
            newErrors.parentName = parentNameErr;
        }
        if (descErr) {
            formIsValid = false;
            newErrors.desc = descErr;
        }
        if (childNameErr) {
            formIsValid = false;
            newErrors.childName = childNameErr;
        }

        // if (testimonialImageErr) {
        //     formIsValid = false;
        //     newErrors.testimonialImage = testimonialImageErr;
        // }

        setErrors(newErrors);
        return formIsValid;
    }

    const handleUpdate = async () => {
        setSuccess(false);
        setError(false);
        setParentNameError(false);
        const trimmedTitle = parentName.trim();
        let oldImageName = null;
        if (validateForm()) {
            if (testimonialImage) {
                const testimonialImgUrl = await uploadFileToSpace(testimonialImage);
                // setTestimonialImage(testimonialImgUrl);
                // const testimonialImgUrl = "test-link";
                oldImageName = extractFileName(oldImage);
                updateTestimonial(dispatch, testimonialId, { parentName, description, childName, testimonialImg : testimonialImgUrl, status, oldImageName });
            }

            else {
                updateTestimonial(dispatch, testimonialId, { parentName, description, childName, status });
            }
        }
    }

    return (
        <div className='container'>
            <div className="row mt-5 mb-3">
                <div className="col-12">
                    <div className="pageTitleWrapper">
                        <h2 className="page-heading">Update testimonial</h2>
                    </div >
                </div >
            </div >
            <div className="row">
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Parent Name</label>
                        <input type="text" name='parentName' value={parentName} className='newUserInput' onChange={(e) => setParentName(e.target.value)} placeholder='Parent Name' required />
                        {
                            parentNameError && (
                                <p className='error-text text-danger'>{parentNameErrorMessage}</p>
                            )
                        }
                        <span className='error-text'>{errors.parentName}</span>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Child Name</label>
                        <input type="text" name='parentName' value={childName} className='newUserInput' onChange={(e) => setChildName(e.target.value)} placeholder='Child Name' required />
                        {
                            parentNameError && (
                                <p className='error-text text-danger'>{parentNameErrorMessage}</p>
                            )
                        }
                        <span className='error-text'>{errors.parentName}</span>
                    </div>
                </div>
                {/* <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Category</label>
                        <select name="blogCategories" value={childName} onChange={(e) => setChildName(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            {
                                blogCategories ? (
                                    blogCategories.map((item, index) => {
                                        return <option value={item.childName} key={index} >{item.childName}</option>
                                    })
                                ) : (
                                    <option value="" selected disabled>Not Available</option>
                                )
                            }
                        </select>
                        <span className='error-text'>{errors.childName}</span>

                    </div>
                </div> */}
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Update Image</label>
                        <input type="file" name='testimonialImage' className='newUserInput' onChange={handleImage} placeholder='Image' />
                        <span className='error-text'>{errors.testimonialImage}</span>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Status</label>
                        <select name="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value="true" >Active</option>
                            <option value="false" >Draft</option>
                        </select>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Image Preview</label>
                        <div className="blog-img-wrapper">
                            <img src={previewImage || testimonialImage} alt="testimonial-img" />
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>description</label>
                        {/* <input type="text" name='description' value={description} className='newUserInput' onChange={(e) => setDescription(e.target.value)} placeholder='Description' /> */}
                        <textarea type="text" rows={10} name='description' value={description} className='newUserInput' onChange={(e) => setDescription(e.target.value)} placeholder='Type here...' />
                        {/* <ReactQuill style={{ height: '200px' }} value={description} onChange={handleDescriptionChange} /> */}

                        <span className='error-text'>{errors.desc}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="newStudentSubmitbtn">
                        <button className="custom-btn purple-bg" onClick={handleUpdate}>update</button>
                    </div>
                </div>

                <div className="col-12 mt-3">
                    {
                        success ? (
                            <div className='alert alert-success'>
                                {successMessage}
                            </div>
                        ) : error && (
                            <div className='alert alert-danger'>
                                {errorMessage}
                            </div>
                        )
                    }
                </div>

                {
                    activeLoader && (
                        <CircleLoader title={loaderText} />
                    )
                }
            </div>
        </div>
    )
}

export default EditTestimonial
