import React, { useState } from 'react';
import './NewAdmin.css';
import { addUserFailure, addUserStart, addUserSuccess } from '../../../redux/registeredUserRedux';
import { useDispatch } from 'react-redux';
import { userRequest } from '../../../requestMethod';
import { validateRequired } from '../../../formValidation';

const NewAdmin = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState(null);
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const isMeritusAdmin = true;

    const dispatch = useDispatch();

    const addAdmin = async (dispatch, admin) => {
        dispatch(addUserStart());
        try {
            const res = await userRequest.post('user/create', admin);
            dispatch(addUserSuccess(res.data));
            if (res.status === 200) {
                setSuccess(true);
                setSuccessMessage("Admin has been added!")
            }
        } catch (error) {
            dispatch(addUserFailure());
            if (error.response.status === 409) {
                setEmailError(true);
                setEmailErrorMessage(error.response.data);
            }

            else {
                setError(true);
                setErrorMessage("Something went wrong!");
            }
        }
    };

    // Validate function for form validation start=======
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const firstNameValidErr = validateRequired(firstName);
        const lastNameValidErr = validateRequired(lastName);
        const emailValidErr = validateRequired(email);
        const phoneValidErr = validateRequired(phone);
        const dobValidErr = validateRequired(dateOfBirth);

        if (firstNameValidErr) {
            formIsValid = false;
            newErrors.firstName = firstNameValidErr;
        }
        if (lastNameValidErr) {
            formIsValid = false;
            newErrors.lastName = lastNameValidErr;
        }
        if (emailValidErr) {
            formIsValid = false;
            newErrors.email = emailValidErr;
        }
        if (phoneValidErr) {
            formIsValid = false;
            newErrors.phone = phoneValidErr;
        }
        if (dobValidErr) {
            formIsValid = false;
            newErrors.dob = dobValidErr;
        }

        setErrors(newErrors);
        return formIsValid;
    }
    // Validate function for form validation end======

    const handleAdminData = () => {
        setEmailError(false);
        setSuccess(false);
        if(validateForm()){
            addAdmin(dispatch, { firstName, lastName, email, phone, dateOfBirth, isMeritusAdmin })
        }
    }


    return (
        <>
            <div className="NewRegisterFormWrapper">
                <div className="container">
                    <div className="row">
                        <h1 className="page-heading py-4">Add new admin</h1>
                        <div className="col-10">
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-input-wrapper">
                                        <label>first name</label>
                                        <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} className='newUserInput' placeholder='Name' />
                                        <span className='error-text'>{errors.firstName}</span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-input-wrapper">
                                        <label>last name</label>
                                        <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} className='newUserInput' placeholder='Name' />
                                        <span className='error-text'>{errors.lastName}</span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-input-wrapper">
                                        <label>email</label>
                                        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} className='newUserInput' placeholder='Email' />
                                        <span className='error-text'>{errors.email}</span>
                                        {
                                            emailError && (
                                                <p className='error-text text-danger'>{emailErrorMessage}</p>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-input-wrapper">
                                        <label>phone</label>
                                        <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} className='newUserInput' placeholder='Phone' />
                                        <span className='error-text'>{errors.phone}</span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-input-wrapper">
                                        <label>user type</label>
                                        <input type="text" value={isMeritusAdmin ? "admin" : ""} className='newUserInput' placeholder='Address' readOnly />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-input-wrapper">
                                        <label>date of birth</label>
                                        <input type="date" value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} className='newUserInput' placeholder='Date of birth' />
                                        <span className='error-text'>{errors.dob}</span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-input-wrapper">
                                        <label>Profile picture</label>
                                        <input type="file" className='newUserInput' />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="newStudentSubmitbtn">
                                        <button className="custom-btn purple-bg" onClick={handleAdminData}>create</button>
                                    </div>
                                </div>
                                <div className="col-12 mt-3">
                                    {
                                        success ? (
                                            <div className='alert alert-success'>
                                                {successMessage}
                                            </div>
                                        ) : error && (
                                            <div className='alert alert-danger'>
                                                {errorMessage}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewAdmin
