// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.availableAgeGroup{
    background: var(--pink);
    color: var(--white);
    font-weight: 600;
    padding: 10px 15px;
}

.avilableAgeGroupWrapper{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.ageGroupContainer{
    display: flex;
    column-gap: 15px;
    flex-wrap: wrap;
    row-gap: 15px;
}

.courseAgeGroupWrapper{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    width: 50%;
    margin: 0 auto;
    margin-top: 200px;
    padding: 30px;
    box-shadow: var(--boxShadow);
    row-gap: 15px;
}

.newAgeGroupInputWrapper{
    width: 100%;
    display: flex;
    row-gap: 10px;
    flex-direction: column;
}

.newAgeGroupInputWrapper input{
    width: 80%;
    border: 2px solid #c3c5f8 !important;
    border-radius: 15px !important;
    padding: 10px!important;
    color: var(--darkGrey);
    width: 100%;
    height: 50px;
}

.newAgeGroupInputWrapper button{
    width: 20%;
}

.ageGroupDelete{
    font-size: 16px;
}

.availableAgeGroup{
    display: flex;
    align-items: center;
    column-gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/course/courseAgeGroup/CourseAgeGroup.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,yBAAyB;IACzB,sBAAsB;IACtB,UAAU;IACV,cAAc;IACd,iBAAiB;IACjB,aAAa;IACb,4BAA4B;IAC5B,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,UAAU;IACV,oCAAoC;IACpC,8BAA8B;IAC9B,uBAAuB;IACvB,sBAAsB;IACtB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".availableAgeGroup{\n    background: var(--pink);\n    color: var(--white);\n    font-weight: 600;\n    padding: 10px 15px;\n}\n\n.avilableAgeGroupWrapper{\n    display: flex;\n    flex-direction: column;\n    row-gap: 15px;\n}\n\n.ageGroupContainer{\n    display: flex;\n    column-gap: 15px;\n    flex-wrap: wrap;\n    row-gap: 15px;\n}\n\n.courseAgeGroupWrapper{\n    display: flex;\n    justify-content: center;\n    /* align-items: center; */\n    flex-direction: column;\n    width: 50%;\n    margin: 0 auto;\n    margin-top: 200px;\n    padding: 30px;\n    box-shadow: var(--boxShadow);\n    row-gap: 15px;\n}\n\n.newAgeGroupInputWrapper{\n    width: 100%;\n    display: flex;\n    row-gap: 10px;\n    flex-direction: column;\n}\n\n.newAgeGroupInputWrapper input{\n    width: 80%;\n    border: 2px solid #c3c5f8 !important;\n    border-radius: 15px !important;\n    padding: 10px!important;\n    color: var(--darkGrey);\n    width: 100%;\n    height: 50px;\n}\n\n.newAgeGroupInputWrapper button{\n    width: 20%;\n}\n\n.ageGroupDelete{\n    font-size: 16px;\n}\n\n.availableAgeGroup{\n    display: flex;\n    align-items: center;\n    column-gap: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
