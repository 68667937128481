import React, { useEffect, useState } from 'react';
import './TeacherTable.css';
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { deleteUser, getAllUsers } from '../../../../redux/apiCalls';
import { useDispatch, useSelector } from 'react-redux';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CircleLoader from '../../../../components/circleLoader/CircleLoader';

const TeacherTable = () => {

  const dispatch = useDispatch();
  // const [teachers, setTeachers] = useState([]);

  useEffect(() => {
    getAllUsers(dispatch);
  }, [dispatch]);

  const isFetching = useSelector((state) => state.allUsers.isFetching);
  const teachers = useSelector((state) => state.allUsers?.users?.filter((item) => item.isMeritusTeacher === true));

  // Add index numbers to the teachers array
  const teachersWithIndex = teachers?.map((teacher, index) => ({
    ...teacher,
    index: index + 1, // Add 1 to the index to start from 1 instead of 0
  }));

  // useEffect(() => {
  //   if (users) {
  //     setTeachers(users.filter((item) => item.isMeritusTeacher === true))
  //   }
  // }, [users])

  const handleDelete = (studentId) => {
    deleteUser(studentId, dispatch);
    setDeletePopup(false);
  }

  const [deletePopup, setDeletePopup] = useState(false);
  const [deletingItem, setDeletingItem] = useState(null);

  const handlePopup = (id) => {
    setDeletePopup(true);
    setDeletingItem(id);
  };

  const handlePopupCancel = () => {
    setDeletePopup(false)
  }


  const columns = [
    { field: 'index', headerName: '#', width: 50 },
    {
      field: 'firstName',
      headerName: 'First Name',
      width: 100,
      editable: false,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      width: 100,
      editable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
      editable: false,
    },
    {
      field: 'grade',
      headerName: 'Grade',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return params.value ? params.value : "Not assigned";
      }
    },
    {
      field: 'level',
      headerName: 'Level',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return params.value ? params.value : "Not assigned";
      }
    },
    {
      field: 'phone',
      headerName: 'Phone',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 150,
    },
    {
      field: 'Action',
      headerName: 'Action',
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <>
            <Link to={'/edit-teacher/' + params.id}>
              <button className='btn btn-primary studentListEdit'>Edit</button>
            </Link>
          </>
        )
      }
    },
    {
      field: 'delete',
      headerName: 'Delete',
      sortable: false,
      width: 100,
      renderCell: (params) => {
        const studentId = params.row._id;
        return (
          <>
            <DeleteOutline onClick={() => handlePopup(studentId)} className='text-danger' />
          </>
        )
      }
    },
  ];

  if (isFetching) {
    return (
      <div className='loader-wrapper'>
        <CircleLoader />
      </div>
    )
  }

  return (
    <div className='py-5'>
      <div className="row">
        <div className="col-12">
          <div className="tableButtonWrapper d-flex justify-content-between mb-3">
            <h2>Trainers</h2>
            <Link to='/add-teacher'>
              <button className='custom-btn purple-bg'>New Trainer <AddCircleOutlineOutlinedIcon className='newStudentIcon' /></button>
            </Link>

          </div>
        </div>
      </div>
      {
        deletePopup && (
          <div className="deletePopupAlertWrapper">
            <div className="deletePopupAlert">
              <WarningAmberIcon style={{ color: "var(--orange)", fontSize: "40px" }} />
              <h4>Warning</h4>
              <div className="deleteAlertTextWrapper text-center">
                <p>This student will be deleted permanently.</p>
                <p>Are you sure?</p>
              </div>

              <div className="deleteBtnsWrapper">
                <button className='btn' onClick={handlePopupCancel} style={{ background: "var(--green)", color: "var(--white)" }}>Cancel</button>
                <button className='btn' onClick={() => handleDelete(deletingItem)} style={{ background: "var(--red)", color: "var(--white)" }}>Delete</button>
              </div>
            </div>
          </div>
        )
      }


      {
        teachers && (
          <DataGrid
            rows={teachersWithIndex}
            getRowId={rows => rows._id}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            checkboxSelection={false}
            disableRowSelectionOnClick
          />
        )
      }

    </div>
  )
}

export default TeacherTable
