
import { createSlice } from "@reduxjs/toolkit";

const successStoriesReducer = createSlice({
    name: 'successStories',
    initialState: {
        videos: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get All Videos
        getStoriesVideoStart: (state) => {
            state.isFetching = true;
        },
        getStoriesVideoSuccess: (state, action) => {
            state.isFetching = false;
            state.videos = action.payload;
        },
        getStoriesVideoFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Delete Videos                         
        deleteStoriesVideoStart: (state) => {
            state.isFetching = true;
        },
        deleteStoriesVideoSuccess: (state, action) => {
            state.isFetching = false;
            state.videos.splice(
                state.videos.findIndex((item) => item._id === action.payload), 1
            );
        },
        deleteStoriesVideoFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Update Videos
        updateStoriesVideoStart: (state) => {
            state.isFetching = true;
        },
        updateStoriesVideoSuccess: (state, action) => {
            state.isFetching = false;
            const videosIndex = state.videos.findIndex(videos => videos._id === action.payload._id);

            if (videosIndex !== -1) {
                state.videos[videosIndex] = action.payload;
            }
        },
        updateStoriesVideoFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        //  Add Videos
        addStoriesVideoStart: (state) => {
            state.isFetching = true;
        },
        addStoriesVideoSuccess: (state, action) => {
            state.isFetching = false;
            const newData = action.payload;
            state.videos = [...state.videos, newData];
        },
        addStoriesVideoFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const { getStoriesVideoStart, getStoriesVideoSuccess, getStoriesVideoFailure, deleteStoriesVideoStart, deleteStoriesVideoSuccess, deleteStoriesVideoFailure, updateStoriesVideoStart, updateStoriesVideoSuccess, updateStoriesVideoFailure, addStoriesVideoStart, addStoriesVideoSuccess, addStoriesVideoFailure } = successStoriesReducer.actions;
export default successStoriesReducer.reducer;