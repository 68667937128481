import React, { useEffect, useState } from 'react'
import './AttendanceTable.css'
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers } from '../../../../../redux/apiCalls';
import { userRequest } from '../../../../../requestMethod';
import Popup from '../../../../../global/Popup';
import CircleLoader from '../../../../../components/circleLoader/CircleLoader';

const AttendanceTable = () => {

  const dispatch = useDispatch();
  const [students, setStudents] = useState([]);
  const [activeLoader, setActiveLoader] = useState(false);
  const [loaderText, setLoaderText] = useState("");
  const [activePopup, setActivePopup] = useState(false);
  const [popupText, setPopuptext] = useState('');
  const [popupStatus, setPopupStatus] = useState(true);
  // const [value]

  useEffect(() => {
    getAllUsers(dispatch);
  }, [dispatch]);

  const isFetching = useSelector((state) => state.allUsers.isFetching);
  const users = useSelector((state) => state.allUsers.users);
  const [attendanceData, setAttendanceData] = useState([]);

  const currentDate = new Date().toISOString().split('T')[0];


  useEffect(() => {
    if (users) {
      // Initialize the attendanceStatus property to null
      const updatedStudents = users
        .filter((item) => item.isMeritusStudent === true)
        .map((student) => ({ userId: student._id, email: student.email, firstName: student.firstName, lastName: student.lastName,  attendanceStatus: false, attendanceDate: currentDate }));

      setStudents(updatedStudents);
      setAttendanceData(updatedStudents);
    }
  }, [users]);


  // const [students, setStudents] = useState([]);


  // Fetch student data from backend API
  // useEffect(() => {
  //   fetchStudents();
  // }, []);

  // const fetchStudents = async () => {
  //   try {
  //     const response = await fetch('/api/students');
  //     const data = await response.json();
  //     // Initialize attendanceData with default values (absent)
  //     const initialAttendanceData = data.map((student) => ({
  //       studentId: student.id,
  //       attendanceStatus: 'absent',
  //     }));
  //     setAttendanceData(initialAttendanceData);
  //     setStudents(data);
  //   } catch (error) {
  //     console.error('Error fetching students:', error);
  //   }
  // };

  const handleAttendanceChange = (studentId, value) => {
    // Update the attendance data for the selected student
    const updatedAttendanceData = attendanceData?.map((data) =>

      data.userId === studentId ? {userId: data._id, email: data.email, firstName: data.firstName, lastName: data.lastName,  attendanceStatus: value, attendanceDate: data.attendanceDate } : { ...data}
    );
    setAttendanceData(updatedAttendanceData);
  };
  //   const addStudent = async (dispatch, student) => {
  //     dispatch(addUserStart());
  //     try {
  //         const res = await userRequest.post('user/create', student);
  //         dispatch(addUserSuccess(res.data));
  //         if (res.status === 200) {
  //             setSuccess(true);
  //             setSuccessMessage("Student has been added!");
  //             setFirstName('');
  //             setLastName('') ;
  //             setEmail('');
  //             setPhone('');
  //             setDateOfBirth('');
  //         }
  //     } catch (error) {
  //         dispatch(addUserFailure());
  //         if (error.response.status === 409) {
  //             setEmailError(true);
  //             setEmailErrorMessage(error.response.data);
  //         }

  //         else {
  //             setError(true);
  //             setErrorMessage("Something went wrong!");
  //         }
  //     }
  // };


  const submitAttendance = async (attendanceData) => {
    try {
      setActiveLoader(true)
      setLoaderText("Uploading")
      // Send attendance data to the backend
      const res = await userRequest.post('/attendance/create', attendanceData);
      if (res.status === 200) {
        setPopupStatus(true);
        setPopuptext("Attendance has been added!");
        setActiveLoader(false)
      }
      // Clear the attendance data after submitting
      setAttendanceData([]);
    } catch (error) {
      setActiveLoader(false)
      console.error('Error submitting attendance:', error);
    }
  };

  const handleAttendanceData = () => {
    if (attendanceData) {
      submitAttendance(attendanceData)
    } else {
    }
  }


  const hidePopup = () => {
    setActivePopup(false);
  }

  if (activePopup) {
    return (
      <Popup status={popupStatus} message={popupText} onClickAction={hidePopup} />
    )
  }

  return (
    <>
      <div className='attendance-main-container'>
        <div className="attendance-wrapper">
          <h2>Attendance Module</h2>
          <table>
            <thead>
              <tr>
                <th>Student Name</th>
                <th>Email</th>
                <th>Attendance</th>
              </tr>
            </thead>
            <tbody>
              {attendanceData.map((student) => (
                <tr key={student.userId}>
                  <td className='text-capitalize'>{student.firstName + " " + student.lastName}</td>
                  <td className='text-capitalize'>{student.email}</td>
                  <td>
                    <select value={student.attendanceStatus} onChange={(e) => handleAttendanceChange(student.userId, e.target.value)}>
                      <option disabled selected>Select</option>
                      <option value={true}>Present</option>
                      <option value={false}>Absent</option>
                    </select>
                  </td>
                </tr>
              ))}
              {/* {students.map((student) => (
              <tr key={student.id}>
                <td className='text-capitalize'>{student.firstName + " " + student.lastName}</td>
                <td className='text-capitalize'>{student.email}</td>
                <td>
                  <select
                    value={attendanceData.find((data) => data.studentId === student.id)?.attendanceStatus || 'absent'}
                    onChange={(e) => handleAttendanceChange(student.id, e.target.value)}
                  >
                    <option value="present">Present</option>
                    <option value="absent">Absent</option>
                  </select>
                </td>
              </tr>
            ))} */}
            </tbody>
          </table>
          <button onClick={handleAttendanceData} className='custom-btn'>Submit Attendance</button>
        </div>
      </div>
      {
        activeLoader && (
          <CircleLoader title={loaderText} />
        )
      }
    </>
  )
}

export default AttendanceTable
