import { getProductStart, getProductSuccess, getProductFailure, deleteProductStart, deleteProductSuccess, deleteProductFailure, updateProductStart, updateProductSuccess, updateProductFailure } from './productRedux';
import { publicRequest, userRequest } from "../requestMethod";
import { deleteUserFailure, deleteUserStart, deleteUserSuccess, getUsersFailure, getUsersStart, getUsersSuccess } from "./registeredUserRedux";
import { getOrdersFailure, getOrdersStart, getOrdersSuccess } from "./orderRedux";
import { getDiscountsStart, getDiscountsSuccess, getDiscountsFailure, deleteDiscountStart, deleteDiscountSuccess, deleteDiscountFailure } from "./discountRedux";
import { getAgeGroupFailure, getAgeGroupStart, getAgeGroupSuccess } from './ageGroupRedux';
import { getCourseTypeFailure, getCourseTypeStart, getCourseTypeSuccess } from './courseTypeRedux';
import { deleteBlogFailure, deleteBlogStart, deleteBlogSuccess, getBlogFailure, getBlogStart, getBlogSuccess } from './blogRedux';
import { deleteBlogCategoryFailure, deleteBlogCategoryStart, deleteBlogCategorySuccess, getBlogCategoryFailure, getBlogCategoryStart, getBlogCategorySuccess } from './blogCategory';
import { deleteTestimonialFailure, deleteTestimonialStart, deleteTestimonialSuccess, getTestimonialFailure, getTestimonialStart, getTestimonialSuccess } from './testimonialRedux';
import { getLecturesFailure, getLecturesStart, getLecturesSuccess } from './lectureRedux';
import { getlectureLibraryFailure, getlectureLibraryStart, getlectureLibrarySuccess } from './lectureLibraryRedux';
import { deleteQuizFailure, deleteQuizStart, deleteQuizSuccess, getQuizFailure, getQuizStart, getQuizSuccess } from './quizRedux';
import { deleteEventFailure, deleteEventStart, deleteEventSuccess, getEventFailure, getEventStart, getEventSuccess } from './eventRedux';
import { deleteNewsFailure, deleteNewsStart, deleteNewsSuccess, getNewsFailure, getNewsStart, getNewsSuccess } from './newsRedux';
import { getRobotixProductFailure, getRobotixProductStart, getRobotixProductSuccess } from './robotixProductRedux';
import { deleteGiftCardFailure, deleteGiftCardStart, deleteGiftCardSuccess, getGiftCardFailure, getGiftCardStart, getGiftCardSuccess } from './giftCardRedux';
import { deleteSectionFailure, deleteSectionStart, deleteSectionSuccess, getSectionFailure, getSectionStart, getSectionSuccess } from './customSectionRedux';
import { getQueriesFailure, getQueriesStart, getQueriesSuccess } from './contactFormQueriesRedux';
import { deleteStoriesVideoFailure, deleteStoriesVideoStart, deleteStoriesVideoSuccess, getStoriesVideoFailure, getStoriesVideoStart, getStoriesVideoSuccess } from './successStoriesRedux';
import { deleteMilestoneFailure, deleteMilestoneStart, deleteMilestoneSuccess, getMilestoneFailure, getMilestoneStart, getMilestoneSuccess } from './milestoneRedux';
import { getSchoolQueriesFailure, getSchoolQueriesStart, getSchoolQueriesSuccess } from './schoolFormQueriesRedux';



// export const login = async (dispatch, user) => {
//     dispatch(loginStart());
//     try {
//         const res = await publicRequest.post('/auth/login', user);
//         dispatch(loginSuccess(res.data));
//     } catch (error) {
//         dispatch(loginFailure());
//     }
// };

export const getProducts = async (dispatch) => {
    dispatch(getProductStart());
    try {
        const res = await publicRequest.get('/product/all/dashboard');
        dispatch(getProductSuccess(res.data));
    } catch (error) {
        dispatch(getProductFailure());
    }
};

export const deleteProducts = async (id, filename, dispatch) => {
    dispatch(deleteProductStart());
    try {
        const res = await userRequest.delete(`/product/${id}`, { data: { filename } });
        dispatch(deleteProductSuccess(id));
    } catch (error) {
        dispatch(deleteProductFailure());
    }
};

export const updateProducts = async (dispatch, id, product) => {
    dispatch(updateProductStart());
    try {
        const res = await userRequest.put(`/product/${id}`, product);
        // dispatch(updateProductSuccess({_id : id, ...product}));
        dispatch(updateProductSuccess(res.data));
    } catch (error) {
        dispatch(updateProductFailure());
    }
};


// Get all users api call
export const getAllUsers = async (dispatch) => {
    dispatch(getUsersStart());
    try {
        const res = await userRequest.get('/user/all');
        dispatch(getUsersSuccess(res.data));
    } catch (error) {
        dispatch(getUsersFailure());
    }
};

// Delete user api call
export const deleteUser = async (id, dispatch) => {
    dispatch(deleteUserStart());
    try {
        const res = await userRequest.delete(`/user/${id}`);
        dispatch(deleteUserSuccess(id));
    } catch (error) {
        dispatch(deleteUserFailure());
    }
};

// Get all orders api call
export const getAllOrders = async (dispatch) => {
    dispatch(getOrdersStart());
    try {
        const res = await userRequest.get('/order/all');
        dispatch(getOrdersSuccess(res.data));
    } catch (error) {
        dispatch(getOrdersFailure());
    }
}

// Get all discounts api call

export const getAllDiscounts = async (dispatch) => {
    dispatch(getDiscountsStart());
    try {
        const res = await userRequest.get('/discountCoupon/all');
        dispatch(getDiscountsSuccess(res.data));
    } catch (error) {
        dispatch(getDiscountsFailure());
    }
};

// Delete discount api call
export const deleteDiscounts = async (id, dispatch) => {
    dispatch(deleteDiscountStart());
    try {
        const res = await userRequest.delete(`/discountCoupon/${id}`);
        dispatch(deleteDiscountSuccess(id));
    } catch (error) {
        dispatch(deleteDiscountFailure());
    }
};

// Get all discounts api call

export const getAllGiftCards = async (dispatch) => {
    dispatch(getGiftCardStart());
    try {
        const res = await userRequest.get('/giftcard/all');
        dispatch(getGiftCardSuccess(res.data));
    } catch (error) {
        dispatch(getGiftCardFailure());
    }
};

// Delete discount api call
export const deleteGiftCard = async (id, dispatch) => {
    dispatch(deleteGiftCardStart());
    try {
        const res = await userRequest.delete(`/giftcard/${id}`);
        dispatch(deleteGiftCardSuccess(id));
    } catch (error) {
        dispatch(deleteGiftCardFailure());
    }
};


// Get age groups api call
export const getAllAgeGroups = async (dispatch) => {
    dispatch(getAgeGroupStart());
    try {
        const res = await userRequest.get('/ageGroup/all');
        dispatch(getAgeGroupSuccess(res.data));
    } catch (error) {
        dispatch(getAgeGroupFailure());
    }
};

// Get age groups api call
export const getAllCourseTypes = async (dispatch) => {
    dispatch(getCourseTypeStart());
    try {
        const res = await userRequest.get('/courseType/all');
        dispatch(getCourseTypeSuccess(res.data));
    } catch (error) {
        dispatch(getCourseTypeFailure());
    }
};

// Get all blogs api call
export const getAllBlogs = async (dispatch) => {
    dispatch(getBlogStart());
    try {
        const res = await userRequest.get('/blog/all');
        dispatch(getBlogSuccess(res.data));
    } catch (error) {
        dispatch(getBlogFailure());
    }
};

// Delete blogs api call
export const deleteBlogs = async (id, filename, dispatch) => {
    dispatch(deleteBlogStart());
    try {
        const res = await userRequest.delete(`/blog/${id}`, { data: { filename } });
        dispatch(deleteBlogSuccess(id));
    } catch (error) {
        dispatch(deleteBlogFailure());
    }
};

// Get all blogs categories api call
export const getAllBlogCategory = async (dispatch) => {
    dispatch(getBlogCategoryStart());
    try {
        const res = await userRequest.get('/blogCategory/all');
        dispatch(getBlogCategorySuccess(res.data));
    } catch (error) {
        dispatch(getBlogCategoryFailure());
    }
};

// Delete blogs api call
export const deleteBlogCatgory = async (id, dispatch) => {
    dispatch(deleteBlogCategoryStart());
    try {
        const res = await userRequest.delete(`/blogCategory/${id}`);
        dispatch(deleteBlogCategorySuccess(id));
    } catch (error) {
        dispatch(deleteBlogCategoryFailure());
    }
};

// Get all testimonial api call
export const getAllTestimonial = async (dispatch) => {
    dispatch(getTestimonialStart());
    try {
        const res = await userRequest.get('/testimonial/all');
        dispatch(getTestimonialSuccess(res.data));
    } catch (error) {
        dispatch(getTestimonialFailure());
    }
};

// Delete testimonial api call
export const deleteTestimonial = async (id, filename, dispatch) => {
    dispatch(deleteTestimonialStart());
    try {
        const res = await userRequest.delete(`/testimonial/${id}`, { data: { filename } });
        dispatch(deleteTestimonialSuccess(id));
    } catch (error) {
        dispatch(deleteTestimonialFailure());
    }
};


// Get all lectures api call
export const getAllLectures = async (dispatch, libraryId) => {
    dispatch(getLecturesStart());
    try {
        const res = await userRequest.get(`/product/all-lectures/${libraryId}`);
        dispatch(getLecturesSuccess(res.data));
    } catch (error) {
        dispatch(getLecturesFailure());
    }
};

// Get all lectures api call
export const getAllLectureLibraries = async (dispatch) => {
    dispatch(getlectureLibraryStart());
    try {
        const res = await userRequest.get('/product/libraries');
        dispatch(getlectureLibrarySuccess(res.data));
    } catch (error) {
        dispatch(getlectureLibraryFailure());
    }
};


// Get all quizzes api call
export const getAllQuiz = async (dispatch) => {
    dispatch(getQuizStart());
    try {
        const res = await userRequest.get('/quiz/all');
        dispatch(getQuizSuccess(res.data));
    } catch (error) {
        dispatch(getQuizFailure());
    }
};


// Delete user api call
export const deleteQuiz = async (id, dispatch) => {
    dispatch(deleteQuizStart());
    try {
        const res = await userRequest.delete(`/quiz/${id}`);
        dispatch(deleteQuizSuccess(id));
    } catch (error) {
        dispatch(deleteQuizFailure());
    }
};


// Get all events api call
export const getAllEvents = async (dispatch) => {
    dispatch(getEventStart());
    try {
        const res = await userRequest.get('/event/all');
        dispatch(getEventSuccess(res.data));
    } catch (error) {
        dispatch(getEventFailure());
    }
};


// Delete event api call
export const deleteEvent = async (id, dispatch) => {
    dispatch(deleteEventStart());
    try {
        const res = await userRequest.delete(`/event/${id}`);
        dispatch(deleteEventSuccess(id));
    } catch (error) {
        dispatch(deleteEventFailure());
    }
};

// Get all news api call
export const getAllNews = async (dispatch) => {
    dispatch(getNewsStart());
    try {
        const res = await userRequest.get('/news/all');
        dispatch(getNewsSuccess(res.data));
    } catch (error) {
        dispatch(getNewsFailure());
    }
};


// Delete news api call
export const deleteNews = async (id, dispatch) => {
    dispatch(deleteNewsStart());
    try {
        const res = await userRequest.delete(`/news/${id}`);
        dispatch(deleteNewsSuccess(id));
    } catch (error) {
        dispatch(deleteNewsFailure());
    }
};

// Get all robotix product api call
export const getAllRobotixProducts = async (dispatch) => {
    dispatch(getRobotixProductStart());
    try {
        const res = await userRequest.get('/robotix/product/all');
        dispatch(getRobotixProductSuccess(res.data));
    } catch (error) {
        dispatch(getRobotixProductFailure());
    }
};


// Get all custom sections api call
export const getAllCustomSections = async (dispatch) => {
    dispatch(getSectionStart());
    try {
        const res = await userRequest.get('/customSection/all');
        dispatch(getSectionSuccess(res.data));
    } catch (error) {
        dispatch(getSectionFailure());
    }
};

// Delete custom section api call
export const deleteCustomSection = async (id, dispatch) => {
    dispatch(deleteSectionStart());
    try {
        const res = await userRequest.delete(`/customSection/${id}`);
        dispatch(deleteSectionSuccess(id));
    } catch (error) {
        dispatch(deleteSectionFailure());
    }
};


// Get all contact form queries api call
export const getAllContactFormQueries = async (dispatch) => {
    dispatch(getQueriesStart());
    try {
        const res = await userRequest.get('/contactForm/all');
        dispatch(getQueriesSuccess(res.data));
    } catch (error) {
        dispatch(getQueriesFailure());
    }
};

// Get all contact form queries api call
export const getAllSchoolFormQueries = async (dispatch) => {
    dispatch(getSchoolQueriesStart());
    try {
        const res = await userRequest.get('/school-form/all');
        dispatch(getSchoolQueriesSuccess(res.data));
    } catch (error) {
        dispatch(getSchoolQueriesFailure());
    }
};

// Get all success stories videos api call
export const getSpecificSuccessStoriesVideo = async (dispatch, id) => {
    dispatch(getStoriesVideoStart());
    try {
        const res = await userRequest.get(`/success-stories/find/${id}`);
        dispatch(getStoriesVideoSuccess(res.data));
    } catch (error) {
        dispatch(getStoriesVideoFailure());
    }
};

// Get all success stories videos api call
export const getAllSuccessStoriesVideo = async (dispatch) => {
    dispatch(getStoriesVideoStart());
    try {
        const res = await userRequest.get('/success-stories/all');
        dispatch(getStoriesVideoSuccess(res.data));
    } catch (error) {
        dispatch(getStoriesVideoFailure());
    }
};


// Delete custom section api call
export const deleteSuccessStoriesVideo = async (id, dispatch) => {
    dispatch(deleteStoriesVideoStart());
    try {
        const res = await userRequest.delete(`/success-stories/${id}`);
        dispatch(deleteStoriesVideoSuccess(id));
    } catch (error) {
        dispatch(deleteStoriesVideoFailure());
    }
};


// Get all success stories videos api call
export const getAllMilestones = async (dispatch) => {
    dispatch(getMilestoneStart());
    try {
        const res = await userRequest.get('/milestone/all');
        dispatch(getMilestoneSuccess(res.data));
    } catch (error) {
        dispatch(getMilestoneFailure());
    }
};


// Delete milestone api call
export const deleteMilestone = async (id, dispatch) => {
    dispatch(deleteMilestoneStart());
    try {
        const res = await userRequest.delete(`/milestone/${id}`);
        dispatch(deleteMilestoneSuccess(id));
    } catch (error) {
        dispatch(deleteMilestoneFailure());
    }
};


// Get all users of a specific user
export const getAllUsersOfSchool = async (id, dispatch) => {
    dispatch(getUsersStart());
    try {
        const res = await userRequest.get(`/user/school-users/${id}`);
        dispatch(getUsersSuccess(res.data));
    } catch (error) {
        dispatch(getUsersFailure());
    }
}



