import React from 'react';
import './SchoolDashboard.css';

const SchoolDashboard = () => {
  return (
    <div>
      <h1>School Dashboard</h1>
    </div>
  )
}

export default SchoolDashboard
