
import { createSlice } from "@reduxjs/toolkit";

const testimonialReducer = createSlice({
    name: 'testimonial',
    initialState: {
        testimonials: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get All Testimonial
        getTestimonialStart: (state) => {
            state.isFetching = true;
        },
        getTestimonialSuccess: (state, action) => {
            state.isFetching = false;
            state.testimonials = action.payload;
        },
        getTestimonialFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Delete Testimonial                         
        deleteTestimonialStart: (state) => {
            state.isFetching = true;
        },
        deleteTestimonialSuccess: (state, action) => {
            state.isFetching = false;
            state.testimonials.splice(
                state.testimonials.findIndex((item) => item._id === action.payload), 1
            );
        },
        deleteTestimonialFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Update Testimonial
        updateTestimonialStart: (state) => {
            state.isFetching = true;
        },
        updateTestimonialSuccess: (state, action) => {
            state.isFetching = false;
            const testimonialIndex = state.testimonials.findIndex(testimonial => testimonial._id === action.payload._id);

            if (testimonialIndex !== -1) {
                state.testimonials[testimonialIndex] = action.payload;
            }
        },
        updateTestimonialFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        //  Add Testimonial
        addTestimonialStart: (state) => {
            state.isFetching = true;
        },
        addTestimonialSuccess: (state, action) => {
            state.isFetching = false;
            state.testimonials = action.payload;
        },
        addTestimonialFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const { getTestimonialStart, getTestimonialSuccess, getTestimonialFailure, deleteTestimonialStart, deleteTestimonialSuccess, deleteTestimonialFailure, updateTestimonialStart, updateTestimonialSuccess, updateTestimonialFailure, addTestimonialStart, addTestimonialSuccess, addTestimonialFailure } = testimonialReducer.actions;
export default testimonialReducer.reducer;