
import { createSlice } from "@reduxjs/toolkit";

const newsReducer = createSlice({
    name: 'news',
    initialState: {
        news: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get All News
        getNewsStart: (state) => {
            state.isFetching = true;
        },
        getNewsSuccess: (state, action) => {
            state.isFetching = false;
            state.news = action.payload;
        },
        getNewsFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Delete News                         
        deleteNewsStart: (state) => {
            state.isFetching = true;
        },
        deleteNewsSuccess: (state, action) => {
            state.isFetching = false;
            state.news.splice(
                state.news.findIndex((item) => item._id === action.payload), 1
            );
        },
        deleteNewsFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Update News
        updateNewsStart: (state) => {
            state.isFetching = true;
        },
        updateNewsSuccess: (state, action) => {
            state.isFetching = false;
            const newsIndex = state.news.findIndex(news => news._id === action.payload._id);

            if (newsIndex !== -1) {
                state.news[newsIndex] = action.payload;
            }
        },
        updateNewsFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        //  Add News
        addNewsStart: (state) => {
            state.isFetching = true;
        },
        addNewsSuccess: (state, action) => {
            state.isFetching = false;
            const newData = action.payload;
            state.news = [...state.news, newData];
        },
        addNewsFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const { getNewsStart, getNewsSuccess, getNewsFailure, deleteNewsStart, deleteNewsSuccess, deleteNewsFailure, updateNewsStart, updateNewsSuccess, updateNewsFailure, addNewsStart, addNewsSuccess, addNewsFailure } = newsReducer.actions;
export default newsReducer.reducer;