// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widgetSm{
    width: 100%;
    /* height: 500px; */
    box-shadow: 0px 0px 10px -5px #ccc;
    border-radius: 10px;
    padding: 20px;
    border-left: solid 4px var(--skyBlue);
    background-color: white;
}
.widgetSmTitle{
    font-size: 18px;
    font-weight: 600;
}


.widgetSmListItem{
    display: flex;
    /* justify-content: space-between; */
    margin-bottom: 15px;
    column-gap: 20px;
}

.widgetSmUser{
    display: flex;
    flex-direction: column;
}

.widgetSmUserName{
    font-weight: 600;
}


.widgetSmImgWrapper{
    width: 50px;
    height: 50px;
}
.widgetSmListItem img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.widgetSmButton{
    display: flex;
    align-items: center;
    height: 40px;
    column-gap: 10px;
    background: var(--lightGray);
    color: #555;
    border-radius: 10px;
    border: 0;
    cursor: pointer;
    padding: 5px 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/widgetSm/WidgetSm.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;IACnB,kCAAkC;IAClC,mBAAmB;IACnB,aAAa;IACb,qCAAqC;IACrC,uBAAuB;AAC3B;AACA;IACI,eAAe;IACf,gBAAgB;AACpB;;;AAGA;IACI,aAAa;IACb,oCAAoC;IACpC,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;AACpB;;;AAGA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;IAChB,4BAA4B;IAC5B,WAAW;IACX,mBAAmB;IACnB,SAAS;IACT,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".widgetSm{\n    width: 100%;\n    /* height: 500px; */\n    box-shadow: 0px 0px 10px -5px #ccc;\n    border-radius: 10px;\n    padding: 20px;\n    border-left: solid 4px var(--skyBlue);\n    background-color: white;\n}\n.widgetSmTitle{\n    font-size: 18px;\n    font-weight: 600;\n}\n\n\n.widgetSmListItem{\n    display: flex;\n    /* justify-content: space-between; */\n    margin-bottom: 15px;\n    column-gap: 20px;\n}\n\n.widgetSmUser{\n    display: flex;\n    flex-direction: column;\n}\n\n.widgetSmUserName{\n    font-weight: 600;\n}\n\n\n.widgetSmImgWrapper{\n    width: 50px;\n    height: 50px;\n}\n.widgetSmListItem img{\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    border-radius: 50%;\n}\n\n.widgetSmButton{\n    display: flex;\n    align-items: center;\n    height: 40px;\n    column-gap: 10px;\n    background: var(--lightGray);\n    color: #555;\n    border-radius: 10px;\n    border: 0;\n    cursor: pointer;\n    padding: 5px 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
