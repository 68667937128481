import React from 'react';
import './Loader.css'

const Loader = () => {
    return (
        <div className="loading">
            <div className="spinner-wrapper">
                <span className="spinner-text">Loading</span>
                <span className="spinner"></span>
            </div>
        </div>
    )
}

export default Loader
