import React, { useEffect, useState } from 'react';
import './NewsTable.css';
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { deleteDiscounts, deleteNews, getAllDiscounts, getAllEvents, getAllNews } from '../../../redux/apiCalls';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Loader from '../../../global/Loader';
import { MEDIA_URL, addRowIndex, isUrl } from '../../../config';
import CircleLoader from '../../../components/circleLoader/CircleLoader';



const NewsTable = () => {

    const { isFetching, news } = useSelector((state) => state.news);
    const dispatch = useDispatch();

    // const [discountsWithIndex, setDiscountsWithIndex] = useState(null);


    // Add index numbers to the news array
    const newsWithIndex = news && news.map((news, index) => ({
        ...news,
        index: index + 1, // Add 1 to the index to start from 1 instead of 0
    }));


    // useEffect(() => {
    //     setDiscountsWithIndex(addRowIndex(discounts));
    // }, []);

    // const isFetching = useSelector((state) => state.discounts.isFetching);

    useEffect(() => {
        getAllNews(dispatch)
    }, [dispatch]);


    const [deletePopup, setDeletePopup] = useState(false);
    const [deletingItem, setDeletingItem] = useState(null)

    const handlePopup = (newsId) => {
        setDeletePopup(true)
        setDeletingItem(newsId)
    };

    const handlePopupCancel = () => {
        setDeletePopup(false)
    };

    const handleDeleteNews = (itemId) => {
        deleteNews(itemId, dispatch);
        setDeletePopup(false);
    }

    const columns = [
        { field: 'index', headerName: '#', width: 50 },
        {
            field: 'title',
            headerName: 'Title',
            width: 200,
            editable: false,
        },

        {
            field: 'newsImage',
            headerName: 'Thumbnail',
            description: 'This column has a value getter and is not sortable.',
            sortable: true,
            width: 130,
            renderCell: (params) => {
                if(isUrl(params.value)){
                    return <img src={params.value} className='courseTable-img' alt="news-img" />
                }
                else{
                    return <img src={ MEDIA_URL + params.value} className='courseTable-img' alt="news-img" />
                }
            }
        },
        // {
        //     field: 'category',
        //     headerName: 'Type',
        //     description: 'This column has a value getter and is not sortable.',
        //     sortable: true,
        //     width: 130,
        //     cellClassName: "text-capitalize"
        //     // renderCell: (params) => {
        //     //     return params.discountTYpe ? "Percentage" : "Fixed"
        //     // }
        // },
        // {
        //     field: 'minOrder',
        //     headerName: 'Minimum Order',
        //     // description: 'This column has a value getter and is not sortable.',
        //     sortable: true,
        //     width: 150,
        // },
        // {
        //     field: 'validFrom',
        //     headerName: 'Valid From',
        //     description: 'This column has a value getter and is not sortable.',
        //     sortable: true,
        //     width: 130,
        // },
        // {
        //     field: 'validTill',
        //     headerName: 'Valid Till',
        //     description: 'This column has a value getter and is not sortable.',
        //     sortable: true,
        //     width: 130,
        // },


        {
            field: 'status',
            headerName: 'Status',
            // description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 100,
            renderCell: (params) => {
                return params.row.status ? "Active" : "Draft";
            }
        },
        {
            field: 'edit',
            headerName: 'Edit',
            sortable: false,
            width: 100,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={'/edit-news/' + params.id}>
                            <button className='btn btn-primary studentListEdit'>Edit</button>
                        </Link>
                    </>
                )
            }
        },
        {
            field: 'delete',
            headerName: 'Delete',
            sortable: false,
            width: 100,
            renderCell: (params) => {
                const newsId = params.row._id;
                return (
                    <>
                        <DeleteOutline onClick={() => handlePopup(newsId)} className='courseListDelete text-danger' />
                    </>
                )
            }
        },
    ];

    if (isFetching) {
        return (
            <div className='loader-wrapper'>
                <CircleLoader />
            </div>
        )
    }

    return (
        <div className='py-5'>
            <div className="row">
                <div className="col-12">
                    <div className="tableButtonWrapper d-flex justify-content-between mb-3">
                        <h2>News</h2>
                        <Link to='/add-news'>
                            <button className='custom-btn purple-bg'>Create News <AddCircleOutlineOutlinedIcon className='newStudentIcon' /></button>
                        </Link>

                    </div>
                </div>
            </div>
            {
                deletePopup && (
                    <div className='deletePopupAlertWrapper' id='deletePopupAlertWrapper'>
                        <div className="deletePopupAlert">
                            <WarningAmberIcon style={{ color: "var(--orange)", fontSize: "40px" }} />
                            <h4>Warning</h4>
                            <div className="deleteAlertTextWrapper text-center">
                                <p>This news will be deleted permanently.</p>
                                <p>Are you sure?</p>
                            </div>

                            <div className="deleteBtnsWrapper">
                                <button className='btn' onClick={handlePopupCancel} style={{ background: "var(--green)", color: "var(--white)" }}>Cancel</button>
                                <button className='btn' onClick={() => handleDeleteNews(deletingItem)} style={{ background: "var(--red)", color: "var(--white)" }}>Delete</button>
                            </div>
                        </div>
                    </div>
                )
            }

            {
                news && (
                    <DataGrid
                        rows={newsWithIndex}
                        getRowId={(rows) => rows._id}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[10]}
                        checkboxSelection={false}
                        disableRowSelectionOnClick
                    />
                )
            }

        </div>
    )
}

export default NewsTable
