// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-progress-container{
    width: 100%;
    /* width: 70%; */
    /* margin: 0 auto; */
    /* position: relative; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
}


.course-progress-inner-wrapper{
    width: 70%;
    padding: 20px;
    height: 500px;
    box-shadow: 0px 0px 28px 4px #eee;
    border-radius: 15px;
    padding: 30px;
}`, "",{"version":3,"sources":["webpack://./src/pages/school/schoolStudent/viewStudentProgress/ViewStudentProgress.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,oBAAoB;IACpB,wBAAwB;IACxB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;AACvB;;;AAGA;IACI,UAAU;IACV,aAAa;IACb,aAAa;IACb,iCAAiC;IACjC,mBAAmB;IACnB,aAAa;AACjB","sourcesContent":[".course-progress-container{\n    width: 100%;\n    /* width: 70%; */\n    /* margin: 0 auto; */\n    /* position: relative; */\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    /* height: 100vh; */\n}\n\n\n.course-progress-inner-wrapper{\n    width: 70%;\n    padding: 20px;\n    height: 500px;\n    box-shadow: 0px 0px 28px 4px #eee;\n    border-radius: 15px;\n    padding: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
