import React, { useState } from 'react';
import './ChangePassword.css'
import { useDispatch, useSelector } from 'react-redux';
import { updatePasswordFailure, updatePasswordStart, updatePasswordSuccess } from '../../redux/registeredUserRedux';
import { userRequest } from '../../requestMethod';

const ChangePassword = () => {

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState(false);
    const [oldPasswordError, setOldPasswordError] = useState(false);
    const [oldPasswordErrorMessage, setOldPasswordErrorMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const { isFetching, currentUser } = useSelector((state) => state.user);


    const dispatch = useDispatch();

    const updatePassword = async (dispatch, userId, passwordData) => {
        dispatch(updatePasswordStart());
        try {
            const res = await userRequest.put(`user/updatePassword/${userId}`, passwordData);
            if (res.status === 200) {
                setError(false);
                setSuccess(true);
                setOldPassword('');
                setNewPassword('');
                setSuccessMessage("Password has been updated!");
            }
            dispatch(updatePasswordSuccess(res.data));

        } catch (error) {
            dispatch(updatePasswordFailure());
            if (error.response.status === 401) {
                setOldPasswordError(true);
                setOldPasswordErrorMessage(error.response.data);
            }

            else {
                setSuccess(false);
                setError(true);
                setErrorMessage("Something went wrong!");
            }
        }
    }

    const handleSubmit = () => {
        setOldPasswordErrorMessage(false);
        setError(false);
        setSuccess(false);
        updatePassword(dispatch, currentUser._id, { oldPassword, newPassword });
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="changePasswordCardWrapper">
                        <div className="changePasswordCardContent">
                            <h2 className='heading'>Change Password</h2>
                            <div className="form-input-wrapper">
                                <label>Old Password</label>
                                <input type="text" className='changePasswordInput' value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} placeholder='Old Password' />
                                {
                                    oldPasswordError && (
                                        <p className='error-text text-danger'>{oldPasswordErrorMessage}</p>
                                    )
                                }
                            </div>
                            <div className="form-input-wrapper">
                                <label>New Password</label>
                                <input type="password" className='changePasswordInput' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder='New Password' />
                            </div>
                            <div className="col-12 mt-3">
                                {
                                    success ? (
                                        <div className='alert alert-success'>
                                            {successMessage}
                                        </div>
                                    ) : error && (
                                        <div className='alert alert-danger'>
                                            {errorMessage}
                                        </div>
                                    )
                                }
                            </div>
                            <div className="changePasswordBtnWrapper">
                                <button className='custom-btn purple-bg' onClick={handleSubmit}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword
