import axios from "axios";

// const BASE_URL = "http://localhost:3003/api/";
const BASE_URL = "https://api.meritus.ai/api/";
// const BASE_URL = "https://meritus-api-nfnxk.ondigitalocean.app/api/";
// const BASE_URL = "https://coral-app-a4xjt.ondigitalocean.app/api/";

// let user = null;
// const persistData = localStorage.getItem('persist:root');
// if (persistData) {
//     const parsedData = JSON.parse(persistData);
//     if (parsedData && parsedData.user) {
//         user = JSON.parse(parsedData.user).currentUser;
//     }
// };

// let TOKEN;
// if (user) {
//     TOKEN = user.accessToken;
// };
// const TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0ODk5NjlkZjFkMmU1NDY3YmZiMzA2ZSIsImlzTWVyaXR1c1N1cGVyQWRtaW4iOnRydWUsImlhdCI6MTY4ODI4MTEyNywiZXhwIjoxNjg4NTQwMzI3fQ.4bI9hmotT7ORyWAMJG-blLikDMVrIoRlF55E1YODi6w";

export const publicRequest = axios.create({
    baseURL: BASE_URL,
});

// export const userRequest = axios.create({
//     baseURL: BASE_URL,
//     headers: { token: `Bearer ${TOKEN}` }
// });

export const userRequest = axios.create({         
    baseURL : BASE_URL,
    withCredentials: true, 
});

export const fileUploadRequest = axios.create({
    baseURL: BASE_URL,
    // headers: {
    //     'Content-Type': 'multipart/form-data',
    // },
    withCredentials: true,
});

// export const userRequest = axios.create({
//     baseURL: BASE_URL,
//     headers: { token: `Bearer ${TOKEN}`, 'Content-Type': 'multipart/form-data' }
// });

// 'Content-Type': 'multipart/form-data'
