
import { createSlice } from "@reduxjs/toolkit";

const blogReducer = createSlice({
    name: 'blog',
    initialState: {
        blogs: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get All Blog
        getBlogStart: (state) => {
            state.isFetching = true;
        },
        getBlogSuccess: (state, action) => {
            state.isFetching = false;
            state.blogs = action.payload;
        },
        getBlogFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Delete Blog                         
        deleteBlogStart: (state) => {
            state.isFetching = true;
        },
        deleteBlogSuccess: (state, action) => {
            state.isFetching = false;
            state.blogs.splice(
                state.blogs.findIndex((item) => item._id === action.payload), 1
            );
        },
        deleteBlogFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Update Blog
        updateBlogStart: (state) => {
            state.isFetching = true;
        },
        updateBlogSuccess: (state, action) => {
            state.isFetching = false;
            const blogIndex = state.blogs.findIndex(blog => blog._id === action.payload._id);

            if (blogIndex !== -1) {
                state.blogs[blogIndex] = action.payload;
            }
        },
        updateBlogFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        //  Add Blog
        addBlogStart: (state) => {
            state.isFetching = true;
        },
        addBlogSuccess: (state, action) => {
            state.isFetching = false;
            const newData = action.payload;
            state.blogs = [...state.blogs, newData];
        },
        addBlogFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const { getBlogStart, getBlogSuccess, getBlogFailure, deleteBlogStart, deleteBlogSuccess, deleteBlogFailure, updateBlogStart, updateBlogSuccess, updateBlogFailure, addBlogStart, addBlogSuccess, addBlogFailure } = blogReducer.actions;
export default blogReducer.reducer;