import React, { useState } from 'react';
import './Popup.css';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelIcon from '@mui/icons-material/Cancel';

const Popup = (props) => {

    const [deletePopup, setDeletePopup] = useState(false)

    // const handlePopupCancel = () => {
    //     setDeletePopup(false);
    // };

    return (
        <div className='deletePopupAlertWrapper popup-box' id='deletePopupAlertWrapper'>
            <div className="deletePopupAlert">
                {
                    props.status ? (
                        <TaskAltIcon style={{ color: "var(--green)", fontSize: "40px" }} />
                    ) : (
                        <CancelIcon style={{ color: "var(--red)", fontSize: "40px" }} />
                    )
                }
                <h4>{props.status ? "Done" : "Failed"}</h4>
                <div className="deleteAlertTextWrapper text-center">
                    <p>{props.message && props.message}</p>
                    {/* <p>Are you sure?</p> */}
                </div>

                <div className="deleteBtnsWrapper">
                    <button className='btn' onClick={props.onClickAction} style={{ background: "var(--dPink)", color: "var(--white)" }}>Close</button>
                    {/* <button className='btn' onClick={() => deleteCoupon(deletingItem)} style={{ background: "var(--red)", color: "var(--white)" }}>Delete</button> */}
                </div>
            </div>
        </div>
    );
}

export default Popup
