import React, { useEffect } from 'react';
import './NewMeeting.css';
import { userRequest } from '../../../requestMethod';
import { useLocation } from 'react-router-dom';

const NewMeeting = () => {

  const BACKEND_URL = 'http://localhost:3003/api/meeting';
  const location = useLocation();
  // const generatedCode = location.pathname.split('');
  // const queryParams = new URLSearchParams(location.search);
  // const generatedCode = queryParams.get('code');

  useEffect(() => {
    // Function to fetch the code after redirect
    const fetchCode = async () => {
      try {
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('code');

        // If the code parameter is present in the URL
        if (code) {
          // Send the code to the backend API server for further processing
          try {
            await userRequest.post(`/meeting/callback`, {
              code,
            });

            // You can optionally redirect the user to another page after sending the code
            // window.location.href = '/success-page';
          } catch (error) {
            console.error('Error sending code to backend:', error);
          }
        }
      } catch (error) {
        console.error('Error parsing URL:', error);
      }
    };

    fetchCode();
  }, [location]);

  const handleLogin = async () => {
    try {
      const response = await userRequest.get(`/meeting/authorize`);
      // Redirect to the Microsoft Teams login page for authentication
      window.location.href = response.data.authUrl;
    } catch (error) {
      console.error('Error initiating OAuth2 flow:', error);
    }
  };

  return (
    <div>
      <h1>Welcome to My MERN App</h1>
      <button onClick={handleLogin}>Log in with Microsoft Teams</button>
    </div>
  );
}
export default NewMeeting
