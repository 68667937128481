// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-input-wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 15px;
}

.form-input-wrapper label{
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
}

.form-input-wrapper .newUserInput {
    padding: 8px 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.newStudentSubmitbtn button{
    padding: 8px 40px;
    font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/pages/D2C/student/newStudent/NewStudent.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;IACI,iBAAiB;IACjB,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".form-input-wrapper{\n    display: flex;\n    flex-direction: column;\n    row-gap: 10px;\n    margin-bottom: 15px;\n}\n\n.form-input-wrapper label{\n    font-size: 16px;\n    font-weight: 600;\n    text-transform: capitalize;\n}\n\n.form-input-wrapper .newUserInput {\n    padding: 8px 15px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n}\n\n.newStudentSubmitbtn button{\n    padding: 8px 40px;\n    font-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
