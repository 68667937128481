import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addStoriesVideoFailure, addStoriesVideoStart, addStoriesVideoSuccess, updateStoriesVideoFailure, updateStoriesVideoStart, updateStoriesVideoSuccess } from '../../redux/successStoriesRedux';
import CircleLoader from '../../components/circleLoader/CircleLoader';
import Popup from '../../global/Popup';
import { validateRequired } from '../../formValidation';
import { userRequest } from '../../requestMethod';
import { MEDIA_URL, uploadProductImageToSpace } from '../../config';
import { useLocation } from 'react-router-dom';
import { getAllSuccessStoriesVideo, getSpecificSuccessStoriesVideo } from '../../redux/apiCalls';
import Loader from '../../global/Loader';

const EditSuccessStory = () => {

    const dispatch = useDispatch();

    const location = useLocation();
    const videoId = location.pathname.split('/')[2];

    useEffect(() => {
        getAllSuccessStoriesVideo(dispatch, videoId)
    }, [videoId]);

    const { isFetching, videos } = useSelector((state) => state.successStories);
    const currentVideo = videos?.find((video) => video._id === videoId);
    const [video, setVideo] = useState(currentVideo?.video);
    const [thumbnailImage, setThumbnailImage] = useState(currentVideo?.thumbnailImage);
    const [isActive, setIsActive] = useState(currentVideo?.status);
    const [imagePreview, setImagePreview] = useState(currentVideo?.thumbnailImage);
    const [activePopup, setActivePopup] = useState(false);
    const [popupText, setPopuptext] = useState('');
    const [popupStatus, setPopupStatus] = useState(true);
    const [activeLoader, setActiveLoader] = useState(false);
    const [loaderText, setLoaderText] = useState("");
    const [category, setCategory] = useState(currentVideo?.category);



    useEffect(() => {
        setVideo(currentVideo?.video);
        setThumbnailImage(currentVideo?.thumbnailImage);
        setImagePreview(currentVideo?.thumbnailImage);
        setIsActive(currentVideo?.status);
    }, [currentVideo])

    const allowedImageFormats = ['image/jpeg', 'image/png', 'image/jpg'];

    const handleThumbnailImageChange = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            // Check if the selected file is an image
            const isImage = allowedImageFormats.includes(files[0].type);
            if (!isImage) {
                // Show an error message or perform some action for invalid image format
                alert('Please select a valid image file (JPEG, PNG, or JPG).');
                event.target.value = null;
                setThumbnailImage('');
                return;
            } else {
                setThumbnailImage(files[0]);
                setImagePreview(URL.createObjectURL(files[0]));
            }
        } else {
            setThumbnailImage(null);
            setImagePreview(currentVideo?.thumbnailImage);
        }
    }

    const handleVideoChange = (event) => {
        setVideo(event.target.value);
    };

    const updateVideo = async (dispatch, video) => {
        dispatch(updateStoriesVideoStart());
        try {
            const res = await userRequest.put(`/success-stories/${videoId}`, video);
            if (res && res.status === 200) {
                setActivePopup(true);
                setPopuptext("Video has been updated.");
                setPopupStatus(true);
                setVideo('');
                setActiveLoader(false);
            }
            dispatch(updateStoriesVideoSuccess(res?.data));
        } catch (error) {
            dispatch(updateStoriesVideoFailure());
            setActiveLoader(false);
            setActivePopup(true);
            setPopuptext(error?.response?.data);
            setPopupStatus(false);
        }
    };

    // Validate function for form validation start=======
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const videoValidErr = validateRequired(video);
        // const thumbnailImgErr = validateRequired(thumbnailImage, 'image');

        if (videoValidErr) {
            formIsValid = false;
            newErrors.video = videoValidErr;
        }

        // if (thumbnailImgErr) {
        //     formIsValid = false;
        //     newErrors.thumbnailImage = thumbnailImgErr;
        // }

        setErrors(newErrors);
        return formIsValid;
    }
    // Validate function for form validation end======

    const handleUpdateVideo = async () => {
        // setDiscountTypeError(false);
        // setSuccess(false);

        if (validateForm()) {
            setActiveLoader(true);
            setLoaderText('uploading');
            const trimmmedVideo = video.trim();
            let thumbnailImageURL;
            if (thumbnailImage?.name) {
                thumbnailImageURL = await uploadProductImageToSpace(thumbnailImage);
                // setThumbnailImage(thumbnailImageURL)
                updateVideo(dispatch, { video: trimmmedVideo, thumbnailImage: thumbnailImageURL, status: isActive, category });
            } else {
                thumbnailImageURL = imagePreview;
                updateVideo(dispatch, { video: trimmmedVideo, thumbnailImage, status: isActive, category });
            }
        }
    }

    const hidePopup = () => {
        setActivePopup(false);
    }

    if (activePopup) {
        return (
            <Popup status={popupStatus} message={popupText} onClickAction={hidePopup} />
        )
    }

    if (isFetching) {
        return (
            <Loader />
        )
    }

    return (
        <div className='container'>
            <div className="row">
                <div className="col-12">
                    <div className="discountCardWrapper">
                        <div className="discountHeader">
                            <h2>Edit Story Video</h2>
                        </div>
                        <div className="discountMiddleWrapper pt-2">
                            <div className="row gy-3">
                                <div className="col-12">
                                    <div className="discountInputFieldWrapper">
                                        <label>Enter Vimeo Video URL</label>
                                        <div className="dicountInfoWrapper">
                                            <input type="text" value={video} onChange={handleVideoChange} placeholder="Video URL" />
                                            <span className='error-text'>{errors.video}</span>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-12">
                                    <div className="discountInputFieldWrapper">
                                        <label>Thumbnail Image</label>
                                        <div className="dicountInfoWrapper">
                                            <input type="file" name='file' className='newCourseInput' placeholder='image' onChange={handleThumbnailImageChange} />
                                            <span className='error-text'>{errors.thumbnailImage}</span>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-12">
                                    <div className="form-input-wrapper">
                                        <label>Update Thumbnail Image</label>
                                        <input type="file" name='file' className='newCourseInput' accept={allowedImageFormats} onChange={handleThumbnailImageChange} placeholder='Price' />
                                        <span className='error-text'>{errors.thumbnailImage}</span>
                                    </div>
                                    <div className="form-input-wrapper">
                                        <label>Preview Image</label>
                                        <div className="course-img-wrapper">
                                            {
                                                (thumbnailImage?.name) ? (
                                                    <img src={imagePreview || thumbnailImage} alt="thumbnail-img" />
                                                ) : (
                                                    <img src={MEDIA_URL + imagePreview} alt="thumbnail-img" />
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="discountInputFieldWrapper">
                                        <label>Category</label>
                                        <div className="dicountInfoWrapper">
                                            <select name="category" value={category} onChange={(e) => setCategory(e.target.value)}>
                                                {/* <option value="" disabled defaultValue>Select</option> */}
                                                <option value="home">Home Page</option>
                                                <option value="learnAI">LearnAI Page</option>
                                                <option value="stemShop">Stem Shop</option>
                                                <option value="success">School Solution</option>
                                                <option value="phiro">Phiro</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="discountInputFieldWrapper">
                                        <label>Status</label>
                                        <div className="dicountInfoWrapper">
                                            <select name="status" id="" value={isActive} onChange={(e) => setIsActive(e.target.value)}>
                                                <option value="true">Active</option>
                                                <option value="false">Inactive</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="discountSubmitBtnWrapper">
                                        <button className='custom-btn btn' onClick={handleUpdateVideo}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                activeLoader && (
                    <CircleLoader title={loaderText} />
                )
            }
        </div>
    )
}

export default EditSuccessStory
