// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-input-wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 15px;
}

.form-input-wrapper label{
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
}

.form-input-wrapper .newUserInput {
    border: 2px solid #c3c5f8 !important;
    border-radius: 15px !important;
    padding: 10px!important;
    color: var(--darkGrey);
}

.newStudentSubmitbtn button{
    padding: 8px 40px;
    font-size: 16px;
}

.ql-toolbar.ql-snow{
    border: 2px solid #c3c5f8 !important;
    border-radius: 15px 15px 0 0 !important;
    padding: 10px!important;
    color: var(--darkGrey);
}
.ql-container.ql-snow{
    border: 2px solid #c3c5f8 !important;
    border-radius: 0 0 15px 15px !important;
    padding: 10px!important;
    color: var(--darkGrey);
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/newAdmin/NewAdmin.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;IACI,oCAAoC;IACpC,8BAA8B;IAC9B,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,oCAAoC;IACpC,uCAAuC;IACvC,uBAAuB;IACvB,sBAAsB;AAC1B;AACA;IACI,oCAAoC;IACpC,uCAAuC;IACvC,uBAAuB;IACvB,sBAAsB;AAC1B","sourcesContent":[".form-input-wrapper{\n    display: flex;\n    flex-direction: column;\n    row-gap: 10px;\n    margin-bottom: 15px;\n}\n\n.form-input-wrapper label{\n    font-size: 16px;\n    font-weight: 600;\n    text-transform: capitalize;\n}\n\n.form-input-wrapper .newUserInput {\n    border: 2px solid #c3c5f8 !important;\n    border-radius: 15px !important;\n    padding: 10px!important;\n    color: var(--darkGrey);\n}\n\n.newStudentSubmitbtn button{\n    padding: 8px 40px;\n    font-size: 16px;\n}\n\n.ql-toolbar.ql-snow{\n    border: 2px solid #c3c5f8 !important;\n    border-radius: 15px 15px 0 0 !important;\n    padding: 10px!important;\n    color: var(--darkGrey);\n}\n.ql-container.ql-snow{\n    border: 2px solid #c3c5f8 !important;\n    border-radius: 0 0 15px 15px !important;\n    padding: 10px!important;\n    color: var(--darkGrey);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
