// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .discountCardWrapper{
    width: 80%;
    margin: 0 auto;
    padding: 30px;
    box-shadow: var(--boxShadow);
    margin-top: 100px;
    border-radius: 8px;
    margin-bottom: 100px;
}

.dicountInfoWrapper{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

button.discountTypeBtn {
    background: var(--lightGray);
    color: #000;
    text-transform: capitalize;
    width: 50%;
}

button.discountTypeBtn:hover{
    background-color: var(--lightGray);;
}

button.discountTypeBtn.active{
    background: var(--purple);
    color: vaR(--white);
}

.dicountTypeBtnWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
}

.dicountInfoWrapper input{
    border: 1px solid #00000070;
    padding: 5px 10px;
    border-radius: 6px;
}

.discountMiddleWrapper label{
    margin-bottom: 5px;
}

.couponGenerateBtn{
    background-color: var(--purple);
    color: #fff;
}

.couponGenerateBtn:hover, .discountSubmitBtnWrapper .btn:hover{
    background-color: var(--purple);
    color: #fff;
}

.discountHeader{
    margin-bottom: 10px;
} */`, "",{"version":3,"sources":["webpack://./src/pages/GiftCard/newGiftCard/NewGiftCard.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GA6DG","sourcesContent":["/* .discountCardWrapper{\n    width: 80%;\n    margin: 0 auto;\n    padding: 30px;\n    box-shadow: var(--boxShadow);\n    margin-top: 100px;\n    border-radius: 8px;\n    margin-bottom: 100px;\n}\n\n.dicountInfoWrapper{\n    display: flex;\n    flex-direction: column;\n    row-gap: 5px;\n}\n\nbutton.discountTypeBtn {\n    background: var(--lightGray);\n    color: #000;\n    text-transform: capitalize;\n    width: 50%;\n}\n\nbutton.discountTypeBtn:hover{\n    background-color: var(--lightGray);;\n}\n\nbutton.discountTypeBtn.active{\n    background: var(--purple);\n    color: vaR(--white);\n}\n\n.dicountTypeBtnWrapper{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    column-gap: 20px;\n}\n\n.dicountInfoWrapper input{\n    border: 1px solid #00000070;\n    padding: 5px 10px;\n    border-radius: 6px;\n}\n\n.discountMiddleWrapper label{\n    margin-bottom: 5px;\n}\n\n.couponGenerateBtn{\n    background-color: var(--purple);\n    color: #fff;\n}\n\n.couponGenerateBtn:hover, .discountSubmitBtnWrapper .btn:hover{\n    background-color: var(--purple);\n    color: #fff;\n}\n\n.discountHeader{\n    margin-bottom: 10px;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
