import { createSlice } from "@reduxjs/toolkit";


const orderRedux = createSlice({
    name: 'order',
    initialState: {
        orders: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        getOrdersStart: (state) => {
            state.isFetching = true;
        },

        getOrdersSuccess: (state, action) => {
            state.isFetching = false;
            state.orders = action.payload;
        },

        getOrdersFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // add order actions 
        addOrderStart: (state) => {
            state.isFetching = true;
        },

        addOrderSuccess: (state, action) => {
            state.isFetching = false;
            const newOrder = action.payload;
            state.orders = [...state.orders, newOrder];
        },

        addOrderFailure: (state) => {
            state.error = true;
            state.isFetching = false;
        },

        assignCourse : (state, action) => {
            state.isFetching = false;
            const newData = action.payload;
            state.orders = [newData, ...state.orders];
        },

        removeAssignedCourse: (state, action) => {
            // const courseToBeRemoved = action.payload;
            const orderId = action.payload.orderId;
            const productId = action.payload.productId;
            // const foundOrder = state.orders.find((order) => order._id === orderId);

            // if(foundOrder){
            //     state.orders = state.order
            // }
            state.orders = state.orders
                .map(order => {
                    if (order._id === orderId) {
                        const updatedProducts = order.products.filter(product => product.productId._id !== productId);

                        if (updatedProducts.length === 0) {
                            return null;
                        }

                        return {
                            ...order,
                            products: updatedProducts
                        };
                    }

                    return order;
                })
                .filter(order => order !== null);
        },
    },
});

export const { getOrdersStart, getOrdersSuccess, getOrdersFailure, removeAssignedCourse, assignCourse } = orderRedux.actions;
export default orderRedux.reducer