import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addQuizFailure, addQuizStart, addQuizSuccess, updateQuizFailure, updateQuizStart, updateQuizSuccess } from '../../../redux/quizRedux';
import { validateRequired } from '../../../formValidation';
import { userRequest } from '../../../requestMethod';
import { useLocation } from 'react-router-dom';
import { getAllQuiz } from '../../../redux/apiCalls';

const EditQuiz = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        getAllQuiz(dispatch);
    }, [dispatch]);

    const location = useLocation();
    const quizId = location.pathname.split('/')[2];

    const quizData = useSelector((state) => state.quiz.quiz.find((item) => item._id == quizId));


    // const [courseId, setCourseId] = useState('');
    const [title, setTitle] = useState(quizData?.title);
    const [questions, setQuestions] = useState(quizData?.questions);
    // const [questions, setQuestions] = useState([{ question: '', options: ['', '', '', ''], correctAnswer: 0, explanation: '', marks: 1 }]);
    const [minPassingMarks, setMinPassingMarks] = useState(quizData?.minPassingMarks);
    const [totalMarks, setTotalMarks] = useState(quizData?.totalMarks);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        setTitle(quizData?.title);
        setQuestions(quizData?.questions);
        setMinPassingMarks(quizData?.minPassingMarks);
        setTotalMarks(quizData?.totalMarks);
    },[quizData])

    const calculateTotalMarks = (questions) => {
        return questions?.reduce((total, question) => total + Number(question.marks), 0);
    };


    // const handleQuestionChange = (index, field, value) => {
    //     const updatedQuestions = [...questions];
    //     updatedQuestions[index][field] = value;
    //     setQuestions(updatedQuestions);
    // };

    const handleQuestionChange = (index, field, value) => {
        const updatedQuestions = [...questions]; // Create a new array
        updatedQuestions[index] = {
            ...updatedQuestions[index], // Create a new object for the specific question
            [field]: value, // Update the specific field of the question
        };
        setQuestions(updatedQuestions);
    };

    useEffect(() => {
        const calculatedTotalMarks = calculateTotalMarks(questions);
        setTotalMarks(calculatedTotalMarks);
    }, [questions]);


    // const handleOptionChange = (questionIndex, optionIndex, value) => {
    //     const updatedQuestions = [...questions];
    //     updatedQuestions[questionIndex].options[optionIndex] = value;
    //     setQuestions(updatedQuestions);
    // };

    const handleOptionChange = (questionIndex, optionIndex, value) => {
        const updatedQuestions = [...questions]; // Create a new array
        updatedQuestions[questionIndex] = {
            ...updatedQuestions[questionIndex], // Create a new object for the specific question
            options: [
                ...updatedQuestions[questionIndex].options.slice(0, optionIndex),
                value,
                ...updatedQuestions[questionIndex].options.slice(optionIndex + 1),
            ], // Update the options field
        };
        setQuestions(updatedQuestions);
    };

    // const handleCorrectAnswerChange = (questionIndex, value) => {
    //     const updatedQuestions = [...questions];
    //     updatedQuestions[questionIndex].correctAnswer = value;
    //     setQuestions(updatedQuestions);
    // };

    const handleCorrectAnswerChange = (questionIndex, value) => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex] = {
            ...updatedQuestions[questionIndex], // Create a new object for the specific question
            correctAnswer: value, // Update the correctAnswer field
        };
        setQuestions(updatedQuestions);
    };

    const handleAddQuestion = () => {
        const newQuestion = { question: '', options: ['', '', '',''], correctAnswer: 0, explanation: '', marks: 1 };
        setQuestions([...questions, newQuestion]);
    };

    const handleRemoveQuestion = (index) => {
        const updatedQuestions = [...questions];
        updatedQuestions.splice(index, 1);
        setQuestions(updatedQuestions);
    };

    // const handleExplanationChange = (questionIndex, value) => {
    //     const updatedQuestions = [...questions];
    //     updatedQuestions[questionIndex].explanation = value;
    //     setQuestions(updatedQuestions);
    // };

    const handleExplanationChange = (questionIndex, value) => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex] = {
            ...updatedQuestions[questionIndex], // Create a new object for the specific question
            explanation: value, // Update the explanation field
        };
        setQuestions(updatedQuestions);
    };

    // const handleMarksChange = (questionIndex, value) => {
    //     const updatedQuestions = [...questions];
    //     updatedQuestions[questionIndex].marks = value;
    //     setQuestions(updatedQuestions);
    // };

    const handleMarksChange = (questionIndex, value) => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex] = {
            ...updatedQuestions[questionIndex], // Create a new object for the specific question
            marks: value, // Update the marks field
        };
        setQuestions(updatedQuestions);
    };

    
    const updateQuiz = async (dispatch, quiz) => {
        dispatch(updateQuizStart());
        try {
            const res = await userRequest.put(`quiz/${quizId}`, quiz);
            dispatch(updateQuizSuccess(res.data));
            if (res.status === 200) {
                setSuccess(true);
                setSuccessMessage("Quiz has been update!");
                // setFirstName('');
                // setLastName('');
                // setEmail('');
                // setPhone('');
                // setDateOfBirth('');
            }
        } catch (error) {
            dispatch(updateQuizFailure());
            if (error.response.status === 409) {
                setEmailError(true);
                setEmailErrorMessage(error.response.data);
            }

            else {
                setError(true);
                setErrorMessage("Something went wrong!");
            }
        }
    };

    // Validate function for form validation start=======
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const titleValidErr = validateRequired(title);
        const minPassingMarksValidErr = validateRequired(minPassingMarks);
        // const emailValidErr = validateRequired(email);
        // const phoneValidErr = validateRequired(phone);
        // const dobValidErr = validateRequired(dateOfBirth);

        if (titleValidErr) {
            formIsValid = false;
            newErrors.title = titleValidErr;
        }
        if (minPassingMarksValidErr) {
            formIsValid = false;
            newErrors.minPassingMarks = minPassingMarksValidErr;
        }

        if (minPassingMarks >= totalMarks) {
            formIsValid = false;
            newErrors.minPassingMarks = "Minimum passing marks should be less than total marks";
        }

        // if (emailValidErr) {
        //     formIsValid = false;
        //     newErrors.email = emailValidErr;
        // }
        // if (phoneValidErr) {
        //     formIsValid = false;
        //     newErrors.phone = phoneValidErr;
        // }
        // if (dobValidErr) {
        //     formIsValid = false;
        //     newErrors.dob = dobValidErr;
        // }

        setErrors(newErrors);
        return formIsValid;
    }
    // Validate function for form validation end==========z

    const handleSubmit = () => {
        setEmailError(false);
        setSuccess(false);

        if (validateForm() && minPassingMarks < totalMarks) {
            updateQuiz(dispatch, { title, questions, minPassingMarks, totalMarks });
        }
    }


    return (
        <>
            <div className="NewRegisterFormWrapper">
                <div className="container">
                    <div className="row">
                        <h1 className="page-heading py-4">Edit Quiz</h1>
                        <div className="col-10">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-input-wrapper">
                                        <label>Title</label>
                                        <input type="text" className='newUserInput' placeholder='Quiz Title' value={title} onChange={(e) => setTitle(e.target.value)} />
                                        <span className='error-text'>{errors.title}</span>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-input-wrapper">
                                        {questions?.map((question, index) => (
                                            <div className="col-12" key={index}>
                                                <div className="form-input-wrapper">
                                                    <label>Question {index + 1}:</label>
                                                    <input type="text" placeholder='Type your question here...' className='newUserInput' value={question.question} onChange={(e) => handleQuestionChange(index, 'question', e.target.value)} />
                                                </div>
                                                <div className="form-input-wrapper">
                                                    <label>Options:</label>
                                                    {question.options.map((option, optionIndex) => (
                                                        <input
                                                            key={optionIndex}
                                                            className='newUserInput'
                                                            type="text"
                                                            value={option}
                                                            placeholder={`Option ${optionIndex + 1}`}
                                                            onChange={(e) => handleOptionChange(index, optionIndex, e.target.value)}
                                                        />
                                                    ))}
                                                </div>
                                                <div className="form-input-wrapper">
                                                    <label>Correct Answer:</label>
                                                    <select className='newUserInput' value={question.correctAnswer} onChange={(e) => handleCorrectAnswerChange(index, e.target.value)}>
                                                        {question.options.map((option, optionIndex) => (
                                                            <option key={optionIndex} value={optionIndex}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-input-wrapper">
                                                    <label>Explanation:</label>
                                                    <textarea
                                                        type="text"
                                                        className="newUserInput"
                                                        value={question.explanation}
                                                        onChange={(e) => handleExplanationChange(index, e.target.value)}

                                                    />
                                                </div>
                                                <div className="form-input-wrapper">
                                                    <label>Marks:</label>
                                                    <input
                                                        type="text"
                                                        className="newUserInput"
                                                        value={question.marks}
                                                        onChange={(e) => handleMarksChange(index, e.target.value)}
                                                    />
                                                </div>

                                                {questions.length > 1 && (
                                                    <button className='custom-btn' onClick={() => handleRemoveQuestion(index)}>Remove Question</button>
                                                )}
                                            </div>
                                        ))}
                                        <button className='custom-btn' onClick={handleAddQuestion}>Add Question</button>
                                        <div className="col-12">
                                            <div className="form-input-wrapper">
                                                <label>Minimum Passing Marks:</label>
                                                <input
                                                    type="text"
                                                    className="newUserInput"
                                                    value={minPassingMarks}
                                                    onChange={(e) => setMinPassingMarks(e.target.value)}
                                                />
                                                <span className='error-text'>{errors.minPassingMarks}</span>
                                            </div>
                                        </div>
                                        <div className="form-input-wrapper">
                                            <label>Total Marks:</label>
                                            <input
                                                type="text"
                                                className="newUserInput"
                                                value={totalMarks}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="newStudentSubmitbtn">
                                        <button className="custom-btn purple-bg" onClick={handleSubmit}>update</button>
                                    </div>
                                </div>
                                <div className="col-12 mt-3">
                                    {
                                        success ? (
                                            <div className='alert alert-success'>
                                                {successMessage}
                                            </div>
                                        ) : error && (
                                            <div className='alert alert-danger'>
                                                {errorMessage}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditQuiz
