import React, { useEffect, useState } from 'react';
import './EditDiscount.css';
import { updateDiscountFailure, updateDiscountStart, updateDiscountSuccess } from '../../../redux/discountRedux';
import { fileUploadRequest, userRequest } from '../../../requestMethod';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { validateRequired } from '../../../formValidation';
import { getAllGiftCards } from '../../../redux/apiCalls';
import { MEDIA_URL, isUrl } from '../../../config';

const EditGiftCard = () => {
    const location = useLocation();
    const giftCardId = location.pathname.split('/')[2];

    const giftCardData = useSelector((state) => state.giftCards.giftCards.find((item) => item._id === giftCardId));

    const [giftCard, setGiftCard] = useState(giftCardData && giftCardData.giftCard);
    const [amount, setAmount] = useState(giftCardData && giftCardData.amount);
    const [giftCardImage, setGiftCardImage] = useState(giftCardData && giftCardData.giftCardImg)
    const [imagePreview, setImagePreview] = useState(giftCardData && giftCardData.giftCardImg)
    const [isActive, setIsActive] = useState(giftCardData && giftCardData.isActive);
    const [giftCardError, setGiftCardError] = useState(false);
    const [giftCardErrorMessage, setGiftCardErrorMessage] = useState('');
    const [activePopup, setActivePopup] = useState(false);
    const [popupText, setPopuptext] = useState('');
    const [popupStatus, setPopupStatus] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        getAllGiftCards(dispatch)
    }, [dispatch]);

    const updateGiftCard = async (dispatch, giftCardId, giftCard) => {
        dispatch(updateDiscountStart());
        try {

            const res = await userRequest.put(`/discountCoupon/${giftCardId}`, giftCard);
            if (res.status === 200) {
                setActivePopup(true)
                setPopuptext("Gift Card has been updated.");
                setPopupStatus(true);
                setGiftCard('');
                setAmount('');
                setIsActive(false);
            }
            dispatch(updateDiscountSuccess(res.data));

        } catch (error) {
            dispatch(updateDiscountFailure());
            if (error.response.status === 409) {
                setGiftCardError(true);
                setGiftCardErrorMessage(error.response.data);
            }

            else {
                setActivePopup(true)
                setPopuptext(error.response.data);
                setPopupStatus(false);
            }
        }
    };


    // Validate function for form validation start=======
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const giftCardValidErr = validateRequired(giftCard);
        const amountValidErr = validateRequired(amount);
        const giftCardImageValidErr = validateRequired(giftCardImage);

        if (giftCardValidErr) {
            formIsValid = false;
            newErrors.giftCard = giftCardValidErr;
        }
        if (amountValidErr) {
            formIsValid = false;
            newErrors.amount = amountValidErr;
        }
        setErrors(newErrors);
        return formIsValid;
    }
    // Validate function for form validation end======

    const handleGiftCardChange = (event) => {
        setGiftCard(event.target.value);
    };

    const allowedImageFormats = ['image/jpeg', 'image/png', 'image/jpg'];

    // const handleGiftCardImageChange = (event) => {
    //     const files = event.target.files;
    //     if (files.length > 0) {
    //         // Check if the selected file is an image
    //         const isImage = allowedImageFormats.includes(files[0].type);
    //         if (!isImage) {
    //             // Show an error message or perform some action for invalid image format
    //             alert('Please select a valid image file (JPEG, PNG, or JPG).');
    //             event.target.value = null;
    //             setGiftCardImage('');
    //             return;
    //         } else {
    //             setGiftCardImage(files[0]);     
    //         }
    //     }
    // }

    const handleGiftCardImageChange = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            // Check if the selected file is an image
            const isImage = allowedImageFormats.includes(files[0].type);
            if (!isImage) {
                // Show an error message or perform some action for invalid image format
                alert('Please select a valid image file (JPEG, PNG, or GIF).');
                event.target.value = null;
                setGiftCardImage('');
                return;
            } else {
                setGiftCardImage(files[0]);
                setImagePreview(URL.createObjectURL(files[0]));
            }
        }
        else {
            setGiftCardImage(null);
            setImagePreview(giftCardData && giftCardData.giftCardImg);
        }
    };

    const uploadProductImageToSpace = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fileUploadRequest.post('/upload/file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response && response.status === 200) {
                const fileUrl = response.data.fileUrl;
                // setProductImage(fileUrl); // Set the product image URL in state
                return fileUrl
            }
        } catch (error) {
            console.error('Error uploading product image:', error);
        }
    };

    const handleUpdateGiftCard = async () => {
        if (validateForm()) {
            const trimmmedGiftCard = giftCard.trim();
            let giftCardImgUrl;
            if(giftCardImage?.type){
                giftCardImgUrl = await uploadProductImageToSpace(giftCardImage);
            }
            else{
                giftCardImgUrl = giftCardImage;
            }
            updateGiftCard(dispatch, giftCardId, { giftCard: trimmmedGiftCard, giftCardImg : giftCardImgUrl, amount, isActive });
        }
    }

    return (
        <div className='container'>
            <div className="row">
                <div className="col-12">
                    <div className="discountCardWrapper">
                        <div className="discountHeader">
                            <h2>Update Gift Card</h2>
                        </div>
                        <div className="discountMiddleWrapper pt-2">
                            <div className="row gy-3">
                                <div className="col-12">
                                    <div className="discountInputFieldWrapper">
                                        <label>Enter a title</label>
                                        <div className="dicountInfoWrapper">
                                            <input type="text" value={giftCard} onChange={handleGiftCardChange} placeholder="e.g. Best Gift" />
                                            {
                                                giftCardError && (
                                                    <p className='error-text text-danger'>{giftCardErrorMessage}</p>
                                                )
                                            }
                                            <span className='error-text'>{errors.giftCard}</span>
                                            {/* <button className='btn couponGenerateBtn text-capitalize' onClick={generateCouponCode}>Generate a coupon</button> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="discountInputFieldWrapper">
                                        <label>Image</label>
                                        <div className="dicountInfoWrapper">
                                            <input type="file" accept={allowedImageFormats} name='file' onChange={handleGiftCardImageChange} />
                                            {/* {
                                                giftCardError && (
                                                    <p className='error-text text-danger'>{giftCardErrorMessage}</p>
                                                )
                                            } */}
                                            <span className='error-text'>{errors.giftCardImage}</span>
                                            {/* <button className='btn couponGenerateBtn text-capitalize' onClick={generateCouponCode}>Generate a coupon</button> */}
                                        </div>
                                    </div>
                                    <div className="form-input-wrapper">
                                        <label>Preview Image</label>
                                        <div className="course-img-wrapper">
                                            {
                                                isUrl(imagePreview) ? (
                                                    <img src={imagePreview || giftCardImage} alt="gift-card-img" />
                                                ) : (giftCardImage?.type) ? (
                                                    <img src={imagePreview || giftCardImage} alt="gift-card-img" />
                                                ) : (
                                                    <img src={MEDIA_URL + imagePreview} alt="gift-card-img" />
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="discountInputFieldWrapper">
                                        <label>Gift Card Value (In Rs)</label>
                                        <div className="dicountInfoWrapper">
                                            <input type="text" value={amount} onChange={(e) => setAmount(e.target.value)} placeholder="e.g. 999" />
                                            <span className='error-text'>{errors.amount}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="discountInputFieldWrapper">
                                        <label>Status</label>
                                        <div className="dicountInfoWrapper">
                                            <select name="status" id="" value={isActive} onChange={(e) => setIsActive(e.target.value)}>
                                                <option value="true">Active</option>
                                                <option value="false">Inactive</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="discountSubmitBtnWrapper">
                                        <button className='custom-btn btn' onClick={handleUpdateGiftCard}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditGiftCard
