import React, { useEffect, useState } from 'react';
import './BlogTable.css';
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline, Visibility } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { deleteBlogs, getAllBlogs } from '../../../redux/apiCalls';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { IMG_URL, MEDIA_URL, addRowIndex, isUrl } from '../../../config';
import CircleLoader from '../../../components/circleLoader/CircleLoader';

const BlogTable = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        getAllBlogs(dispatch);
    }, [dispatch]);

    const isFetching = useSelector((state) => state.blog.isFetching);
    // const state = useSelector((state) => state);
    const blogs = useSelector((state) => state.blog.blogs);

    // const [blogsWithIndex, setBlogsWithIndex] = useState(null);

    // Add index numbers to the blogs array
    const blogsWithIndex = blogs?.map((blog, index) => ({
        ...blog,
        index: index + 1, // Add 1 to the index to start from 1 instead of 0
    }));


    // useEffect(() => {
    //     setBlogsWithIndex(addRowIndex(blogs));
    // }, []);
    // const admins = useSelector((state) => state.allblogs.users);


    // const handleDelete = (adminId) => {
    //     deleteAdmin(adminId, dispatch);
    //     setDeletePopup(false);
    // }
    const handleDelete = (blogId, filename) => {
        deleteBlogs(blogId, filename, dispatch);
        setDeletePopup(false);
    }

    const [deletePopup, setDeletePopup] = useState(false);
    const [deletingItem, setDeletingItem] = useState(null);
    const [testimoialImageName, setTestimoialImageName] = useState(null);

    const handlePopup = (id, filename) => {
        setDeletePopup(true);
        setDeletingItem(id);
        setTestimoialImageName(filename);
    };

    const handlePopupCancel = () => {
        setDeletePopup(false)
    }

    const columns = [
        { field: 'index', headerName: '#', width: 50 },
        {
            field: 'title',
            headerName: 'Blog Title',
            width: 250,
            editable: true,
        },
        {
            field: 'blogImage',
            headerName: 'Thumbnail',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 150,
            renderCell: (params) => {
                if(isUrl(params.value)){
                    return <img src={params.value} className='courseTable-img' alt="product-img" />
                }
                else{
                    return <img src={ MEDIA_URL + params.value} className='courseTable-img' alt="product-img" />
                }
                
            }
        },
        {
            field: 'category',
            headerName: 'Category',
            width: 100,
            editable: true,
        },
        {
            field: 'status',
            headerName: 'Status',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 150,
            renderCell: (params) => {
                return params.row.status ? "Published" : "Draft";
            }
        },
        {
            field: 'Action',
            headerName: 'Action',
            sortable: false,
            width: 100,
            renderCell: (params) => {

                return (
                    <>
                        <Link to={'/edit-blog/' + params.id}>
                            <button className='btn btn-primary studentListEdit'>Edit</button>
                        </Link>
                        {/* <DeleteOutline className='studentListDelete text-danger' /> */}
                    </>
                )
            }
        },
        {
            field: 'delete',
            headerName: 'Delete',
            sortable: false,
            width: 100,
            renderCell: (params) => {
                const blogId = params.row._id;
                // const blogImageURL = params.row.blogImage;
                // // Create a URL object
                // const url = blogImageURL ? new URL(blogImageURL) : null;
                // // Extract the filename
                // const filename = url ? url.pathname.split('/').pop() : null;
                return (
                    <>
                        <DeleteOutline onClick={() => handlePopup(blogId)} className='courseListDelete text-danger' />
                    </>
                )
            }
        },
    ];

    if (isFetching) {
        return (
            <div className='loader-wrapper'>
                <CircleLoader />
            </div>
        )
    }

    return (
        <div className='studentTableWrapper py-5'>
            <div className="row">
                <div className="col-12">
                    <div className="tableButtonWrapper d-flex justify-content-between mb-3">
                        <h2>Blogs</h2>
                        <div className="buttonsWrapper d-flex gx-2">
                            {/* <Link to='/course-age-groups'>
                                <button className='custom-btn purple-bg'>View Age Groups <Visibility className='newStudentIcon' /></button>
                            </Link> */}
                            <Link to='/blog/view-category'>
                                <button className='custom-btn purple-bg'>View Category <Visibility className='newStudentIcon' /></button>
                            </Link>
                            <Link to='/new-blog'>
                                <button className='custom-btn purple-bg'>New Blog <AddCircleOutlineOutlinedIcon className='newStudentIcon' /></button>
                            </Link>
                        </div>


                    </div>
                </div>
            </div>

            {
                deletePopup && (
                    <div className="deletePopupAlertWrapper">
                        <div className="deletePopupAlert">
                            <WarningAmberIcon style={{ color: "var(--orange)", fontSize: "40px" }} />
                            <h4>Warning</h4>
                            <div className="deleteAlertTextWrapper text-center">
                                <p>This blog will be deleted permanently.</p>
                                <p>Are you sure?</p>
                            </div>

                            <div className="deleteBtnsWrapper">
                                <button className='btn' onClick={handlePopupCancel} style={{ background: "var(--green)", color: "var(--white)" }}>Cancel</button>
                                <button className='btn' onClick={() => handleDelete(deletingItem, testimoialImageName)} style={{ background: "var(--red)", color: "var(--white)" }}>Delete</button>
                            </div>
                        </div>
                    </div>
                )
            }

            {
                blogs && (
                    <DataGrid
                        rows={blogsWithIndex}
                        getRowId={(rows) => rows._id}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[10]}
                        checkboxSelection={false}
                        disableRowSelectionOnClick
                    />
                )
            }


        </div>
    )
}

export default BlogTable
