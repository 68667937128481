import React, { useEffect, useState } from 'react';
import { userRequest } from '../../../requestMethod';
import { useDispatch, useSelector } from 'react-redux';
import { validateRequired } from '../../../formValidation';
import Popup from '../../../global/Popup';
import { getProducts } from '../../../redux/apiCalls';
import { addSectionFailure, addSectionStart, addSectionSuccess } from '../../../redux/customSectionRedux';
import CircleLoader from '../../../components/circleLoader/CircleLoader';

const NewCustomSection = () => {

    const [sectionTitle, setSectionTitle] = useState('');
    const [sectionTitleError, setSectionTitleError] = useState(false);
    const [sectionTitleErrorMessage, setSectionTitleErrorMessage] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [discountTypeError, setDiscountTypeError] = useState(false);
    const [discountTypeErrorMessage, setDiscountTypeErrorMessage] = useState('');
    const [activePopup, setActivePopup] = useState(false);
    const [popupText, setPopuptext] = useState('');
    const [popupStatus, setPopupStatus] = useState(true);
    const [activeLoader, setActiveLoader] = useState(false);
    const [loaderText, setLoaderText] = useState("");


    const dispatch = useDispatch();

    useEffect(() => {
        getProducts(dispatch)
    }, [dispatch]);

    const { isFetching, products } = useSelector((state) => state.products);

    const [selectedProducts, setSelectedProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');


    const handleStatusChange = (e) => {
        // Handle the change of the "For Specific Product" dropdown
        const status = e.target.value;
        // Do something with the selected status (true or false)
    };

    const handleSearchChange = (e) => {
        // Handle the change of the search input
        setSearchTerm(e.target.value);
    };

    // const handleProductSelect = (e) => {
    //     // Handle the change of selected products in the multi-select dropdown
    //     const selectedProductIds = Array.from(e.target.selectedOptions, option => option.value);
    //     setSelectedProducts(selectedProductIds);
    // };

    const handleProductToggle = (productId) => {
        setSelectedProducts((prevSelectedProducts) => {
            if (prevSelectedProducts.includes(productId)) {
                // Remove the product if it's already selected
                return prevSelectedProducts.filter((id) => id !== productId);
            } else {
                // Add the product if it's not selected
                return [...prevSelectedProducts, productId];
            }
        });
    };


    // Filter products based on the search term
    const filteredProducts = products?.filter(product =>
        product.title.toLowerCase().includes(searchTerm.toLowerCase())
    );


    const handleSectionTitleChange = (event) => {
        setSectionTitle(event.target.value);
    };

    const addSection = async (dispatch, section) => {
        dispatch(addSectionStart());
        try {
            const res = await userRequest.post('/customSection/create', section);
            if (res.status === 200) {
                setError(false);
                setSuccess(true);
                setSuccessMessage("Section has been added!");
                setActivePopup(true)
                setPopuptext("Section has been added.");
                setPopupStatus(true);
                setSectionTitle('');
                setActiveLoader(false)
                
            }
            dispatch(addSectionSuccess(res.data));

        } catch (error) {
            dispatch(addSectionFailure());
            setActiveLoader(false)
            if (error.response.status === 409) {
                setSectionTitleError(true);
                setSectionTitleErrorMessage(error.response.data);
            }

            else {
                setActivePopup(true)
                setPopuptext(error.response.data);
                setPopupStatus(false);
            }
        }
    };

    // Validate function for form validation start=======
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const sectionTitleValidErr = validateRequired(sectionTitle);

        if (sectionTitleValidErr) {
            formIsValid = false;
            newErrors.sectionTitle = sectionTitleValidErr;
        }

        if (selectedProducts.length < 1) {
            formIsValid = false;
            newErrors.selectProductError = "Please select at least one product"
        }

        setErrors(newErrors);
        return formIsValid;
    }
    // Validate function for form validation end======

    const handleSection = () => {
        // setDiscountTypeError(false);
        // setSuccess(false);
        setActiveLoader(true);
        setLoaderText('uploading');

        if (validateForm()) {
            const trimmmedSectionTitle = sectionTitle.trim();
            // if(isForSpecificProduct === "true"){
            addSection(dispatch, { sectionTitle: trimmmedSectionTitle, products: selectedProducts, status : isActive });
            // }
            // else{
            //     addDiscount(dispatch, { couponCode: trimmmedSectionTitle, amount, minOrder, discountType, validFrom, validTill, isActive, isForSpecificProducts : isForSpecificProduct });
            // }
        }
    }

    const hidePopup = () => {
        setActivePopup(false);
    }

    if (activePopup) {
        return (
            <Popup status={popupStatus} message={popupText} onClickAction={hidePopup} />
        )
    }

    return (
        <div className='container'>
            <div className="row">
                <div className="col-12">
                    <div className="discountCardWrapper">
                        <div className="discountHeader">
                            <h2>New Section</h2>
                        </div>
                        <div className="discountMiddleWrapper pt-2">
                            <div className="row gy-3">
                                <div className="col-12">
                                    <div className="discountInputFieldWrapper">
                                        <label>Enter Section Title</label>
                                        <div className="dicountInfoWrapper">
                                            <input type="text" value={sectionTitle} onChange={handleSectionTitleChange} placeholder="e.g. new arrivals" />
                                            {
                                                sectionTitleError && (
                                                    <p className='error-text text-danger'>{sectionTitleErrorMessage}</p>
                                                )
                                            }
                                            <span className='error-text'>{errors.sectionTitle}</span>
                                            {/* <button className='btn couponGenerateBtn text-capitalize' onClick={generateCouponCode}>Generate a coupon</button> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="discountInputFieldWrapper">
                                        <label>Status</label>
                                        <div className="dicountInfoWrapper">
                                            <select name="status" id="" value={isActive} onChange={(e) => setIsActive(e.target.value)}>
                                                <option value="true">Active</option>
                                                <option value="false">Inactive</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="discountInputFieldWrapper">
                                        <label>Select Product</label>
                                        <div className="dicountInfoWrapper">
                                            <input type="text" placeholder='Search here...' value={searchTerm}
                                                onChange={handleSearchChange} />
                                            <div className="product-list-wrapper">
                                                {filteredProducts.map((product, index) => {
                                                    return (
                                                        <button className={`product-option-button ${selectedProducts.includes(product._id) ? 'selected' : ''
                                                            }`} key={index} onClick={() => handleProductToggle(product._id)}>
                                                            <div className="product-option-wrapper">
                                                                <div className="option-img-wrapper">
                                                                    <img src={product.productImg} alt='img' />
                                                                </div>
                                                                <p>{product.title}</p>
                                                                <span className={`${product.status === true ? "green-bg" : "red-bg"} product-status`}>{product.status === true ? "active" : "draft"}</span>
                                                            </div>
                                                        </button>
                                                    )
                                                })}
                                            </div>
                                            <span className='error-text'>{errors.selectProductError}</span>
                                        </div>
                                    </div>
                                </div>
                                {/* )
                                } */}
                                <div className="col-12">
                                    <div className="discountSubmitBtnWrapper">
                                        <button className='custom-btn btn' onClick={handleSection}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                activeLoader && (
                    <CircleLoader title={loaderText} />
                )
            }
        </div>
    )
}

export default NewCustomSection
