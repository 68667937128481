// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overview-img-wrapper{
    display: flex;
    column-gap: 20px;
    flex-wrap: wrap;
}

/* .add-more-icon{
    color: var(--dBlue);
} */

.add-more-button{
    background: none;
}

.update-wrapper-main{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    transition: all 0.6s ease-in-out;
    /* display: none; */
}

.update-tool-wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 15px;
    background: #c3c5f8;
}

.update-tool-wrapper button{
    padding: 5px 25px;
    color: #fff;
}

.update-tool-wrapper .update-btn{
    background-color: var(--dPink);
}

.update-tool-wrapper .delete-btn{
    background-color: var(--purple);
}

.overview-img-wrapper .course-img-wrapper:hover .update-wrapper-main{
    /* display: block; */
    top: 0%;
}`, "",{"version":3,"sources":["webpack://./src/pages/robotix-products/editRobotixProduct/EditRobotixProduct.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,eAAe;AACnB;;AAEA;;GAEG;;AAEH;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,gCAAgC;IAChC,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,oBAAoB;IACpB,OAAO;AACX","sourcesContent":[".overview-img-wrapper{\n    display: flex;\n    column-gap: 20px;\n    flex-wrap: wrap;\n}\n\n/* .add-more-icon{\n    color: var(--dBlue);\n} */\n\n.add-more-button{\n    background: none;\n}\n\n.update-wrapper-main{\n    width: 100%;\n    height: 100%;\n    position: absolute;\n    top: 100%;\n    left: 0;\n    transition: all 0.6s ease-in-out;\n    /* display: none; */\n}\n\n.update-tool-wrapper{\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    row-gap: 15px;\n    background: #c3c5f8;\n}\n\n.update-tool-wrapper button{\n    padding: 5px 25px;\n    color: #fff;\n}\n\n.update-tool-wrapper .update-btn{\n    background-color: var(--dPink);\n}\n\n.update-tool-wrapper .delete-btn{\n    background-color: var(--purple);\n}\n\n.overview-img-wrapper .course-img-wrapper:hover .update-wrapper-main{\n    /* display: block; */\n    top: 0%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
