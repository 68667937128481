import React, { useState } from 'react';
import './NewSchoolTeacher.css';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addUserFailure, addUserStart, addUserSuccess } from '../../../../../redux/registeredUserRedux';
import { userRequest } from '../../../../../requestMethod';
import { validateRequired } from '../../../../../formValidation';

const NewSchoolTeacher = () => {
    const location = useLocation();

    const schoolId = location.pathname.split('/')[2];
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState(null);
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const isSchoolTeacher = true;

    const dispatch = useDispatch();

    const addSchoolTeacher = async (dispatch, schoolTeacher) => {
        dispatch(addUserStart());
        try {
            const res = await userRequest.post('user/create', schoolTeacher);
            dispatch(addUserSuccess(res.data));
            if(res.status){
                if (res.status === 200) {
                    setSuccess(true);
                    setSuccessMessage("School Teacher has been added!");
                    setFirstName('');
                    setLastName('');
                    setEmail('');
                    setPhone('');
                }
            }

            else{
                setError(true);
                setErrorMessage('You are not connected!')
            }
        } catch (error) {
            dispatch(addUserFailure());

            if (error.response.status === 409) {
                setEmailError(true);
                setEmailErrorMessage(error.response.data);
            }

            else if (error.response.data) {
                setError(true);
                setErrorMessage(error.response.data);
            }

            else {
                setError(true);
                setErrorMessage("Something went wrong!");
            }
        }
    };

    // Validate function for form validation start=======
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const firstNameValidErr = validateRequired(firstName);
        const lastNameValidErr = validateRequired(lastName);
        const emailValidErr = validateRequired(email);
        const phoneValidErr = validateRequired(phone);
        const dobValidErr = validateRequired(dateOfBirth);

        if (firstNameValidErr) {
            formIsValid = false;
            newErrors.firstName = firstNameValidErr;
        }
        if (lastNameValidErr) {
            formIsValid = false;
            newErrors.lastName = lastNameValidErr;
        }
        if (emailValidErr) {
            formIsValid = false;
            newErrors.email = emailValidErr;
        }
        if (phoneValidErr) {
            formIsValid = false;
            newErrors.phone = phoneValidErr;
        }
        if (dobValidErr) {
            formIsValid = false;
            newErrors.dateOfBirth = dobValidErr;
        }

        setErrors(newErrors);
        return formIsValid;
    }
    // Validate function for form validation end======

    const handleSchoolTeacherData = () => {
        setEmailError(false);
        setSuccess(false);
        if (validateForm()) {
            addSchoolTeacher(dispatch, { firstName, lastName, email, phone, dateOfBirth, schoolId, isSchoolTeacher })
        }
    }


    return (
        <>
            <div className="NewRegisterFormWrapper">
                <div className="container">
                    <div className="row">
                        <h1 className="page-heading py-4 text-capitalize">Add new school teacher</h1>
                        <div className="col-10">
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-input-wrapper">
                                        <label>first name</label>
                                        <input type="text" className='newUserInput' value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder='e.g. john' />
                                        <span className='error-text'>{errors.firstName}</span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-input-wrapper">
                                        <label>last name</label>
                                        <input type="text" className='newUserInput' value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder='e.g. smith' />
                                        <span className='error-text'>{errors.lastName}</span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-input-wrapper">
                                        <label>email</label>
                                        <input type="text" className='newUserInput' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='e.g. xyz@gmail.com' />
                                        <span className='error-text'>{errors.email}</span>
                                        {
                                            emailError && (
                                                <p className='error-text text-danger'>{emailErrorMessage}</p>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-input-wrapper">
                                        <label>Date of Birth</label>
                                        <input type="date" className='newUserInput' value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} />
                                        <span className='error-text'>{errors.dateOfBirth}</span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-input-wrapper">
                                        <label>phone</label>
                                        <input type="text" className='newUserInput' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder='e.g. 9876543219' />
                                        <span className='error-text'>{errors.phone}</span>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="newStudentSubmitbtn">
                                        <button className="custom-btn purple-bg" onClick={handleSchoolTeacherData}>create</button>
                                    </div>
                                </div>

                                <div className="col-12 mt-3">
                                    {
                                        success ? (
                                            <div className='alert alert-success'>
                                                {successMessage}
                                            </div>
                                        ) : error && (
                                            <div className='alert alert-danger'>
                                                {errorMessage}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewSchoolTeacher
