// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.availableCourseType{
    background: var(--pink);
    color: var(--white);
    font-weight: 600;
    padding: 10px 15px;
}

.avilableCourseTypeGroupWrapper{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.ageGroupContainer{
    display: flex;
    column-gap: 15px;
}

.courseTypeWrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    margin: 0 auto;
    margin-top: 200px;
    padding: 30px;
    box-shadow: var(--boxShadow);
    row-gap: 15px;
}


.newCourseTypeInputWrapper{
    width: 100%;
    display: flex;
    column-gap: 20px;
}

.newCourseTypeInputWrapper input{
    width: 80%;
}

.newCourseTypeInputWrapper button{
    width: 20%;
}

.courseTypeDelete{
    font-size: 16px;
}

.availableCourseType{
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.courseTypeContainer{
    display: flex;
    column-gap: 15px;
    flex-wrap: wrap;
    row-gap: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/course/courseType/CourseType.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,UAAU;IACV,cAAc;IACd,iBAAiB;IACjB,aAAa;IACb,4BAA4B;IAC5B,aAAa;AACjB;;;AAGA;IACI,WAAW;IACX,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,aAAa;AACjB","sourcesContent":[".availableCourseType{\n    background: var(--pink);\n    color: var(--white);\n    font-weight: 600;\n    padding: 10px 15px;\n}\n\n.avilableCourseTypeGroupWrapper{\n    display: flex;\n    flex-direction: column;\n    row-gap: 15px;\n}\n\n.ageGroupContainer{\n    display: flex;\n    column-gap: 15px;\n}\n\n.courseTypeWrapper{\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    width: 50%;\n    margin: 0 auto;\n    margin-top: 200px;\n    padding: 30px;\n    box-shadow: var(--boxShadow);\n    row-gap: 15px;\n}\n\n\n.newCourseTypeInputWrapper{\n    width: 100%;\n    display: flex;\n    column-gap: 20px;\n}\n\n.newCourseTypeInputWrapper input{\n    width: 80%;\n}\n\n.newCourseTypeInputWrapper button{\n    width: 20%;\n}\n\n.courseTypeDelete{\n    font-size: 16px;\n}\n\n.availableCourseType{\n    display: flex;\n    align-items: center;\n    column-gap: 10px;\n}\n\n.courseTypeContainer{\n    display: flex;\n    column-gap: 15px;\n    flex-wrap: wrap;\n    row-gap: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
