import React, { useState } from 'react'
import { publicRequest, userRequest } from '../../../requestMethod';

const Gmeet = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [attendees, setAttendees] = useState([]);
    const [message, setMessage] = useState('');


    // const handleInputChange = (event) => {
    //     const { name, value } = event.target;

    //     if (name === 'title') setTitle(value);
    //     if (name === 'description') setDescription(value);
    //     if (name === 'startTime') setStartTime(value);
    //     if (name === 'endTime') setEndTime(value);
    //     if (name === 'attendees') setAttendees(value);
    // };

    const handleFormSubmit = async (event) => {
        event.preventDefault();


        const meetingData = {
            title,
            description,
            startTime,
            endTime,
            attendees: attendees.split(',').map((email) => email.trim()),
        };

        try {
            const res = await userRequest.post('/google-meeting/schedule-meeting', {meetingData});
        } catch (error) {
        }

        
            //  publicRequest.post('/meeting/schedule-meeting', meetingData) // Assuming your backend route is '/schedule-meeting'
            // .then((response) => {
            //     const { success, event } = response.data;
            //     if (success) {
            //         setMessage('Meeting scheduled successfully!');
            //     }
            // })
            // .catch((error) => {
            //     setMessage('Failed to schedule meeting.');
            //     console.error(error);
            // });
    };

    return (
        <div>
            <h1>Schedule a Meeting</h1>
            <form>
                <label>Title:</label>
                <input type="text" name="title" value={title} onChange={(e) => setTitle(e.target.value)} required />

                <label>Description:</label>
                <textarea name="description" value={description} onChange={(e) => setDescription(e.target.value)} required />

                <label>Start Time:</label>
                <input
                    type="datetime-local"
                    name="startTime"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                    required
                />

                <label>End Time:</label>
                <input
                    type="datetime-local"
                    name="endTime"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                    required
                />

                <label>Attendees (comma-separated emails):</label>
                <input
                    type="text"
                    name="attendees"
                    value={attendees}
                    onChange={(e) => setAttendees(e.target.value)}
                    required
                />

                <button type="submit" onClick={handleFormSubmit}>Schedule Meeting</button>
            </form>

            {message && <p>{message}</p>}
        </div>
    );
}

export default Gmeet
