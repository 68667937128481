import { createSlice } from "@reduxjs/toolkit";

const lectureReducer = createSlice({
    name : 'lecture',
    initialState : {
        lectures : [],
        isFetching : false,
        error : false,
    },

    reducers : {
        // Get lecture actions
        getLecturesStart : (state) => {
            state.isFetching = true;
        },

        getLecturesSuccess : (state, action) => {
            state.isFetching = false;
            state.lectures = action.payload;
        },

        getLecturesFailure : (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Delete lecture actions
        deleteLectureStart: (state) => {
            state.isFetching = true;
        },
        deleteLectureSuccess: (state, action) => {
            state.isFetching = false;
            state.lectures.splice(
                state.lectures.findIndex((item) => item._id === action.payload), 1
            );
        },
        deleteLectureFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Add lecture actions
        addLectureStart : (state) => {
            state.isFetching  = true;
        },

        addLectureSuccess : (state, action) => {
            state.isFetching = false;
            state.lectures = action.payload;
        },

        addLecturefailure : (state) => {
            state.isFetching = false;
            state.error = false;
        },

        // Update lecture actions
        updateLectureStart: (state) => {
            state.isFetching = true;
        },
        updateLectureSuccess: (state, action) => {
            state.isFetching = false;
            const lectureIndex = state.lectures.findIndex(lecture => lecture._id === action.payload._id);
            if (lectureIndex !== -1) {
                state.lectures[lectureIndex] = action.payload;
            }
        },
        updateLectureFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

    },
});

export const { getLecturesStart, getLecturesSuccess, getLecturesFailure, deleteLectureStart, deleteLectureSuccess,deleteLectureFailure, addLectureStart, addLectureSuccess, addLecturefailure, updateLectureStart, updateLectureSuccess, updateLectureFailure } = lectureReducer.actions;
export default lectureReducer.reducer