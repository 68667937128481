// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container{
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 0 20px;
    /* display: flex;
    flex-direction: column; */
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf;6BACyB;AAC7B","sourcesContent":[".main-container{\n    width: 100%;\n    position: relative;\n    overflow: hidden;\n    padding: 0 20px;\n    /* display: flex;\n    flex-direction: column; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
