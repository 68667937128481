import React, { useEffect, useState } from 'react';
import './ViewSchoolTable.css';
import { DataGrid } from '@mui/x-data-grid';
import { School, Visibility } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, getAllUsers } from '../../../../redux/apiCalls';
import SchoolTable from '../../../../components/schoolTable/SchoolTable';
import CircleLoader from '../../../../components/circleLoader/CircleLoader';

const ViewSchoolTable = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        getAllUsers(dispatch);
    }, [dispatch]);

    const isFetching = useSelector((state) => state.allUsers.isFetching);
    const users = useSelector((state) => state.allUsers.users);
    const schools = users && users.filter((item) => item.isSchoolPrincipal === true);

    const handleDelete = (studentId) => {
        deleteUser(studentId, dispatch);
        setDeletePopup(false);
    }

    const [deletePopup, setDeletePopup] = useState(false);
    const [deletingItem, setDeletingItem] = useState(null);

    const handlePopup = (id) => {
        setDeletePopup(true);
        setDeletingItem(id);
    };

    const handlePopupCancel = () => {
        setDeletePopup(false)
    }


    return (
        <SchoolTable />
    )

    // const columns = [
    //     { field: '_id', headerName: 'ID', width: 200},
    //     {
    //         field: 'schoolName',
    //         headerName: 'School',
    //         width: 200,
    //         editable: false,
    //     },
    //     {
    //         field: 'firstName',
    //         headerName: 'Principal Name',
    //         width: 150,
    //         editable: false,
    //     },
    //     {
    //         field: 'email',
    //         headerName: 'Email',
    //         description: 'This column has a value getter and is not sortable.',
    //         sortable: false,
    //         width: 200,
    //     },
    //     {
    //         field: 'phone',
    //         headerName: 'Phone',
    //         description: 'This column has a value getter and is not sortable.',
    //         sortable: false,
    //         width: 100,
    //     },
    //     {
    //         field: 'View',
    //         headerName: 'View School',
    //         // sortable: false,
    //         width: 150,
    //         renderCell: (params) => {
    //             return (
    //                 <>
    //                     <Link to={'/view-school/' + params.id}>
    //                         <button className='btn btn-primary viewSchoolBtn'>View <Visibility /> </button>
    //                     </Link>
    //                     {/* <DeleteOutline className='studentListDelete text-danger' /> */}
    //                 </>
    //             )
    //         }
    //     },
    //     {
    //         field: 'edit',
    //         headerName: 'Edit',
    //         // sortable: false,
    //         width: 100,
    //         renderCell: (params) => {
    //             return (
    //                 <>
    //                     <Link to={'/edit-school/' + params.id}>
    //                         <button className='btn btn-primary studentListEdit'>Edit</button>
    //                     </Link>
    //                     {/* <DeleteOutline className='studentListDelete text-danger' /> */}
    //                 </>
    //             )
    //         }
    //     },
    // ];
    // return (
    //     <div className='studentTableWrapper py-5'>
    //         <div className="row">
    //             <div className="col-12">
    //                 <div className="tableButtonWrapper d-flex justify-content-between mb-3">
    //                     <h2>School</h2>
    //                     <Link to='/add-school'>
    //                         <button className='custom-btn purple-bg'>New <AddCircleOutlineOutlinedIcon className='newStudentIcon' /></button>
    //                     </Link>

    //                 </div>
    //             </div>
    //         </div>
    //         <DataGrid
    //             rows={schools}
    //             getRowId={(rows) => rows._id}
    //             columns={columns}
    //             initialState={{
    //                 pagination: {
    //                     paginationModel: {
    //                         pageSize: 10,
    //                     },
    //                 },
    //             }}
    //             pageSizeOptions={[10]}
    //             checkboxSelection
    //             disableRowSelectionOnClick
    //         />
    //     </div>
    // )
}

export default ViewSchoolTable
