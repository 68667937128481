// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.token-expire-container{
    width: 100%;
    height: 100vh;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    position: fixed;
    background: #fff;
    overflow: hidden;
    z-index: 9999999999 !important;
    flex-direction: column;
}

.expired-popup{
    padding: 50px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 31px -4px #ccc;
    row-gap: 20px;
    background: #fff;
}

.expired-popup h1{
    font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/global/TokenExpirationInterceptor.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,aAAa;IACb,yBAAyB;IACzB,8BAA8B;IAC9B,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,8BAA8B;IAC9B,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,kCAAkC;IAClC,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".token-expire-container{\n    width: 100%;\n    height: 100vh;\n    display: flex;\n    /* align-items: center; */\n    justify-content: space-between;\n    position: fixed;\n    background: #fff;\n    overflow: hidden;\n    z-index: 9999999999 !important;\n    flex-direction: column;\n}\n\n.expired-popup{\n    padding: 50px;\n    display: flex;\n    flex-direction: column;\n    box-shadow: 0px 0px 31px -4px #ccc;\n    row-gap: 20px;\n    background: #fff;\n}\n\n.expired-popup h1{\n    font-size: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
