import React from 'react';
import './ViewCard.css';
import { Delete } from '@mui/icons-material';
import CircleLoader from '../circleLoader/CircleLoader';

const ViewCard = (props) => {


    return (
        <div className='container'>
            <div className="row">
                <div className="col-12">
                    <div className="courseAgeGroupWrapper">
                        <h1>{props.cardTitle}</h1>
                        <div className="newAgeGroupWrapper">
                            <div className="newAgeGroupInputWrapper">
                                <div className="row">
                                    {
                                        props.pageType === "blogCategory" ? (
                                            <>
                                                <div className="col-12 p-0 mb-3">
                                                    <input type="text" placeholder='Enter title here' value={props.value} onChange={(e) => props.setValue(e.target.value)} />
                                                    {
                                                        props.errors.category && (
                                                            <p className='error-text'>{props.errors.category}</p>
                                                        )
                                                    }
                                                </div>
                                                <div className="col-12 p-0">
                                                    <input type="file" placeholder='' onChange={(e) => props.setImgValue(e.target.files[0])} />
                                                    {
                                                        props.errors.categoryImage && (
                                                            <p className='error-text'>{props.errors.categoryImage}</p>
                                                        )
                                                    }
                                                </div>
                                                <button className="custom-btn mt-3" onClick={props.handleSubmitData}>Add</button>
                                            </>
                                        ) : (
                                            <>
                                                <div className="col-8">
                                                    <input type="text" placeholder='Enter title here' value={props.value} onChange={(e) => props.setValue(e.target.value)} />
                                                    <span className='error-text'>{props.errors.category}</span>
                                                </div>
                                                <div className="col-4">
                                                    <button className="custom-btn" onClick={props.handleSubmitData}>Add</button>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>

                            <div className="col-12 mt-3">
                                {
                                    props.success ? (
                                        <div className='alert alert-success'>
                                            {props.successMessage}
                                        </div>
                                    ) : props.error && (
                                        <div className='alert alert-danger'>
                                            {props.errorMessage}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="avilableAgeGroupWrapper">
                            <h2>Existing: </h2>

                            {
                                props.data ? (
                                    <div className="ageGroupContainer">
                                        {
                                            props.data.length > 0 ? props.data.map((item, index) => {
                                                return (
                                                    <div className="availableAgeGroup" key={index}>
                                                        <span className="groupItem">{item.category}</span>
                                                        <Delete className='ageGroupDelete' onClick={() => props.deleteAction(item._id)} />
                                                    </div>
                                                )
                                            }) : (
                                                <p>No blog category available!</p>
                                            )

                                        }
                                    </div>
                                ) : (
                                    <p>No blog category available!</p>
                                )
                            }

                        </div>

                    </div>
                </div>
            </div>

            {
                props.activeLoader && (
                    <CircleLoader title={props.loaderText} />
                )
            }
        </div>
    )
}

export default ViewCard
