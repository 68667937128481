import React, { useEffect, useState } from 'react';
import './DiscountTable.css';
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { deleteDiscounts, getAllDiscounts, getAllGiftCards } from '../../../redux/apiCalls';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Loader from '../../../global/Loader';
import { MEDIA_URL, addRowIndex } from '../../../config';
import CircleLoader from '../../../components/circleLoader/CircleLoader';



const GiftCardTable = () => {

    const { isFetching, giftCards } = useSelector((state) => state.giftCards);
    const dispatch = useDispatch();

    // const [giftCardsWithIndex, setgiftCardsWithIndex] = useState(null);


    // Add index numbers to the giftCards array
    const giftCardsWithIndex = giftCards?.map((giftCard, index) => ({
        ...giftCard,
        index: index + 1, // Add 1 to the index to start from 1 instead of 0
    }));

    // const discountsWithIndex = []


    // useEffect(() => {
    //     setDiscountsWithIndex(addRowIndex(discounts));
    // }, []);

    // const isFetching = useSelector((state) => state.discounts.isFetching);

    useEffect(() => {
        getAllGiftCards(dispatch)
    }, [dispatch]);


    const [deletePopup, setDeletePopup] = useState(false);
    const [deletingItem, setDeletingItem] = useState(null)

    const handlePopup = (couponId) => {
        setDeletePopup(true)
        setDeletingItem(couponId)
    };

    const handlePopupCancel = () => {
        setDeletePopup(false)
    };

    const deleteCoupon = (itemId) => {
        deleteDiscounts(itemId, dispatch);
        setDeletePopup(false);
    }

    const columns = [
        { field: 'index', headerName: '#', width: 50 },
        {
            field: 'giftCardImg',
            headerName: 'Image',
            width: 100,
            editable: false,
            sortable: false,
            renderCell: (params) => {
                return <img src={MEDIA_URL + params.value} className='courseTable-img' alt="gift-img" />
            }
        },
        {
            field: 'giftCard',
            headerName: 'Title',
            width: 200,
            editable: false,
        },

        {
            field: 'amount',
            headerName: 'Amount',
            description: 'This column has a value getter and is not sortable.',
            sortable: true,
            width: 100,
        },
        // {
        //     field: 'discountType',
        //     headerName: 'Type',
        //     description: 'This column has a value getter and is not sortable.',
        //     sortable: true,
        //     width: 130,
        //     cellClassName: "text-capitalize"
        //     // renderCell: (params) => {
        //     //     return params.discountTYpe ? "Percentage" : "Fixed"
        //     // }
        // },
        // {
        //     field: 'minOrder',
        //     headerName: 'Minimum Order',
        //     // description: 'This column has a value getter and is not sortable.',
        //     sortable: true,
        //     width: 150,
        // },
        // {
        //     field: 'validFrom',
        //     headerName: 'Valid From',
        //     description: 'This column has a value getter and is not sortable.',
        //     sortable: true,
        //     width: 130,
        // },
        // {
        //     field: 'validTill',
        //     headerName: 'Valid Till',
        //     description: 'This column has a value getter and is not sortable.',
        //     sortable: true,
        //     width: 130,
        // },


        {
            field: 'isActive',
            headerName: 'Status',
            // description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 100,
            renderCell: (params) => {
                return params.row.isActive ? "Active" : "Inactive";
            }
        },
        {
            field: 'edit',
            headerName: 'Edit',
            sortable: false,
            width: 100,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={'/edit-gift-card/' + params.id}>
                            <button className='btn btn-primary studentListEdit'>Edit</button>
                        </Link>
                    </>
                )
            }
        },
        {
            field: 'delete',
            headerName: 'Delete',
            sortable: false,
            width: 100,
            renderCell: (params) => {
                const couponId = params.row._id;
                return (
                    <>
                        <DeleteOutline onClick={() => handlePopup(couponId)} className='courseListDelete text-danger' />
                    </>
                )
            }
        },
    ];

    if (isFetching) {
        return (
            <div className='loader-wrapper'>
                <CircleLoader />
            </div>
        )
    }

    return (
        <div className='py-5'>
            <div className="row">
                <div className="col-12">
                    <div className="tableButtonWrapper d-flex justify-content-between mb-3">
                        <h2>Gift Cards</h2>
                        <Link to='/create-gift-card'>
                            <button className='custom-btn purple-bg'>Create Gift Card <AddCircleOutlineOutlinedIcon className='newStudentIcon' /></button>
                        </Link>

                    </div>
                </div>
            </div>
            {
                deletePopup && (
                    <div className='deletePopupAlertWrapper' id='deletePopupAlertWrapper'>
                        <div className="deletePopupAlert">
                            <WarningAmberIcon style={{ color: "var(--orange)", fontSize: "40px" }} />
                            <h4>Warning</h4>
                            <div className="deleteAlertTextWrapper text-center">
                                <p>This coupon will be deleted permanently.</p>
                                <p>Are you sure?</p>
                            </div>

                            <div className="deleteBtnsWrapper">
                                <button className='btn' onClick={handlePopupCancel} style={{ background: "var(--green)", color: "var(--white)" }}>Cancel</button>
                                <button className='btn' onClick={() => deleteCoupon(deletingItem)} style={{ background: "var(--red)", color: "var(--white)" }}>Delete</button>
                            </div>
                        </div>
                    </div>
                )
            }

            {
                giftCards && (
                    <DataGrid
                        rows={giftCardsWithIndex}
                        getRowId={(rows) => rows._id}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[10]}
                        checkboxSelection={false}
                        disableRowSelectionOnClick
                    />
                )
            }

        </div>
    )
}

export default GiftCardTable
