import React, { useEffect, useState } from 'react';
import './FranchiseTable.css';
import { DataGrid } from '@mui/x-data-grid';
import { Visibility } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, getAllUsers } from '../../../../redux/apiCalls';
import CircleLoader from '../../../../components/circleLoader/CircleLoader';

const FranchiseTable = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        getAllUsers(dispatch);
    }, [dispatch]);

    const isFetching = useSelector((state) => state.allUsers.isFetching);
    const users = useSelector((state) => state.allUsers.users);
    const franchises = users && users.filter((item) => item.isFranchiseOwner === true);

    // Add index numbers to the franchises array
    const franchisesWithIndex = franchises?.map((franchise, index) => ({
        ...franchise,
        index: index + 1, // Add 1 to the index to start from 1 instead of 0
    }));

    const handleDelete = (studentId) => {
        deleteUser(studentId, dispatch);
        setDeletePopup(false);
    }

    const [deletePopup, setDeletePopup] = useState(false);
    const [deletingItem, setDeletingItem] = useState(null);

    const handlePopup = (id) => {
        setDeletePopup(true);
        setDeletingItem(id);
    };

    const handlePopupCancel = () => {
        setDeletePopup(false)
    }

    const columns = [
        { field: 'index', headerName: '#', width: 50 },
        // {
        //     field: 'schoolName',
        //     headerName: 'School',
        //     width: 200,
        //     editable: false,
        // },
        {
            field: 'firstName',
            headerName: 'Name',
            width: 150,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email',
            // description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 200,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            // description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 150,
        },
        {
            field: 'View',
            headerName: 'View Franchise',
            sortable: false,
            width: 150,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={'/view-franchise/' + params.id}>
                            <button className='btn btn-primary viewSchoolBtn'>View <Visibility /> </button>
                        </Link>
                        {/* <DeleteOutline className='studentListDelete text-danger' /> */}
                    </>
                )
            }
        },
        {
            field: 'edit',
            headerName: 'Edit',
            sortable: false,
            width: 100,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={'/edit-franchise/' + params.id}>
                            <button className='btn btn-primary studentListEdit'>Edit</button>
                        </Link>
                        {/* <DeleteOutline className='studentListDelete text-danger' /> */}
                    </>
                )
            }
        },
    ];

    if (isFetching) {
        return (
            <CircleLoader />
        )
    }

    return (
        <div className='studentTableWrapper py-5'>
            <div className="row">
                <div className="col-12">
                    <div className="tableButtonWrapper d-flex justify-content-between mb-3">
                        <h2>Franchise List</h2>
                        <Link to='/add-franchise'>
                            <button className='custom-btn purple-bg'>New Franchise <AddCircleOutlineOutlinedIcon className='newStudentIcon' /></button>
                        </Link>

                    </div>
                </div>
            </div>
            {
                franchises && (
                    <DataGrid
                        rows={franchisesWithIndex}
                        getRowId={(rows) => rows._id}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[10]}
                        disableRowSelectionOnClick
                    />
                )
            }

        </div>
    )
}

export default FranchiseTable
