import React, { useState } from 'react';
import './NewTestimonial.css';
import { fileUploadRequest, userRequest } from '../../../requestMethod';
import { useDispatch, useSelector } from 'react-redux';
import { validateRequired } from '../../../formValidation';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { addTestimonialFailure, addTestimonialStart, addTestimonialSuccess } from '../../../redux/testimonialRedux';
import CircleLoader from '../../../components/circleLoader/CircleLoader';

const NewTestimonial = () => {

    const blogCategories = useSelector((state) => state.blogCategory.blogCategory);

    const [parentName, setParentName] = useState('');
    const [description, setDescription] = useState('');
    const [childName, setChildName] = useState('');
    const [category, setCategory] = useState('');
    const [status, setStatus] = useState(false);
    const [parentNameError, setParentNameError] = useState(false);
    const [parentNameErrorMessage, setParentNameErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [testimonialImage, setTestimonialImage] = useState(null);
    const [activeLoader, setActiveLoader] = useState(false);
    const [loaderText, setLoaderText] = useState("");
    // const { isFetching, products } = useSelector((state) => state.products)

    const handleDescriptionChange = (value) => {
        setDescription(value);
    };

    const dispatch = useDispatch();

    const addBlog = async (dispatch, testimonial) => {
        dispatch(addTestimonialStart());
        setActiveLoader(true);
        setLoaderText("uploading details");
        try {
            const res = await userRequest.post('/testimonial/create', testimonial);
            if (res.status === 200) {
                setActiveLoader(false);
                setLoaderText("");
                setSuccess(true);
                setSuccessMessage("Testimonial has been added!")
                setParentName('');
                setDescription('');
                setChildName('');
                setStatus(false);
                setTestimonialImage(null);
            }
            dispatch(addTestimonialSuccess(res.data));
        } catch (error) {
            dispatch(addTestimonialFailure());
            setActiveLoader(false);
            setLoaderText("");
            if (error.response.status === 409) {
                setParentNameError(true);
                setParentNameErrorMessage(error.response.data);
            }

            else {
                setError(true);
                setErrorMessage("Something went wrong!");
            }
        }
    };


    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const parentNameErr = validateRequired(parentName);
        const descErr = validateRequired(description);
        const childNameErr = validateRequired(childName);
        const testimonialImageErr = validateRequired(testimonialImage);
        const categoryErr = validateRequired(category);

        if (parentNameErr) {
            formIsValid = false;
            newErrors.parentName = parentNameErr;
        }
        if (descErr) {
            formIsValid = false;
            newErrors.desc = descErr;
        }
        if (childNameErr) {
            formIsValid = false;
            newErrors.childName = childNameErr;
        }

        if (testimonialImageErr) {
            formIsValid = false;
            newErrors.testimonialImage = testimonialImageErr;
        }

        if (categoryErr) {
            formIsValid = false;
            newErrors.category = categoryErr;
        }

        setErrors(newErrors);
        return formIsValid;
    }

    const uploadFileToSpace = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        setActiveLoader(true)
        setLoaderText("Uploading Image");
        try {
            const response = await fileUploadRequest.post('/upload/file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response && response.status === 200) {
                setActiveLoader(false)
                setLoaderText("");
                return response.data.fileUrl;
            }
            // You can now use the file URL as needed, e.g., storing it in your database.
        } catch (error) {
            setActiveLoader(false)
            setLoaderText("");
            console.error('Error uploading file:', error);
        }
    };

    const handleAdd = async () => {
        setSuccess(false);
        setError(false);
        setParentNameError(false);
        const trimmedTitle = parentName.trim();
        const testimonialImgUrl = testimonialImage &&  await uploadFileToSpace(testimonialImage);
        if (validateForm()) {
            addBlog(dispatch, { parentName: trimmedTitle, description, childName, category, status, testimonialImg : testimonialImgUrl });
        }
    }

    return (
        <div className='container'>
            <div className="row mt-5 mb-3">
                <div className="col-12">
                    <div className="pageTitleWrapper">
                        <h2 className="page-heading">Add new testimonial</h2>
                    </div >
                </div >
            </div >
            <div className="row">
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Parent/Trainer Name</label>
                        <input type="text" name='parentName' value={parentName} className='newUserInput' onChange={(e) => setParentName(e.target.value)} placeholder='Type here...' required />
                        {
                            parentNameError && (
                                <p className='error-text text-danger'>{parentNameErrorMessage}</p>
                            )
                        }
                        <span className='error-text'>{errors.parentName}</span>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Child Name/ Trainer Designation</label>
                        <input type="text" name='parentName' value={childName} className='newUserInput' onChange={(e) => setChildName(e.target.value)} placeholder='Type here...' required />
                        {
                            parentNameError && (
                                <p className='error-text text-danger'>{parentNameErrorMessage}</p>
                            )
                        }
                        <span className='error-text'>{errors.parentName}</span>
                    </div>
                </div>
                {/* <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Category</label>
                        <select name="blogCategories" value={childName} onChange={(e) => setChildName(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            {
                                blogCategories ? (
                                    blogCategories.map((item, index) => {
                                        return <option value={item.childName} key={index} >{item.childName}</option>
                                    })
                                ) : (
                                    <option value="" selected disabled>Not Available</option>
                                )
                            }
                        </select>
                        <span className='error-text'>{errors.childName}</span>

                    </div>
                </div> */}
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Image</label>
                        <input type="file" name='file' className='newUserInput' onChange={(e) => setTestimonialImage(e.target.files[0])} placeholder='Image' />
                        <span className='error-text'>{errors.testimonialImage}</span>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Status</label>
                        <select name="status" className='newUserInput' value={status} onChange={(e) => setStatus(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value="true" >Active</option>
                            <option value="false" >Draft</option>
                        </select>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Category</label>
                        <select name="category" className='newUserInput' value={category} onChange={(e) => setCategory(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value="parent" >Parent</option>
                            <option value="trainer" >Trainer</option>
                        </select>
                        <span className='error-text'>{errors.category}</span>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>description</label>
                        <textarea type="text" rows={10} name='description' value={description} className='newUserInput' onChange={(e) => setDescription(e.target.value)} placeholder='Type here...' />
                        {/* <ReactQuill style={{ height: '200px' }} value={description} onChange={handleDescriptionChange} /> */}

                        <span className='error-text'>{errors.desc}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="newStudentSubmitbtn">
                        <button className="custom-btn purple-bg" onClick={handleAdd}>create</button>
                    </div>
                </div>

                <div className="col-12 mt-3">
                    {
                        success ? (
                            <div className='alert alert-success'>
                                {successMessage}
                            </div>
                        ) : error && (
                            <div className='alert alert-danger'>
                                {errorMessage}
                            </div>
                        )
                    }
                </div>

                {
                    activeLoader && (
                        <CircleLoader title={loaderText} />
                    )
                }
            </div>
        </div>
    )
}

export default NewTestimonial
