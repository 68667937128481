import React from 'react';
import './CircleLoader.css';

const CircleLoader = (props) => {
    return (
        <div className="circle-loader-wrapper">
            <div className="loader text-white text-capitalize text-center">{props.title ? props.title : "Loading"}</div>
        </div>
    )
}

export default CircleLoader
