import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'; // Assuming you're using Redux for state management
import { logoutUser } from '../redux/userRedux';
import { userRequest } from '../requestMethod';
import './TokenExpirationInterceptor.css'
import { Link } from 'react-router-dom';
import { getCurrentYear } from '../config';

const TokenExpirationInterceptor = () => {
  const dispatch = useDispatch();
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  useEffect(() => {
    // const interceptor = axios.interceptors.response.use(
    //   response => response,
    //   error => {
    //     if (error.response && error.response.status === 403) {
    //       // Logout if the API response indicates that the token is not valid
    //       dispatch(logoutUser());
    //     }
    //     return Promise.reject(error);
    //   }
    // );

    // return () => {
    //   // Clean up the interceptor when component unmounts
    //   axios.interceptors.response.eject(interceptor);
    // };

    // Add a response interceptor
    userRequest.interceptors.response.use(
      (response) => {
        //setLocalStorageToken(token);
        return response;
      },
      (error) => {
        if (error.response.status === 403 || error.response.status === 401 ) {
          dispatch(logoutUser())
          setIsPopupVisible(true);
          //(`unauthorized :)`);
          //localStorage.removeItem("persist:root");
          //removeLocalStorageToken
          //window.location.href = "/login";
        }
        return Promise.reject(error);
      }
    );

  }, [dispatch]);

  if (isPopupVisible) {
    return (
      <>
        {/* <div className='container-fluid p-0'>
          <div className="row">
            <div className="col-12">
              
                <div className="expired-popup">
                  <h1>Your authentication token is has expired.</h1>
                  <Link to={'/'}>
                  <button className="custom-btn" onClick={() => setIsPopupVisible(false)}>Login</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className='w-100'>
          <div className="container-fluid px-0">
            <div className='token-expire-container'>
              <div className="row m-0">
                <div className="col-12 p-0">
                  <div className="login-topbar">
                    <div className="loginPageLogoWrapper">
                      <img src={`${process.env.PUBLIC_URL}/images/logo/brand-logo.svg`} alt="" />
                    </div>
                  </div>

                </div>
              </div>
              <div className="row d-flex align-items-center">
                <div className="col-md-6">
                  <img src={`${process.env.PUBLIC_URL}/images/pages/token-expired.jpg`} />
                </div>
                <div className="col-md-6">
                  <div className="loginContainer">
                    <div className="login-wrapper">
                      <h3 className="text-uppercase mt-5 expired-text">
                        Your session has expired
                      </h3>
                      <h3 className="text-uppercase">
                        Kinldy login again to continue
                      </h3>
                      <button className="custom-btn" onClick={() => setIsPopupVisible(false)}>Login</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="login-announcement-bar p-2">
                <p>&copy; {getCurrentYear() && '2023'}. All Rights Reserved || Meritus</p>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return null


};

export default TokenExpirationInterceptor;
