
import { createSlice } from "@reduxjs/toolkit";

const eventReducer = createSlice({
    name: 'event',
    initialState: {
        events: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get All Event
        getEventStart: (state) => {
            state.isFetching = true;
        },
        getEventSuccess: (state, action) => {
            state.isFetching = false;
            state.events = action.payload;
        },
        getEventFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Delete Event                         
        deleteEventStart: (state) => {
            state.isFetching = true;
        },
        deleteEventSuccess: (state, action) => {
            state.isFetching = false;
            state.events.splice(
                state.events.findIndex((item) => item._id === action.payload), 1
            );
        },
        deleteEventFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Update Event
        updateEventStart: (state) => {
            state.isFetching = true;
        },
        updateEventSuccess: (state, action) => {
            state.isFetching = false;
            const eventIndex = state.events.findIndex(event => event._id === action.payload._id);

            if (eventIndex !== -1) {
                state.events[eventIndex] = action.payload;
            }
        },
        updateEventFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        //  Add Event
        addEventStart: (state) => {
            state.isFetching = true;
        },
        addEventSuccess: (state, action) => {
            state.isFetching = false;
            const newData = action.payload;
            state.events = [...state.events, newData];
        },
        addEventFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const { getEventStart, getEventSuccess, getEventFailure, deleteEventStart, deleteEventSuccess, deleteEventFailure, updateEventStart, updateEventSuccess, updateEventFailure, addEventStart, addEventSuccess, addEventFailure } = eventReducer.actions;
export default eventReducer.reducer;