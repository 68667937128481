import React, { useState } from 'react';
import './EditFranchise.css';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserFailure, updateUserStart, updateUserSuccess } from '../../../../redux/registeredUserRedux';
import { userRequest } from '../../../../requestMethod';
import { validateRequired } from '../../../../formValidation';
import { CalendarMonthOutlined, EmailOutlined, PermIdentity, PhoneOutlined, Publish } from '@mui/icons-material';

const EditFranchise = () => {


    const location = useLocation();
    const franchiseId = location.pathname.split('/')[2];
    const isFetching = useSelector((state) => state.allUsers);
    const franchise = useSelector((state) => state.allUsers.users.find((item) => item._id === franchiseId));


    const [firstName, setFirstName] = useState(franchise.firstName);
    const [lastName, setLastName] = useState(franchise.lastName);
    const [email, setEmail] = useState(franchise.email);
    const [phone, setPhone] = useState(franchise.phone);
    const [dateOfBirth, setDateOfBirth] = useState(franchise.dateOfBirth);
    // const [schoolName, setSchoolName] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const dispatch = useDispatch();

    // update action for school
    const updateUser = async (dispatch, franchiseId, franchise) => {
        dispatch(updateUserStart());
        try {
            const res = await userRequest.put(`/user/${franchiseId}`, franchise);
            if (res.status === 200) {
                setError(false);
                setSuccess(true);
                setSuccessMessage("User has been updated!");
            }
            dispatch(updateUserSuccess(res.data));

        } catch (error) {
            dispatch(updateUserFailure());
            if (error.response.status === 409) {
                setEmailError(true);
                setEmailErrorMessage(error.response.data);
            }

            else {
                setSuccess(false);
                setError(true);
                setErrorMessage("Something went wrong!");
            }
        }
    };

    // Validate function for form validation start=======
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const firstNameValidErr = validateRequired(firstName);
        const lastNameValidErr = validateRequired(lastName);
        const emailValidErr = validateRequired(email);
        const phoneValidErr = validateRequired(phone);
        const dobValidErr = validateRequired(dateOfBirth);

        if (firstNameValidErr) {
            formIsValid = false;
            newErrors.firstName = firstNameValidErr;
        }
        if (lastNameValidErr) {
            formIsValid = false;
            newErrors.lastName = lastNameValidErr;
        }
        if (emailValidErr) {
            formIsValid = false;
            newErrors.email = emailValidErr;
        }
        if (phoneValidErr) {
            formIsValid = false;
            newErrors.phone = phoneValidErr;
        }

        if (dobValidErr) {
            formIsValid = false;
            newErrors.dateOfBirth = dobValidErr;
        }

        setErrors(newErrors);
        return formIsValid;
    }
    // Validate function for form validation end======

    const handleUpdate = () => {
        setSuccess(false);
        setError(false);

        if (validateForm()) {
            updateUser(dispatch, franchiseId, { firstName, lastName, email, dateOfBirth, phone })
        }
    }



    return (
        <>
            <div className='container'>
                <div className='row mt-100'>
                    <div className="col-12">
                        <h2 className='page-heading mb-4 pl-4'>Update Franchise</h2>
                    </div>
                    <div className="col-lg-4">
                        <div className="userShowWrapper">
                            <div className="userShowTop">
                                <div className="userShowImgWrapper">
                                    <img src={`${process.env.PUBLIC_URL}/Images/profile.jpg`} alt="userImg" />
                                </div>
                                <div className="userShowTopTitle">
                                    <div className="userShowUserName text-capitalize">{franchise.firstName + " " + franchise.lastName}</div>
                                    <div className="userShowUserDesignation">Principal</div>
                                </div>
                            </div>
                            <div className="userShowBottom">
                                <span className="userShowBottomTitle">Account Details</span>
                                <div className="userShowBottomInfo">
                                    <PermIdentity className='userShowBottomInfoIcon' />
                                    <span className="userShowBottominfoTitle">{franchise.isSchoolPrincipal ? "School Principal" : "N.A."}</span>
                                </div>
                                <div className="userShowBottomInfo">
                                <CalendarMonthOutlined className='userShowBottomInfoIcon' />
                                    <span className="userShowBottominfoTitle">{franchise.dateOfBirth}</span>
                                </div>
                                <span className="userShowBottomTitle">Contact Details</span>
                                <div className="userShowBottomInfo">
                                    <PhoneOutlined className='userShowBottomInfoIcon' />
                                    <span className="userShowBottominfoTitle">{franchise.phone}</span>
                                </div>
                                <div className="userShowBottomInfo">
                                    <EmailOutlined className='userShowBottomInfoIcon' />
                                    <span className="userShowBottominfoTitle">{franchise.email}</span>
                                </div>
                                {/* <div className="userShowBottomInfo">
                  <LocationOnOutlined className='userShowBottomInfoIcon' />
                  <span className="userShowBottominfoTitle">2607 S Rochester Rd United States</span>
                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8">
                        <div className="userUpdateWrapper">
                            <h2 className='userUpdateTitle'>Edit</h2>
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="userUpdateLeft">
                                        <div className="userUpdateItem">
                                            <label>First Name</label>
                                            <input type="text" placeholder='e.g. john' className='userUpdateInput' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                            <span className='error-text'>{errors.firstName}</span>
                                        </div>
                                        <div className="userUpdateItem">
                                            <label>Last Name</label>
                                            <input type="text" placeholder='smith' className='userUpdateInput' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                            <span className='error-text'>{errors.lastName}</span>
                                        </div>
                                        <div className="userUpdateItem">
                                            <label>Email</label>
                                            <input type="text" placeholder='user@meritus.com' className='userUpdateInput' value={email} onChange={(e) => setEmail(e.target.value)} />
                                            <span className='error-text'>{errors.email}</span>
                                            {
                                                emailError && (
                                                    <p className='error-text text-danger'>{emailErrorMessage}</p>
                                                )
                                            }
                                        </div>

                                        <div className="userUpdateItem">
                                            <label>Date of Birth</label>
                                            <input type="date" className='userUpdateInput' value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} />
                                            <span className='error-text'>{errors.dateOfBirth}</span>
                                        </div>
                                        <div className="userUpdateItem">
                                            <label>Phone</label>
                                            <input type="text" placeholder='9876543212' className='userUpdateInput' value={phone} onChange={(e) => setPhone(e.target.value)} />
                                            <span className='error-text'>{errors.phone}</span>
                                        </div>
                                        {/* <div className="userUpdateItem">
                      <label>Date of Birth</label>
                      <input type="date" className='userUpdateInput' value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} />
                      <span className='error-text'>{errors.dob}</span>
                    </div> */}
                                        <div className="col-12 mt-3">
                                            {
                                                success ? (
                                                    <div className='alert alert-success'>
                                                        {successMessage}
                                                    </div>
                                                ) : error && (
                                                    <div className='alert alert-danger'>
                                                        {errorMessage}
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-5">
                                    <div className="userUpdateRight">
                                        <div className="userUpdateUpload">
                                            <div className="userUploadImgWrapper">
                                                <img src={`${process.env.PUBLIC_URL}/images/profile.jpg`} alt="" />
                                            </div>
                                            <label htmlFor="file"><Publish /></label>
                                            <input type="file" id='file' style={{ display: 'none' }} />
                                        </div>

                                        <div className="userUpdateButtonWrapper">
                                            <button className='btn btn-primary' onClick={handleUpdate}>Update</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditFranchise
