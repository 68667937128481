import React from 'react'
import NewSchool from '../../../../../components/newSchool/NewSchool'
import { useLocation } from 'react-router-dom'

const NewFranchiseSchool = () => {

    const location = useLocation();

    const franchiseId = location.pathname.split('/')[2]

    return (
        <NewSchool franchiseId = {franchiseId} isFranchise = {true} />
    )
}

export default NewFranchiseSchool
