// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.csv-box-wrapper{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #0000004a;
    display: flex;
    justify-content: center;
    align-items: center;
}

.csv-box-container{
    /* padding: 20px; */
    width: 40%;
    height: 250px;
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
}

.cvs-box-header{
    background: #cccccc80;
    padding: 20px;
}

.cvs-box-bottom{
    padding: 10px;
    border-top: 1px solid #0000001f;
}

.cvs-box-middle{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cvs-box-middle button{
    padding: 5px 15px;
}

.csv-box-selected-file{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/users/Users.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,qBAAqB;IACrB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,UAAU;IACV,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,+BAA+B;AACnC;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":[".csv-box-wrapper{\n    width: 100%;\n    height: 100%;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background: #0000004a;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.csv-box-container{\n    /* padding: 20px; */\n    width: 40%;\n    height: 250px;\n    display: flex;\n    flex-direction: column;\n    background: #fff;\n    border-radius: 10px;\n    overflow: hidden;\n}\n\n.cvs-box-header{\n    background: #cccccc80;\n    padding: 20px;\n}\n\n.cvs-box-bottom{\n    padding: 10px;\n    border-top: 1px solid #0000001f;\n}\n\n.cvs-box-middle{\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.cvs-box-middle button{\n    padding: 5px 15px;\n}\n\n.csv-box-selected-file{\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    row-gap: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
