import React, { useEffect, useRef, useState } from 'react'
import './Topbar.css'
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import NotificationsIcon from '@mui/icons-material/Notifications';
import GridViewIcon from '@mui/icons-material/GridView';
import SearchIcon from '@mui/icons-material/Search';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../redux/userRedux';
import { Link, Navigate } from 'react-router-dom';
import { persistor } from '../redux/store';
import SellIcon from '@mui/icons-material/Sell';
import io from 'socket.io-client';
import { formatRelativeTime } from '../config';
import Cookies from 'js-cookie';


const Topbar = (props) => {

    const dispatch = useDispatch();

    const { isFetching, currentUser } = useSelector((state) => state.user);
    if (currentUser) {
    }

    const [activeProfileCard, setActiveProfileCard] = useState(false);
    const [activeNotificationCard, setActiveNotificationCard] = useState(false);

    // useEffect(() => {
    //     if (currentUser) {

    //     }
    // })

    const handleProfileCard = () => {
        setActiveProfileCard(!activeProfileCard);
    };

    const handleLogout = () => {
        localStorage.removeItem('persist:root');
        Cookies.remove('userIn');
        persistor.purge().then(() => {
            dispatch(logoutUser());
            <Navigate to='/' />
        });

    }

    let menuRef = useRef();

    useEffect(() => {
        let handler = (e) => {
            if (menuRef.current) {
                if (!menuRef.current.contains(e.target)) {
                    setActiveNotificationCard(false);
                }
            }
        };
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler)
        }
    }, [menuRef]);


    // if (isFetching) {
    //     return <h2>logging you out</h2>
    // }

    // const [notifications, setNotifications] = useState([]);

    // useEffect(() => {
    //     const socket = io('http://localhost:3005');


    //     // socket.on('connect', () => {
    //     // });

    //     // socket.on('disconnect', () => {
    //     // });

    //     // socket.on('newOrder', (data) => {
    //     //     // Add the new order notification to the list
    //     //     setNotifications((prevNotifications) => [
    //     //         { orderId: data.orderId, timestamp: new Date() },
    //     //         ...prevNotifications,
    //     //     ]);
    //     // });

    //     // return () => {
    //     //     socket.disconnect();
    //     // };
    // }, []);


    return (
        <>
            <div className='container-fluid p-0' style={{ height: "30px", marginBottom: "30px" }}>
                <div className='nav-wrapper d-flex align-items-center justify-content-end'>
                    {/* <div className='search-bar-container'>
                        <SearchIcon style={{ color: '#00000057' }} />
                        <input className='nav-search-bar' type="text" placeholder='Search here...' name="searchBar" />
                    </div> */}

                    <ul ref={menuRef}>
                        {/* <li className='navbar-item'><WbSunnyIcon /></li> */}
                        <li className='navbar-item position-relative' onClick={() => setActiveNotificationCard(!activeNotificationCard)}><NotificationsIcon />
                            {
                                props.orderNotification.length > 0 && (
                                    <span className="notification-count">{props.orderNotification.length}</span>
                                )
                            }

                            {
                                activeNotificationCard && (
                                    <div className="notification-container">
                                        <ul className="notification-ul">
                                            {
                                                props.orderNotification.length > 0 ? (
                                                    props.orderNotification && props.orderNotification.map((notification) => (
                                                        <Link className='notification-link' to={`/view-order/${notification.orderId}`}>
                                                            <li className="notification-item" key={notification.orderId}>
                                                                {/* {`${notification.userId} placed an order!`} */}
                                                                <span className="bag-icon-wrapper">
                                                                    <SellIcon className="bag-icon" />
                                                                </span>
                                                                <div className="notification-detail">
                                                                    <p> <strong>Moin Khan</strong> placed an order.</p>
                                                                    <p className='text-capitalize text-muted'>{formatRelativeTime(notification.orderTime)}</p>
                                                                </div>
                                                            </li>
                                                        </Link>
                                                    ))
                                                ) : (
                                                    <li className="notification-item text-center">
                                                        No notification available
                                                    </li>
                                                )
                                            }
                                            {/* <li className="notification-item">
                                                <span className="bag-icon-wrapper">
                                                    <SellIcon className="bag-icon" />
                                                </span>
                                                <div className="notification-detail">
                                                    <p> <strong>Moin Khan</strong> placed an order.</p>
                                                    <p className='text-capitalize text-muted'>just now</p>
                                                </div>
                                            </li>
                                            <li className="notification-item">
                                                <span className="bag-icon-wrapper">
                                                    <SellIcon className="bag-icon" />
                                                </span>
                                                <div className="notification-detail">
                                                    <p> <strong>Aayushi</strong> placed an order.</p>
                                                    <p className='text-capitalize text-muted'>1h ago</p>
                                                </div>
                                            </li>
                                            <li className="notification-item">
                                                <span className="bag-icon-wrapper">
                                                    <SellIcon className="bag-icon" />
                                                </span>
                                                <div className="notification-detail">
                                                    <p> <strong>John</strong> placed an order.</p>
                                                    <p className='text-capitalize text-muted'>yesterday</p>
                                                </div>
                                            </li> */}
                                        </ul>
                                    </div>
                                )
                            }

                        </li>
                        <li className='navbar-item' onClick={handleProfileCard}><GridViewIcon />
                        </li>
                    </ul>
                    {

                        activeProfileCard && (
                            <div className="profileCardWrapper">
                                <ul>
                                    <li className='profileCardItem profileCardHead'>
                                        {
                                            currentUser && (
                                                <>
                                                    <div className="prfoileCardImgWrapper">
                                                        <img src={`${process.env.PUBLIC_URL}/images/profile.png`} alt="profileImg" />
                                                    </div>
                                                    <div className="profileTitle">
                                                        <p className="profileName text-capitalize">{currentUser.firstName + " " + currentUser.lastName}</p>
                                                        <p className="profileEmail">{currentUser.email}</p>
                                                    </div>
                                                </>
                                            )
                                        }

                                    </li>
                                    {/* <Link className='profileCardLinkItem' to='/update-profile'>
                                        <li className='profileCardItem'>Update Profile</li>
                                    </Link> */}

                                    <Link className='profileCardLinkItem' to='/update-password'>
                                        <li className='profileCardItem'>Change Password</li>
                                    </Link>

                                    <Link className='profileCardLinkItem'>
                                        <li className='profileCardItem' onClick={handleLogout}>Logout <LogoutIcon className='logoutIcon' /></li>
                                    </Link>


                                </ul>
                            </div>
                        )
                    }


                </div>
            </div>
        </>

    )
}

export default Topbar
