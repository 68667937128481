
import { createSlice } from "@reduxjs/toolkit";

const customSectionReducer = createSlice({
    name: 'customSection',
    initialState: {
        sections: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get All section
        getSectionStart: (state) => {
            state.isFetching = true;
        },
        getSectionSuccess: (state, action) => {
            state.isFetching = false;
            state.sections = action.payload;
        },
        getSectionFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Delete Section                         
        deleteSectionStart: (state) => {
            state.isFetching = true;
        },
        deleteSectionSuccess: (state, action) => {
            state.isFetching = false;
            state.sections.splice(
                state.sections.findIndex((item) => item._id === action.payload), 1
            );
        },
        deleteSectionFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Update Section
        updateSectionStart: (state) => {
            state.isFetching = true;
        },
        updateSectionSuccess: (state, action) => {
            state.isFetching = false;
            const sectionIndex = state.sections.findIndex(section => section._id === action.payload._id);

            if (sectionIndex !== -1) {
                state.sections[sectionIndex] = action.payload;
            }
        },
        updateSectionFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        //  Add Section
        addSectionStart: (state) => {
            state.isFetching = true;
        },
        addSectionSuccess: (state, action) => {
            state.isFetching = false;
            const newData = action.payload;
            state.sections = [...state.sections, newData];
        },
        addSectionFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const { getSectionStart, getSectionSuccess, getSectionFailure, deleteSectionStart, deleteSectionSuccess, deleteSectionFailure, updateSectionStart, updateSectionSuccess, updateSectionFailure, addSectionStart, addSectionSuccess, addSectionFailure } = customSectionReducer.actions;
export default customSectionReducer.reducer;