import CryptoJS from 'crypto-js';
import { fileUploadRequest } from './requestMethod';

export const IMG_URL = 'http://localhost:3003/uploads/';
export const MEDIA_URL = 'https://meritus-media.blr1.digitaloceanspaces.com/course-files/';
// export const MEDIA_URL = 'https://meritus-media.blr1.digitaloceanspaces.com/deleting-files/';


// Helper function to format time as "just now," "x min ago," "x hours ago," etc.
export const formatRelativeTime = (timestamp) => {
  const now = new Date();
  const diff = now.getTime() - new Date(timestamp).getTime();
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} day${days !== 1 ? 's' : ''} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
  } else {
    return 'just now';
  }
};

// Helper function to add row index in tables
export const addRowIndex = (array) => {
  if (array && array.length > 0) {
    return array.map((item, index) => ({
      ...item,
      rowIndex: index + 1,
    }));
  }
};

// Helper function to convert amount in million form
export function formatNumberInThousandsAndMillions(number) {
  if (number && number >= 1000000) {
    const millions = (number / 1000000).toFixed(1);
    return `${millions} M`;
  } else if (number && number >= 1000) {
    const thousands = (number / 1000).toFixed(1);
    return `${thousands} K`;
  } else if (number) {
    return number.toString();
  }
  else {
    return 0
  }
};

// Get current year
export function getCurrentYear() {
  const currentDate = new Date();
  return currentDate.getFullYear();
}


// Extract file name from URL
export const extractFileName = (fileURL) => {
  if (fileURL) {
    const url = new URL(fileURL);
    // Extract the filename
    const filename = url.pathname.split('/').pop();
    return filename
  }
  else {
    return null;
  }
}

// Function to check value is URL or not
export const isUrl = (str) => {
  // Regular expression to check if the string is a URL
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  // Test the string against the URL regex
  return urlRegex.test(str);
}



// Function to convert month number to month name
export const convertMonthNumberToName = (monthNumber) => {
  const months = [
      "Jan", "Feb", "Mar", "Apr",
      "May", "Jun", "Jul", "Aug",
      "Sep", "Oct", "Nov", "Dec"
  ];

  // Ensure the monthNumber is a valid index
  if (monthNumber >= 1 && monthNumber <= 12) {
      return months[monthNumber - 1];
  } else {
      return "Invalid Month";
  }
}

// const USER_ID_SEC_KEY = "fgchjkkhgfc456785fgvhbjn"

// const secretKey = process.env.REACT_APP_USER_ID_SEC_KEY;

 // Encryption function
 export const encryptUniqueId = (uniqueId) => {
  if(uniqueId){
    const encrypted = CryptoJS.AES.encrypt(uniqueId.toString(), process.env.REACT_APP_UNIQUE_ID_SEC_KEY).toString();
    const urlEncoded = encodeURIComponent(encrypted);
    return urlEncoded;
  }

  return null
};


export const uploadProductImageToSpace = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
      const response = await fileUploadRequest.post('/upload/file', formData, {
          headers: {
              'Content-Type': 'multipart/form-data',
          },
      });

      if (response && response.status === 200) {
          const fileUrl = response.data.fileUrl;
          // setProductImage(fileUrl); // Set the product image URL in state
          return fileUrl
      }
  } catch (error) {
      console.error('Error uploading product image:', error);
  }
};


export const allowedImageFormats = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];


export const changeDateFormat = (date) => {
  const fetchedDate = new Date(date);
  const formattedDate = fetchedDate.toLocaleDateString();
  return formattedDate;
}