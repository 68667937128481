import { createSlice } from "@reduxjs/toolkit";

const ageGroupReducer = createSlice({
    name : 'ageGroup',
    initialState : {
        ageGroups : [],
        isFetching : false,
        error : false,
    },

    reducers : {
        // action for add age groups
        addAgeGroupStart : (state) => {
            state.isFetching = true;
        },

        addAgeGroupSuccess : (state, action) => {
            state.isFetching = false;
            const newData = action.payload;
            state.ageGroups = [...state.ageGroups, newData];
        },

        addAgeGroupFailure : (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // action to get all age groups

        getAgeGroupStart : (state) => {
            state.isFetching  = true;
        },

        getAgeGroupSuccess : (state, action) => {
            state.isFetching = false;
            state.ageGroups = action.payload;
        },

        getAgeGroupFailure : (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // action to delete age groups
        deleteAgeGroupStart : (state) => {
            state.isFetching = true;
        },

        deleteAgeGroupSuccess : (state, action) => {
            state.isFetching = false;
            state.ageGroups.splice(
                state.ageGroups.findIndex((item) => item._id === action.payload), 1
            );
        },
        deleteAgeGroupFailure : (state) => {
            state.isFetching = false;
            state.error = true
        },
    }
});

export const {addAgeGroupStart, addAgeGroupSuccess, addAgeGroupFailure, getAgeGroupStart, getAgeGroupSuccess, getAgeGroupFailure, deleteAgeGroupStart, deleteAgeGroupSuccess, deleteAgeGroupFailure  } = ageGroupReducer.actions;
export default ageGroupReducer.reducer;