// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attendance-main-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin-top: -60px;
}

.attendance-wrapper{
    padding: 50px;
    background: #fff;
    box-shadow: 0px 0px 27px -9px #ccc;
}`, "",{"version":3,"sources":["webpack://./src/pages/D2C/student/attendance/Attendance/AttendanceTable.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,kCAAkC;AACtC","sourcesContent":[".attendance-main-container{\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    margin-top: -60px;\n}\n\n.attendance-wrapper{\n    padding: 50px;\n    background: #fff;\n    box-shadow: 0px 0px 27px -9px #ccc;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
