import React, { useEffect, useState } from 'react';
import './AdminTable.css';
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, getAllUsers } from '../../../redux/apiCalls';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { addRowIndex } from '../../../config';
import CircleLoader from '../../../components/circleLoader/CircleLoader';

const AdminTable = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        getAllUsers(dispatch);
    }, [dispatch]);

    const isFetching = useSelector((state) => state.allUsers.isFetching);
    const users = useSelector((state) => state.allUsers.users);
    const admins = users && users.filter((item) => item.isMeritusAdmin === true);
    // let admins;

    // Add index numbers to the admins array
    const adminsWithIndex = admins?.map((admin, index) => ({
        ...admin,
        index: index + 1, // Add 1 to the index to start from 1 instead of 0
    }));

    // const [adminWithIndex, setAdminsWithIndex] = useState(null);

    // useEffect(() => {
    //     setAdminsWithIndex(addRowIndex(admins));
    // }, [admins]);

    // useEffect(() => {
    //     if(users){
    //         setAdmins(users.filter((item) => item.isMeritusAdmin === true));
    //     }
    // },[users])


    // const handleDelete = (adminId) => {
    //     deleteAdmin(adminId, dispatch);
    //     setDeletePopup(false);
    // }
    const handleDelete = (adminId) => {
        deleteUser(adminId, dispatch);
        setDeletePopup(false);
    }

    const [deletePopup, setDeletePopup] = useState(false);
    const [deletingItem, setDeletingItem] = useState(null);

    const handlePopup = (id) => {
        setDeletePopup(true);
        setDeletingItem(id);
    };

    const handlePopupCancel = () => {
        setDeletePopup(false)
    }

    const columns = [
        { field: 'index', headerName: '#', width: 50 },
        {
            field: 'firstName',
            headerName: 'Name',
            width: 100,
            editable: true,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 200,
            editable: true,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 150,
        },
        // {
        //     field: 'Level',
        //     headerName: 'Level',
        //     description: 'This column has a value getter and is not sortable.',
        //     sortable: false,
        //     width: 150,
        // },
        {
            field: 'Action',
            headerName: 'Action',
            sortable: false,
            width: 100,
            renderCell: (params) => {

                return (
                    <>
                        <Link to={'/edit-admin/' + params.id}>
                            <button className='btn btn-primary studentListEdit'>Edit</button>
                        </Link>
                        {/* <DeleteOutline className='studentListDelete text-danger' /> */}
                    </>
                )
            }
        },
        {
            field: 'delete',
            headerName: 'Delete',
            sortable: false,
            width: 100,
            renderCell: (params) => {
                const adminId = params.row._id;
                return (
                    <>
                        <DeleteOutline onClick={() => handlePopup(adminId)} className='courseListDelete text-danger' />
                    </>
                )
            }
        },
    ];

    if (isFetching) {
        return (
            <div className='loader-wrapper'>
                <CircleLoader />
            </div>
        )
    }

    return (
        <div className='studentTableWrapper py-5'>
            <div className="row">
                <div className="col-12">
                    <div className="tableButtonWrapper d-flex justify-content-between mb-3">
                        <h2>Admins</h2>
                        <Link to='/new-admin'>
                            <button className='custom-btn purple-bg'>New Admin <AddCircleOutlineOutlinedIcon className='newStudentIcon' /></button>
                        </Link>

                    </div>
                </div>
            </div>

            {
                deletePopup && (
                    <div className="deletePopupAlertWrapper">
                        <div className="deletePopupAlert">
                            <WarningAmberIcon style={{ color: "var(--orange)", fontSize: "40px" }} />
                            <h4>Warning</h4>
                            <div className="deleteAlertTextWrapper text-center">
                                <p>This admin will be deleted permanently.</p>
                                <p>Are you sure?</p>
                            </div>

                            <div className="deleteBtnsWrapper">
                                <button className='btn' onClick={handlePopupCancel} style={{ background: "var(--green)", color: "var(--white)" }}>Cancel</button>
                                <button className='btn' onClick={() => handleDelete(deletingItem)} style={{ background: "var(--red)", color: "var(--white)" }}>Delete</button>
                            </div>
                        </div>
                    </div>
                )
            }

            {
                admins && (
                    <DataGrid
                        rows={adminsWithIndex}
                        getRowId={(rows) => rows._id}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[10]}
                        disableRowSelectionOnClick
                    />
                )
            }


        </div>
    )
}

export default AdminTable
